import { useCallback } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useQueryClient } from "react-query";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import { InspectionCounterSKU } from "hooks/receiving/useSKUCountingForInspection";

import Styled from "./index.styles";

export default function useCompleteInspection({
  locationType,
  receivingId,
  counterInProgress,
  resetAfterComplete,
}: {
  locationType: "single" | "multi";
  receivingId: number;
  counterInProgress: UseCounterDataValue<InspectionCounterSKU> | undefined;
  resetAfterComplete: () => void;
}) {
  const queryClient = useQueryClient();

  const {
    mutate: setItemInspectionDone,
    ResponseHandler: ResponseHandlerOfSetItemInspectionDone,
  } = RECEIVING_QUERY.useSetItemInspectionDone({ locationType });

  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const canComplete = !!counterInProgress?.current;

  const complete = useCallback(() => {
    if (!counterInProgress || !counterInProgress.current) return;

    setItemInspectionDone(
      {
        pathParams: {
          receivingId,
        },
        inspectedItems: [
          {
            skuId: counterInProgress.skuId,
            inspectingId: counterInProgress.inspectingId,
            actualQty: counterInProgress.current,
          },
        ],
      },
      {
        onSuccess: () => {
          setVisibleConfirmModal(false);
          resetAfterComplete();

          queryClient.invalidateQueries(
            RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({ receivingId })
          );
        },
      }
    );
  }, [
    counterInProgress,
    queryClient,
    receivingId,
    resetAfterComplete,
    setItemInspectionDone,
  ]);

  const handleInspectionComplete = useCallback(() => {
    if (!counterInProgress || !counterInProgress.current) return;

    const countingIsLessThanMax =
      counterInProgress.current < counterInProgress.max;

    if (countingIsLessThanMax) {
      setVisibleConfirmModal(true);
      return;
    }

    complete();
  }, [complete, counterInProgress]);

  const ConfirmModal = useMemo(() => {
    if (!visibleConfirmModal || !counterInProgress) return null;

    return (
      <Modal
        active={true}
        uiType="content"
        title={`${getFormattedSingleSkuId(counterInProgress.skuId)}(SKU ID)`}
        body={
          <Styled.completeInspectionConfirmModalBody>
            <div className="counting">
              <span className="strong">{counterInProgress.current}</span> /{" "}
              <span>{counterInProgress.max}</span>
            </div>

            <div className="message">이대로 완료하시겠습니까?</div>
          </Styled.completeInspectionConfirmModalBody>
        }
        actionPositive={{
          label: "예",
          handleClick: complete,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: () => setVisibleConfirmModal(false),
        }}
      />
    );
  }, [complete, counterInProgress, visibleConfirmModal]);

  return {
    canComplete,
    handleInspectionComplete,

    ConfirmModal,

    ResponseHandlerOfSetItemInspectionDone,
  };
}
