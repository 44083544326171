import Styled from "./index.styles";

export type BorderType = "filled" | "stroked";

export default function Badge({
  borderType,
  label,
  className,
}: {
  borderType: BorderType;
  label: string;
  className?: string;
}) {
  return (
    <Styled.container
      borderType={borderType}
      className={`${className ? className : ""} badge`}
    >
      {label}
    </Styled.container>
  );
}
