import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const tileOfAddedItem = styled.div`
  ${setFontStyle("SubHead3", "Bold")};
  color: ${COLOR.secondaryDeepBlue};

  > .text-button {
    margin-left: 8px;
  }
`;

const itemToMove = styled.div<{ isAddedItem: boolean }>`
  margin-bottom: ${({ isAddedItem }) => (isAddedItem ? "8px" : "")};
  display: flex;
  flex-direction: column;

  > .text-button {
    margin: 8px 0;
    align-self: flex-end;
  }
`;

export default {
  tileOfAddedItem,
  itemToMove,
};
