import React, { useEffect, useRef } from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { getTrimmedValue } from "@sellernote/_shared/src/utils/common/string";
import { isWhiteSpace } from "@sellernote/_shared/src/utils/common/validation";

import Styled from "./index.styles";

export default function InputTextArea({
  label,
  placeholder,
  value,
  setValue,
  width,
  height,
  disabled,
  isAutoResize,
  isValidated,
  className,
}: {
  label?: string;
  placeholder?: string;
  value?: string;
  setValue: (val: string) => void;
  width?: number;
  height?: number;
  disabled?: boolean;
  isAutoResize?: boolean; // 개행 시 height가 자동으로 늘어남
  isValidated?: boolean;
  className?: string;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef === null || textareaRef.current === null) {
      return;
    }
    textareaRef.current.style.height = "44px";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  }, []);

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    // eslint-disable-next-line prefer-const
    let val = e.target.value;

    if (isWhiteSpace(val)) {
      return;
    }

    setValue(val);
  }

  function handleResizeHeight() {
    if (textareaRef === null || textareaRef.current === null) {
      return;
    }
    textareaRef.current.style.height = "44px";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  }

  return (
    <Styled.container
      className={`${className ? className : ""} input-text-area`}
      width={width}
      height={height}
    >
      {label && <div className="label">{label}</div>}

      <textarea
        placeholder={placeholder || "입력해주세요."}
        value={value}
        onChange={handleChange}
        readOnly={disabled}
        onBlur={() => {
          setValue && setValue(getTrimmedValue(value));
        }}
        onInput={handleResizeHeight}
        ref={isAutoResize ? textareaRef : null}
      />

      {isAutoResize && isValidated && (
        <Styled.postFix>
          <Icon type="check" size={1} color={COLOR.pointSuccess} />
        </Styled.postFix>
      )}
    </Styled.container>
  );
}
