import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";

import useScan from "hooks/common/useScan";

import { CompletedList, WorkingLocation } from "./usePickingInvoice";
import usePickingMessageModal from "./usePickingMessageModal";

export default function useScanPickingLocation({
  selectedScanButtonType,
  workingLocation,
  setWorkingLocation,
  SKUInfoForScanning,
  completedList,
}: {
  selectedScanButtonType: ScanType | undefined;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  completedList: React.MutableRefObject<CompletedList>;
}) {
  const isActiveScanLocationButton =
    useRecoilValue(FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE)
      .shippingId !== 0;
  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const { MessageModal, modalOpenHandler } = usePickingMessageModal();

  const locationIdListInPickingList = useMemo(
    () => SKUInfoForScanning.map((skuInfo) => skuInfo.locationId),
    [SKUInfoForScanning]
  );

  const setLocationScanningResult = useCallback(
    ({ id, barcode }: { id: number; barcode: string }) => {
      setWorkingLocation({ id, barcode });
    },
    [setWorkingLocation]
  );

  const checkIsCorrectLocation = useCallback(
    ({ scannedBarcode }: { scannedBarcode: string }) => {
      const scannedLocationId =
        locationListOfWarehouse.find(
          (location) => location.barCode === scannedBarcode
        )?.id ?? 0;

      // 자신에게 할당된 위치(송장 스캔 시 등록되는 리스트 위치)가 아닌 경우
      // 해당 위치(A1-1)에 피킹할 목록이 없습니다.
      if (!locationIdListInPickingList.includes(scannedLocationId)) {
        modalOpenHandler.openNoLocationInInvoiceMessage(scannedBarcode);
        return;
      }

      // 이미 완료된 위치를 스캔한 경우
      // 해당 위치(A1-1)에서의 피킹은 이미 완료되었습니다.
      if (completedList.current.locationIdList.has(scannedLocationId)) {
        modalOpenHandler.openCompletedLocationScanMessage(scannedBarcode);
        return;
      }

      // 현재 위치가 완료되기 전에 다른 위치를 스캔 한 경우
      // 올바른 SKU ID를 스캔해주세요.
      const SKUInfoByWorkingLocationId = SKUInfoForScanning.filter(
        (v) => v.locationId === workingLocation?.id
      );
      const isStartWorkingInWorkingLocation = SKUInfoByWorkingLocationId.some(
        (v) => v.currentQty > 0
      );
      const isAllCompletedWorkingLocation = SKUInfoByWorkingLocationId.every(
        (v) => v.currentQty === v.quantity
      );
      if (isStartWorkingInWorkingLocation && !isAllCompletedWorkingLocation) {
        modalOpenHandler.openCorrectSKUIdScanMessage();
        return;
      }

      setLocationScanningResult({
        id: scannedLocationId,
        barcode: scannedBarcode,
      });
    },
    [
      SKUInfoForScanning,
      completedList,
      locationIdListInPickingList,
      locationListOfWarehouse,
      modalOpenHandler,
      setLocationScanningResult,
      workingLocation?.id,
    ]
  );

  const scanLocation = useCallback(
    (scannedBarcode: string) => {
      if (selectedScanButtonType !== "location") {
        return;
      }

      checkIsCorrectLocation({ scannedBarcode });
    },
    [checkIsCorrectLocation, selectedScanButtonType]
  );
  useScan(scanLocation);

  return {
    isActiveScanLocationButton,
    ScanLocationMessageModal: MessageModal,
  };
}
