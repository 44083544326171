import styled from "styled-components";

const inputMixQtyModalBody = styled.div`
  > .table {
    margin-bottom: 24px;

    > table {
      width: 100%;
    }
  }
`;

const skuId = styled.div`
  text-align: left;
`;

export default {
  inputMixQtyModalBody,
  skuId,
};
