import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import {
  PickingSKUInfoForScanning,
  ReportProblem,
} from "@sellernote/_shared/src/types/fulfillment/shipping";

import { ProblemList } from "pages/shipping/picking/ReportProblemButton/ReportProblemModal";

import usePickingMessageModal from "./usePickingMessageModal";

export default function useCompleteReportingPickingProblem({
  SKUInfoForScanning,
  problemList,
  resetPickingState,
  handleReportProblemModalClose,
}: {
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  problemList: ProblemList;
  resetPickingState: () => void;
  handleReportProblemModalClose: () => void;
}) {
  const currentUserId = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  )?.id;

  const { invoiceNo, shippingId } = useRecoilValue(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );

  const {
    mutate: interpretBarcode,
    ResponseHandler: ResponseHandlerOfCheckingCancelingInvoice,
  } = SCAN_QUERY.useInterpretBarcode<"shipping">({
    isToCheckForCanceling: true,
  });

  const {
    mutate: reportShippingProblem,
    ResponseHandler: ResponseHandlerReportingShippingProblem,
  } = SHIPPING_QUERY.useReportShippingProblem({
    handleReportProblemModalClose,
    resetPickingState,
    wmsStatus: "picking",
  });

  const {
    MessageModal,
    modalOpenHandler,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  } = usePickingMessageModal();

  const isDisabledCompleteButton = useMemo(() => {
    const problemListValues = Object.values(problemList);
    const selectedProblemKindList = problemListValues.filter(
      (v) => v.kind?.value !== undefined
    );

    if (selectedProblemKindList.length === 0) {
      return true;
    } else {
      const isAllSelectedProblem =
        selectedProblemKindList.length === problemListValues.length;

      // directInput이 있는 경우 모두 값이 들어있어야 활성화
      let isAllCompletedDirectInput = true;
      const directInputList = selectedProblemKindList.filter(
        (v) => v.kind?.value === "directInput"
      );
      const hasDirectInputList = directInputList.length > 0;
      if (hasDirectInputList) {
        isAllCompletedDirectInput = directInputList.every(
          (v) => Number(v.directInput?.length) > 0
        );
      }
      return !(isAllSelectedProblem && isAllCompletedDirectInput);
    }
  }, [problemList]);

  const handleReportProblemConfirm = useCallback(() => {
    const problemItems: ReportProblem[] = Object.entries(problemList).map(
      ([id, v]) => {
        const SKUInfoToReport = SKUInfoForScanning.find(
          (info) => info.id === Number(id)
        );

        return {
          skuId: SKUInfoToReport?.skuId ?? 0,
          locationId: SKUInfoToReport?.locationId ?? 0,
          workerId: currentUserId ?? 0,
          wmsStatus: "picking",
          kind: v.kind?.value ?? "none",
          directInput: v.directInput,
        };
      }
    );

    reportShippingProblem(problemItems);
  }, [problemList, reportShippingProblem, SKUInfoForScanning, currentUserId]);

  const checkIsCanceledInvoice = useCallback(() => {
    interpretBarcode(
      { type: "shipping", barCode: invoiceNo },
      {
        onSuccess: ({ data: invoiceData }) => {
          if (invoiceData.shippingStatus === "cancel") {
            const restockItems = SKUInfoForScanning.map((v) => ({
              itemId: v.id,
              restockQty: v.currentQty,
            }));

            modalOpenHandler.openCanceledInvoiceInPickingMessage({
              invoiceNo: invoiceData.invoiceNo,
              shippingId,
              restockItems,
            });
            return;
          }

          handleReportProblemConfirm();
        },
      }
    );
  }, [
    SKUInfoForScanning,
    handleReportProblemConfirm,
    interpretBarcode,
    modalOpenHandler,
    invoiceNo,
    shippingId,
  ]);

  return {
    isDisabledCompleteButton,
    checkIsCanceledInvoice,
    CanceledInvoiceModal: MessageModal,

    ResponseHandlerReportingShippingProblem,
    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  };
}
