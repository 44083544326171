import { useLayoutEffect, useState } from "react";
import styled, { css } from "styled-components";

import { COLOR } from "./colors";

type InteractionTheme = "primary" | "secondary" | "critical" | "tertiary";

type BorderTypeConfiguration = "filled" | "outlined";

const setInteractionStyle = (
  theme: InteractionTheme,
  borderType: BorderTypeConfiguration
) => {
  return css`
    ${defaultStyle};

    > * {
      ${setInteractionTheme(theme, borderType)};
    }
  `;
};

const defaultStyle = css`
  @keyframes ripple {
    0% {
      transform: scale(0);
      opacity: 100%;
    }

    100% {
      transform: scale(4);
      opacity: 0;
    }
  }

  > * {
    position: relative;
    transition: background 400ms;
    overflow: hidden;
  }

  > *::after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: scale(0);
    transform-origin: center center;
    z-index: 1;
  }

  &.active > *::after {
    transform: scale(0);
    animation: ripple 850ms ease-out;
  }
`;

const setInteractionTheme = (
  theme: InteractionTheme,
  borderType: BorderTypeConfiguration
) => {
  switch (theme) {
    case "primary":
      return css`
        background-color: ${COLOR.primary_600};
        color: ${COLOR.wh};
        border: 0;

        &:hover:enabled {
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.08),
            rgba(255, 255, 255, 0.08)
          );
          background-color: ${COLOR.primary_600};
        }

        &:focus:enabled {
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.16),
            rgba(255, 255, 255, 0.16)
          );
          background-color: ${COLOR.primary_600};
        }

        &::after {
          background-color: rgba(255, 255, 255, 0.1);
        }
      `;

    case "secondary":
      return css`
        background-color: ${COLOR.primary_50};
        color: ${COLOR.primary_600};
        border: ${borderType === "outlined"
          ? `1px solid ${COLOR.primary_300}`
          : "0"};

        &:hover:enabled {
          background: linear-gradient(
            0deg,
            rgba(84, 130, 214, 0.08),
            rgba(84, 130, 214, 0.08)
          );
          background-color: ${COLOR.primary_50};
        }

        &:focus:enabled {
          background: linear-gradient(
            0deg,
            rgba(84, 130, 214, 0.16),
            rgba(84, 130, 214, 0.16)
          );
          background-color: ${COLOR.primary_50};
        }

        &::after {
          background-color: rgba(84, 130, 214, 0.1);
        }
      `;

    case "critical": {
      if (borderType === "filled")
        return css`
          background-color: ${COLOR.error_400};
          color: ${COLOR.wh};
          border: 0;

          &:hover:enabled {
            background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.16),
              rgba(255, 255, 255, 0.16)
            );
            background-color: ${COLOR.error_400};
          }

          &:focus:enabled {
            background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.24),
              rgba(255, 255, 255, 0.24)
            );
            background-color: ${COLOR.error_400};
          }

          &::after {
            background-color: rgba(255, 255, 255, 0.24);
          }
        `;
      else
        return css`
          background-color: ${COLOR.wh};
          color: ${COLOR.error_300};
          border: 1px solid ${COLOR.error_300};

          &:hover:enabled {
            background: linear-gradient(
              0deg,
              rgba(255, 0, 46, 0.08),
              rgba(255, 0, 46, 0.08)
            );
            background-color: ${COLOR.wh};
          }

          &:focus:enabled {
            background: linear-gradient(
              0deg,
              rgba(255, 0, 46, 0.16),
              rgba(255, 0, 46, 0.16)
            );
            background-color: ${COLOR.wh};
          }

          &::after {
            background-color: rgba(255, 0, 46, 0.1);
          }
        `;
    }

    case "tertiary": {
      if (borderType === "filled")
        return css`
          background-color: ${COLOR.grayScale_200};
          color: ${COLOR.grayScale_600};
          border: 0;

          &:hover:enabled {
            background: linear-gradient(
              0deg,
              rgba(141, 148, 160, 0.04),
              rgba(141, 148, 160, 0.04)
            );
            background-color: ${COLOR.grayScale_200};
          }

          &:focus:enabled {
            background: linear-gradient(
              0deg,
              rgba(141, 148, 160, 0.08),
              rgba(141, 148, 160, 0.08)
            );
            background-color: ${COLOR.grayScale_200};
          }

          &::after {
            background-color: rgba(141, 148, 160, 0.1);
          }
        `;
      else
        return css`
          background-color: ${COLOR.wh};
          color: ${COLOR.grayScale_700};
          border: 1px solid ${COLOR.grayScale_300};

          &:hover:enabled {
            background: linear-gradient(
              0deg,
              rgba(141, 148, 160, 0.04),
              rgba(141, 148, 160, 0.04)
            );
            background-color: ${COLOR.wh};
          }

          &:focus:enabled {
            background: linear-gradient(
              0deg,
              rgba(141, 148, 160, 0.08),
              rgba(141, 148, 160, 0.08)
            );
            background-color: ${COLOR.wh};
          }

          &::after {
            background-color: rgba(141, 148, 160, 0.1);
          }
        `;
    }
  }
};

const WrapperBlock = styled.div<{
  theme: InteractionTheme;
  borderType: BorderTypeConfiguration;
}>`
  ${({ theme, borderType }) => css`
    ${setInteractionStyle(theme, borderType)};
  `};
`;

/**
 * interaction style (figma의 states) 이 필요한 컴포넌트를 감싸면 theme와 borderType 별로 hover, focus, pressed 스타일이 반영된다.
 */
const InteractionStyleWrapper = ({
  theme,
  borderType,
  children,
}: {
  theme: InteractionTheme;
  borderType: BorderTypeConfiguration;
  children: React.ReactNode;
}) => {
  const [activeButton, setActiveButton] = useState(false);

  // 클릭(active 효과) 이후, active class를 제거하여 반복 클릭 animation 시작을 위한 종료 처리.
  useLayoutEffect(() => {
    let timeout: NodeJS.Timeout;

    if (activeButton) {
      timeout = setTimeout(() => {
        setActiveButton(false);
      }, 400);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [activeButton]);

  return (
    <WrapperBlock
      theme={theme}
      borderType={borderType}
      className={activeButton ? `active` : ``}
      onClick={(e) => {
        setActiveButton(true);
      }}
    >
      {children}
    </WrapperBlock>
  );
};

export default InteractionStyleWrapper;

export type { InteractionTheme, BorderTypeConfiguration };
