import React from "react";

import useBarcode, {
  BarcodeProps,
} from "@sellernote/_shared/src/headlessComponents/useBarcode";

export default function Barcode({ value, options, className }: BarcodeProps) {
  const { barcodeRef } = useBarcode({ value, options, className });

  return (
    <img
      className={`${className ? className : ""} barcode`}
      ref={barcodeRef}
      alt={value}
    />
  );
}
