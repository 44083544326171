import { memo } from "react";
import { useHistory } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function SelectMultiLocation({
  type,
  canSelectMultiLocation,
  skuId,
  openConfirmModal,
}: {
  type: "inspection" | "warehousing";
  canSelectMultiLocation: boolean;
  skuId: number;
  openConfirmModal: () => void;
}) {
  const history = useHistory();

  return (
    // <ConfirmWrapper
    //   confirmMessageTitle={`S${skuId}(SKU ID)`}
    //   confirmMessageBody={`분할${
    //     type === "inspection" ? "검수" : "입고"
    //   }를 진행하겠습니까?`}
    //   confirmModalUiType="content"
    //   disabled={!canSelectMultiLocation}
    // >
    <Button
      label={`분할${type === "inspection" ? "검수" : "입고"}`}
      size="small"
      theme="tertiary"
      handleClick={openConfirmModal}
      // handleClick={() =>
      //   history.push(`${history.location?.pathname}/multi-location/${skuId}`)
      // }
      disabled={!canSelectMultiLocation}
    />
    // </ConfirmWrapper>
  );
}

export default memo(SelectMultiLocation);
