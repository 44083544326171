import React from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import Styled from "./index.styles";

export interface ChipWithClearProps {
  label: string;
  /**
   * default는 dark
   */
  colorTheme?: ChipWithClearColorTheme;
  handleClear?: () => void;
  className?: string;
}

export type ChipWithClearColorTheme = "light" | "dark";

export default function ChipWithClear({
  label,
  colorTheme,
  handleClear,
  className,
}: ChipWithClearProps) {
  return (
    <Styled.container
      className={`${className ? className : ""} chip-with-clear`}
      colorTheme={colorTheme}
      {...(handleClear ? { onClick: handleClear } : {})}
    >
      <div className="label">{label}</div>

      {handleClear && (
        <Icon type="circleFilledCancel" size={1} color={COLOR.grayScale_600} />
      )}
    </Styled.container>
  );
}
