import React from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { IconType } from "@sellernote/_shared/src/componentsToMoveToV1/Icon/DATA";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import Styled from "./index.styles";

export type InputRadioDirection = "row" | "column";

export type InputRadioOptionItem<T> = {
  label: React.ReactNode;
  description?: React.ReactNode;
  disabled?: boolean;
  value: T;
};

function getColor(selected: boolean, disabled?: boolean): string {
  let color = COLOR.grayScale_400;

  if (selected) {
    color = COLOR.primaryBlue;
  }

  if (disabled) {
    if (selected) {
      color = COLOR.primaryBlue_40;
    } else {
      color = COLOR.grayScale_300;
    }
  }

  return color;
}

function getIconType(selected: boolean, disabled?: boolean): IconType {
  let iconType: IconType = "radio";

  if (selected) {
    iconType = "radioSelected";
  }

  if (disabled) {
    // TODO: radio Disabled용 아이콘이 필요함
  }

  return iconType;
}

export default function InputRadio<T>({
  direction,
  optionList,
  selectedValue,
  selectCallback,
  deleteCallback,
  disabled,
  hasStroke = true,
  className,
}: {
  direction: InputRadioDirection;
  optionList: InputRadioOptionItem<T>[];
  selectedValue?: T;
  selectCallback?: (v: T) => void;
  deleteCallback?: (v: T) => void;
  disabled?: boolean;
  hasStroke?: boolean;
  className?: string;
}) {
  function handleSelect(v: any) {
    if (disabled) {
      return;
    }

    if (selectCallback) {
      selectCallback(v);
    }
  }

  function handleDelete(v: any) {
    if (deleteCallback) {
      deleteCallback(v);
    }
  }

  return (
    <Styled.container
      className={`${className ? className : ""} input-radio`}
      direction={direction}
    >
      {optionList.map((v, i) => {
        const isOptionItemDisabled =
          typeof v.disabled === "boolean" ? v.disabled : disabled;
        const selected = v.value === selectedValue;
        const color = getColor(selected, isOptionItemDisabled);

        return (
          <Styled.option
            key={i}
            direction={direction}
            selected={selected}
            onClick={() => {
              if (!v.disabled) {
                handleSelect(v.value);
              }
            }}
            color={color}
            disabled={isOptionItemDisabled}
            hasDescription={Boolean(v.description)}
            hasStroke={hasStroke}
            className="input-radio-item"
          >
            <div className="title">
              <Icon
                type={getIconType(selected, disabled)}
                color={color}
                size={1}
              />

              <div className="label">{v.label}</div>
            </div>

            {v.description && (
              <div className="description">{v.description}</div>
            )}

            {deleteCallback && (
              <Icon
                type="clear"
                color={COLOR.grayScale_600}
                size={1}
                onClick={() => handleDelete(v.value)}
              />
            )}
          </Styled.option>
        );
      })}
    </Styled.container>
  );
}
