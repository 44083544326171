import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useResetRecoilState } from "recoil";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { ModalFooterActions } from "@sellernote/_shared/src/headlessComponents/useModal";
import CANCELING_QUERY from "@sellernote/_shared/src/queries/fulfillment/CANCELING_QUERY";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import { RestockingItem } from "@sellernote/_shared/src/types/fulfillment/canceling";
import {
  getPickingModalInfo,
  PickingModalInfo,
} from "@sellernote/_shared/src/utils/fulfillment/shipping";

export default function usePickingMessageModal() {
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalInfo, setModalInfo] = useState<PickingModalInfo>();

  const history = useHistory();

  const MessageModal = useMemo(
    () => (
      <Modal
        active={visibleModal}
        uiType={modalInfo?.uiType || "titleOnly"}
        title={modalInfo?.title}
        body={modalInfo?.body}
        className={modalInfo?.className}
        actionPositive={
          modalInfo?.actionPositive || {
            label: "확인",
            handleClick: () => setVisibleModal(false),
          }
        }
        actionNegative={modalInfo?.actionNegative}
      />
    ),
    [modalInfo, visibleModal]
  );

  const openMessageModal = useCallback(
    (
      type: string,
      data?: string | number,
      action?: Pick<ModalFooterActions, "actionPositive" | "actionNegative">
    ) => {
      setVisibleModal(true);
      setModalInfo(getPickingModalInfo(type, data, action));
    },
    []
  );

  // 위치를 먼저 스캔해주세요.
  const openLocationScanFirstMessage = useCallback(() => {
    openMessageModal("locationScanFirst");
  }, [openMessageModal]);

  // todo - 기획 X) 해당 위치(A-1)에 피킹할 목록이 없습니다.
  const openNoLocationInInvoiceMessage = useCallback(
    (locationName: string) => {
      openMessageModal("noLocationInInvoice", locationName);
    },
    [openMessageModal]
  );

  // 1111은(는) 피킹목록에 없습니다.
  const openNoSKUInPickingListMessage = useCallback(
    (barcode: string) => {
      openMessageModal("noSKUInPickingList", barcode);
    },
    [openMessageModal]
  );

  // 해당 위치(A1-1)에 없는 상품입니다 (07/20 v15, 규칙3)
  const openNoSKUInCurrentLocationMessage = useCallback(
    (locationName: string) => {
      // currentLocationName.current!
      openMessageModal("noSKUInCurrentLocation", locationName);
    },
    [openMessageModal]
  );

  // 본 운송 건은 취소되었습니다.
  const openCanceledPickingInvoice = useCallback(
    (resetPickingState?: () => void) => {
      resetPickingState
        ? openMessageModal("canceledPickingInvoice", undefined, {
            actionPositive: {
              label: "확인",
              handleClick: () => {
                resetPickingState();
                setVisibleModal(false);
              },
            },
          })
        : openMessageModal("canceledPickingInvoice");
    },
    [openMessageModal]
  );

  // todo - 기획 X)  올바른 송장(QR)번호를 스캔해주세요.
  const openCorrectInvoiceScanMessage = useCallback(() => {
    openMessageModal("correctInvoicedScan");
  }, [openMessageModal]);

  // todo - 기획 X) 올바른 위치를 스캔해주세요.
  const openCorrectLocationScanMessage = useCallback(() => {
    openMessageModal("correctLocationScan");
  }, [openMessageModal]);

  // todo - 기획 X) 올바른 상품을 스캔해주세요.
  const openCorrectSKUIdScanMessage = useCallback(() => {
    openMessageModal("correctSKUIdScan");
  }, [openMessageModal]);

  //  todo - 기획 X) 11111(SKU ID) 최대 스캔 가능 수량을 초과하였습니다. 다시 확인해주세요.
  const openOverQuantityScanMessage = useCallback(
    (scannedSKUId: string) => {
      openMessageModal("overQuantityScan", scannedSKUId);
    },
    [openMessageModal]
  );

  // todo - 기획 X) 해당 위치(A1-1)에서의 피킹은 이미 완료되었습니다.
  const openCompletedLocationScanMessage = useCallback(
    (locationName: string) => {
      openMessageModal("completedLocation", locationName);
    },
    [openMessageModal]
  );

  // SKU ID 별 피킹 완료
  const openCompletedPickingBySKUIdMessage = useCallback(
    (scannedSKUId: string) => {
      openMessageModal("completedPickingBySKUId", scannedSKUId);
    },
    [openMessageModal]
  );

  // 234(송장번호) 피킹이 완료되었습니다.
  const openCompletedPickingInvoiceMessage = useCallback(
    (invoiceNo: string) => {
      openMessageModal("completedPickingInvoice", invoiceNo);
    },
    [openMessageModal]
  );

  const {
    mutate: setRestockByPickingQuantity,
    ResponseHandler: ResponseHandlerOfSettingRestockByPickingQuantity,
  } = CANCELING_QUERY.useSetRestockByPickingQuantity();
  const resetScannedPickingInvoice = useResetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );
  // 고객사의 요청에 의해 주문이 취소되었습니다. 재입고를 진행해주세요.
  const openCanceledInvoiceInPickingMessage = useCallback(
    ({
      invoiceNo,
      shippingId,
      restockItems,
    }: {
      invoiceNo: string;
      shippingId: number;
      restockItems: RestockingItem[];
    }) => {
      openMessageModal("canceledInvoiceInPicking", "", {
        actionPositive: {
          label: "바로가기 >",
          handleClick: () => {
            setRestockByPickingQuantity(
              {
                // TODO: pathParams를 사용하는 방식으로 수정
                _customPath: `/canceling/set/restock/${shippingId}`,
                restockItems,
              },
              {
                onSuccess: () => {
                  resetScannedPickingInvoice();

                  setVisibleModal(false);
                  history.push({
                    pathname: `/canceling/detail/${invoiceNo}`,
                    state: { from: "picking" },
                  });
                },
                onError: () => setVisibleModal(false),
              }
            );
          },
        },
      });
    },
    [
      history,
      openMessageModal,
      resetScannedPickingInvoice,
      setRestockByPickingQuantity,
    ]
  );

  return {
    MessageModal,
    modalOpenHandler: {
      openLocationScanFirstMessage,
      openNoLocationInInvoiceMessage,
      openNoSKUInPickingListMessage,
      openNoSKUInCurrentLocationMessage,
      openCanceledPickingInvoice,
      openCorrectInvoiceScanMessage,
      openCorrectLocationScanMessage,
      openCorrectSKUIdScanMessage,
      openOverQuantityScanMessage,
      openCompletedLocationScanMessage,
      openCompletedPickingBySKUIdMessage,
      openCompletedPickingInvoiceMessage,
      openCanceledInvoiceInPickingMessage,
    },
    // 커스텀할 일이 있을 경우에 사용
    openMessageModal,
    setVisibleModal,

    ResponseHandlerOfSettingRestockByPickingQuantity,
  };
}
