import { useCallback } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import { InspectionCounterSKU } from "hooks/returning/useSKUCountingForInspection";
import { returningActions } from "modules/returning";

import Styled from "./index.styles";

export default function useCompleteInspection({
  returningId,
  counterInProgress,
  resetAfterComplete,
}: {
  returningId: number;
  counterInProgress: UseCounterDataValue<InspectionCounterSKU> | undefined;
  resetAfterComplete: () => void;
}) {
  const dispatch = useDispatch();

  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const {
    mutate: setItemInspectionDone,
    ResponseHandler: ResponseHandlerOfSetItemInspectionDone,
  } = RETURNING_QUERY.useSetItemInspectionDone();

  const canComplete = !!counterInProgress?.current;

  const complete = useCallback(() => {
    if (!counterInProgress || !counterInProgress.current) return;

    setItemInspectionDone(
      {
        pathParams: { returningId },
        inspectedItems: [
          {
            skuId: counterInProgress.skuId,
            actualQty: counterInProgress.current,
          },
        ],
        actualQty: counterInProgress.current,
      },
      {
        onSuccess: () => {
          dispatch(
            returningActions.GET_DETAIL({
              id: returningId,
              _postSuccessCallback: () => {
                setVisibleConfirmModal(false);
                resetAfterComplete();
              },
            })
          );
        },
      }
    );
  }, [
    counterInProgress,
    setItemInspectionDone,
    returningId,
    dispatch,
    resetAfterComplete,
  ]);

  const handleCompleteInspectionClick = useCallback(() => {
    if (!counterInProgress || !counterInProgress.current) return;

    const countingIsLessThanMax =
      counterInProgress.current < counterInProgress.max;

    if (countingIsLessThanMax) {
      setVisibleConfirmModal(true);
      return;
    }

    complete();
  }, [complete, counterInProgress]);

  const ConfirmModal = useMemo(() => {
    if (!visibleConfirmModal || !counterInProgress) return null;

    return (
      <Modal
        active={true}
        uiType="content"
        title={`${getFormattedSingleSkuId(counterInProgress.skuId)}(SKU ID)`}
        body={
          <Styled.completeInspectionConfirmModalBody>
            <div className="counting">
              <span className="strong">{counterInProgress.current}</span> /{" "}
              <span>{counterInProgress.max}</span>
            </div>

            <div className="message">이대로 완료하시겠습니까?</div>
          </Styled.completeInspectionConfirmModalBody>
        }
        actionPositive={{
          label: "예",
          handleClick: complete,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: () => setVisibleConfirmModal(false),
        }}
      />
    );
  }, [complete, counterInProgress, visibleConfirmModal]);

  return {
    canComplete,
    handleCompleteInspectionClick,

    ConfirmModal,

    ResponseHandlerOfSetItemInspectionDone,
  };
}
