import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { SKU } from "@sellernote/_shared/src/types/fulfillment/sku";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

export default function SkuDetailModal({
  active,
  skuDetail,
  onClose,
}: {
  active: boolean;
  skuDetail: SKU | undefined;
  onClose: () => void;
}) {
  return (
    <Modal
      active={active}
      uiType="contentWithCustomBody"
      title={`SKU ID: ${getFormattedSingleSkuId(skuDetail?.id)}`}
      body={
        <>
          <ListItem
            type="withContentAlignRight"
            label="회사명"
            value={skuDetail?.team?.company}
          />
          <ListItem
            type="withContentAlignRight"
            label="상품명"
            value={skuDetail?.itemName}
          />
          <ListItem
            type="withContentAlignRight"
            label="판매자상품코드"
            value={skuDetail?.productCode}
          />
          <ListItem
            type="withContentAlignRight"
            label="옵션관리코드"
            value={skuDetail?.managementCode}
          />
          <ListItem
            type="withContentAlignRight"
            label="상품바코드"
            value={skuDetail?.barCode}
          />
        </>
      }
      onClose={onClose}
    />
  );
}
