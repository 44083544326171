import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { RecoilRoot } from "recoil";

import "@sellernote/_shared/src/i18n/initI18nForCRA";

import { IS_UNDER_PRODUCTION } from "@sellernote/_shared/src/constants";
import { initSentryForCRA } from "@sellernote/_shared/src/services/sentry/sentryForCRA";
import { initVConsole } from "@sellernote/_shared/src/services/vConsole";

import store from "./store";

import App from "./App";

if (!IS_UNDER_PRODUCTION) {
  initVConsole();
}

initSentryForCRA();

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Provider store={store}>
        <App />
      </Provider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);
