import { MutableRefObject, RefObject, useMemo, useState } from "react";
import React from "react";

import { noop } from "@sellernote/_shared/src/utils/common/etc";

import SelectButton from "../../form/SelectButton";
import Styled from "./index.styles";

type TableRow<CellKey extends string, RowKey extends string | number> = {
  [K in CellKey]: React.ReactNode;
} & {
  /**
   * row를 식별하는 unique한 key (id 등)
   */
  rowKey: RowKey;
  onRowClick?: () => void;
  /**
   * row의 배경색을 지정
   */
  rowBackgroundColor?: string;
};

type SelectableTableRow<
  CellKey extends string,
  RowKey extends string | number
> = TableRow<CellKey, RowKey> & {
  isSelected?: boolean;
  isNotSelectable?: boolean;
  /**
   * Row 선택/체크 핸들러
   * - onRowClick과 구분됨에 유의
   */
  onRowSelect: () => void;
};

type TableCellHorizontalAlign = "center" | "left" | "right";

type TableCellVerticalAlign = "center" | "top" | "bottom";

interface TableCellInfo<CellKey extends string> {
  cellKey: CellKey;
  label: React.ReactNode;
  /**
   * 고정 width를 사용하고 싶을때 사용.
   * 미입력시 1fr이 적용됨
   */
  width?: string;
  /**
   * 반응형으로 적용되는 비율을 사용하고 싶을때 사용.
   * width와 동시에 입력하면 ratio는 무시되고 width가 사용됨
   * 미입력시 1이 적용됨 (grid 의 {n}fr의 형태로 사용됨)
   */
  minWidth?: string;
  /**
   * 반응형으로 작업시 줄어들 수 있는 최소 너비를 지정할 때 사용
   * ratio 미입력시 1이 적용됨 (grid 의 minmax(minWidth, {ratio}fr)의 형태로 사용됨)
   */
  ratio?: number;
  /**
   * 미입력시 'left'
   */
  horizontalAlign?: TableCellHorizontalAlign;
  /**
   * 미입력시 'top'
   */
  verticalAlign?: TableCellVerticalAlign;
  /**
   * 'TableHeaderFilter' 컴포넌트를 전달하여 사용
   */
  HeaderFilter?: React.ReactNode;
  /**
   * 'TableHeaderSort' 컴포넌트를 전달하여 사용
   */
  HeaderSort?: React.ReactNode;
  /**
   * 'TableHeaderTooltip' 컴포넌트를 전달하여 사용
   */
  HeaderTooltip?: React.ReactNode;
}

interface CommonTableProps<CellKey extends string> {
  /**
   * Table Cell에 대한 Meta 정보들
   * 배열의 순서가 중요함 (순서대로 데이터 표시함)
   */
  cellInfos: TableCellInfo<CellKey>[];
  className?: string;
  /**
   * string 타입이 아닌 경우 스타일을 작성한 jsx를 전달해야 합니다.
   *
   * 기본값 : "데이터가 없습니다"
   */
  labelForNoData?: React.ReactNode;
  isStickyHeader?: boolean;
  containerRef?: RefObject<HTMLDivElement>;
  tableDataCellRef?: MutableRefObject<(HTMLTableCellElement | null)[]>;
  tableHeaderRef?: MutableRefObject<HTMLTableSectionElement | null>;
}

type BasicTableProps<
  CellKey extends string,
  RowKey extends string | number
> = CommonTableProps<CellKey> & {
  selectableType?: undefined;
  rows: TableRow<CellKey, RowKey>[];
};

type SelectableTableProps<
  CellKey extends string,
  RowKey extends string | number
> = CommonTableProps<CellKey> & {
  /**
   * Row별 선택/체크이 필요할때 사용
   * - 단순히 cell에 체크박스 컴포넌트를 전달하는 방식으로 하려했으나(sds-v1의 방식),
   * (디자이너의 요청) 클릭 이벤트 영역이 특정 Cell 전체여야 한다고 하여 테이블 자체에서 구현.
   */
  selectableType: "single" | "multi";
  allSelectInfo?: {
    onAllSelect: () => void;
    isAllSelected: boolean;
  };
  rows: SelectableTableRow<CellKey, RowKey>[];
};

type TableProps<CellKey extends string, RowKey extends string | number> =
  | BasicTableProps<CellKey, RowKey>
  | SelectableTableProps<CellKey, RowKey>;

export type {
  SelectableTableRow,
  TableCellInfo,
  TableRow,
  TableCellHorizontalAlign,
  TableCellVerticalAlign,
};

export default function Table<
  CellKey extends string,
  RowKey extends string | number
>({
  cellInfos: cellInfoList,
  rows,
  className,
  containerRef,
  tableDataCellRef,
  tableHeaderRef,
  labelForNoData = "데이터가 없습니다",
  isStickyHeader = false,
  ...propsByType
}: TableProps<CellKey, RowKey>) {
  /**
   * 원활한 grid사용을 위해 tr을 'display: contents'로 컨테이너효과를 없앴기때문에 css hover지원이 안 므로 JS로 row hover효과를 구현
   */
  const [hoveredRowKey, setHoveredRowKey] = useState<RowKey>();

  const cellGridSizeList = useMemo(() => {
    const sizes = cellInfoList.map((cellInfo) => {
      return {
        width: cellInfo.width,
        ratio: cellInfo.ratio,
        minWidth: cellInfo.minWidth,
      };
    });

    // 선택가능한 테이블인 경우, 첫 columSize에 SelectUI용 width를 추가
    return propsByType.selectableType ? [{ width: "44px" }, ...sizes] : sizes;
  }, [cellInfoList, propsByType.selectableType]);

  return (
    <Styled.container
      ref={containerRef}
      className={`${className ? className : ""} table`}
    >
      <Styled.table>
        <Styled.tableHeader
          isStickyHeader={isStickyHeader}
          cellGridSizeList={cellGridSizeList}
          ref={tableHeaderRef}
        >
          <Styled.tableRow>
            {/* 선택 가능 한 테이블인 경우, headCell에 칼럼을 추가
            (전체 체크박스가 필요하면 allSelectInfo props 활용. 그 외에는 빈 칼럼) */}
            {propsByType.selectableType &&
              (propsByType.allSelectInfo ? (
                <Styled.tableHeaderCell key={"headCellOfSelectableType"}>
                  <SelectButton
                    uiType="checkboxOutlined"
                    size="default"
                    isLabelHidden
                    label="select button"
                    onSelect={() => {
                      if (propsByType.allSelectInfo?.onAllSelect) {
                        propsByType.allSelectInfo.onAllSelect();
                      }
                    }}
                    selected={propsByType.allSelectInfo.isAllSelected}
                    value=""
                  />
                </Styled.tableHeaderCell>
              ) : (
                <Styled.tableHeaderCell key={"headCellOfSelectableType"} />
              ))}
            {cellInfoList.map((cellInfo) => {
              return (
                <Styled.tableHeaderCell
                  key={cellInfo.cellKey}
                  horizontalAlign={cellInfo.horizontalAlign}
                >
                  {cellInfo.label}

                  {cellInfo.HeaderTooltip && cellInfo.HeaderTooltip}

                  {cellInfo.HeaderFilter && cellInfo.HeaderFilter}

                  {cellInfo.HeaderSort && cellInfo.HeaderSort}
                </Styled.tableHeaderCell>
              );
            })}
          </Styled.tableRow>
        </Styled.tableHeader>

        <Styled.tableBody cellGridSizeList={cellGridSizeList}>
          {rows.map((row, rowIndex) => {
            const isHoveredRow = hoveredRowKey === row.rowKey;
            const rowBackgroundColor = row.rowBackgroundColor;

            return (
              <Styled.tableRow
                key={rowIndex}
                onMouseEnter={() => setHoveredRowKey(row.rowKey)}
                onMouseOut={() => setHoveredRowKey(undefined)}
              >
                {propsByType.selectableType && (
                  // 선택가능한 테이블인 경우, 첫 dataCell에 선택 UI를 추가
                  <Styled.tableDataCell
                    ref={(ref) => {
                      if (tableDataCellRef) {
                        tableDataCellRef.current[rowIndex] = ref;
                      }
                    }}
                    onClick={() => {
                      // selectableType이 있으면 SelectableTableRow임 (타입컴파일러가 정확히 인식 못해 as로 처리)
                      const selectableRow = row as SelectableTableRow<
                        CellKey,
                        RowKey
                      >;

                      if (selectableRow.isNotSelectable) return;

                      selectableRow.onRowSelect();
                    }}
                    key={`dataCellOfSelectableType-${row.rowKey}`}
                    isFirstCell
                    hasClickAction
                    isHovered={isHoveredRow}
                    // selectableType이 있으면 isNotSelectable, isSelected이 있음. (타입컴파일러가 정확히 인식 못해 as로 처리)
                    isDisabled={
                      (row as { isNotSelectable?: boolean }).isNotSelectable
                    }
                    isSelected={(row as { isSelected?: boolean }).isSelected}
                    rowBackgroundColor={rowBackgroundColor}
                  >
                    {propsByType.selectableType === "single" && (
                      <SelectButton
                        uiType="radio"
                        size="default"
                        isLabelHidden
                        label="select button"
                        // onRowSelect에서 대신 처리
                        onSelect={noop}
                        // selectableType이 있으면 isNotSelectable, isSelected이 있음. (타입컴파일러가 정확히 인식 못해 as로 처리)
                        disabled={
                          (row as { isNotSelectable?: boolean }).isNotSelectable
                        }
                        selected={(row as { isSelected?: boolean }).isSelected}
                        value=""
                      />
                    )}

                    {propsByType.selectableType === "multi" && (
                      <SelectButton
                        uiType="checkboxOutlined"
                        size="default"
                        isLabelHidden
                        label="select button"
                        // onRowSelect에서 대신 처리
                        onSelect={noop}
                        // selectableType이 있으면 isNotSelectable, isSelected이 있음. (타입컴파일러가 정확히 인식 못해 as로 처리)
                        disabled={
                          (row as { isNotSelectable?: boolean }).isNotSelectable
                        }
                        selected={(row as { isSelected?: boolean }).isSelected}
                        value=""
                      />
                    )}
                  </Styled.tableDataCell>
                )}

                {cellInfoList.map((cellInfo, i) => {
                  return (
                    <Styled.tableDataCell
                      ref={(ref) => {
                        if (tableDataCellRef) {
                          tableDataCellRef.current[rowIndex] = ref;
                        }
                      }}
                      key={cellInfo.cellKey}
                      horizontalAlign={cellInfo.horizontalAlign}
                      verticalAlign={cellInfo.verticalAlign}
                      {...(row.onRowClick ? { onClick: row.onRowClick } : {})}
                      {...(propsByType.selectableType
                        ? {
                            // selectableType이 있으면 isSelected이 있음. (타입컴파일러가 정확히 인식 못해 as로 처리)
                            isSelected: (row as { isSelected?: boolean })
                              .isSelected,
                          }
                        : {})}
                      isFirstCell={propsByType.selectableType ? false : i === 0}
                      hasClickAction={!!row.onRowClick}
                      isHovered={isHoveredRow}
                      rowBackgroundColor={rowBackgroundColor}
                    >
                      {row[cellInfo.cellKey]}
                    </Styled.tableDataCell>
                  );
                })}
              </Styled.tableRow>
            );
          })}
        </Styled.tableBody>
      </Styled.table>

      {!rows?.length && labelForNoData && (
        <>
          {typeof labelForNoData === "string" ? (
            <Styled.noData>{labelForNoData}</Styled.noData>
          ) : (
            labelForNoData
          )}
        </>
      )}
    </Styled.container>
  );
}
