import {
  createGlobalStyle,
  css,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import reset from "styled-reset";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const customReset = css`
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    word-break: break-word;

    /* 스크롤바 관련 */
    -ms-overflow-style: none;

    /* 가로 스크롤바만 표시 - 크롬, 사파리 */
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar:horizontal {
      height: 8px;
      background-color: transparent;
      display: block;
    }
    &::-webkit-scrollbar-thumb:horizontal {
      background-color: ${COLOR.grayScale_400};
      border: 3px solid transparent;
      border-radius: 9px;
    }
  }

  html {
    font-size: 100%;
  }

  a {
    text-decoration: none;

    &:visited {
      color: none;
    }
  }

  b {
    font-weight: bold;
  }

  input,
  textarea {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;

    &:focus {
      outline: none;
    }
    &:disabled {
      background: none;
    }
  }

  /* 시각적으로 보이지는 않지만, 스크린 리더기 등에서는 표시되야하는 경우 사용 (ex. Input의 Label을 표시하지 않는 경우, custom input UI를 사용하는 경우) */
  .visually-hidden {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }

  .only-mobile {
    display: none;

    ${mobile(css`
      display: inherit;
    `)}
  }

  .only-desktop {
    ${mobile(css`
      display: none;
    `)}
  }

  /* ### input number의 화살표 없애기 - start ### */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* ### input number의 화살표 없애기 - end ### */
`;

export const globalReset = css`
  ${reset};
  ${customReset};
`;

export default createGlobalStyle<{
  /**
   * 특정 App에서만 사용하는 custom reset이 필요할땟 ㅏ용
   */
  appCustomReset?: FlattenInterpolation<ThemeProps<unknown>>;
}>`
  ${globalReset};
  
  body {
    ${() => {
      if (APP_NAME === "boful-admin") {
        return css`
          font-family: "Pretendard Variable", Pretendard, -apple-system,
            BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
            "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        `;
      }

      //antd 디자인에서 윈도우 font 반영이 안돼서 변경 전까지 임시로 쉽다 어드민 전용 분기를 추가
      //TODO: MUI로 모두 변경 시 삭제
      if (APP_NAME === "shipda-admin" || APP_NAME === "partner-admin") {
        return css`
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            "Helvetica Neue", Arial, "Noto Sans", sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
        `;
      }
      return css`
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Dotum, "돋움",
          sans-serif;
      `;
    }}
   
    font-size: 15px;
    font-weight: 400;
    color: #000;
  }

  ${({ appCustomReset }) => appCustomReset};
`;
