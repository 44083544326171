import React, { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { BofulWorker } from "@sellernote/_shared/src/types/fulfillment/auth";
import { BofulProblem } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { BOFUL_PROBLEM_OPTION_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";
import InputTextArea from "@sellernote/_sds-v1/src/components/input/InputTextArea";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";

import SkuIdForReportingProblem from "components/SkuIdForReportingProblem";

import { ReceivingReportedStep } from "..";
import Styled from "../index.styles";
import ButtonForReporting from "./ButtonForReporting";

type ItemListToReportTableItem =
  | {
      skuId: React.ReactNode;
      counter: React.ReactNode;
      manager: string;
      problem: React.ReactNode;
    }
  | Record<string, unknown>;

export type BofulProblemDict = {
  [itemId in string]: BofulProblemDictValue;
};

export type BofulProblemDictValue = {
  type: InputSelectOption<BofulProblem> | undefined;
  directInput?: string;
};

function SKUProblem({
  selectedProblem,
  setProblem,
}: {
  selectedProblem?: InputSelectOption<BofulProblem>;
  setProblem: (problem: InputSelectOption<BofulProblem>) => void;
}) {
  return (
    <InputSelect<BofulProblem>
      placeholder="선택"
      uiType="outline"
      optionList={BOFUL_PROBLEM_OPTION_LIST}
      selectedOption={selectedProblem}
      handleSelect={setProblem}
      width="100%"
    />
  );
}

function getManagerName({
  workerIds,
  getWorkerById,
}: {
  workerIds: number[];
  getWorkerById: (workerId?: number | undefined) => BofulWorker | undefined;
}) {
  if (!workerIds.length) return "";

  const setOfWorkerIds = new Set(workerIds);

  const isMultiItems = workerIds.length > 1;

  const firstWorkerName = getWorkerById(workerIds[0])?.name ?? "";

  const isMultiWorker = setOfWorkerIds.size > 1;

  return isMultiItems
    ? `[분할]${firstWorkerName}${
        isMultiWorker ? ` 외 ${setOfWorkerIds.size - 1}` : ""
      }`
    : firstWorkerName;
}

function SKUList({
  closeModal,
  receivingId,
  reportedStep,
}: {
  closeModal: () => void;
  receivingId: number;
  reportedStep: ReceivingReportedStep;
}) {
  // 검수
  const skuItems = useRecoilValue(FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS);

  // 입고
  const skuItemsFilteredByActualQty = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS_FILTERED_BY_ACTUAL_QTY
  );

  const getWorkerById = useGetWorkerByIdFactory();

  const itemsToReport = useMemo(() => {
    if (reportedStep === "inspection") {
      return skuItems.filter((v) => {
        if (!v.inspectItems) return true;

        const sumOfActualQty = v.inspectItems.reduce(
          (totalOfInspectionItems, InspectItems) =>
            totalOfInspectionItems + (InspectItems.actualQty ?? 0),
          0
        );

        return v.quantity !== sumOfActualQty;
      });
    }

    if (reportedStep === "warehousing") {
      return skuItemsFilteredByActualQty.filter((v) => {
        if (!v.placeItems) return true;

        const sumOfPlaceQty = v.placeItems.reduce(
          (totalOfPlaceItems, placeItem) =>
            totalOfPlaceItems + (placeItem.placeQty ?? 0),
          0
        );

        return v.actualQty !== sumOfPlaceQty;
      });
    }

    return [];
  }, [reportedStep, skuItems, skuItemsFilteredByActualQty]);

  const [problemDict, setProblemDict] = useState<BofulProblemDict>(
    itemsToReport.reduce((a, c) => {
      a[c.id] = { type: undefined };
      return a;
    }, {} as BofulProblemDict)
  );

  const TableToReport = useMemo(() => {
    const dataList: TableDataListItem<ItemListToReportTableItem>[] = [];

    itemsToReport.forEach((v) => {
      const hasTextArea = problemDict[v.id].type?.value === "directInput";

      const currentQty = {
        inspection: v.inspectItems.reduce((a, c) => {
          return a + (c.actualQty ?? 0);
        }, 0),
        warehousing: v.placeItems.reduce((a, c) => {
          return a + (c.placeQty ?? 0);
        }, 0),
      }[reportedStep];

      const workerIds = {
        inspection: v.inspectItems.reduce((a: number[], c) => {
          if (c.inspectorId) {
            a.push(c.inspectorId);
          }
          return a;
        }, []),
        warehousing: v.placeItems.reduce((a: number[], c) => {
          if (c.placerId) {
            a.push(c.placerId);
          }
          return a;
        }, []),
      }[reportedStep];

      const mapped: TableDataListItem<ItemListToReportTableItem> = {
        rowKey: v.id,
        noBorderBottom: hasTextArea,

        skuId: (
          <SkuIdForReportingProblem
            skuId={v.sku.id}
            skuBarcode={v.sku.barCode}
          />
        ),

        counter: getStyledSKUCount({
          currentCount: currentQty,
          goalCount: v.quantity,
        }),

        manager: getManagerName({
          workerIds,
          getWorkerById,
        }),

        problem: (
          <SKUProblem
            selectedProblem={problemDict[v.id]?.type}
            setProblem={(problem) => {
              const newDict = { ...problemDict };
              newDict[v.id] = {
                ...newDict[v.id],
                type: problem,
                directInput: undefined,
              };

              setProblemDict(newDict);
            }}
          />
        ),
      };

      dataList.push(mapped);

      if (hasTextArea) {
        const additional: TableDataListItem<ItemListToReportTableItem> = {
          rowKey: `${v.skuId}-additional`,
          colSpan: {
            value: 4,
            content: (
              <InputTextArea
                value={problemDict[v.id].directInput}
                setValue={(value) => {
                  const newDict = { ...problemDict };
                  newDict[v.id] = {
                    ...newDict[v.id],
                    directInput: value,
                  };

                  setProblemDict(newDict);
                }}
                placeholder="직접입력"
                isAutoResize
                isValidated={!!problemDict[v.id].directInput}
              />
            ),
            hasFullWidth: true,
          },
        };

        dataList.push(additional);
      }
    });

    return (
      <Styled.modalBodyContainer>
        <Table<ItemListToReportTableItem>
          columnInfo={{
            skuId: {
              label: (
                <>
                  SKU ID
                  <br />
                  (상품 바코드)
                </>
              ),
              fixedWidth: 120,
            },
            counter: {
              label: "카운트",
              fixedWidth: 100,
            },
            manager: {
              label: "담당자",
              fixedWidth: 150,
            },
            problem: {
              label: "발생문제 선택",
              fixedWidth: 200,
            },
          }}
          dataList={dataList}
        />

        <ButtonForReporting
          receivingId={receivingId}
          problemDict={problemDict}
          reportedStep={reportedStep}
        />
      </Styled.modalBodyContainer>
    );
  }, [itemsToReport, receivingId, problemDict, reportedStep, getWorkerById]);

  if (!itemsToReport.length) return null;

  return (
    <Modal
      active
      uiType="contentWithCustomBody"
      title={"문제가 발생한 상품(들)을 선택해주세요."}
      body={<>{TableToReport}</>}
      onClose={closeModal}
    />
  );
}

export default SKUList;
