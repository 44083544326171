import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { noop } from "@sellernote/_shared/src/utils/common/etc";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import useScan from "hooks/common/useScan";
import useSelectDuplicateBarcode from "hooks/common/useSelectDuplicateBarcode";
import { returningActions } from "modules/returning";

import {
  getCounterKeyFromScanResultByPlacingIdInProgress,
  getIncompleteSingleLocationCounterKeyFromScanResult,
  getIncompleteSingleLocationSKUListBySkuBarcode,
  getSingleLocationCounterKeyFromScanResult,
  SKUCountingForWarehousing,
  WarehousingCounterSKU,
} from "./useSKUCountingForWarehousing";

/**
 * 일반입고/분할입고를 구분
 */
type ScanTypeInfo =
  | {
      scanType: "single";
      setSkuInProgress: (val: WarehousingCounterSKU | undefined) => void;
    }
  | { scanType: "multi"; skuId: number; placingId?: string };

export default function useScanWarehousingSKU({
  skuCounting,
  returningId,
  setRowInfoToHighlight,
  ...scanTypeInfo
}: ScanTypeInfo & {
  skuCounting: SKUCountingForWarehousing;
  returningId: number;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
}) {
  const dispatch = useDispatch();

  const [active, setActive] = useState(false);

  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const {
    mutate: assignPlacerToItem,
    ResponseHandler: ResponseHandlerOfAssigningPlacerToItem,
  } = RETURNING_QUERY.useAssignPlacerToItem();

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const {
    handleConfirmSelectionModalOpen,
    ResultHandlerOfSelectDuplicateBarcode,
  } = useSelectDuplicateBarcode({
    type: "returningWarehousing",
    setSkuInProgress:
      scanTypeInfo.scanType === "single" ? scanTypeInfo.setSkuInProgress : noop,
  });

  const setScanSKUActive = useCallback((val: boolean) => {
    setActive(val);
  }, []);

  const showSelectionErrorForScanSKU = useCallback(() => {
    setValidationError({
      title: `분할입고할 항목을 '선택'한 후 '상품스캔'을 진행해주세요.`,
    });
  }, [setValidationError]);

  const handleScanResult = useCallback(
    (scanResult: string) => {
      if (!active) return;

      if (!returningId || !currentManager) return;

      let scannedCounterKey: string | undefined;

      if (scanTypeInfo.scanType === "multi") {
        if (!scanTypeInfo.placingId) {
          setValidationError({
            title: `분할입고할 항목을 '선택'한 후 '상품스캔'을 진행해주세요.`,
          });
          return;
        }

        // 분할입고의 경우 선택된 placingId를 기준으로 바코드를 찾는다.
        scannedCounterKey = getCounterKeyFromScanResultByPlacingIdInProgress({
          counterData: skuCounting,
          scanResult,
          placingIdInProgress: scanTypeInfo.placingId,
        });
      }

      if (scanTypeInfo.scanType === "single") {
        const incompleteSingleLocationSKUIdListBySkuBarcode =
          getIncompleteSingleLocationSKUListBySkuBarcode({
            counterData: skuCounting,
            scanResult,
          });

        /**
         * 새로운 입고 작업을 진행하는데, 입고완료되지 않은 중복된 바코드가 있는 경우
         * 작업자가 작업할 SKU ID를 선택하도록 한다.
         */
        if (
          !skuCounting.skuInProgress &&
          incompleteSingleLocationSKUIdListBySkuBarcode.length > 1
        ) {
          // 선택되는 경우 skuInProgress에 등록해서 이후 스캔 부터는 해당 상품이 스캔되도록 한다.
          handleConfirmSelectionModalOpen(
            incompleteSingleLocationSKUIdListBySkuBarcode
          );
          return;
        }

        if (incompleteSingleLocationSKUIdListBySkuBarcode.length > 1) {
          // 입고완료되지 않은 중복된 바코드가 2개 이상 있는 경우 위에서 등록된 skuInProgress를 기준으로 바코드를 찾는다.
          scannedCounterKey = getCounterKeyFromScanResultByPlacingIdInProgress({
            counterData: skuCounting,
            scanResult,
            placingIdInProgress: skuCounting.skuInProgress?.placingId,
          });

          /**
           * 현재 작업 중이진 않지만, 리스트에는 존재할 수 있다.
           * (더 적절한 오류 메시지를 띄우기 위해서 다시 찾는다.)
           */
          if (!scannedCounterKey) {
            scannedCounterKey = getSingleLocationCounterKeyFromScanResult({
              counterData: skuCounting,
              scanResult,
            });
          }
        } else if (incompleteSingleLocationSKUIdListBySkuBarcode.length === 1) {
          /**
           * 일반적인 미완료된 상품바코드 스캔(입고완료되지 않은 중복된 상품 바코드가 1개 남은 경우)
           * (이미 완료된 중복된 바코드와 구분을 위해서 별도로 바코드를 찾는다.)
           */
          scannedCounterKey =
            getIncompleteSingleLocationCounterKeyFromScanResult({
              counterData: skuCounting,
              scanResult,
            });
        } else {
          // SKU ID 또는 이미 입고완료된 상품바코드
          scannedCounterKey = getSingleLocationCounterKeyFromScanResult({
            counterData: skuCounting,
            scanResult,
          });
        }
      }

      if (!scannedCounterKey) {
        setValidationError({
          title: "상품 바코드가 일치하는 상품이 없습니다.",
        });
        return;
      }

      const target = skuCounting.counter.counterInfo[scannedCounterKey];

      if (target.isCompletePlacing) {
        setValidationError({
          title: `이미 완료한 SKU ID 입니다.`,
        });
        return;
      }

      const otherSkuIsInProgress =
        !!skuCounting.skuInProgress &&
        target.counterKey !== skuCounting.skuInProgress.counterKey;
      if (otherSkuIsInProgress) {
        const title =
          scanTypeInfo.scanType === "single"
            ? `현재 작업중인 입고(SKU ID: ${getFormattedSingleSkuId(
                skuCounting.skuInProgress?.skuId
              )})을 완료한 후에 다른 입고를 진행할 수 있습니다`
            : `현재 작업중인 분할 입고를 완료한 후에 다른 분할 입고를 진행할 수 있습니다`;

        setValidationError({
          title,
        });
        return;
      }

      const isInitialScan = !target.placerId && !target.current;
      if (isInitialScan) {
        // 1) 작업자 지정 api 호출
        assignPlacerToItem(
          {
            pathParams: { returningId, itemId: target.itemId },
            placingId: target.placingId,
          },
          {
            onSuccess: () => {
              // 2) local count ++
              // 위에서 존재여부 이미 검사함
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              skuCounting.counter.addCountById(scannedCounterKey!);
              skuCounting.setSkuInProgress(target);

              setRowInfoToHighlight({
                rowKey:
                  scanTypeInfo.scanType === "single"
                    ? target.itemId
                    : target.placingId,
              });

              // 3) 변경된 상세 데이터 불러오기
              dispatch(
                returningActions.GET_DETAIL({
                  id: returningId,
                })
              );
            },
          }
        );
      } else {
        const isAssignedWorker = target.placerId === currentManager.id;
        if (!isAssignedWorker) {
          setValidationError({
            title: "이미 타 담당자가 해당 상품을 입고중입니다.",
          });
          return;
        }

        skuCounting.counter.addCountById(scannedCounterKey);
        if (target.counterKey !== skuCounting.skuInProgress?.counterKey) {
          skuCounting.setSkuInProgress(target);
        }
        setRowInfoToHighlight({
          rowKey:
            scanTypeInfo.scanType === "single"
              ? target.itemId
              : target.placingId,
        });
      }
    },
    [
      active,
      returningId,
      currentManager,
      scanTypeInfo,
      skuCounting,
      setValidationError,
      handleConfirmSelectionModalOpen,
      assignPlacerToItem,
      setRowInfoToHighlight,
      dispatch,
    ]
  );

  useScan(handleScanResult);

  const ResultHandlerOfScanSKU = useMemo(() => {
    return (
      <>
        {ValidationErrorModal}

        {ResultHandlerOfSelectDuplicateBarcode}

        {ResponseHandlerOfAssigningPlacerToItem}
      </>
    );
  }, [
    ResponseHandlerOfAssigningPlacerToItem,
    ResultHandlerOfSelectDuplicateBarcode,
    ValidationErrorModal,
  ]);

  return {
    setScanSKUActive,
    showSelectionErrorForScanSKU,
    ResultHandlerOfScanSKU,
  };
}
