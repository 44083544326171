import { selector } from "recoil";

import {
  FileDomainType,
  FulfillmentAttachment,
} from "../../../types/fulfillment/fulfillment";

import { FULFILLMENT_AUTH_SELECTORS } from "../auth";
import { FULFILLMENT_RECEIVING_ATOMS } from ".";

const SKU_ITEMS = selector({
  key: "fulfillment/receiving/selectors/SKU_ITEMS",
  get: ({ get }) =>
    get(FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL)?.receiving
      .items ?? [],
});

const PICTURE_DICT = selector({
  key: "fulfillment/receiving/selectors/PICTURE_LIST",
  get: ({ get }) => {
    const attachment = get(
      FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL
    )?.attachment;

    const dict: {
      [P in FileDomainType]?: FulfillmentAttachment[];
    } = {};

    if (!attachment?.length) return dict;

    attachment.forEach((v) => {
      if (dict[v.domain]) {
        dict[v.domain]?.push(v);
      } else {
        dict[v.domain] = [v];
      }
    });

    return dict;
  },
});

const CAN_COMPLETE_CONFIRMATION = selector({
  key: "fulfillment/receiving/selectors/CAN_COMPLETE_CONFIRMATION",
  get: ({ get }) => {
    const hasAuthority =
      get(FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER)?.authority === "admin" ||
      get(FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER)?.authority === "whMaster";

    const hasDamage = !!get(PICTURE_DICT)["damages"]?.length;

    const isUploadedAllRequiredPictures = (() => {
      const receivingKind = get(
        FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL
      )?.receiving.receivingKind;
      const delivery = get(FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL)
        ?.receiving.delivery;
      const invoiceNoList = get(
        FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL
      )?.receiving.invoiceNo;
      const pictureDict = get(PICTURE_DICT);

      if (
        receivingKind === "import" ||
        (receivingKind === "domestic" && delivery === "truck")
      ) {
        return !!pictureDict["packing"]?.length;
      }

      if (receivingKind === "domestic" && delivery === "parcel") {
        const hasMatchedInvoice = !!invoiceNoList?.some(
          (invoiceNo) => !!invoiceNo.isInvoiceMatched
        );

        const hasInvoiceChecked =
          !!hasMatchedInvoice || !!pictureDict["invoice"]?.length;

        return hasInvoiceChecked && !!pictureDict["packing"]?.length;
      }

      return false;
    })();

    if (hasAuthority) {
      return isUploadedAllRequiredPictures;
    }

    if (hasDamage) {
      return false;
    }

    return isUploadedAllRequiredPictures;
  },
});

const IS_CURRENT_MANAGER_RECEIVING_MANAGER = selector({
  key: "fulfillment/receiving/selectors/IS_CURRENT_MANAGER_RECEIVING_MANAGER",
  get: ({ get }) => {
    const currentManager = get(FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER);
    const managerReceivingDetail = get(
      FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL
    );

    if (!currentManager || !managerReceivingDetail?.receiving) {
      return false;
    }

    return managerReceivingDetail.receiving.managerId === currentManager.id;
  },
});

const IS_CURRENT_MANAGER_PERSON_IN_CHARGE = selector({
  key: "fulfillment/receiving/selectors/IS_CURRENT_MANAGER_PERSON_IN_CHARGE",
  get: ({ get }) => {
    const currentManager = get(FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER);
    const receivingWorkerList = get(
      FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL
    )?.receiving.workerList;

    if (!currentManager || !receivingWorkerList) {
      return false;
    }

    return receivingWorkerList.some(
      (worker) => Number(worker.id) === currentManager.id
    );
  },
});

const IS_COMPLETED_INSPECTION_WITH_MATCHED_COUNT = selector({
  key: "fulfillment/receiving/selectors/IS_COMPLETED_INSPECTION_WITH_MATCHED_COUNT",
  get: ({ get }) => {
    const skuItems = get(SKU_ITEMS);

    if (!skuItems.length) {
      return false;
    }

    return skuItems.every((item) => {
      if (!item.inspectItems) {
        return false;
      }

      /**
       * 초과수량 검수가 가능하기 때문에 전체수량과 검수된 수량의 총합을 비교하는 것이 아니라,
       * 개별 아이템의 수량 일치 여부를 확인하여 수량 불일치인 경우 문제보고가 될 수 있도록 함
       */
      return item.inspectItems.every((inspectItem) => {
        const isMatched = inspectItem.quantity === inspectItem.actualQty;
        const isCompleted = inspectItem.isCompleteInspecting;

        return isMatched && isCompleted;
      });
    });
  },
});

const CAN_CLOSE_INSPECTION = selector({
  key: "fulfillment/receiving/selectors/CAN_CLOSE_INSPECTION",
  get: ({ get }) => {
    const isCurrentManagerReceivingManger = get(
      IS_CURRENT_MANAGER_RECEIVING_MANAGER
    );

    if (!isCurrentManagerReceivingManger) {
      return false;
    }

    const isCompletedAllSkuItemsForInspection = (() => {
      const skuItems = get(SKU_ITEMS);

      if (!skuItems.length) {
        return false;
      }

      return skuItems.every((item) =>
        item.inspectItems.every(
          (inspectItem) => inspectItem.isCompleteInspecting
        )
      );
    })();

    return isCompletedAllSkuItemsForInspection;
  },
});

const SKU_ITEMS_FILTERED_BY_ACTUAL_QTY = selector({
  key: "fulfillment/receiving/selectors/SKU_ITEMS_FILTERED_BY_ACTUAL_QTY",
  get: ({ get }) => get(SKU_ITEMS).filter((item) => (item.actualQty ?? 0) > 0),
});

const IS_COMPLETED_WAREHOUSING_WITH_MATCHED_COUNT = selector({
  key: "fulfillment/receiving/selectors/IS_COMPLETED_WAREHOUSING_WITH_MATCHED_COUNT",
  get: ({ get }) => {
    const skuItemsFilteredByActualQty = get(SKU_ITEMS_FILTERED_BY_ACTUAL_QTY);

    if (!skuItemsFilteredByActualQty.length) {
      return false;
    }

    return skuItemsFilteredByActualQty.every((item) => {
      if (!item.placeItems) {
        return false;
      }

      /**
       * 초과수량 입고가 가능하기 때문에 전체수량과 입고된 수량의 총합을 비교하는 것이 아니라,
       * 개별 아이템의 수량 일치 여부를 확인하여 수량 불일치인 경우 문제보고가 될 수 있도록 함
       */
      return item.placeItems.every((placeItem) => {
        const isMatched = placeItem.quantity === placeItem.placeQty;
        const isCompleted = placeItem.isCompletePlacing;

        return isMatched && isCompleted;
      });
    });
  },
});

const CAN_CLOSE_WAREHOUSING = selector({
  key: "fulfillment/receiving/selectors/CAN_CLOSE_WAREHOUSING",
  get: ({ get }) => {
    const isCurrentManagerReceivingManger = get(
      IS_CURRENT_MANAGER_RECEIVING_MANAGER
    );

    if (!isCurrentManagerReceivingManger) {
      return false;
    }

    const isCompletedAllSkuItemsForWarehousing = (() => {
      const skuItemsByActualQty = get(SKU_ITEMS_FILTERED_BY_ACTUAL_QTY);

      if (!skuItemsByActualQty.length) {
        return false;
      }

      return skuItemsByActualQty.every((item) =>
        item.placeItems.every((placeItem) => placeItem.isCompletePlacing)
      );
    })();

    return isCompletedAllSkuItemsForWarehousing;
  },
});

export default {
  SKU_ITEMS,
  PICTURE_DICT,
  CAN_COMPLETE_CONFIRMATION,

  IS_CURRENT_MANAGER_RECEIVING_MANAGER,

  IS_CURRENT_MANAGER_PERSON_IN_CHARGE,
  IS_COMPLETED_INSPECTION_WITH_MATCHED_COUNT,
  CAN_CLOSE_INSPECTION,

  SKU_ITEMS_FILTERED_BY_ACTUAL_QTY,
  IS_COMPLETED_WAREHOUSING_WITH_MATCHED_COUNT,
  CAN_CLOSE_WAREHOUSING,
};
