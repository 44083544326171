import React from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";

import {
  CompletedList,
  WorkingLocation,
} from "hooks/canceling/useRestockCanceling";
import useScanCancelingLocation from "hooks/canceling/useScanCancelingLocation";

export default function ScanLocationButton({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  SKUInfoForScanning,
  completedList,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  completedList: React.MutableRefObject<CompletedList>;
}) {
  const { ScanLocationMessageModal } = useScanCancelingLocation({
    selectedScanButtonType,
    workingLocation,
    setWorkingLocation,
    SKUInfoForScanning,
    completedList,
  });

  return (
    <>
      <Button
        className="scan-location"
        theme={selectedScanButtonType === "location" ? "scan" : "secondary"}
        size="small"
        label="위치 스캔"
        handleClick={() => handleScanButtonClick("location")}
      />

      {ScanLocationMessageModal}
    </>
  );
}
