import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "../../styles/colors";
import { setFontStyle } from "../../styles/typography";

import { BorderType } from ".";

const container = styled.div<{ borderType: BorderType }>`
  border-radius: 4px;
  padding: 4px 8px;
  ${setFontStyle("Caption")};

  ${({ borderType }) => {
    if (borderType === "filled")
      return css`
        background-color: ${COLOR.secondary_400};
        color: ${TEXT_COLOR.white_1};
      `;

    if (borderType === "stroked")
      return css`
        background-color: ${COLOR.secondary_50};
        color: ${COLOR.secondary_400};
        border: 1px solid ${COLOR.secondary_400};
      `;
  }}
`;

export default {
  container,
};
