import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const selectSkuInput = styled.div`
  margin-bottom: 40px;
`;

const optionListWrapper = styled.div`
  position: relative;
`;

const optionList = styled.div`
  position: absolute;
  left: 1px;
  top: 0;
  width: calc(100% - 2px);
  padding: 0 8px;
  background-color: ${COLOR.white};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  height: auto;
  max-height: 128px;
  overflow: scroll;
  z-index: 2;
`;

const option = styled.div`
  padding: 10px 0;
  ${setFontStyle("Body7")};
  color: ${COLOR.grayScale_800};
  background-color: ${COLOR.white};
  text-align: left;

  &:not(:last-child) {
    border-bottom: 1px solid ${COLOR.grayScale_100};
  }
`;

export default {
  selectSkuInput,
  optionListWrapper,
  optionList,
  option,
};
