import { selector } from "recoil";

import { SKUInfoForCancelingRestocking } from "../../../types/fulfillment/canceling";

import { FULFILLMENT_CANCELING_ATOMS } from ".";

const RESTOCKING_LIST = selector<SKUInfoForCancelingRestocking[]>({
  key: "fulfillment/canceling/selectors/RESTOCKING_LIST",
  get: ({ get }) =>
    (
      get(FULFILLMENT_CANCELING_ATOMS.CANCELING_DETAIL).cancelingData?.items ??
      []
    )
      .filter((v) => v.quantity > 0)
      .map<SKUInfoForCancelingRestocking>((v) => ({
        id: v.id,
        locationId: v.locationId,
        skuId: v.skuId,
        skuBarcode: v.sku.barCode,
        currentQty: 0,
        quantity: v.quantity,
      })),
});

const LOCATION_ID_LIST_IN_RESTOCKING_LIST = selector<number[]>({
  key: "fulfillment/shipping/selectors/LOCATION_ID_LIST_IN_RESTOCKING_LIST",
  get: ({ get }) =>
    (
      get(FULFILLMENT_CANCELING_ATOMS.CANCELING_DETAIL).cancelingData?.items ??
      []
    ).map((v) => v.locationId),
});

export default {
  RESTOCKING_LIST,
  LOCATION_ID_LIST_IN_RESTOCKING_LIST,
};
