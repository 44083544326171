import React from "react";
import { useRouteMatch } from "react-router-dom";

import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import {
  ListWithMoreResultComponent,
  ListWithMoreResultProps,
} from "@sellernote/_shared/src/headlessComponents/useListWithMoreResult";
import {
  ModalCommonPropsV1,
  ModalContentProps,
  ModalFooterActions,
  ModalTitleOnlyProps,
} from "@sellernote/_shared/src/headlessComponents/useModal";
import {
  AdminShippingLitItem,
  PartialShippingListItem,
  PickingSKUInfoForScanning,
  ShipmentParcelSummary,
  ShipmentSummary,
  ShippingDeliveringStatus,
  ShippingDeliveryType,
  ShippingItem,
  ShippingTruckType,
} from "@sellernote/_shared/src/types/fulfillment/shipping";

import {
  ClassifiedGroupItem,
  GroupDataByType,
} from "../../types/fulfillment/common";
import {
  FulfillmentTrackingLevel,
  FulfillmentTruckCompany,
  WmsStatus,
} from "../../types/fulfillment/fulfillment";
import { ProductGroup } from "../../types/fulfillment/sku";

import { isSameOrBeforeToday } from "../common/date";
import {
  getDetailSkuIdList,
  getFilteredItemsByQuantity,
  getGroupDataByType,
  getGroupItems,
  getGroupItemsV2,
  getGroupItemsWithTotalQuantity,
  getGroupItemsWithTotalQuantityV2,
  getItemsWithoutGroupItemsQuantity,
  mergeItemsBySkuId,
} from "./common";
import { getFormattedSingleSkuId } from "./fulfillment";

export function getItemIdListByWorkingLocation({
  pickingList,
  workingLocationId,
}: {
  pickingList: PickingSKUInfoForScanning[];
  workingLocationId: number;
}) {
  return pickingList
    .filter((v) => v.locationId === workingLocationId)
    .map((v) => v.id);
}

export type PickingModalInfo = Pick<
  ModalCommonPropsV1 & (ModalTitleOnlyProps | ModalContentProps),
  "uiType" | "className" | "title" | "actionPositive" | "actionNegative"
> & { body?: React.ReactNode };

export function getPickingModalInfo(
  type: string,
  data?: string | number,
  action?: Pick<ModalFooterActions, "actionPositive" | "actionNegative">
) {
  const pickingModalInfo = new Map<string, PickingModalInfo>([
    [
      "locationScanFirst",
      {
        uiType: "titleOnly",
        title: `위치를 먼저 스캔해주세요.`,
      },
    ],
    [
      "noLocationInInvoice",
      {
        uiType: "titleOnly",
        title: `해당 위치(${data})에 피킹할 목록이 없습니다.`,
        className: `title-warning-modal`,
      },
    ],
    [
      "noSKUInPickingList",
      {
        uiType: "titleOnly",
        title: `${data}은(는) 피킹목록에 없습니다.`,
        className: `title-warning-modal`,
      },
    ],
    [
      "noSKUInCurrentLocation",
      {
        uiType: "titleOnly",
        title: `해당 위치(${data})에 없는 상품입니다.`,
        className: `title-warning-modal`,
      },
    ],

    [
      "correctInvoicedScan",
      {
        uiType: "titleOnly",
        title: `올바른 송장(QR)번호를 스캔해주세요.`,
      },
    ],
    [
      "correctLocationScan",
      {
        uiType: "titleOnly",
        title: `올바른 위치를 스캔해주세요.`,
      },
    ],
    [
      "correctSKUIdScan",
      {
        uiType: "titleOnly",
        title: `올바른 상품을 스캔해주세요.`,
      },
    ],
    [
      "overQuantityScan",
      {
        uiType: "content",
        title: (
          <div>
            {`${data}(SKU ID)`}
            <br />
            최대 스캔 가능 수량을 초과하였습니다.
          </div>
        ),
        body: `다시 확인해주세요.`,
      },
    ],
    [
      "completedLocation",
      {
        uiType: "titleOnly",
        title: `해당 위치(${data})에서의 피킹은 이미 완료되었습니다.`,
      },
    ],
    [
      "completedPickingBySKUId",
      {
        uiType: "content",
        title: `${data}(SKU ID)`,
        body: `피킹이 완료되었습니다.`,
      },
    ],
    [
      "completedPickingInvoice",
      {
        uiType: "content",
        title: `${data}(송장번호)`,
        body: `피킹이 완료되었습니다.`,
      },
    ],

    [
      "canceledPickingInvoice",
      {
        uiType: "titleOnly",
        title: `본 운송 건은 취소되었습니다.`,
        actionPositive: action?.actionPositive,
        className: `title-warning-modal`,
      },
    ],

    [
      "canceledInvoiceInPicking",
      {
        uiType: "content",
        title: (
          <>
            고객사의 요청에 의해
            <br />
            <strong className="warning-message">주문이 취소</strong>되었습니다.
          </>
        ),
        body: "재입고를 진행해주세요.",
        actionPositive: action?.actionPositive,
      },
    ],
  ]);

  return pickingModalInfo.get(type);
}

export function useShipmentSummary<T extends ShippingDeliveryType>(
  data: T extends "parcel"
    ? ShipmentParcelSummary | undefined
    : ShipmentSummary | undefined,
  ListWithMoreResult: ListWithMoreResultComponent
) {
  const { url } = useRouteMatch();

  const getTodayExpectedShipmentSummaryData = ({
    url,
    data,
  }: {
    url: string;
    data: T extends "parcel"
      ? ShipmentParcelSummary | undefined
      : ShipmentSummary | undefined;
  }): ListWithMoreResultProps => ({
    title: {
      label: "당일 출하 예정 건",
      value: (data?.todayRequest || 0) + (data?.overdueRequest || 0),
      path: `${url}/expected`,
    },
    list: [
      {
        label: "금일 출하 요청 건",
        value: data?.todayRequest || 0,
      },
      {
        label: "이전 출하 요청 건",
        value: data?.overdueRequest || 0,
      },
    ],
  });

  const getCompletedShipmentSummaryData = ({
    url,
    data,
  }: {
    url: string;
    data: T extends "parcel"
      ? ShipmentParcelSummary | undefined
      : ShipmentSummary | undefined;
  }): ListWithMoreResultProps => ({
    title: {
      label: "현재 마감 건",
      value: `${data?.todayDone || 0} / ${
        (data?.todayRequest || 0) + (data?.overdueRequest || 0)
      }`,
      path: `${url}/completed`,
    },
  });

  const getUnprocessedShipmentSummaryData = ({
    url,
    data,
  }: {
    url: string;
    data: T extends "parcel"
      ? ShipmentParcelSummary | undefined
      : ShipmentSummary | undefined;
  }): ListWithMoreResultProps => ({
    title: {
      label: "현재 미처리 건",
      value:
        (data?.todayDelay || 0) +
        (data?.onedayDelay || 0) +
        (data?.twodayDelay || 0),
      path: `${url}/unprocessed`,
    },
    list: [
      {
        label: "당일 지연",
        value: data?.todayDelay || 0,
      },
      {
        label: "1일 지연",
        value: data?.onedayDelay || 0,
      },
      {
        label: "2일 이상 지연",
        value: data?.twodayDelay || 0,
      },
    ],
  });

  const todayExpectedShipmentSummaryData = getTodayExpectedShipmentSummaryData({
    url,
    data,
  });
  const completedShipmentSummaryData = getCompletedShipmentSummaryData({
    url,
    data,
  });
  const unprocessedShipmentSummaryData = getUnprocessedShipmentSummaryData({
    url,
    data,
  });

  const TodayExpectedShipmentSummary = (
    <ListWithMoreResult
      title={todayExpectedShipmentSummaryData.title}
      list={todayExpectedShipmentSummaryData.list}
    />
  );

  const CompletedShipmentSummary = (
    <ListWithMoreResult title={completedShipmentSummaryData.title} />
  );

  const UnprocessedShipmentSummary = (
    <ListWithMoreResult
      title={unprocessedShipmentSummaryData.title}
      list={unprocessedShipmentSummaryData.list}
    />
  );

  return {
    TodayExpectedShipmentSummary,
    CompletedShipmentSummary,
    UnprocessedShipmentSummary,
  };
}

export const shipmentStatus = new Map([
  ["waiting", "송장출력전"],
  ["picking", "피킹 중"],
  ["packing", "패킹 중"],
  ["ready", "출하준비"],
  ["done", "출하완료"],
]);

export function sortListByLocationIdAndSKUId<
  T extends ShippingItem | PickingSKUInfoForScanning
>(list?: readonly T[]): T[] {
  return [...(list || [])].sort(
    (a, b) => a.locationId - b.locationId || a.skuId - b.skuId
  );
}

/**
 * '수거 준비 중': 1(배송준비중)
 * '택배사 전달': 2(집화완료),
 * '배송 중': 3(배송중), 4(지점 도착), 5(배송출발)
 * '배송 완료': 6(배송완료)
 * 0(오류): CJ에서 택배 업무를 수행하지 못하는 경우입니다.
 */
export function toShippingLevelLabel(level: FulfillmentTrackingLevel) {
  switch (level) {
    case 1: {
      return "수거 준비 중";
    }
    case 2: {
      return "택배사 전달";
    }
    case 3:
    case 4:
    case 5: {
      return "배송 중";
    }
    case 6: {
      return "배송 완료";
    }
    default: {
      return "";
    }
  }
}

export function toShippingDeliveringStatusLabel(
  status: ShippingDeliveringStatus
) {
  switch (status) {
    case "notSent": {
      return "배송 전";
    }
    case "delivering": {
      return "배송 중";
    }
    case "done": {
      return "배송 완료";
    }
    default: {
      return "";
    }
  }
}

export const TRUCK_COMPANY_OPTION_LIST: InputSelectOption<FulfillmentTruckCompany>[] =
  [
    { label: "가나물류", value: "gana" },
    { label: "고고밴", value: "gogoVan" },
    { label: "Sandy", value: "sandy" },
    { label: "해피로지스틱스", value: "happyLogistics" },
  ];

export const TRUCK_TYPE_OPTION_LIST: InputSelectOption<ShippingTruckType>[] = [
  { label: "오토바이", value: "motorcycle" },
  { label: "다마스", value: "damas" },
  { label: "라보", value: "labo" },
  { label: "벤", value: "van" },
  { label: "1톤 트럭", value: "1ton" },
  { label: "1톤 윙바디 트럭", value: "1ton_wingbody" },
  { label: "1.2톤&1.4톤 트럭", value: "ton1_2_and_ton1_4" },
  { label: "2.5톤&3.5톤 트럭", value: "ton2_5_and_ton3_5" },
  { label: "2.5톤&3.5톤 윙바디 트럭", value: "ton2_5_and_ton3_5_wingbody" },
  { label: "5톤 트럭", value: "5ton" },
  { label: "5톤 축차", value: "5ton_rotor" },
  { label: "5톤 윙바디 트럭", value: "5ton_wingbody" },
  { label: "7.5톤 트럭", value: "ton7_5" },
  { label: "11톤 트럭", value: "11ton" },
  { label: "14톤 트럭", value: "14ton" },
  { label: "25톤 트럭", value: "25ton" },
];

export function getPickingItemId({
  scannedBarcode,
  locationId,
  pickingList,
}: {
  scannedBarcode: string;
  locationId: number;
  pickingList: PickingSKUInfoForScanning[];
}) {
  return (
    pickingList.find(
      (item) =>
        (getFormattedSingleSkuId(item.skuId) === scannedBarcode ||
          item.skuBarcode === scannedBarcode) &&
        item.locationId === locationId
    )?.id ?? 0
  );
}

export function getPickingSKUId({
  itemId,
  pickingList,
}: {
  itemId: number;
  pickingList: PickingSKUInfoForScanning[];
}) {
  return pickingList.find((item) => item.id === itemId)?.skuId ?? 0;
}

/**
 * 송장 분류코드의 앞부분, 뒷부분을 분할하여 반환
 */
function getDepartedInvoiceClassificationCode(classificationCode?: string): {
  classificationCodeUnited: string;
  classificationCode1: string;
  classificationCode2: string;
  classificationCode3: string;
} {
  return {
    classificationCodeUnited: classificationCode
      ? classificationCode.slice(0, 4)
      : "",
    classificationCode1: classificationCode
      ? classificationCode.slice(0, 1)
      : "",
    classificationCode2: classificationCode
      ? classificationCode.slice(1, 4)
      : "",
    classificationCode3: classificationCode ? classificationCode.slice(4) : "",
  };
}

/**
 * 송장출력이 가능한 출고인지 체크
 */
function checkCanPrintInvoice({
  isPrinted,
  deliveryType,
  needNumberingWhenPrintingInvoice,
}: {
  isPrinted: boolean;
  deliveryType?: ShippingDeliveryType;
  needNumberingWhenPrintingInvoice: boolean;
}) {
  // 채번이 필요한 경우라면, 이미 프린팅된 이력이 있다면 출력불가(중복채번을 막기위함)
  if (needNumberingWhenPrintingInvoice && isPrinted) return false;

  if (deliveryType !== "parcel") return false;

  return true;
}

/**
 * 출하 완료가 가능한 출고인지 체크
 */
function checkCanCompleteShipment({ wmsStatus }: { wmsStatus: WmsStatus }) {
  if (wmsStatus === "ready") {
    return true;
  }

  return false;
}

/**
 * items와 그룹상품의 정보를 통해 items에서 단일상품만 필터링
 * - needsToMerge
 *   - location 별로 아이템이 나뉘어져 있는 경우 SKU ID를 기준으로 아이템을 합치는 작업이 필요
 *   - 다음의 두 개의 API에서는 location이 다르더라도 SKU ID별로 이미 묶여 있음
 *     - [GET] /shipping/admin/new/:id, [GET] /shipper/:id
 */
const getSingleShippingItems = ({
  items,
  needsToMerge = false,
  ...groupDataByType
}: {
  items: ShippingItem[] | undefined;
  needsToMerge?: boolean;
} & GroupDataByType) => {
  const groupItemsWithTotalQuantity =
    getGroupItemsWithTotalQuantity(groupDataByType);
  const detailSkuIdList = getDetailSkuIdList(groupItemsWithTotalQuantity);

  const mergedItems = needsToMerge ? mergeItemsBySkuId(items) : items;

  // items에서 그룹상품의 수량을 빼고 quantity가 남는 item은 단일상품
  return getFilteredItemsByQuantity(
    getItemsWithoutGroupItemsQuantity({
      items: mergedItems,
      detailSkuIdList,
      groupItemsWithTotalQuantity,
    })
  );
};

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * items와 그룹상품의 정보를 통해 items에서 단일상품만 필터링
 * - needsToMerge
 *   - location 별로 아이템이 나뉘어져 있는 경우 SKU ID를 기준으로 아이템을 합치는 작업이 필요
 *   - 다음의 두 개의 API에서는 location이 다르더라도 SKU ID별로 이미 묶여 있음
 *     - [GET] /shipping/admin/new/:id, [GET] /shipper/:id
 */
const getSingleShippingItemsV2 = ({
  items,
  groupList,
  needsToMerge = false,
}: {
  items: ShippingItem[] | undefined;
  groupList: ProductGroup[] | undefined;
  needsToMerge?: boolean;
}) => {
  const groupItemsWithTotalQuantity =
    getGroupItemsWithTotalQuantityV2(groupList);
  const detailSkuIdList = getDetailSkuIdList(groupItemsWithTotalQuantity);

  const mergedItems = needsToMerge ? mergeItemsBySkuId(items) : items;

  // items에서 그룹상품의 수량을 빼고 quantity가 남는 item은 단일상품
  return getFilteredItemsByQuantity(
    getItemsWithoutGroupItemsQuantity({
      items: mergedItems,
      detailSkuIdList,
      groupItemsWithTotalQuantity,
    })
  );
};

/**
 * 출고관리에서 items를 [그룹상품, 단일상품] 형태로 분류하기 위한 함수
 */
const classifyShippingItems = ({
  items,
  needsToMerge = false,
  ...groupDataByType
}: {
  items: ShippingItem[] | undefined;
  needsToMerge?: boolean;
} & GroupDataByType) => {
  const groupData = getGroupDataByType(groupDataByType);

  const hasGroupItems = groupData.length > 0;
  if (!hasGroupItems) {
    return needsToMerge ? mergeItemsBySkuId(items) : items ?? [];
  }

  const groupItems = getGroupItems(groupDataByType);
  const singleItems = getSingleShippingItems({
    items,
    needsToMerge,
    ...groupDataByType,
  });

  return [...groupItems, ...singleItems];
};

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * 출고관리에서 items를 [그룹상품, 단일상품] 형태로 분류하기 위한 함수
 */
const classifyShippingItemsV2 = ({
  items,
  groupList,
  needsToMerge = false,
}: {
  items: ShippingItem[] | undefined;
  groupList: ProductGroup[] | undefined;
  needsToMerge?: boolean;
}) => {
  const hasGroupItems = (groupList ?? []).length > 0;
  if (!hasGroupItems) {
    return needsToMerge ? mergeItemsBySkuId(items) : items ?? [];
  }

  const groupItems = getGroupItemsV2(groupList);
  const singleItems = getSingleShippingItemsV2({
    items,
    groupList,
    needsToMerge,
  });

  return [...groupItems, ...singleItems];
};

const getShippingItemShippingQuantity = ({
  shippingItem,
  groups,
}: {
  shippingItem: ShippingItem | ClassifiedGroupItem | undefined;
  groups: ProductGroup[] | undefined;
}) => {
  if (!shippingItem) {
    return 0;
  }

  const isGroupSkuItem = shippingItem.isGroup;
  if (isGroupSkuItem) {
    const targetGroupSkuItem = (groups ?? []).find(
      (groupItem) => groupItem.id === shippingItem.skuId
    );

    return (
      shippingItem.quantity *
      (targetGroupSkuItem?.groupItems ?? []).reduce(
        (sum, groupItem) => sum + groupItem.qty,
        0
      )
    );
  }

  return shippingItem.quantity;
};

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 */
const getShippingItemShippingQuantityV2 = ({
  shippingItem,
  groupList,
}: {
  shippingItem: ShippingItem | ClassifiedGroupItem | undefined;
  groupList: ProductGroup[] | undefined;
}) => {
  if (!shippingItem) {
    return 0;
  }

  const isGroupSkuItem = shippingItem.isGroup;
  if (isGroupSkuItem) {
    const targetGroupSkuItem = (groupList ?? []).find(
      (groupData) => groupData.id === shippingItem.skuId
    );

    return (
      shippingItem.quantity *
      (targetGroupSkuItem?.groupItems ?? []).reduce(
        (sum, groupItem) => sum + groupItem.qty,
        0
      )
    );
  }

  return shippingItem.quantity;
};

const getAdminStatus = (
  shipping: AdminShippingLitItem | PartialShippingListItem | undefined
) => {
  if (!shipping) {
    return "";
  }

  if (shipping.shippingStatus === "waiting") {
    return isSameOrBeforeToday(shipping.dueDate)
      ? "의뢰 대기(당일)"
      : "의뢰 대기(예정)";
  }

  if (shipping.shippingStatus === "inProgress") {
    if (
      (shipping.wmsStatus === "picking" || shipping.wmsStatus === "waiting") &&
      !shipping.endedPickingAt
    ) {
      return "피킹 중";
    }

    if (
      (shipping.wmsStatus === "picking" || shipping.wmsStatus === "packing") &&
      shipping.endedPickingAt
    ) {
      return "패킹 중";
    }
  }

  if (shipping.shippingStatus === "close" && shipping.wmsStatus === "ready") {
    return "출하 준비";
  }

  if (
    shipping.shippingStatus === "delivering" &&
    shipping.wmsStatus === "done"
  ) {
    return "출하 완료";
  }

  if (shipping.shippingStatus === "done") {
    return "배송 완료";
  }

  if (shipping.shippingStatus === "cancel") {
    return "취소";
  }

  if (shipping.shippingStatus === "return") {
    return "반품";
  }

  return "";
};

export {
  getDepartedInvoiceClassificationCode,
  checkCanPrintInvoice,
  checkCanCompleteShipment,
  getSingleShippingItems,
  getSingleShippingItemsV2,
  classifyShippingItems,
  classifyShippingItemsV2,
  getShippingItemShippingQuantity,
  getShippingItemShippingQuantityV2,
  getAdminStatus,
};
