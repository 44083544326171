import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section`
  > .tab-filter {
    margin-bottom: 24px;
  }

  .picking-list-table {
    margin-bottom: 32px;

    tr:not(:last-child) {
      td {
        border-bottom: 0;
      }
    }
  }

  .complete-picking-button {
    margin-bottom: 80px;
  }

  .modal {
    strong.warning-message {
      color: ${COLOR.pointWarning};
    }
  }

  .title-warning-modal {
    .title {
      color: ${COLOR.pointWarning};
    }
  }

  .body-warning-modal {
    .body {
      color: ${COLOR.pointWarning};
    }
  }
`;

const scanContainer = styled.div`
  display: flex;
  flex-direction: column;

  .scanned-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    strong {
      color: ${COLOR.grayScale_800};
      ${setFontStyle("SubHead2", "Bold")};
    }

    .outer-packaging-info {
      color: ${COLOR.primaryBlue};
      ${setFontStyle("SubHead3", "Bold")};
    }
  }
`;

const scanButtonContainer = styled.div`
  margin-right: -8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;

  .scan-button {
    padding: 0 8px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.6px;
  }

  .button {
    margin-right: 8px;
  }
`;

const invoiceDirectInputModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  > .input-text {
    margin-bottom: 56px;
  }
`;

const countContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const countWithDirectInputContainer = styled.div`
  > .count-values {
    margin-bottom: 16px;
    > .input-text {
      margin-right: 8px;
    }
  }
  > .count-buttons {
    display: flex;
    > .button:first-child {
      margin-right: 4px;
    }
  }
`;

const locationToPicking = styled.div`
  display: flex;
  flex-direction: column;
`;

export default {
  container,
  scanContainer,
  scanButtonContainer,
  countContainer,
  invoiceDirectInputModalBody,
  countWithDirectInputContainer,
  locationToPicking,
};
