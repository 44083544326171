import { useCallback, useState } from "react";

import { SKU } from "@sellernote/_shared/src/types/fulfillment/sku";

type SelectType = "scan" | "input";

export type SkuToSelect = Pick<SKU, "id" | "barCode">;

export default function useSelectSkuModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const [selectType, setSelectType] = useState<SelectType>("scan");
  const [tempSku, setTempSku] = useState<SkuToSelect>();

  const handleSelectTypeChange = useCallback(
    (v: SelectType) => {
      setSelectType(v);
      setTempSku(undefined);
    },
    [setTempSku]
  );

  const handleSelectSkuModalClose = useCallback(() => {
    setSelectType("scan");
    setTempSku(undefined);
    onClose();
  }, [onClose]);

  return {
    selectType,
    handleSelectTypeChange,

    tempSku,
    setTempSku,

    handleSelectSkuModalClose,
  };
}
