import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import useDebounce from "@sellernote/_shared/src/hooks/common/useDebounce";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";

import { SkuToSelect } from "../useSelectSkuModal";

export default function useInputForSku({
  tempSku,
  setTempSku,
  setSelectedSku,
  onSelectSkuModalClose,
}: {
  tempSku: SkuToSelect | undefined;
  setTempSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
  setSelectedSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
  onSelectSkuModalClose: () => void;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  // 선택 후 수정할 때 검색했던 값을 되살리기 위해서 사용
  const tempSearchTerm = useRef("");

  // debounce로 검색해야되기 때문에 ResponseHandler를 추가하지 않음
  const {
    data: searchedSkuList,
    mutate: interpretBarcode,
    reset: resetSearchedSkuList,
  } = SCAN_QUERY.useInterpretBarcode<"sku">({
    type: "directInput",
  });

  useEffect(() => {
    if (!debouncedSearchTerm) return;

    interpretBarcode({ type: "sku", barCode: debouncedSearchTerm });
  }, [debouncedSearchTerm, interpretBarcode]);

  const handleSkuSelect = useCallback(
    (v: SkuToSelect) => () => {
      setSearchTerm("");
      setTempSku(v);
      tempSearchTerm.current = searchTerm;
    },
    [searchTerm, setTempSku]
  );

  const handleTempSkuReset = useCallback(() => {
    resetSearchedSkuList();
    setTempSku(undefined);
  }, [resetSearchedSkuList, setTempSku]);

  const handleTempSearchTermRestore = useCallback(() => {
    resetSearchedSkuList();
    setSearchTerm(tempSearchTerm.current);
    setTempSku(undefined);
  }, [resetSearchedSkuList, setTempSku]);

  const handleSelectSkuModalConfirm = useCallback(() => {
    setSelectedSku(tempSku);
    onSelectSkuModalClose();
  }, [onSelectSkuModalClose, setSelectedSku, tempSku]);

  return {
    searchTerm,
    setSearchTerm,
    searchedSkuList,
    handleSkuSelect,
    handleTempSkuReset,
    handleTempSearchTermRestore,

    handleSelectSkuModalConfirm,
  };
}
