import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import useCloseAsNormal from "./useCloseAsNormal";

function CloseAsNormal({ returningId }: { returningId: number }) {
  const {
    confirmMessageTitle,
    handleSubmit,
    ResponseHandlerOfSetInspectionDone,
  } = useCloseAsNormal({ returningId });

  return (
    <>
      <ConfirmWrapper
        confirmMessageTitle={confirmMessageTitle}
        confirmModalUiType="content"
        confirmMessageBody={
          <>
            입고요청수량과 검수수량이 일치합니다. <br />
            검수를 마치시겠습니까?
          </>
        }
      >
        <Button
          label="검수마감"
          size="block"
          theme="primary"
          handleClick={handleSubmit}
        />
      </ConfirmWrapper>

      {ResponseHandlerOfSetInspectionDone}
    </>
  );
}

export default CloseAsNormal;
