import { useCallback } from "react";

import {
  ADMIN_INVENTORY_ADJUSTMENT_REQ,
  COMPLETE_INVENTORY_ADJUSTMENT_REQ,
  COMPLETE_INVENTORY_CONSOLIDATION_REQ,
  COMPLETE_INVENTORY_MOVEMENT_REQ,
  FORCE_CREATE_INVENTORY_REQ,
  FORCE_CREATE_INVENTORY_RES,
  GET_ADMIN_ADJUSTMENT_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_ADMIN_ADJUSTMENT_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_ADMIN_ADJUSTMENT_LIST_REQ,
  GET_ADMIN_ADJUSTMENT_LIST_RES,
  GET_ADMIN_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_ADMIN_LIST_REQ,
  GET_ADMIN_LIST_RES,
  GET_GROUP_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_GROUP_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_GROUP_SKU_INVENTORY_LIST_REQ,
  GET_GROUP_SKU_INVENTORY_LIST_RES,
  GET_INVENTORY_DETAIL_BY_LOCATION_REQ,
  GET_INVENTORY_DETAIL_BY_LOCATION_RES,
  GET_INVENTORY_DETAIL_BY_SKU_ID_REQ,
  GET_INVENTORY_DETAIL_BY_SKU_ID_RES,
  GET_INVENTORY_LIST_BY_SKU_ID_REQ,
  GET_INVENTORY_LIST_BY_SKU_ID_RES,
  GET_LOCATION_DETAILS_BY_LOCATION_IDS_REQ,
  GET_LOCATION_DETAILS_BY_LOCATION_IDS_RES,
  GET_SINGLE_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_SINGLE_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_SINGLE_SKU_INVENTORY_LIST_REQ,
  GET_SINGLE_SKU_INVENTORY_LIST_RES,
  MODIFY_FAULTY_INVENTORY_REQ,
  MODIFY_FAULTY_INVENTORY_RES,
} from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import {
  MutationSideEffectType,
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import { ResponseFailureInfo } from "../../types/common/common";
import { Inventory } from "../../types/fulfillment/inventory";
import { reOrderInventoryAdjustmentPayloadByLocationId } from "../../utils/fulfillment/inventory";

import {
  QueryResponseHandlerCustomMessage,
  QueryResponseHandlerFailureModalInfo,
} from "../../components/QueryResponseHandler";

export const INVENTORY_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/INVENTORY_QUERY" }] as const,
  getAdminList: ({
    page,
    perPage,
    searchKind,
    searchTerm,
  }: GET_ADMIN_LIST_REQ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        page,
        perPage,
        searchKind,
        searchTerm,
        entity: "adminList",
      },
    ] as const,
  getAdminListForCSVDownload: (params: GET_ADMIN_LIST_FOR_CSV_DOWNLOAD_REQ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminListForCSVDownload",
      },
    ] as const,

  getAdminAdjustmentList: ({
    page,
    perPage,
    searchKind,
    searchTerm,
    dateFrom,
    dateTo,
  }: GET_ADMIN_ADJUSTMENT_LIST_REQ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        page,
        perPage,
        searchKind,
        searchTerm,
        dateFrom,
        dateTo,
        entity: "adminAdjustmentList",
      },
    ] as const,
  getAdminAdjustmentListForCSVDownload: ({
    searchKind,
    searchTerm,
    dateFrom,
    dateTo,
  }: GET_ADMIN_ADJUSTMENT_LIST_FOR_CSV_DOWNLOAD_REQ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        searchKind,
        searchTerm,
        dateFrom,
        dateTo,
        entity: "adminAdjustmentListForCSVDownload",
      },
    ] as const,
  getListBySkuId: (params: GET_INVENTORY_LIST_BY_SKU_ID_REQ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getListBySkuId",
      },
    ] as const,
  getInventoryDetailBySkuId: (params: GET_INVENTORY_DETAIL_BY_SKU_ID_REQ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "inventoryDetailBySkuId",
      },
    ] as const,

  getInventoryDetailByLocation: (
    params: GET_INVENTORY_DETAIL_BY_LOCATION_REQ
  ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "inventoryDetailByLocation",
      },
    ] as const,
  getLocationDetailsByLocationIds: (
    params: GET_LOCATION_DETAILS_BY_LOCATION_IDS_REQ
  ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "locationDetailsByLocationIds",
      },
    ] as const,

  getSingleSkuInventoryList: (params: GET_SINGLE_SKU_INVENTORY_LIST_REQ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "singleSkuInventoryList",
      },
    ] as const,
  getSingleSkuInventoryListForCSVDownload: (
    params: GET_SINGLE_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_REQ
  ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "singleSkuInventoryListForCSVDownload",
      },
    ] as const,
  getGroupSkuInventoryList: (params: GET_GROUP_SKU_INVENTORY_LIST_REQ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "groupSkuInventoryList",
      },
    ] as const,
  getGroupSkuInventoryListForCSVDownload: (
    params: GET_GROUP_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_REQ
  ) =>
    [
      {
        ...INVENTORY_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "groupSkuInventoryListForCSVDownload",
      },
    ] as const,
};

function useGetAdminList(params: GET_ADMIN_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof INVENTORY_QUERY_KEY_GEN.getAdminList>,
    GET_ADMIN_LIST_RES
  >({
    queryKey: INVENTORY_QUERY_KEY_GEN.getAdminList(params),
    requestOptions: {
      method: "get",
      path: "/inventory/admin",
      params,
    },
    keepPreviousData: true,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.error === "invalid skuid") {
          return {
            messageType: "titleOnly",
            title: "잘못된 skuID입니다",
          };
        }

        return {
          messageType: "titleOnly",
          title: "처리중 오류가 발생했습니다",
        };
      },
    },
  });

  return { ...queryResult };
}

function useGetAdminListForCSVDownload({
  enabled,
  ...params
}: GET_ADMIN_LIST_FOR_CSV_DOWNLOAD_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof INVENTORY_QUERY_KEY_GEN.getAdminListForCSVDownload>,
    GET_ADMIN_LIST_RES
  >({
    queryKey: INVENTORY_QUERY_KEY_GEN.getAdminListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/inventory/admin",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetAdminAdjustmentList({
  page,
  perPage,
  searchKind,
  searchTerm,
  dateFrom,
  dateTo,
}: GET_ADMIN_ADJUSTMENT_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof INVENTORY_QUERY_KEY_GEN.getAdminAdjustmentList>,
    GET_ADMIN_ADJUSTMENT_LIST_RES
  >({
    queryKey: INVENTORY_QUERY_KEY_GEN.getAdminAdjustmentList({
      page,
      perPage,
      searchKind,
      searchTerm,
      dateFrom,
      dateTo,
    }),
    requestOptions: {
      method: "get",
      path: "/inventory/admin/adjustment",
      apiType: "BofulDefault",
      params: {
        page,
        perPage,
        dateFrom,
        dateTo,
        ...(searchKind && searchTerm ? { searchKind, searchTerm } : {}),
      },
    },
    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useGetAdminAdjustmentListForCSVDownload({
  enabled,
  ...params
}: GET_ADMIN_ADJUSTMENT_LIST_FOR_CSV_DOWNLOAD_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof INVENTORY_QUERY_KEY_GEN.getAdminAdjustmentListForCSVDownload
    >,
    GET_ADMIN_ADJUSTMENT_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey:
      INVENTORY_QUERY_KEY_GEN.getAdminAdjustmentListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/inventory/admin/adjustment",
      apiType: "BofulDefault",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetInventoryListBySkuId({
  skuId,
  params,
  enabled,
}: {
  skuId: number;
  params: GET_INVENTORY_LIST_BY_SKU_ID_REQ;
  enabled: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof INVENTORY_QUERY_KEY_GEN.getListBySkuId>,
    GET_INVENTORY_LIST_BY_SKU_ID_RES
  >({
    queryKey: INVENTORY_QUERY_KEY_GEN.getListBySkuId(params),
    requestOptions: {
      method: "get",
      path: `/inventory/list/sku/${skuId}`,
      params,
    },

    keepPreviousData: true,

    enabled,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "SKU 재고 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetInventoryDetailBySkuId({
  skuId,
  enabled,
  onSuccess,
  failureModalInfo,
}: GET_INVENTORY_DETAIL_BY_SKU_ID_REQ & {
  enabled?: boolean;
  onSuccess?: ((data: GET_INVENTORY_DETAIL_BY_SKU_ID_RES) => void) | undefined;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo | undefined;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof INVENTORY_QUERY_KEY_GEN.getInventoryDetailBySkuId>,
    GET_INVENTORY_DETAIL_BY_SKU_ID_RES
  >({
    queryKey: INVENTORY_QUERY_KEY_GEN.getInventoryDetailBySkuId({ skuId }),
    requestOptions: {
      method: "get",
      path: `/inventory/sku/${skuId}`,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    onSuccess,

    failureModalInfo,
  });

  return { ...queryResult };
}

function useGetInventoryDetailByLocation({
  barCode,
  type,
  enabled,
  onSuccess,
  onError,
}: GET_INVENTORY_DETAIL_BY_LOCATION_REQ & {
  type?: "adjustment";
  enabled?: boolean;
  onSuccess?: (data: GET_INVENTORY_DETAIL_BY_LOCATION_RES) => void;
  onError?: (err: ResponseFailureInfo | undefined) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof INVENTORY_QUERY_KEY_GEN.getInventoryDetailByLocation>,
    GET_INVENTORY_DETAIL_BY_LOCATION_RES
  >({
    queryKey: INVENTORY_QUERY_KEY_GEN.getInventoryDetailByLocation({ barCode }),
    requestOptions: {
      method: "get",
      path: `/inventory/location`,
      params: { barCode },
    },

    enabled,

    onSuccess,

    onError,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({ code }: { code: number | undefined }) =>
          ({
            404: {
              title: "해당 위치에는 재고가 없습니다.",
              body:
                type === "adjustment"
                  ? "재고 조정을 하시려면 SKU ID를 입력해주세요."
                  : undefined,
            },
          }[code ?? 0] ?? {
            title: "위치별 재고 조회 중에 오류가 발생했습니다.",
          });

        return getErrorMessage({ code: failureInfo?.code });
      },
    },
  });

  return { ...queryResult };
}

function useCompleteInventoryMovement() {
  const mutation = useAppMutation<COMPLETE_INVENTORY_MOVEMENT_REQ, Inventory[]>(
    {
      requestOptions: {
        method: "patch",
        path: `/inventory/location/movement`,
        apiType: "BofulDefault",
      },

      failureModalInfo: {
        customizeMessage: () => ({
          title: `재고 이동 / 분할 완료 중에 오류가 발생했습니다.`,
        }),
      },
    }
  );

  return { ...mutation };
}

function useCompleteInventoryConsolidation() {
  const mutation = useAppMutation<
    COMPLETE_INVENTORY_CONSOLIDATION_REQ,
    Inventory
  >({
    requestOptions: {
      method: "patch",
      path: `/inventory/location/consolidation`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `재고 병합 완료 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...mutation };
}

function useCompleteInventoryAdjustment() {
  const mutation = useAppMutation<COMPLETE_INVENTORY_ADJUSTMENT_REQ, Inventory>(
    {
      requestOptions: {
        method: "patch",
        path: `/inventory/location/adjustment`,
        apiType: "BofulDefault",
      },

      failureModalInfo: {
        customizeMessage: () => ({
          title: `재고 조정 완료 중에 오류가 발생했습니다.`,
        }),
      },
    }
  );

  return { ...mutation };
}

function useAdminInventoryAdjustment(
  sideEffectOptions?: MutationSideEffectType<
    ADMIN_INVENTORY_ADJUSTMENT_REQ,
    Inventory
  >
) {
  const mutation = useAppMutation<ADMIN_INVENTORY_ADJUSTMENT_REQ, Inventory>({
    requestOptions: {
      method: "patch",
      path: `/inventory/admin/adjustment`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,
    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 400 && failureInfo?.errorCode === "E5006") {
          return {
            title: "비어있는 '보관위치'를 선택해주세요",
            body: (
              <>
                아래 위치는 선택할 수 없습니다
                {/* TODO: failureInfo에 동적으로 추가 속성을 추가할 수 있도록 수정한 후 타입단언 빼기 */}
                {(
                  failureInfo as unknown as {
                    locations?: { id: number; barCode: string }[];
                  }
                ).locations?.map((v) => (
                  <p key={v.id}>{v.barCode}</p>
                ))}
              </>
            ),
          };
        }

        return { title: "재고 이동 처리 중에 오류가 발생했습니다" };
      },
    },
  });

  const customizedMutate = useCallback(
    (params: Parameters<typeof mutation.mutate>[0]) => {
      // API에서 inventoryFrom과  inventoryTo를 정렬해서 보내달라고 요청함
      const newParams = params
        ? {
            ...params,
            inventoryFrom: reOrderInventoryAdjustmentPayloadByLocationId(
              params.inventoryFrom
            ),
            inventoryTo: reOrderInventoryAdjustmentPayloadByLocationId(
              params.inventoryTo
            ),
          }
        : params;

      return mutation.mutate(newParams);
    },

    [mutation]
  );

  return { ...mutation, mutate: customizedMutate };
}

function useGetLocationDetailsByLocationIds({
  enabled,
  onSuccess,
  ...params
}: GET_LOCATION_DETAILS_BY_LOCATION_IDS_REQ & {
  enabled?: boolean;
  onSuccess?: (data: GET_LOCATION_DETAILS_BY_LOCATION_IDS_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof INVENTORY_QUERY_KEY_GEN.getLocationDetailsByLocationIds>,
    GET_LOCATION_DETAILS_BY_LOCATION_IDS_RES
  >({
    queryKey: INVENTORY_QUERY_KEY_GEN.getLocationDetailsByLocationIds(params),
    requestOptions: {
      method: "get",
      path: `/location`,
      params: params,
    },
    enabled,
    onSuccess,
  });

  return { ...queryResult };
}

function useGetSingleSkuInventoryList(
  params: GET_SINGLE_SKU_INVENTORY_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof INVENTORY_QUERY_KEY_GEN.getSingleSkuInventoryList>,
    GET_SINGLE_SKU_INVENTORY_LIST_RES
  >({
    queryKey: INVENTORY_QUERY_KEY_GEN.getSingleSkuInventoryList(params),
    requestOptions: {
      apiType: "BofulDefault",
      method: "get",
      path: `/inventory/user`,
      params,
    },
  });

  return { ...queryResult };
}

function useGetSingleSkuInventoryListForCSVDownload({
  enabled,
  ...params
}: GET_SINGLE_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof INVENTORY_QUERY_KEY_GEN.getSingleSkuInventoryListForCSVDownload
    >,
    GET_SINGLE_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey:
      INVENTORY_QUERY_KEY_GEN.getSingleSkuInventoryListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/inventory/user",
      apiType: "BofulDefault",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetGroupSkuInventoryList(params: GET_GROUP_SKU_INVENTORY_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof INVENTORY_QUERY_KEY_GEN.getGroupSkuInventoryList>,
    GET_GROUP_SKU_INVENTORY_LIST_RES
  >({
    queryKey: INVENTORY_QUERY_KEY_GEN.getGroupSkuInventoryList(params),
    requestOptions: {
      apiType: "BofulDefault",
      method: "get",
      path: `/inventory/group`,
      params,
    },
  });

  return { ...queryResult };
}

function useGetGroupSkuInventoryListForCSVDownload({
  enabled,
  ...params
}: GET_GROUP_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof INVENTORY_QUERY_KEY_GEN.getGroupSkuInventoryListForCSVDownload
    >,
    GET_GROUP_SKU_INVENTORY_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey:
      INVENTORY_QUERY_KEY_GEN.getGroupSkuInventoryListForCSVDownload(params),
    requestOptions: {
      apiType: "BofulDefault",
      method: "get",
      path: `/inventory/group`,
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useModifyFaultyInventory() {
  const mutation = useAppMutation<
    MODIFY_FAULTY_INVENTORY_REQ,
    MODIFY_FAULTY_INVENTORY_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/inventory/admin/faulty`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        const errorMessage: {
          [errorCode: string]: QueryResponseHandlerCustomMessage;
        } = {
          E5008: {
            messageType: "titleOnly",
            title: "요청하신 수량이 재고 수량과 맞지 않습니다.",
          },
          E4005: {
            messageType: "titleOnly",
            title: "존재하지 않는 상품입니다.",
          },
        };

        return (
          errorMessage[failureInfo?.errorCode ?? ""] ?? {
            title: "불량 재고 수정 중에 중에 오류가 발생했습니다.",
          }
        );
      },
    },
  });

  return { ...mutation };
}

function useForceCreateInventory() {
  const mutation = useAppMutation<
    FORCE_CREATE_INVENTORY_REQ,
    FORCE_CREATE_INVENTORY_RES
  >({
    requestOptions: {
      method: "post",
      path: `/inventory/admin/force`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        const errorMessage: {
          [errorCode: string]: QueryResponseHandlerCustomMessage;
        } = {
          E5020: {
            messageType: "titleOnly",
            title: "바코드에 일치하는 로케이션이 없습니다.",
          },

          E5021: {
            messageType: "titleOnly",
            title: "SKU ID에 일치하는 SKU가 없습니다.",
          },

          E5022: {
            messageType: "titleOnly",
            title: "해당 로케이션은 이미 할당되어있습니다.",
          },
        };

        return (
          errorMessage[failureInfo?.errorCode ?? ""] ?? {
            title: "유통 상품 재고 등록 중에 오류가 발생했습니다.",
          }
        );
      },
    },
  });

  return { ...mutation };
}

const INVENTORY_QUERY = {
  useGetAdminList,
  useGetAdminListForCSVDownload,
  useGetAdminAdjustmentList,
  useGetAdminAdjustmentListForCSVDownload,
  useGetInventoryListBySkuId,
  useGetInventoryDetailBySkuId,
  useGetInventoryDetailByLocation,
  useCompleteInventoryMovement,
  useCompleteInventoryConsolidation,
  useCompleteInventoryAdjustment,
  useAdminInventoryAdjustment,
  useGetLocationDetailsByLocationIds,
  useGetSingleSkuInventoryList,
  useGetSingleSkuInventoryListForCSVDownload,
  useGetGroupSkuInventoryList,
  useGetGroupSkuInventoryListForCSVDownload,
  useModifyFaultyInventory,
  useForceCreateInventory,
};

export default INVENTORY_QUERY;
