import { useRecoilValue } from "recoil";

import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";

import ReportReceivingProblem from "containers/ReportReceivingProblem";

import CloseAsNormal from "./CloseAsNormal";

function CloseInspection({ receivingId }: { receivingId: number }) {
  const isCompletedInspectionWithMatchedCount = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_COMPLETED_INSPECTION_WITH_MATCHED_COUNT
  );

  return (
    <>
      {isCompletedInspectionWithMatchedCount ? (
        <CloseAsNormal receivingId={receivingId} />
      ) : (
        <ReportReceivingProblem
          receivingId={receivingId}
          reportedStep="inspection"
        />
      )}
    </>
  );
}

export default CloseInspection;
