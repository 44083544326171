import { memo } from "react";

import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";

import Styled from "./index.styles";

function LocationToPicking({
  locationBarcode,
  setSkuInfoToChangePickingLocation,
}: {
  locationBarcode: string;
  setSkuInfoToChangePickingLocation: () => void;
}) {
  return (
    <Styled.locationToPicking>
      <span>{locationBarcode}</span>
      <TextButton
        label="변경"
        size="14px"
        theme="withIcon"
        icon="arrowRight"
        handleClick={setSkuInfoToChangePickingLocation}
      />
    </Styled.locationToPicking>
  );
}

export default memo(LocationToPicking);
