import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import BofulRequestResponseHandler from "containers/BofulRequestResponseHandler";
import { returningActions, returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

export default function useFetchDetailForInspection({
  returningId,
}: {
  returningId: number;
}) {
  const dispatch = useDispatch();

  const { getDetailReturningRes, SKUItemsFilteredByQuantityReturning } =
    useAppSelector((state) => {
      return {
        getDetailReturningRes: state.returning.GET_DETAIL,
        SKUItemsFilteredByQuantityReturning:
          returningSelectors.getSKUItemsFilteredByQuantity(state),
      };
    });

  useEffect(() => {
    if (returningId) {
      dispatch(returningActions.GET_DETAIL({ id: returningId }));
    }

    return () => {
      dispatch(returningActions.INIT_GET_DETAIL({}));
    };
  }, [returningId, dispatch]);

  const ResponseHandlerOfDetail = useMemo(() => {
    return (
      <BofulRequestResponseHandler
        response={getDetailReturningRes}
        initRequest={() => {
          dispatch(returningActions.INIT_GET_DETAIL({}));
        }}
        loading={{
          label: "검수 정보 조회 중",
          actionType: returningActions.GET_DETAIL.type,
        }}
        failureModalInfo={{
          customizeMessage: () => ({
            title: "검수 정보 조회 중에 오류가 발생했습니다.",
          }),
        }}
      />
    );
  }, [getDetailReturningRes, dispatch]);

  return {
    returningDetailData: getDetailReturningRes?.data?.returning,
    SKUItemsFilteredByQuantityReturning,

    ResponseHandlerOfDetail,
  };
}
