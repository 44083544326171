import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

import { TabDirection, TabUiType } from ".";

const container = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * <overflow관련 디자이너와의 협의내용>
 * - 가로 넘침의 경우 모바일은 공통적으로 가로 스크롤 적용함
 * - 데스크탑은 실제 사용 필요한 것들의 스타일만 넣어줌(기본 상하 간격 16px)
 *
 */
const tabList = styled.div<{ uiType: TabUiType; direction: TabDirection }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* 데스크탑 overflow때의 row간 gap용  */
  row-gap: 8px;

  ${mobile(css`
    margin: 0;
  `)}

  ${({ direction }) => {
    if (direction === "vertical")
      return css`
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;

        > button {
          width: 100%;
          margin: 8px 0;
        }
      `;

    return css`
      padding: 8px;
      flex-direction: row;
      flex-wrap: wrap;

      ${mobile(css`
        flex-wrap: nowrap;
      `)}
    `;
  }};

  ${({ uiType }) => {
    switch (uiType) {
      case "lined": {
        return css`
          /* underline 효과때문에 추가 */
          padding-bottom: 8px;
        `;
      }
      case "rounded": {
        return css`
          justify-content: flex-start;
          margin-left: 0;
          padding: 0;
        `;
      }

      case "text": {
        return css`
          padding: 0;

          ${mobile(css`
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            padding: 0;
            margin: -10px 0;
          `)}
        `;
      }
    }
  }};
`;

const tabItem = styled.button<{ uiType: TabUiType }>`
  flex-shrink: 0;

  ${mobile(css`
    margin-top: 0;
    margin-bottom: 0;
  `)}

  ${({ uiType }) => {
    switch (uiType) {
      case "box": {
        return css`
          ${setFontStyle("SubHead2")};
          padding: 8px 16px;
          background-color: transparent;
          border-radius: 8px;
          margin-bottom: 16px;
          text-align: center;
          color: ${TEXT_COLOR.black_2};
          cursor: pointer;
          border: 0;

          ${mobile(css`
            margin-bottom: 0;
          `)}

          &:hover:enabled {
            background-color: ${COLOR.grayScale_50};
            color: ${TEXT_COLOR.black_2};
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${COLOR.wh};
            background-color: ${COLOR.grayScale_600};
          }

          &:disabled {
            color: ${TEXT_COLOR.black_disabled};
            cursor: not-allowed;
          }
        `;
      }
      case "rounded": {
        return css`
          ${setFontStyle("SubHead2")};
          padding: 4px 15px;
          background-color: transparent;
          border: 1px solid ${COLOR.grayScale_500};
          border-radius: 100px;
          color: ${COLOR.grayScale_700};
          text-align: center;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 16px;
          }

          &:hover:enabled {
            color: ${COLOR.grayScale_500};
            border: 1px solid ${COLOR.grayScale_500};
            background-color: ${COLOR.bk_4};
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${COLOR.wh};
            border: 1px solid ${COLOR.grayScale_600};
            background-color: ${COLOR.grayScale_600};
          }

          &:disabled {
            color: ${COLOR.grayScale_400};
            border: 1px solid ${COLOR.grayScale_400};
            background-color: ${COLOR.grayScale_50};
            cursor: not-allowed;
          }
        `;
      }

      case "text": {
        return css`
          ${setFontStyle("SubHead3")};
          padding: 1px 16px;
          background-color: transparent;
          color: ${TEXT_COLOR.black_3};
          text-align: center;
          cursor: pointer;
          border: none;
          position: relative;
          margin: 10px 9px 10px 8px;

          ${mobile(css`
            margin: 10px 8px;
            padding: 1px 13px;
          `)}

          &:not(:last-child) {
            > .divider {
              position: absolute;
              background-color: ${COLOR.grayScale_400};
              top: 0;
              right: -9px;
              width: 1px;
              height: 22px;
            }
          }

          &:hover:enabled {
            color: ${COLOR.grayScale_700};
            background-color: ${COLOR.bk_4};
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${TEXT_COLOR.black_1};
          }

          &:disabled {
            color: ${COLOR.grayScale_300};
            cursor: not-allowed;
          }
        `;
      }

      case "radioCheck": {
        return css`
          ${setFontStyle("SubHead2")};
          background-color: transparent;
          color: ${COLOR.grayScale_400};
          text-align: center;
          cursor: pointer;
          border: none;
          position: relative;
          display: inline-flex;
          align-items: center;

          > .radio-icon {
            display: inline-flex;
            margin-right: 4px;
          }

          &:not(:last-child) {
            margin-right: 16px;
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${COLOR.primary_600};
          }

          &:disabled {
            color: ${COLOR.grayScale_300};
            cursor: not-allowed;
          }
        `;
      }

      case "lined": {
        return css`
          ${setFontStyle("SubHead2")};
          padding: 0;
          margin: 0;
          background-color: transparent;
          color: ${COLOR.grayScale_500};
          text-align: center;
          cursor: pointer;
          border: none;
          position: relative;
          display: inline-flex;

          > .upper-dot {
            width: 4px;
            height: 4px;
            margin-top: 3px;
            margin-left: 4px;
            border-radius: 50%;
            background-color: ${COLOR.point_400};
          }

          > .underline {
            position: absolute;
            bottom: -8px;
            left: 0;
            height: 2px;
            width: 100%;
            background-color: transparent;
          }

          &:not(:last-child) {
            margin-right: 24px;
          }

          &:hover:enabled {
            color: ${COLOR.grayScale_500};
            background-color: ${COLOR.bk_4};
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${COLOR.grayScale_800};

            > .underline {
              background-color: ${COLOR.grayScale_800};
            }
          }

          &:disabled {
            color: ${COLOR.grayScale_300};
            cursor: not-allowed;
          }
        `;
      }
    }
  }};
`;

const contentsContainer = styled.div`
  margin-top: 20px;
`;

export default {
  container,
  tabList,
  tabItem,
  contentsContainer,
};
