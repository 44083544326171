import { memo } from "react";

import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";

import Styled from "./index.styles";

function CountForScanning({
  // type,
  canOpenDirectInputModal,
  // skuId,
  currentQty,
  quantity,
  // onCountAdd,
  openDirectInputModal,
}: {
  // type: "inspection" | "warehousing" | "picking" | "restocking";
  canOpenDirectInputModal: boolean;
  // skuId: number;
  currentQty: number | undefined;
  quantity: number;
  // onCountAdd: (count: number | undefined) => void;
  openDirectInputModal: () => void;
}) {
  // const {
  //   isVisibleDirectInputModal,

  //   directInputForCount,
  //   setDirectInputForCount,

  //   remainingQuantity,

  //   handleDirectInputModalOpen,
  //   handleDirectInputModalClose,
  //   handleCountAdd,
  // } = useCountForScanning({ currentQty, quantity, onCountAdd });

  return (
    <>
      <Styled.count>
        {getStyledSKUCount({ currentCount: currentQty, goalCount: quantity })}

        <TextButton
          label="직접 입력"
          theme="withIcon"
          icon="arrowRight"
          size="14px"
          handleClick={openDirectInputModal}
          disabled={!canOpenDirectInputModal}
        />
      </Styled.count>

      {/* <Modal
        active={isVisibleDirectInputModal}
        uiType="contentWithCustomBody"
        title={`${getFormattedSingleSkuId(skuId)}(SKU ID)`}
        body={
          <Styled.directInputModalBody>
            <strong className="remaining-quantity">
              잔여수량: {remainingQuantity}
            </strong>

            <InputText
              borderType="outline"
              valueType="int"
              label={`${
                {
                  inspection: "검수",
                  warehousing: "입고",
                  picking: "피킹",
                  restocking: "재입고",
                }[type]
              } 수량`}
              placeholder="입력해주세요."
              value={directInputForCount}
              setValue={setDirectInputForCount}
              errorMessage={
                validateCount({
                  count: directInputForCount,
                  maxCount: remainingQuantity,
                }).message
              }
            />

            <Button
              theme="primary"
              size="normal"
              label="확인"
              disabled={
                !validateCount({
                  count: directInputForCount,
                  maxCount: remainingQuantity,
                }).result
              }
              handleClick={handleCountAdd}
            />
          </Styled.directInputModalBody>
        }
        onClose={handleDirectInputModalClose}
      /> */}
    </>
  );
}

export default memo(CountForScanning);
