import React, { useCallback } from "react";
import { useQueryClient } from "react-query";

import { SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

import { ConfirmModal } from "hooks/common/useConfirmModal";

export default function useCompleteWarehousingByForce() {
  const queryClient = useQueryClient();

  const {
    mutate: setItemWarehousingDoneByForce,
    ResponseHandler: ResponseHandlerOfCompletingWarehousingByForce,
  } = RECEIVING_QUERY.useSetItemWarehousingDoneByForce();

  const completeWarehousingByForce = useCallback(
    ({
        receivingId,
        skuId,
        setConfirmModal,
      }: SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS & {
        setConfirmModal: React.Dispatch<
          React.SetStateAction<ConfirmModal | undefined>
        >;
      }) =>
      () => {
        setItemWarehousingDoneByForce(
          {
            pathParams: {
              receivingId,
              skuId,
            },
          },
          {
            onSuccess: () => {
              setConfirmModal(undefined);

              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      },
    [queryClient, setItemWarehousingDoneByForce]
  );

  return {
    completeWarehousingByForce,
    ResponseHandlerOfCompletingWarehousingByForce,
  };
}
