import styled from "styled-components";

const modalBodyContainer = styled.div`
  > .table > table {
    width: 100%;
    height: 390px;
  }
`;

const modalButtonContainer = styled.div`
  padding-top: 16px;
`;

export default {
  modalBodyContainer,
  modalButtonContainer,
};
