import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function ResetCounting({
  skuId,
  canReset,
  // reset,
  openConfirmModal,
}: {
  skuId: number;
  canReset: boolean;
  // reset: () => void;
  openConfirmModal: () => void;
}) {
  return (
    // <ConfirmWrapper
    //   confirmMessageTitle={`${getFormattedSingleSkuId(skuId)}(SKU ID)`}
    //   confirmMessageBody={
    //     <>
    //       초기화를 진행하겠습니까? <br />
    //       (분할내역도 초기화 됩니다.)
    //     </>
    //   }
    //   confirmModalUiType="content"
    //   disabled={!canReset}
    // >
    <Button
      label="초기화"
      size="small"
      theme="tertiary"
      handleClick={openConfirmModal}
      // handleClick={reset}
      disabled={!canReset}
    />
    // </ConfirmWrapper>
  );
}

export default memo(ResetCounting);
