import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";
import { getWorkerNameByAuthority } from "@sellernote/_shared/src/utils/fulfillment/common";
import { sortListByLocationIdAndSKUId } from "@sellernote/_shared/src/utils/fulfillment/shipping";

export interface ReportingListItem {
  id: number;
  locationBarcode: string;
  skuId: number;
  skuBarcode: string | undefined;
  currentQty: number;
  quantity: number;
  workerName: string;
}

export default function useReportPickingProblem({
  SKUInfoForScanning,
}: {
  SKUInfoForScanning: PickingSKUInfoForScanning[];
}) {
  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const reportingList = useMemo((): ReportingListItem[] => {
    const filteredList = SKUInfoForScanning.filter(
      (v) => v.currentQty !== v.quantity
    );

    const sortedList = sortListByLocationIdAndSKUId(filteredList);

    return sortedList.map((v) => ({
      id: v.id,
      locationBarcode:
        locationListOfWarehouse.find((location) => location.id === v.locationId)
          ?.barCode ?? "",
      skuId: v.skuId,
      skuBarcode: v.skuBarcode,
      currentQty: v.currentQty,
      quantity: v.quantity,
      workerName: getWorkerNameByAuthority(currentUser),
    }));
  }, [SKUInfoForScanning, currentUser, locationListOfWarehouse]);

  return { reportingList };
}
