import React, { useCallback, useMemo, useRef } from "react";

import ReactWindowList from "@sellernote/_shared/src/components/ReactWindowList";
import useTable, {
  ColSpan,
  NoBorderBottomForTd,
  RowToHighlightRef,
  TableColumnInfo,
  TableColumnInfoValue,
  TableDataListItem,
  TableProps,
  TablePropsWithRef,
  TableRowInfoToHighlight,
  TableSubRowsInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { useWindowResize } from "@sellernote/_shared/src/utils/common/hook";

import Styled from "./index.styles";

function TableBodyRow<T>({
  rowKey,
  rowMinHeight,
  rowInfoToHighlight,
  subRowsInfoToHighlight,
  rowToHighlightRef,
  disabled,
  handleRowClick,
  rowClassName,
  isBlinkOnHighlightRow,
  rowFontColor,
  rowBackgroundColor,
  disabledRowHoverBgColor,
  makeColumns,
  rowData,
}: {
  rowKey: string | number;
  rowMinHeight?: string;
  rowInfoToHighlight?: TableRowInfoToHighlight;
  subRowsInfoToHighlight?: TableSubRowsInfoToHighlight;
  rowToHighlightRef: RowToHighlightRef;
  disabled?: boolean;
  handleRowClick?: () => void;
  rowClassName?: string;
  isBlinkOnHighlightRow: boolean;
  rowFontColor?: string;
  rowBackgroundColor?: string;
  disabledRowHoverBgColor?: boolean;
  makeColumns: (rowData: TableDataListItem<T>) => JSX.Element[];
  rowData: TableDataListItem<T>;
}) {
  return (
    <Styled.TableTr
      key={`row${rowKey}`}
      rowMinHeight={rowMinHeight}
      ref={
        rowKey === rowInfoToHighlight?.rowKey ? rowToHighlightRef : undefined
      }
      onClick={handleRowClick}
      className={`${disabled ? "disabled" : ""} ${
        handleRowClick ? "clickable" : ""
      } ${rowClassName || ""} ${(() => {
        const isRowToHighlight =
          rowKey === rowInfoToHighlight?.rowKey && isBlinkOnHighlightRow;
        const isSubRowToHighlight =
          subRowsInfoToHighlight?.some(
            (subRowInfo) => subRowInfo.rowKey === rowKey
          ) && isBlinkOnHighlightRow;
        if (isRowToHighlight || isSubRowToHighlight) {
          return "blink";
        }

        return "";
      })()}`}
      rowFontColor={rowFontColor}
      isHighlighted={(() => {
        const isRowToHighlight = rowKey === rowInfoToHighlight?.rowKey;
        const isSubRowToHighlight = subRowsInfoToHighlight?.some(
          (subRowInfo) => subRowInfo.rowKey === rowKey
        );
        if (isRowToHighlight || isSubRowToHighlight) {
          return true;
        }

        return false;
      })()}
      rowBackgroundColor={rowBackgroundColor}
      disabledRowHoverBgColor={disabledRowHoverBgColor}
    >
      {makeColumns(rowData)}
    </Styled.TableTr>
  );
}

const Table = <T extends unknown>(
  {
    columnGroupInfo,
    columnInfo,
    dataList,
    rowInfoToHighlight,
    subRowsInfoToHighlight,
    rowMinHeight,
    isHorizontalScrollable,
    disabledRowHoverBgColor,
    className,
    ...propsByType
  }: TableProps<T>,
  ref: RowToHighlightRef
) => {
  const {
    tableRef,
    tableLeftEndRef,
    tableRightEndRef,
    tableHasArriveLeftEnd,
    tableHasArriveRightEnd,
    onScrollTable,
    isBlinkOnHighlightRow,
    isOverflowed,
    columnGroupSizes,
  } = useTable({
    columnGroupInfo,
    columnInfo,
    dataList,
    rowInfoToHighlight,
    rowMinHeight,
    isHorizontalScrollable,
    disabledRowHoverBgColor,
    className,
    ...propsByType,
  });

  const tableContainerRef = useRef<HTMLDivElement>(null);

  // windowSize 가 바뀔때 리렌더링되어 ref관련 사이즈를 재계산하기 위함
  useWindowResize();

  const tableWidth = (() => {
    if (!isHorizontalScrollable) return;

    const tableContainerWidth =
      tableContainerRef.current?.getClientRects()[0]?.width || 0;

    // 가로 스크롤이 가능한 테이블인 경우만 tableContainer만큼의 고정 tableWidth를 부여
    return `${tableContainerWidth}px`;
  })();

  const makeColumns = useCallback(
    (rowData: TableDataListItem<T>) => {
      const result = [];

      let noBorderBottomForTd: NoBorderBottomForTd[] = [];
      for (const [key, value] of Object.entries(rowData)) {
        if (
          (key as keyof TableDataListItem<T>) === "disabled" ||
          (key as keyof TableDataListItem<T>) === "rowKey" ||
          (key as keyof TableDataListItem<T>) === "rowFontColor" ||
          (key as keyof TableDataListItem<T>) === "rowBackgroundColor" ||
          (key as keyof TableDataListItem<T>) === "handleRowClick" ||
          (key as keyof TableDataListItem<T>) === "noBorderBottom" ||
          (key as keyof TableDataListItem<T>) === "rowClassName" ||
          !Object.keys(columnInfo).includes(key)
        ) {
          continue;
        }

        const columnPortion =
          columnInfo[key as keyof TableColumnInfo<T>]?.portion;

        const columnMinWidth =
          columnInfo[key as keyof TableColumnInfo<T>]?.minWidth;

        const columnMaxWidth =
          columnInfo[key as keyof TableColumnInfo<T>]?.maxWidth;

        const columnFixedWidth =
          columnInfo[key as keyof TableColumnInfo<T>]?.fixedWidth;

        if ((key as keyof TableDataListItem<T>) === "colSpan") {
          const colSpanValue = value as ColSpan;

          result.push(
            <Styled.TableTd
              colSpan={colSpanValue.value}
              key={key}
              portion={columnPortion}
              minWidth={columnMinWidth}
              maxWidth={columnMaxWidth}
              fixedWidth={columnFixedWidth}
              isOverflowed={isOverflowed}
              noBorderBottom={rowData.noBorderBottom}
              rowMinHeight={rowMinHeight}
              style={{
                ...(colSpanValue.hasFullWidth && {
                  width: tableRef.current.scrollWidth,
                }),
              }}
            >
              {colSpanValue.content}
            </Styled.TableTd>
          );

          continue;
        }

        /** noBorderBottomForTd */
        if ((key as keyof TableDataListItem<T>) === "noBorderBottomForTd") {
          noBorderBottomForTd = value as NoBorderBottomForTd[];

          continue;
        }

        const noBorderBottomForTdKeys = noBorderBottomForTd.map((v) => v.key);
        if (noBorderBottomForTdKeys.includes(key)) {
          const tdInfo = noBorderBottomForTd.find((v) => v.key === key);

          result.push(
            <Styled.TableTd
              noBorderBottomForTd={tdInfo}
              key={key}
              portion={columnPortion}
              minWidth={columnMinWidth}
              maxWidth={columnMaxWidth}
              fixedWidth={columnFixedWidth}
              isOverflowed={isOverflowed}
              noBorderBottom={rowData.noBorderBottom}
              rowMinHeight={rowMinHeight}
            >
              {value}
            </Styled.TableTd>
          );

          continue;
        }

        /** 일반 Td */
        result.push(
          <Styled.TableTd
            key={key}
            portion={columnPortion}
            minWidth={columnMinWidth}
            maxWidth={columnMaxWidth}
            fixedWidth={columnFixedWidth}
            isOverflowed={isOverflowed}
            noBorderBottom={rowData.noBorderBottom}
            rowMinHeight={rowMinHeight}
          >
            {value}
          </Styled.TableTd>
        );
      }

      return result;
    },
    [columnInfo, isOverflowed, rowMinHeight, tableRef]
  );

  const RowsForNotIsWindowed = useMemo(() => {
    if (propsByType.isWindowed) return null;

    return dataList?.map((row) => {
      return (
        <TableBodyRow<T>
          key={row.rowKey}
          rowKey={row.rowKey}
          rowMinHeight={rowMinHeight}
          rowInfoToHighlight={rowInfoToHighlight}
          subRowsInfoToHighlight={subRowsInfoToHighlight}
          rowToHighlightRef={ref}
          disabled={row.disabled}
          handleRowClick={row.handleRowClick}
          rowClassName={row.rowClassName}
          isBlinkOnHighlightRow={isBlinkOnHighlightRow}
          rowFontColor={row.rowFontColor}
          rowBackgroundColor={row.rowBackgroundColor}
          disabledRowHoverBgColor={row.disabledRowHoverBgColor}
          makeColumns={makeColumns}
          rowData={row}
        />
      );
    });
  }, [
    propsByType.isWindowed,
    dataList,
    rowMinHeight,
    rowInfoToHighlight,
    subRowsInfoToHighlight,
    ref,
    isBlinkOnHighlightRow,
    makeColumns,
  ]);

  return (
    <Styled.container
      className={`${className || ""} table`}
      isOverflowed={isOverflowed}
      tableWidth={tableWidth}
      columnGroupSizes={columnGroupSizes}
      ref={tableContainerRef}
    >
      {!tableHasArriveLeftEnd && <div className="curtain left" />}

      {!tableHasArriveRightEnd && <div className="curtain right" />}

      <table
        ref={tableRef}
        {...(isOverflowed
          ? {
              onScroll: () => {
                onScrollTable();
              },
            }
          : {})}
      >
        <thead>
          {columnGroupInfo && (
            <Styled.TableTr>
              {columnGroupInfo.map((col, i) => (
                <Styled.TableThGroup
                  key={`col${i}`}
                  scope="colgroup"
                  portion={(col as TableColumnInfoValue).portion}
                  minWidth={(col as TableColumnInfoValue).minWidth}
                  maxWidth={(col as TableColumnInfoValue).maxWidth}
                  fixedWidth={col.fixedWidth}
                  isOverflowed={isOverflowed}
                  colSpan={col.colSpan}
                >
                  {(col as TableColumnInfoValue).label}
                </Styled.TableThGroup>
              ))}
            </Styled.TableTr>
          )}
          <Styled.TableTr>
            <th ref={tableLeftEndRef} style={{ visibility: "hidden" }} />
            {Object.values(columnInfo).map((col, i) => (
              <Styled.TableTh
                key={`col${i}`}
                scope="col"
                portion={(col as TableColumnInfoValue).portion}
                minWidth={(col as TableColumnInfoValue).minWidth}
                maxWidth={(col as TableColumnInfoValue).maxWidth}
                fixedWidth={(col as TableColumnInfoValue).fixedWidth}
                isOverflowed={isOverflowed}
              >
                {(col as TableColumnInfoValue).label}
              </Styled.TableTh>
            ))}
            <th ref={tableRightEndRef} style={{ visibility: "hidden" }} />
          </Styled.TableTr>
        </thead>

        <tbody>
          {propsByType.isWindowed ? (
            <ReactWindowList
              height={propsByType.height}
              scrollWidth={tableRef.current?.scrollWidth}
              dataList={dataList}
              rowKeyToHighlight={rowInfoToHighlight?.rowKey}
              Row={({ data, index }) => (
                <TableBodyRow
                  rowKey={data[index].rowKey}
                  rowMinHeight={rowMinHeight}
                  rowInfoToHighlight={rowInfoToHighlight}
                  rowToHighlightRef={ref}
                  disabled={data[index].disabled}
                  handleRowClick={data[index].handleRowClick}
                  rowClassName={data[index].rowClassName}
                  isBlinkOnHighlightRow={isBlinkOnHighlightRow}
                  rowFontColor={data[index].rowFontColor}
                  rowBackgroundColor={data[index].rowBackgroundColor}
                  disabledRowHoverBgColor={data[index].disabledRowHoverBgColor}
                  makeColumns={makeColumns}
                  rowData={data[index]}
                />
              )}
            />
          ) : (
            RowsForNotIsWindowed
          )}
        </tbody>
      </table>
    </Styled.container>
  );
};

export default React.forwardRef(Table) as <T extends unknown>(
  props: TablePropsWithRef<T>
) => React.ReactElement;
