import { useMemo } from "react";

import useToolTip, {
  ToolTipPropsV2,
} from "@sellernote/_shared/src/headlessComponents/useToolTip";
import {
  useCheckIsMobile,
  useWindowResize,
} from "@sellernote/_shared/src/utils/common/hook";
import XMarkDefaultIcon from "@sellernote/_sds-v2/src/components/svgIcons/XMarkDefaultIcon";

import { COLOR } from "../../styles/colors";

import Styled from "./index.styles";

export default function Tooltip({
  title,
  desc,
  width,
  disabled,
  position,
  children,
  className,
}: ToolTipPropsV2) {
  const {
    isVisibleToolTipBody,
    setIsVisibleToolTipBody,
    tooltipBodyTargetInfo,
    triggerRef,
    triggerTargetInfo,
    checkTriggerTarget,
    tooltipBodyRef,
    handleMouseEnter,
    handleMouseLeave,
    handleToolTipClick,
  } = useToolTip(disabled);

  const { isMobile } = useCheckIsMobile();

  const [windowWidth] = useWindowResize();

  const ToolTipBody = useMemo(() => {
    return (
      <Styled.tooltipBody
        isVisibleToolTipBody={isVisibleToolTipBody}
        position={position}
        triggerTargetInfo={triggerTargetInfo}
        tooltipBodyTargetInfo={tooltipBodyTargetInfo}
        width={width}
        vw={windowWidth}
        isMobile={isMobile}
        ref={tooltipBodyRef}
        className="tooltip-body"
      >
        <div className="contents">
          {title && (
            <div className="title-container">
              {title.Icon && (
                <title.Icon
                  width={16}
                  height={16}
                  color={COLOR.wh}
                  className="icon"
                />
              )}

              <div className="title">{title.message}</div>
            </div>
          )}

          {desc && (
            <Styled.desc>
              {Array.isArray(desc) ? (
                <div className="desc-list">
                  {desc.map((descItem, i) => (
                    <div className="item" key={i}>
                      <div className="bullet">・</div>

                      <div className="contents">{descItem}</div>
                    </div>
                  ))}
                </div>
              ) : (
                desc
              )}
            </Styled.desc>
          )}
        </div>

        {/**
         * <isVisibleToolTipBody를 다시 체크하는 이유>
         * parent에서 isVisibleToolTipBody에따라 visibility 속성만 조정하는데(툴팁의 height 계산 때문에 dom이 항상 있어야함)
         * SVG관련 충돌이 나므로 아예 Node자체를 없애도록 하기 위함
         */}
        {isMobile && isVisibleToolTipBody && (
          <div
            className="close"
            onClick={(e) => {
              e.stopPropagation();
              setIsVisibleToolTipBody(false);
            }}
            style={{ color: COLOR.wh }}
          >
            <XMarkDefaultIcon width={16} height={16} color={COLOR.wh} />
          </div>
        )}
      </Styled.tooltipBody>
    );
  }, [
    desc,
    isMobile,
    isVisibleToolTipBody,
    position,
    setIsVisibleToolTipBody,
    title,
    tooltipBodyRef,
    tooltipBodyTargetInfo,
    triggerTargetInfo,
    width,
    windowWidth,
  ]);

  return (
    <Styled.container
      className={`${className ? className : ""} tooltip`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        if (isMobile) {
          // 모바일의 경우는 close버튼으로만 닫히게 함
          return;
        }
        handleMouseLeave();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleToolTipClick();
      }}
      disabled={disabled}
    >
      <div
        ref={triggerRef}
        className="trigger"
        onMouseEnter={checkTriggerTarget}
        onClick={(e) => {
          e.preventDefault();
          checkTriggerTarget();
        }}
      >
        {children}
      </div>

      {ToolTipBody}
    </Styled.container>
  );
}
