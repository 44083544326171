import { useMemo } from "react";

import { ButtonPropsV2 } from "@sellernote/_shared/src/headlessComponents/button/useButton";

import InteractionStyleWrapper, {
  InteractionTheme,
} from "../../../styles/interactionStyle";

import Styled from "./index.styles";

export type ButtonTheme = InteractionTheme;

export default function Button({
  theme,
  borderType,
  size,
  width, // px, rem, % 관계없이 단위 포함해서 입력.
  label,
  handleClick,
  disabled,
  iconInfo,
  buttonType,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  className,
}: ButtonPropsV2) {
  const iconSize: number = useMemo(() => {
    if (size === "small") {
      return 12;
    }

    return 16;
  }, [size]);

  return (
    <Styled.container
      size={size}
      width={width}
      onClick={(e) => {
        if (!disabled) {
          handleClick(e);
        }
      }}
      className={`${className ? className : ""} button`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <InteractionStyleWrapper theme={theme} borderType={borderType}>
        <Styled.button
          borderType={borderType}
          disabled={disabled}
          size={size}
          iconInfo={iconInfo}
          type={buttonType ?? "submit"}
        >
          {iconInfo?.position === "left" && iconInfo.Icon && (
            <iconInfo.Icon
              width={iconSize}
              height={iconSize}
              color={iconInfo.color}
              className="icon"
            />
          )}

          <div className="label">{label}</div>

          {iconInfo?.position === "right" && iconInfo.Icon && (
            <iconInfo.Icon
              width={iconSize}
              height={iconSize}
              color={iconInfo.color}
              className="icon"
            />
          )}
        </Styled.button>
      </InteractionStyleWrapper>
    </Styled.container>
  );
}
