import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import {
  REMOVE_PARTIAL_INSPECTION_REQ,
  REMOVE_PARTIAL_INSPECTION_REQ_PATH_PARAMS,
} from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

export default function useRemovePartialInspection({
  receivingId,
}: {
  receivingId: number;
}) {
  const queryClient = useQueryClient();

  const {
    mutate: removePartialInspection,
    ResponseHandler: ResponseHandlerOfRemovingPartialInspection,
  } = RECEIVING_QUERY.useRemovePartialInspection();

  const {
    mutate: addPartialInspection,
    ResponseHandler: ResponseHandlerOfAddPartialInspection,
  } = RECEIVING_QUERY.useAddPartialInspection({
    receivingId,
    type: "AfterRemove",
  });

  const removePartialInspectionFromItem = useCallback(
    ({
        receivingId,
        itemId,
        inspectingId,
        skuId,
        maxCount,
        isLastRemaining,
        postSuccessCallback,
      }: REMOVE_PARTIAL_INSPECTION_REQ &
        REMOVE_PARTIAL_INSPECTION_REQ_PATH_PARAMS & {
          skuId: number;
          maxCount: number;
          isLastRemaining: boolean;
          postSuccessCallback: () => void;
        }) =>
      () => {
        const onSuccessAfterRemoving = () => {
          postSuccessCallback();

          queryClient.invalidateQueries(
            RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
              receivingId,
            })
          );
        };

        removePartialInspection(
          { pathParams: { receivingId, itemId }, inspectingId },
          {
            onSuccess: isLastRemaining
              ? () => {
                  // 마지막으로 남은 분할검수를 삭제하는 경우, 삭제후 일반검수(기본형)를 생성한다.
                  addPartialInspection(
                    { skuId, quantity: maxCount, isInit: true },
                    {
                      onSuccess: onSuccessAfterRemoving,
                    }
                  );
                }
              : onSuccessAfterRemoving,
          }
        );
      },
    [addPartialInspection, queryClient, removePartialInspection]
  );

  const ResponseHandlerOfRemovingPartialInspectionFromItem = useMemo(() => {
    return (
      <>
        {ResponseHandlerOfRemovingPartialInspection}

        {ResponseHandlerOfAddPartialInspection}
      </>
    );
  }, [
    ResponseHandlerOfAddPartialInspection,
    ResponseHandlerOfRemovingPartialInspection,
  ]);

  return {
    removePartialInspectionFromItem,
    ResponseHandlerOfRemovingPartialInspectionFromItem,
  };
}
