import styled from "styled-components";

const consolidationTable = styled.div`
  margin-bottom: 16px;
`;

const consolidationQty = styled.div`
  display: flex;

  > .button {
    min-width: 0;
    width: auto;
    height: auto;
    margin-left: 4px;
    padding: 10px 8px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.6px;
  }
`;

export default {
  consolidationTable,
  consolidationQty,
};
