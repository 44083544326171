import { memo, useCallback, useState } from "react";

import { SKU } from "@sellernote/_shared/src/types/fulfillment/sku";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import SkuDetailModal from "../../SkuDetailModal";
import Styled from "./index.styles";

function SkuDetail({ skuDetail }: { skuDetail: SKU }) {
  const [isVisibleSkuDetailModal, setIsVisibleSkuDetailModal] = useState(false);

  const handleSkuDetailModalOpen = useCallback(() => {
    setIsVisibleSkuDetailModal(true);
  }, []);

  const handleSkuDetailModalClose = useCallback(() => {
    setIsVisibleSkuDetailModal(false);
  }, []);

  return (
    <>
      <Styled.skuDetail>
        <ListItem
          label="상품명"
          type="withButton"
          value={skuDetail.itemName}
          button={{
            label: "보기",
            handleClick: handleSkuDetailModalOpen,
          }}
        />
      </Styled.skuDetail>

      <SkuDetailModal
        active={isVisibleSkuDetailModal}
        skuDetail={skuDetail}
        onClose={handleSkuDetailModalClose}
      />
    </>
  );
}

export default memo(SkuDetail);
