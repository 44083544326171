import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { SET_WAREHOUSING_DONE_ITEM } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { getFormattedReturningId } from "@sellernote/_shared/src/utils/fulfillment/returning";

import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

export default function useCloseAsNormal({
  returningId,
}: {
  returningId: number;
}) {
  const history = useHistory();

  const {
    isCompletedWarehousingWithMatchedCount,
    SKUItemsFilteredByActualQtyReturning,

    returningCompanyName,
    returningTeamName,
  } = useAppSelector((state) => {
    return {
      isCompletedWarehousingWithMatchedCount:
        returningSelectors.checkIsCompletedWarehousingWithMatchedCount(state),
      SKUItemsFilteredByActualQtyReturning:
        returningSelectors.getSKUItemsFilteredByActualQty(state),

      returningCompanyName:
        state.returning.GET_DETAIL?.data?.returning.team?.company,
      returningTeamName: state.returning.GET_DETAIL?.data?.returning.team?.name,
    };
  });

  const {
    mutate: setWarehousingDone,
    ResponseHandler: ResponseHandlerOfSetWarehousingDone,
  } = RETURNING_QUERY.useSetWarehousingDone({
    returningId,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.replace("/returning/warehousing");
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "입고 마감 처리되었습니다.",
      }),
    },
  });

  const handleSubmit = useCallback(() => {
    if (
      !isCompletedWarehousingWithMatchedCount ||
      !SKUItemsFilteredByActualQtyReturning.length
    )
      return;

    const putAwayItems = SKUItemsFilteredByActualQtyReturning.reduce(
      (a: SET_WAREHOUSING_DONE_ITEM[], c) => {
        if (c.placeItems) {
          c.placeItems.forEach((pi) => {
            a.push({
              skuId: c.sku?.id,
              // 입고마감은 모든 입고가 완료된 상태이기 때문에 placeQty가 항상 존재
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              placeQty: pi.placeQty!,
              placingId: pi.placingId,
              // 입고마감은 모든 입고가 완료된 상태이기 때문에 placeQty가 항상 존재
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              locationId: pi.locationId!,
            });
          });
        }

        return a;
      },
      []
    );

    setWarehousingDone({ putAwayItems });
  }, [
    SKUItemsFilteredByActualQtyReturning,
    isCompletedWarehousingWithMatchedCount,
    setWarehousingDone,
  ]);

  const confirmMessageTitle = `${getFormattedReturningId({
    id: returningId,
  })} / ${returningCompanyName ?? "-"} (${returningTeamName ?? "-"})`;

  return {
    confirmMessageTitle,

    handleSubmit,

    ResponseHandlerOfSetWarehousingDone,
  };
}
