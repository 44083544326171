import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { FULFILLMENT_RECEIVING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import useInputMixQty from "hooks/receiving/useInputMixQty";

function CloseAsNormal({ receivingId }: { receivingId: number }) {
  const { id, bidId, team } =
    useRecoilValue(FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL)
      ?.receiving || {};

  const {
    handleCheckHasMixQtyModalOpen,

    ResultHandlerOfInputMixQty,
  } = useInputMixQty({ receivingId });

  return (
    <>
      <ConfirmWrapper
        confirmMessageTitle={`${getFormattedReceivingId({ bidId, id })} / ${
          team?.company ?? "-"
        } (${team?.name ?? "-"})`}
        confirmModalUiType="content"
        confirmMessageBody={
          <>
            입고요청수량과 검수수량이 일치합니다. <br />
            검수를 마치시겠습니까?
          </>
        }
      >
        <Button
          label="검수 마감"
          size="block"
          theme="primary"
          handleClick={handleCheckHasMixQtyModalOpen}
        />
      </ConfirmWrapper>

      {ResultHandlerOfInputMixQty}
    </>
  );
}

export default CloseAsNormal;
