import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { SET_WAREHOUSING_DONE_REQ_ITEM } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import {
  FULFILLMENT_RECEIVING_ATOMS,
  FULFILLMENT_RECEIVING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/receiving";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";

export default function useCloseAsNormal({
  receivingId,
}: {
  receivingId: number;
}) {
  const history = useHistory();

  const isCompletedWarehousingWithMatchedCount = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_COMPLETED_WAREHOUSING_WITH_MATCHED_COUNT
  );
  const skuItemsFilteredByActualQty = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS_FILTERED_BY_ACTUAL_QTY
  );
  const { id, bidId, team } =
    useRecoilValue(FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL)
      ?.receiving || {};

  const {
    mutate: setWarehousingDone,
    ResponseHandler: ResponseHandlerOfSetWarehousingDone,
  } = RECEIVING_QUERY.useSetWarehousingDone({
    receivingId,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.replace("/receiving/warehousing");
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "입고 마감 처리되었습니다.",
      }),
    },
  });

  const handleSubmit = useCallback(() => {
    if (
      !isCompletedWarehousingWithMatchedCount ||
      !skuItemsFilteredByActualQty.length
    )
      return;

    const putAwayItems = skuItemsFilteredByActualQty.reduce(
      (a: SET_WAREHOUSING_DONE_REQ_ITEM[], c) => {
        if (c.placeItems) {
          c.placeItems.forEach((pi) => {
            a.push({
              skuId: c.sku?.id,
              // 입고마감은 모든 입고가 완료된 상태이기 때문에 placeQty가 항상 존재
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              placeQty: pi.placeQty!,
              placingId: pi.placingId,
              // 입고마감은 모든 입고가 완료된 상태이기 때문에 locationId가 항상 존재
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              locationId: pi.locationId!,
            });
          });
        }

        return a;
      },
      []
    );

    setWarehousingDone({ putAwayItems });
  }, [
    skuItemsFilteredByActualQty,
    isCompletedWarehousingWithMatchedCount,
    setWarehousingDone,
  ]);

  const confirmMessageTitle = `${getFormattedReceivingId({ bidId, id })} / ${
    team?.company ?? "-"
  } (${team?.name ?? "-"})`;

  return {
    confirmMessageTitle,

    handleSubmit,

    ResponseHandlerOfSetWarehousingDone,
  };
}
