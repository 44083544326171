import styled from "styled-components";

const skuDetail = styled.div`
  display: flex;
  flex-direction: column;

  > .text-button {
    margin: 4px 0;
    align-self: flex-end;
  }
`;

export default {
  skuDetail,
};
