import React, { FC, useMemo, useRef, useState } from "react";

import { SvgIconComponent } from "./useSvgIcon";

interface ToolTipProps {
  displayType: ToolTipDisplayType;
  contentForDesktop: {
    content: ToolTipContentItem;
    position: ToolTipPosition;
    bodyWidth?: number; // 데스크탑에서 width를 정하고싶을때 사용
  };
  contentForMobile: {
    content?: ToolTipContentItem[];
    isSameToDesktop?: boolean; // contentForDesktop과 동일한 content인 경우, content 데이터 없이 true로 셋팅해서 사용한다.
  };
  children: React.ReactNode;
  toolTipBodyRef?: React.RefObject<HTMLDivElement>; // 상위 컴포넌트에서 Styled.bodyForDesktop에 접근하여 해당 위치를 조절하는 등의 용도로 사용
  className?: string;
}

// TODO: ToolTip -> Tooltip으로 변경 (V1쪽도 같이 변경해야함. 지금 변경하면 코드 변경내역이 너무 많아서 나중에 하기로함)
interface ToolTipPropsV2 {
  title?: {
    message: string;
    Icon?: SvgIconComponent;
  };
  /**
   * 배열로 오는 경우, '・' 으로 구분해서 표시 (모바일에서 주로 사용)
   */
  desc: React.ReactNode | React.ReactNode[];
  /**
   * 툴팁의 width를 정하고싶을때 사용(px)
   * 입력하지 않으면 컨텐츠 width에 맞춰짐
   * 모바일에서는 width와 무관하게 표시됨
   */
  width?: number;
  disabled?: boolean;
  /**
   * 모바일에서는 position과 무관하게 표시됨
   */
  position: ToolTipPositionV2;
  children: JSX.Element;
  className?: string;
}
type ToolTipComponent = FC<ToolTipProps | ToolTipPropsV2>;

type ToolTipPosition =
  | "left"
  | "right"
  | "bottomLeft"
  | "bottomRight"
  | "topRight";

type ToolTipComponentV2 = FC<ToolTipPropsV2>;

type ToolTipPositionV2 =
  | "topCenter"
  | "topLeft"
  | "topRight"
  | "bottomCenter"
  | "bottomLeft"
  | "bottomRight"
  | "sideRightCenter"
  | "sideRightTop"
  | "sideRightBottom"
  | "sideLeftCenter"
  | "sideLeftTop"
  | "sideLeftBottom";

type ToolTipDisplayType = "block" | "inline-block";

interface ToolTipContentTitleAndDesc {
  type: "titleAndDesc";
  title: React.ReactNode;
  desc: React.ReactNode;
}

interface ToolTipContentTitleOnly {
  type: "titleOnly";
  title: React.ReactNode;
}

interface ToolTipContentItemDescOnly {
  type: "descOnly";
  desc: React.ReactNode;
}

type ToolTipContentItem =
  | ToolTipContentTitleAndDesc
  | ToolTipContentTitleOnly
  | ToolTipContentItemDescOnly;

type ToolTipTriggerTargetInfo = {
  width: number;
  height: number;
  left: number;
};
type TooltipBodyTargetInfo = {
  width: number;
  height: number;
};

function useToolTip(disabled?: boolean) {
  const [isVisibleToolTipBody, setIsVisibleToolTipBody] = useState(false);

  /**
   * 툴팁 body의 정확한 위치를 찾기 위함이다.
   * triggerRef를 이용해서 trigger의 height, width를 파악하여 triggerTargetInfo로 저장 -> triggerTargetInfo를 이용하여 툴팁 위치를 정한다.
   */
  const triggerRef = useRef<HTMLDivElement>(null);
  const [triggerTargetInfo, setTriggerTargetInfo] = useState({
    width: 0,
    height: 0,
    left: 0,
  });

  const checkTriggerTarget = () => {
    if (disabled) return;

    if (triggerRef.current?.firstChild) {
      const { left } = triggerRef.current.getBoundingClientRect();

      setTriggerTargetInfo({
        height: (triggerRef.current.firstChild as Element).clientHeight,
        width: (triggerRef.current.firstChild as Element).clientWidth,
        left,
      });
    }
  };

  /**
   * 툴팁 body의 width, height를 이용하여 정확한 center를 찾을 때 사용함
   */
  const tooltipBodyRef = useRef<HTMLDivElement>(null);
  const tooltipBodyTargetInfo = useMemo<{
    width: number;
    height: number;
  }>(() => {
    if (isVisibleToolTipBody && tooltipBodyRef.current) {
      return {
        height: tooltipBodyRef.current.clientHeight,
        width: tooltipBodyRef.current.clientWidth,
      };
    }
    return {
      width: 0,
      height: 0,
    };
  }, [isVisibleToolTipBody]);

  const handleMouseEnter = () => !disabled && setIsVisibleToolTipBody(true);
  const handleMouseLeave = () => !disabled && setIsVisibleToolTipBody(false);

  const handleToolTipClick = () => !disabled && setIsVisibleToolTipBody(true);

  return {
    isVisibleToolTipBody,
    triggerRef,
    setIsVisibleToolTipBody,
    triggerTargetInfo,
    checkTriggerTarget,
    tooltipBodyRef,
    tooltipBodyTargetInfo,
    handleMouseEnter,
    handleMouseLeave,
    handleToolTipClick,
  };
}

export default useToolTip;

export type {
  ToolTipProps,
  ToolTipPropsV2,
  ToolTipPosition,
  ToolTipPositionV2,
  ToolTipDisplayType,
  ToolTipContentTitleAndDesc,
  ToolTipComponent,
  ToolTipComponentV2,
  ToolTipContentTitleOnly,
  ToolTipContentItemDescOnly,
  ToolTipContentItem,
  ToolTipTriggerTargetInfo,
  TooltipBodyTargetInfo,
};
