import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";

import useCompleteReportingPickingProblem from "hooks/shipping/useCompleteReportingPickingProblem";

import { ProblemList } from ".";

export default function CompleteButton({
  SKUInfoForScanning,
  problemList,
  resetPickingState,
  handleReportProblemModalClose,
}: {
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  problemList: ProblemList;
  resetPickingState: () => void;
  handleReportProblemModalClose: () => void;
}) {
  const {
    isDisabledCompleteButton,
    checkIsCanceledInvoice,
    CanceledInvoiceModal,

    ResponseHandlerReportingShippingProblem,
    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  } = useCompleteReportingPickingProblem({
    SKUInfoForScanning,
    problemList,
    resetPickingState,
    handleReportProblemModalClose,
  });

  return (
    <>
      <Button
        theme="primary"
        size="normal"
        label="확인"
        handleClick={checkIsCanceledInvoice}
        disabled={isDisabledCompleteButton}
      />

      {CanceledInvoiceModal}

      {ResponseHandlerReportingShippingProblem}
      {ResponseHandlerOfCheckingCancelingInvoice}
      {ResponseHandlerOfSettingRestockByPickingQuantity}
    </>
  );
}
