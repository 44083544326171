import React from "react";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import {
  CompletedList,
  WorkingLocation,
} from "hooks/canceling/useRestockCanceling";
import useRestockCancelingTable, {
  CancelingRestockingTableItem,
} from "hooks/canceling/useRestockCancelingTable";

export default function RestockingTable({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  SKUInfoForScanning,
  setSKUInfoForScanning,
  completedList,
  isFirstSKUScan,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  setSKUInfoForScanning: React.Dispatch<
    React.SetStateAction<SKUInfoForCancelingRestocking[]>
  >;
  completedList: React.MutableRefObject<CompletedList>;
  isFirstSKUScan: React.MutableRefObject<boolean>;
}) {
  const {
    ConfirmModal,
    CountForScanningModal,

    restockingTableList,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    MessageModalOfDirectInput,
    ResponseHandlerOfGettingFileURLList,
  } = useRestockCancelingTable({
    selectedScanButtonType,
    handleScanButtonClick,
    workingLocation,
    setWorkingLocation,
    SKUInfoForScanning,
    setSKUInfoForScanning,
    setRowInfoToHighlight,
    completedList,
    isFirstSKUScan,
  });

  return (
    <>
      <TableForCounting<CancelingRestockingTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          locationName: {
            label: "위치",
            fixedWidth: 90,
          },
          SKUId: {
            label: "SKU ID / 상품바코드",
            fixedWidth: 130,
          },
          count: {
            label: "카운트",
            fixedWidth: 150,
          },
          reset: {
            label: "초기화",
          },
        }}
        dataList={restockingTableList}
      />

      {ConfirmModal}
      {CountForScanningModal}

      {MessageModalOfDirectInput}
      {SKUImageInfoModal}

      {ResponseHandlerOfGettingSKUInfo}
      {ResponseHandlerOfGettingFileURLList}
    </>
  );
}
