import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { SET_WAREHOUSING_DONE_REQ_ITEM } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import { RETURNING_PROBLEM_ITEM } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";

import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import { ReturningReportedStep } from "..";
import Styled from "../index.styles";
import { BofulProblemDict, BofulProblemDictValue } from ".";

function ButtonForReporting({
  returningId,
  problemDict,
  reportedStep,
}: {
  returningId: number;
  problemDict: BofulProblemDict;
  reportedStep: ReturningReportedStep;
}) {
  const history = useHistory();

  const {
    SKUItemsFilteredByQuantityReturning,
    SKUItemsFilteredByActualQtyReturning,
    SKUItemsReturning,
  } = useAppSelector((state) => {
    return {
      SKUItemsFilteredByQuantityReturning:
        returningSelectors.getSKUItemsFilteredByQuantity(state),
      SKUItemsFilteredByActualQtyReturning:
        returningSelectors.getSKUItemsFilteredByActualQty(state),
      SKUItemsReturning: returningSelectors.getSKUItems(state),
    };
  });

  const {
    mutate: reportInspectionProblem,
    ResponseHandler: ResponseHandlerOfReportingInspectionProblem,
  } = RETURNING_QUERY.useReportInspectionProblem({ returningId });

  const {
    mutate: reportWarehousingProblem,
    ResponseHandler: ResponseHandlerOfReportingWarehousingProblem,
  } = RETURNING_QUERY.useReportWarehousingProblem({ returningId });

  const {
    mutate: setInspectionDone,
    ResponseHandler: ResponseHandlerOfSetInspectionDone,
  } = RETURNING_QUERY.useSetInspectionDone({
    returningId,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.replace("/returning/inspection");
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "검수 마감 처리되었습니다.",
      }),
    },
  });

  const {
    mutate: setWarehousingDone,
    ResponseHandler: ResponseHandlerOfSetWarehousingDone,
  } = RETURNING_QUERY.useSetWarehousingDone({
    returningId,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.replace("/returning/warehousing");
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "입고 마감 처리되었습니다.",
      }),
    },
  });

  const [allProblemAreSelected, problemList] = useMemo(() => {
    let allProblemAreSelected = true;
    const problemList: (RETURNING_PROBLEM_ITEM | undefined)[] = [];

    Object.entries(problemDict).forEach(([key, val]) => {
      if (!hasSelectedProblem(val)) {
        allProblemAreSelected = false;
      }

      const problem: RETURNING_PROBLEM_ITEM | undefined = val.type
        ? {
            returningProblem: val.type.value,
            itemId: +key,
            ...(val.directInput ? { problemDirectInput: val.directInput } : {}),
          }
        : undefined;

      problemList.push(problem);
    });

    return [allProblemAreSelected, problemList];

    function hasSelectedProblem(val: BofulProblemDictValue) {
      if (!val.type?.value) return false;

      if (val.type.value === "directInput") {
        return !!val.directInput;
      }

      return true;
    }
  }, [problemDict]);

  const handleSubmit = useCallback(() => {
    if (!allProblemAreSelected) return;

    if (reportedStep === "inspection") {
      reportInspectionProblem(
        { problems: problemList as RETURNING_PROBLEM_ITEM[] },
        {
          onSuccess: () => {
            const totalActualQty = SKUItemsFilteredByQuantityReturning.reduce(
              (a, c) => {
                return a + (c.actualQty ?? 0);
              },
              0
            );

            setInspectionDone({
              // 검수마감(반품)의 경우 0개인 item도 포함
              inspectedItems: SKUItemsReturning.map((v) => {
                return {
                  skuId: v.skuId,
                  // 마감 시에는 값이 존재
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  actualQty: v.actualQty!,
                };
              }),
              actualQty: totalActualQty,
            });
          },
        }
      );
    }

    if (reportedStep === "warehousing") {
      reportWarehousingProblem(
        { problems: problemList as RETURNING_PROBLEM_ITEM[] },
        {
          onSuccess: () => {
            const putAwayItems = SKUItemsFilteredByActualQtyReturning.reduce(
              (a: SET_WAREHOUSING_DONE_REQ_ITEM[], c) => {
                if (c.placeItems) {
                  c.placeItems.forEach((pi) => {
                    a.push({
                      skuId: c.sku?.id,
                      // 입고마감은 모든 입고가 완료된 상태이기 때문에 placeQty가 항상 존재
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      placeQty: pi.placeQty!,
                      placingId: pi.placingId,
                      // 입고마감은 모든 입고가 완료된 상태이기 때문에 placeQty가 항상 존재
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      locationId: pi.locationId!,
                    });
                  });
                }

                return a;
              },
              []
            );

            setWarehousingDone({ putAwayItems });
          },
        }
      );
    }
  }, [
    SKUItemsFilteredByActualQtyReturning,
    SKUItemsFilteredByQuantityReturning,
    SKUItemsReturning,
    allProblemAreSelected,
    problemList,
    reportInspectionProblem,
    reportWarehousingProblem,
    reportedStep,
    setInspectionDone,
    setWarehousingDone,
  ]);

  return (
    <>
      <Styled.modalButtonContainer>
        <Button
          theme="primary"
          size="normal"
          label="확인"
          handleClick={handleSubmit}
          disabled={!allProblemAreSelected}
        />
      </Styled.modalButtonContainer>

      {reportedStep === "inspection" && (
        <>
          {ResponseHandlerOfReportingInspectionProblem}

          {ResponseHandlerOfSetInspectionDone}
        </>
      )}

      {reportedStep === "warehousing" && (
        <>
          {ResponseHandlerOfReportingWarehousingProblem}

          {ResponseHandlerOfSetWarehousingDone}
        </>
      )}
    </>
  );
}

export default ButtonForReporting;
