import React, { useCallback, useState } from "react";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import COMMON_QUERY from "@sellernote/_shared/src/queries/fulfillment/COMMON_QUERY";
import {
  FULFILLMENT_AUTH_ATOMS,
  FULFILLMENT_AUTH_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import Menu from "./Menu";
import Refresh, { LayoutRefreshInfo } from "./Refresh";
import Styled from "./index.styles";

function Layout({
  children,
  navigator,
  isHiddenBackButton,
  refreshInfo,
  confirmMessageToLeave,
}: {
  navigator: {
    title: React.ReactNode;
    canNotLeavePageMessage?: string;
  };
  isHiddenBackButton?: boolean;
  children: React.ReactNode;
  refreshInfo?: LayoutRefreshInfo;
  /**
   * 페이지 이탈시 confirm 단계가 필요할때 사용
   */
  confirmMessageToLeave?: {
    title: React.ReactNode;
    body: React.ReactNode;
  };
}) {
  const history = useHistory();

  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const loggedIn = useRecoilValue(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);
  const canNotLeavePage = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );
  const [workerList, setWorkerList] = useRecoilState(
    FULFILLMENT_COMMON_ATOMS.WORKER_LIST
  );
  const [locationListOfWarehouse, setLocationListOfWarehouse] = useRecoilState(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const [visibleCannotLeaveModal, setVisibleCannotLeaveModal] = useState(false);

  const { ResponseHandler: ResponseHandlerOfGettingWorkerList } =
    COMMON_QUERY.useGetWorkerList({
      enabled: Boolean(loggedIn && workerList.length === 0),
      onSuccess: (data) => setWorkerList(data),
    });
  const { ResponseHandler: ResponseHandlerOfGettingLocationListOfWarehouse } =
    COMMON_QUERY.useGetLocationListOfWarehouse({
      enabled: Boolean(
        loggedIn &&
          currentUser?.warehouseId &&
          locationListOfWarehouse.length === 0
      ),
      onSuccess: (data) => setLocationListOfWarehouse(data),
      warehouseId: currentUser?.warehouseId ?? 1,
    });

  const userName = useMemo(() => {
    if (!currentUser) return "";

    return currentUser.authority === "partTimer"
      ? currentUser.accountId
      : currentUser.name;
  }, [currentUser]);

  const validateWhenLeavePage = useCallback(
    (callbackWhenValidated: () => void) => {
      if (canNotLeavePage) {
        setVisibleCannotLeaveModal(true);
      } else {
        callbackWhenValidated();
      }
    },
    [canNotLeavePage]
  );

  return (
    <>
      <Styled.container>
        {loggedIn && (
          <>
            <header>
              <ConfirmWrapper
                confirmMessageTitle={confirmMessageToLeave?.title}
                confirmMessageBody={confirmMessageToLeave?.body}
                confirmModalUiType="content"
                disabled={!confirmMessageToLeave}
              >
                <img
                  src="/images/logo_boful.png"
                  alt="logo"
                  className="logo"
                  onClick={() => validateWhenLeavePage(() => history.push("/"))}
                />
              </ConfirmWrapper>

              <Menu setVisibleCannotLeaveModal={setVisibleCannotLeaveModal} />
            </header>

            <div className="user-info">
              {/* 한동안 창고가 하나뿐일 예정이라 INCWH로 하드코딩함  */}
              <div className="left">{`INCWH(${toFormattedDate(
                currentUser?.loggedInAt,
                "YYYY.MM.DD.HH:mm"
              )})`}</div>
              <div className="right">{userName}</div>
            </div>
          </>
        )}

        <div className="navigator">
          {!isHiddenBackButton && (
            <ConfirmWrapper
              className="confirm-back"
              confirmMessageTitle={confirmMessageToLeave?.title}
              confirmMessageBody={confirmMessageToLeave?.body}
              confirmModalUiType="content"
              disabled={!confirmMessageToLeave}
            >
              <Icon
                type="arrowLeft"
                size={2}
                color={COLOR.grayScale_700}
                onClick={() => validateWhenLeavePage(() => history.goBack())}
              />
            </ConfirmWrapper>
          )}

          <div className="title">{navigator.title}</div>

          {refreshInfo && <Refresh refreshInfo={refreshInfo} />}
        </div>

        <main>{children}</main>
      </Styled.container>

      <Modal
        uiType="titleOnly"
        active={visibleCannotLeaveModal}
        title={
          navigator.canNotLeavePageMessage ||
          "현재 진행중인 작업 마감 전까지 뒤로가기는 불가합니다."
        }
        actionPositive={{
          label: "확인",
          handleClick: () => setVisibleCannotLeaveModal(false),
        }}
      />

      {ResponseHandlerOfGettingWorkerList}
      {ResponseHandlerOfGettingLocationListOfWarehouse}
    </>
  );
}

export default Layout;
