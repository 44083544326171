import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

function InventoryInformation() {
  return (
    <Layout
      navigator={{
        title: "위치/제품 정보",
      }}
    >
      <div>위치/제품 정보</div>
    </Layout>
  );
}

export default withRequireAuth(InventoryInformation);
