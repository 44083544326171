import { atom } from "recoil";

import { FileTypes } from "../../../types/common/upload";
import {
  AttachmentFileItem,
  FileDataItem,
  MaterialPackageType,
  ParcelInfo,
  SelectedPackageList,
} from "../../../types/fulfillment/fulfillment";
import { SelectedSKUItem } from "../../../types/fulfillment/shipping";
import { ContractorType } from "../../../types/fulfillment/sku";
import { BASIC_PACKAGE_LIST } from "../../../utils/fulfillment/fulfillment";

import {
  POST_GROUP_SKU_ITEM_REQ,
  SKU_ITEM_INFO_REQ,
} from "../../../api-interfaces/boful-api/fulfillment";
import {
  GET_DETAIL_RES_DATA,
  GET_USER_RECEIVING_LIST_REQ,
} from "../../../api-interfaces/boful-api/receiving";

export interface ParcelInfoState extends ParcelInfo {
  id: number;
}

export const initParcelInfoList: ParcelInfoState[] = [
  {
    id: 1,
    parcelCompany: undefined,
    invoiceNo: "",
  },
];

const initialSelectedPackageList: SelectedPackageList = {
  outside: BASIC_PACKAGE_LIST.outside[0],
  buffer: BASIC_PACKAGE_LIST.buffer[0],
  tape: BASIC_PACKAGE_LIST.tape[0],
};

const SEARCH_QUERY_PARAMS = atom<Omit<GET_USER_RECEIVING_LIST_REQ, "mode">>({
  key: "fulfillment/receiving/atoms/SEARCH_QUERY_PARAMS",
  default: {
    page: 0,
    perPage: 10,
    dateKind: "expectedDate",
  },
});

const SHOWS_ADD_SINGLE_SKU_MODAL = atom<boolean>({
  key: "fulfillment/receiving/atoms/SHOWS_ADD_SINGLE_SKU_MODAL",
  default: false,
});

const SHOWS_ADD_GROUP_SKU_MODAL = atom<boolean>({
  key: "fulfillment/receiving/atoms/SHOWS_ADD_GROUP_SKU_MODAL",
  default: false,
});

type SingleSkuItemInfo = SKU_ITEM_INFO_REQ & {
  contractorType: ContractorType;
};

export const SINGLE_SKU_ITEM_INFO_INITIAL_STATE: SingleSkuItemInfo = {
  itemName: "",
  materialPackageType: "basic",
  returningCondition: "openNotUsed",
  contractorType: "domesticBuyer",
};

const SINGLE_SKU_ITEM_INFO = atom<SingleSkuItemInfo>({
  key: "fulfillment/receiving/atoms/SINGLE_SKU_ITEM_INFO",
  default: SINGLE_SKU_ITEM_INFO_INITIAL_STATE,
});

const IS_EDITING_SINGLE_SKU_ITEM = atom<boolean>({
  key: "fulfillment/receiving/atoms/IS_EDITING_SINGLE_SKU_ITEM",
  default: false,
});

const PREV_SAVED_SINGLE_SKU_ITEM_DATA = atom<{
  singleSkuItemInfo: SingleSkuItemInfo;
  fileList: (AttachmentFileItem | FileDataItem)[];
  materialPackageType: MaterialPackageType;
  selectedPackageList: SelectedPackageList;
}>({
  key: "fulfillment/receiving/atoms/PREV_SAVED_SINGLE_SKU_ITEM_DATA",
  default: {
    singleSkuItemInfo: SINGLE_SKU_ITEM_INFO_INITIAL_STATE,
    fileList: [],
    materialPackageType: "basic",
    selectedPackageList: initialSelectedPackageList,
  },
});

type GroupSkuItemInfo = Omit<POST_GROUP_SKU_ITEM_REQ, "groupItems">;
type GroupSkuItemSelectedSkuList = SelectedSKUItem[];

export const GROUP_SKU_ITEM_INFO_INITIAL_STATE: GroupSkuItemInfo = {
  groupName: "",
  materialPackageType: "basic",
  returningCondition: "openNotUsed",
};

const GROUP_SKU_ITEM_INFO = atom<GroupSkuItemInfo>({
  key: "fulfillment/receiving/atoms/GROUP_SKU_ITEM_INFO",
  default: GROUP_SKU_ITEM_INFO_INITIAL_STATE,
});

const GROUP_SKU_ITEM_SELECTED_SKU_LIST = atom<GroupSkuItemSelectedSkuList>({
  key: "fulfillment/receiving/atoms/GROUP_SKU_ITEM_SELECTED_SKU_LIST",
  default: [],
});

const IS_EDITING_GROUP_SKU_ITEM = atom<boolean>({
  key: "fulfillment/receiving/atoms/IS_EDITING_GROUP_SKU_ITEM",
  default: false,
});

const PREV_SAVED_GROUP_SKU_ITEM_DATA = atom<{
  groupSkuItemInfo: GroupSkuItemInfo;
  fileList: (AttachmentFileItem | FileDataItem)[];
  materialPackageType: MaterialPackageType;
  selectedPackageList: SelectedPackageList;
}>({
  key: "fulfillment/receiving/atoms/PREV_SAVED_GROUP_SKU_ITEM_DATA",
  default: {
    groupSkuItemInfo: GROUP_SKU_ITEM_INFO_INITIAL_STATE,
    fileList: [],
    materialPackageType: "basic",
    selectedPackageList: initialSelectedPackageList,
  },
});

const FILES = atom<FileTypes[]>({
  key: "fulfillment/receiving/atoms/FILES",
  default: [],
});

const FILE_LIST = atom<(AttachmentFileItem | FileDataItem)[]>({
  key: "fulfillment/receiving/atoms/FILE_LIST",
  default: [],
});

const MATERIAL_PACKAGE_TYPE = atom<MaterialPackageType>({
  key: "fulfillment/receiving/atoms/MATERIAL_PACKAGE_TYPE",
  default: "basic",
});

const SELECTED_PACKAGE_LIST = atom<SelectedPackageList>({
  key: "fulfillment/receiving/atoms/SELECTED_PACKAGE_LIST",
  default: initialSelectedPackageList,
});

const PARCEL_INFO_LIST = atom<ParcelInfoState[]>({
  key: "fulfillment/receiving/atoms/PARCEL_INFO_LIST",
  default: initParcelInfoList,
});

const MANAGER_RECEIVING_DETAIL = atom<GET_DETAIL_RES_DATA | undefined>({
  key: "fulfillment/receiving/atoms/MANAGER_RECEIVING_DETAIL",
  default: undefined,
});

export default {
  SEARCH_QUERY_PARAMS,
  SHOWS_ADD_SINGLE_SKU_MODAL,
  SHOWS_ADD_GROUP_SKU_MODAL,
  SINGLE_SKU_ITEM_INFO,
  IS_EDITING_SINGLE_SKU_ITEM,
  IS_EDITING_GROUP_SKU_ITEM,
  PREV_SAVED_SINGLE_SKU_ITEM_DATA,
  PREV_SAVED_GROUP_SKU_ITEM_DATA,
  GROUP_SKU_ITEM_INFO,
  GROUP_SKU_ITEM_SELECTED_SKU_LIST,
  FILES,
  FILE_LIST,
  MATERIAL_PACKAGE_TYPE,
  SELECTED_PACKAGE_LIST,
  PARCEL_INFO_LIST,
  MANAGER_RECEIVING_DETAIL,
};
