import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { ADD_PARTIAL_WAREHOUSING_REQ } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  checkConfirmedAsSingleLocationWarehousing,
  checkHasOnlyInitialWarehousing,
  getRemainedQtyForWarehousing,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";

export default function useAddPartialWarehousing({
  receivingId,
  receivingItem,
}: {
  receivingId: number;
  receivingItem: ReceivingItem;
}) {
  const history = useHistory();

  const queryClient = useQueryClient();

  const {
    mutate: addPartialWarehousing,
    ResponseHandler: ResponseHandlerOfAddingPartialWarehousing,
  } = RECEIVING_QUERY.useAddPartialWarehousing({
    type: "add",
    receivingId,
  });

  const {
    refetch: fetchManagerReceivingDetailToCheckCount,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetailToCheckCount,
  } = RECEIVING_QUERY.useGetManagerReceivingDetailToCheck({
    receivingId,
    enabled: false,
  });

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const addPartialWarehousingToItem = useCallback(
    ({
        itemId,
        remainedQty,
        skuId,
        quantity,
        isInit,
        callbackAfterAdd,
      }: ADD_PARTIAL_WAREHOUSING_REQ & {
        remainedQty: number;
        itemId: number;
        callbackAfterAdd: () => void;
      }) =>
      async () => {
        const { data } = await fetchManagerReceivingDetailToCheckCount({
          throwOnError: true,
        });

        if (!data) {
          return;
        }

        const targetItem = data.data.receiving.items.find(
          (v) => v.id === itemId
        );

        if (!targetItem) {
          setValidationError({
            title: "이전 분할입고 내역을 확인하는 중 오류가 발생했습니다",
          });
          return;
        }

        const remainedQtyLatest = getRemainedQtyForWarehousing(targetItem);
        if (remainedQty !== remainedQtyLatest) {
          // 잔여입고 수량이 바꼈으면 중단하고 입력을 다시 받도록 함
          setValidationError({
            title: "다른 작업자에 의해 수량이 조정되었습니다.",
            confirmCallback: () => {
              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          });
          return;
        }

        addPartialWarehousing(
          { skuId, quantity, isInit },
          {
            onSuccess: ({ data }) => {
              const targetItem = data.items.find((v) => v.id === itemId);
              const confirmedAsSingleLocationWarehousing =
                checkConfirmedAsSingleLocationWarehousing(targetItem);

              // 전체수량으로 분할할 경우 일반입고로 취급하여 검수페이지로 돌아감
              confirmedAsSingleLocationWarehousing
                ? history.goBack()
                : callbackAfterAdd();

              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      },
    [
      addPartialWarehousing,
      fetchManagerReceivingDetailToCheckCount,
      history,
      queryClient,
      receivingId,
      setValidationError,
    ]
  );

  const hasOnlyInitialWarehousing = useMemo(
    () => checkHasOnlyInitialWarehousing(receivingItem),
    [receivingItem]
  );

  const canAddPartialWarehousing = useMemo(() => {
    if (!receivingItem.placeItems) return false;

    if (hasOnlyInitialWarehousing) {
      return true;
    }

    const partedQuantityTotal = receivingItem.placeItems.reduce((a, c) => {
      return a + c.quantity;
    }, 0);
    const max = receivingItem.actualQty ?? 0;
    if (partedQuantityTotal < max) return true;

    return false;
  }, [receivingItem, hasOnlyInitialWarehousing]);

  const remainedQtyForWarehousing = useMemo((): number => {
    if (!receivingItem.placeItems) return 0;

    if (hasOnlyInitialWarehousing) return receivingItem.actualQty ?? 0;

    return getRemainedQtyForWarehousing(receivingItem);
  }, [receivingItem, hasOnlyInitialWarehousing]);

  const ResponseHandlerOfAddingPartialWarehousingToItem = useMemo(() => {
    return (
      <>
        {ValidationErrorModal}

        {ResponseHandlerOfAddingPartialWarehousing}

        {ResponseHandlerOfGettingManagerReceivingDetailToCheckCount}
      </>
    );
  }, [
    ValidationErrorModal,
    ResponseHandlerOfAddingPartialWarehousing,
    ResponseHandlerOfGettingManagerReceivingDetailToCheckCount,
  ]);

  return {
    addPartialWarehousingToItem,
    canAddPartialWarehousing,
    remainedQtyForWarehousing,
    ResponseHandlerOfAddingPartialWarehousingToItem,
  };
}
