import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import {
  REMOVE_PARTIAL_WAREHOUSING_REQ,
  REMOVE_PARTIAL_WAREHOUSING_REQ_PATH_PARAMS,
} from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

export default function useRemovePartialWarehousing({
  receivingId,
}: {
  receivingId: number;
}) {
  const queryClient = useQueryClient();

  const {
    mutate: removePartialWarehousing,
    ResponseHandler: ResponseHandlerOfRemovePartialWarehousing,
  } = RECEIVING_QUERY.useRemovePartialWarehousing();

  const {
    mutate: addPartialWarehousing,
    ResponseHandler: ResponseHandlerOfAddPartialWarehousing,
  } = RECEIVING_QUERY.useAddPartialWarehousing({
    receivingId,
    type: "AfterRemove",
  });

  const removePartialWarehousingFromItem = useCallback(
    ({
        receivingId,
        itemId,
        placingId,
        skuId,
        maxCount,
        isLastRemaining,
        postSuccessCallback,
      }: REMOVE_PARTIAL_WAREHOUSING_REQ_PATH_PARAMS &
        REMOVE_PARTIAL_WAREHOUSING_REQ & {
          skuId: number;
          maxCount: number;
          isLastRemaining: boolean;
          postSuccessCallback: () => void;
        }) =>
      () => {
        const onSuccessAfterRemoving = () => {
          postSuccessCallback();

          queryClient.invalidateQueries(
            RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
              receivingId,
            })
          );
        };

        removePartialWarehousing(
          {
            pathParams: { receivingId, itemId },
            placingId,
          },
          {
            onSuccess: isLastRemaining
              ? () => {
                  // 마지막으로 남은 분할입고를 삭제하는 경우, 삭제후 일반입고(기본형)를 생성한다.
                  addPartialWarehousing(
                    { skuId, quantity: maxCount, isInit: true },
                    { onSuccess: onSuccessAfterRemoving }
                  );
                }
              : onSuccessAfterRemoving,
          }
        );
      },
    [addPartialWarehousing, queryClient, removePartialWarehousing]
  );

  const ResponseHandlerOfRemovingPartialWarehousingFromItem = useMemo(() => {
    return (
      <>
        {ResponseHandlerOfRemovePartialWarehousing}

        {ResponseHandlerOfAddPartialWarehousing}
      </>
    );
  }, [
    ResponseHandlerOfRemovePartialWarehousing,
    ResponseHandlerOfAddPartialWarehousing,
  ]);

  return {
    removePartialWarehousingFromItem,
    ResponseHandlerOfRemovingPartialWarehousingFromItem,
  };
}
