import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import useCloseAsNormal from "./useCloseAsNormal";

function CloseAsNormal({ returningId }: { returningId: number }) {
  const {
    confirmMessageTitle,
    handleSubmit,
    ResponseHandlerOfSetWarehousingDone,
  } = useCloseAsNormal({ returningId });

  return (
    <>
      <ConfirmWrapper
        confirmMessageTitle={confirmMessageTitle}
        confirmModalUiType="content"
        confirmMessageBody={
          <>
            검수수량과 입고수량이 일치합니다. <br />
            입고마감 처리하시겠습니까?
          </>
        }
      >
        <Button
          label="입고 마감"
          size="block"
          theme="primary"
          handleClick={handleSubmit}
        />
      </ConfirmWrapper>

      {ResponseHandlerOfSetWarehousingDone}
    </>
  );
}

export default CloseAsNormal;
