import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { ReceivingWorker } from "@sellernote/_shared/src/types/fulfillment/receiving";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";

import Styled from "../index.styles";
import useCandidateSelector from "./useCandidateSelector";

function CandidateSelector({
  deActivate,
  existedWorkerList,
}: {
  deActivate: () => void;
  existedWorkerList: ReceivingWorker[] | undefined;
}) {
  const {
    candidateOptionList,
    selectedCandidate,
    setSelectedCandidate,

    handleCandidateSelect,

    ResponseHandlerOfAddingWorkerToInspection,
  } = useCandidateSelector({
    deActivate,
    existedWorkerList,
  });

  return (
    <>
      <Modal
        active
        uiType="contentWithCustomBody"
        title="담당자를 선택해주세요."
        allowOverflow={true}
        body={
          <Styled.selectorBody>
            <InputSelect
              label="담당자 선택"
              placeholder="선택해주세요."
              uiType="outline"
              optionList={candidateOptionList}
              selectedOption={selectedCandidate}
              handleSelect={setSelectedCandidate}
            />

            <Button
              theme="primary"
              size="normal"
              label={"선택 완료"}
              disabled={!selectedCandidate}
              handleClick={handleCandidateSelect}
            />
          </Styled.selectorBody>
        }
        onClose={deActivate}
      />

      {ResponseHandlerOfAddingWorkerToInspection}
    </>
  );
}

export default CandidateSelector;
