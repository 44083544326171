import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { InventoryDetailBySKUId } from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import TempInput from "../../TempInput";
import { InventoryToConsolidate } from "../useInventoryConsolidation";
import Styled from "./index.styles";

export interface InventoryConsolidationTableItem {
  location: ReactNode;
  availableQty: number;
  consolidationQty: ReactNode;
  reset: ReactNode;
}

export default function useConsolidationTable({
  inventoryDetailOfSelectedSku,
  listOfInventoryToConsolidate,
  setListOfInventoryToConsolidate,
}: {
  inventoryDetailOfSelectedSku: InventoryDetailBySKUId[];
  listOfInventoryToConsolidate: InventoryToConsolidate[];
  setListOfInventoryToConsolidate: Dispatch<
    SetStateAction<InventoryToConsolidate[]>
  >;
}) {
  const [isVisibleCountQuantityModal, setIsVisibleCountQuantityModal] =
    useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<number>();
  const [quantityToConsolidate, setQuantityToConsolidate] = useState<number>();

  useEffect(() => {
    if (quantityToConsolidate) {
      setListOfInventoryToConsolidate((prevListOfInventoryToConsolidate) => {
        const newListOfInventoryToConsolidate = [
          ...prevListOfInventoryToConsolidate,
        ];
        const indexOfSelectedItem = newListOfInventoryToConsolidate.findIndex(
          (item) => item.locationId === selectedLocationId
        );

        return [
          ...newListOfInventoryToConsolidate.slice(0, indexOfSelectedItem),
          {
            ...newListOfInventoryToConsolidate[indexOfSelectedItem],
            consolidationQty: quantityToConsolidate,
          },
          ...newListOfInventoryToConsolidate.slice(indexOfSelectedItem + 1),
        ];
      });
    }
  }, [
    quantityToConsolidate,
    selectedLocationId,
    setListOfInventoryToConsolidate,
  ]);

  const availableQty = useMemo(
    () =>
      listOfInventoryToConsolidate.find(
        (item) => item.locationId === selectedLocationId
      )?.availableQty ?? 0,
    [listOfInventoryToConsolidate, selectedLocationId]
  );

  const handleCountQuantityModalOpen = useCallback(
    (locationId: number) => () => {
      setSelectedLocationId(locationId);
      setIsVisibleCountQuantityModal(true);
    },
    []
  );

  const handleCountQuantityModalClose = useCallback(() => {
    setIsVisibleCountQuantityModal(false);
  }, []);

  const handleAllQuantitySelect = useCallback(
    ({
        locationId,
        availableQty,
      }: {
        locationId: number;
        availableQty: number;
      }) =>
      () => {
        setListOfInventoryToConsolidate((prevListOfInventoryToConsolidate) => {
          const newListOfInventoryToConsolidate = [
            ...prevListOfInventoryToConsolidate,
          ];
          const indexOfSelectedItem = newListOfInventoryToConsolidate.findIndex(
            (item) => item.locationId === locationId
          );

          return [
            ...newListOfInventoryToConsolidate.slice(0, indexOfSelectedItem),
            {
              ...newListOfInventoryToConsolidate[indexOfSelectedItem],
              consolidationQty: availableQty,
            },
            ...newListOfInventoryToConsolidate.slice(indexOfSelectedItem + 1),
          ];
        });
      },
    [setListOfInventoryToConsolidate]
  );

  const handleResetQuantityClick = useCallback(
    (locationId: number) => () => {
      setListOfInventoryToConsolidate((prevListOfInventoryToConsolidate) => {
        const newListOfInventoryToConsolidate = [
          ...prevListOfInventoryToConsolidate,
        ];
        const indexOfSelectedItem = newListOfInventoryToConsolidate.findIndex(
          (item) => item.locationId === locationId
        );

        return [
          ...newListOfInventoryToConsolidate.slice(0, indexOfSelectedItem),
          {
            ...newListOfInventoryToConsolidate[indexOfSelectedItem],
            consolidationQty: 0,
          },
          ...newListOfInventoryToConsolidate.slice(indexOfSelectedItem + 1),
        ];
      });
    },
    [setListOfInventoryToConsolidate]
  );

  const tableDataList: TableDataListItem<InventoryConsolidationTableItem>[] =
    useMemo(
      () =>
        inventoryDetailOfSelectedSku.map(
          ({ id, locationId, availableQty, location }) => {
            const consolidationQty = listOfInventoryToConsolidate?.find(
              (item) => item.locationId === locationId
            )?.consolidationQty;

            return {
              rowKey: id,

              location: (
                <>
                  인천1센터/
                  <br />
                  {location?.barCode}
                </>
              ),

              availableQty: availableQty,

              consolidationQty: (
                <Styled.consolidationQty>
                  <div
                    style={{
                      position: "relative",
                    }}
                    onClick={handleCountQuantityModalOpen(locationId)}
                  >
                    <InputText
                      placeholder="수량 입력"
                      width={7.5}
                      borderType="outline"
                      valueType="int"
                      value={consolidationQty}
                      disabledToFocus
                      visiblePostFix
                    />
                    <TempInput />
                  </div>

                  <Button
                    size="small"
                    theme="secondary"
                    label="전체병합"
                    handleClick={handleAllQuantitySelect({
                      locationId,
                      availableQty,
                    })}
                  />
                </Styled.consolidationQty>
              ),

              reset: (
                <Button
                  theme="tertiary"
                  size="small"
                  label="초기화"
                  handleClick={handleResetQuantityClick(locationId)}
                />
              ),
            };
          }
        ),
      [
        handleAllQuantitySelect,
        handleCountQuantityModalOpen,
        handleResetQuantityClick,
        inventoryDetailOfSelectedSku,
        listOfInventoryToConsolidate,
      ]
    );

  return {
    tableDataList,

    isVisibleCountQuantityModal,
    availableQty,
    setQuantityToConsolidate,
    handleCountQuantityModalClose,
  };
}
