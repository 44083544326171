import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { SET_ITEM_INSPECTION_DONE_BY_FORCE_REQ_PATH_PARAMS } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import { returningActions } from "modules/returning";

export default function useCompleteInspectionByForce() {
  const dispatch = useDispatch();

  const {
    mutate: setItemInspectionDoneByForce,
    ResponseHandler: ResponseHandlerOfCompletingInspectionByForce,
  } = RETURNING_QUERY.useSetItemInspectionDoneByForce();

  const completeInspectionByForce = useCallback(
    ({
        returningId,
        skuId,
        setConfirmModal,
      }: SET_ITEM_INSPECTION_DONE_BY_FORCE_REQ_PATH_PARAMS & {
        setConfirmModal: React.Dispatch<
          React.SetStateAction<ConfirmModal | undefined>
        >;
      }) =>
      () => {
        setItemInspectionDoneByForce(
          {
            pathParams: { returningId, skuId },
          },
          {
            onSuccess: () => {
              dispatch(
                returningActions.GET_DETAIL({
                  id: returningId,
                  _postSuccessCallback: () => setConfirmModal(undefined),
                })
              );
            },
          }
        );
      },
    [dispatch, setItemInspectionDoneByForce]
  );

  return {
    completeInspectionByForce,
    ResponseHandlerOfCompletingInspectionByForce,
  };
}
