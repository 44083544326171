import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ_PATH_PARAMS } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";

import { returningActions } from "modules/returning";

export default function useResetInspectionOfItem() {
  const dispatch = useDispatch();

  const {
    mutate: resetInspectionOfItemExceptInspector,
    ResponseHandler: ResponseHandlerOfResetInspectionOfItemExceptInspector,
  } = RETURNING_QUERY.useResetInspectionOfItemExceptInspector();

  const resetInspectionOfItem = useCallback(
    ({
        returningId,
        itemId,
        resetLocalCount,
      }: RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ_PATH_PARAMS & {
        resetLocalCount: () => void;
      }) =>
      () => {
        if (!returningId) return;

        resetInspectionOfItemExceptInspector(
          {
            pathParams: { returningId, itemId },
          },
          {
            onSuccess: () => {
              dispatch(
                returningActions.GET_DETAIL({
                  id: returningId,
                  _postSuccessCallback: resetLocalCount,
                })
              );
            },
          }
        );
      },
    [dispatch, resetInspectionOfItemExceptInspector]
  );

  return {
    resetInspectionOfItem,
    ResponseHandlerOfResetInspectionOfItemExceptInspector,
  };
}
