import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import useToast, {
  ToastProps,
} from "@sellernote/_shared/src/headlessComponents/useToast";

import Styled from "./index.styles";

export default function Toast({
  message,
  status,
  reset,
  needIcon,
  className,
}: ToastProps) {
  const { isVisibleToast } = useToast({
    reset,
  });

  if (!isVisibleToast) return null;

  return (
    <Styled.container
      {...{ status }}
      className={`${className ? className : ""} toast`}
    >
      <div className="toast-block">
        {needIcon && (
          <Icon
            type={
              status === "success"
                ? "checkFilledCircleChecked"
                : "circleFilledError"
            }
            size={1}
            color="#fff"
          />
        )}
        <span className="message">{message}</span>
      </div>
    </Styled.container>
  );
}
