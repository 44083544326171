import { useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";

import useReportPickingProblem from "hooks/shipping/useReportPickingProblem";

import ReportProblemModal from "./ReportProblemModal";

export default function ReportProblemButton({
  SKUInfoForScanning,
  resetPickingState,
}: {
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  resetPickingState: () => void;
}) {
  const [visibleReportProblemModal, setVisibleReportProblemModal] =
    useState(false);

  const { reportingList } = useReportPickingProblem({ SKUInfoForScanning });

  return (
    <>
      <Button
        theme="primary"
        size="block"
        label="피킹완료"
        handleClick={() => setVisibleReportProblemModal(true)}
      />

      {visibleReportProblemModal && (
        <ReportProblemModal
          SKUInfoForScanning={SKUInfoForScanning}
          reportingList={reportingList}
          resetPickingState={resetPickingState}
          handleReportProblemModalClose={() =>
            setVisibleReportProblemModal(false)
          }
        />
      )}
    </>
  );
}
