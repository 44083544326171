import React, { memo, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { BofulWorker } from "@sellernote/_shared/src/types/fulfillment/auth";
import {
  ReceivingInspectItem,
  ReceivingItem,
} from "@sellernote/_shared/src/types/fulfillment/receiving";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import {
  checkConfirmedAsSingleLocationInspection,
  getHasMultiLocationInspection,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useCompleteInspectionByForce from "hooks/receiving/useCompleteInspectionByForce";
import useRemoveInspectionPersonInCharge from "hooks/receiving/useRemoveInspectionPersonInCharge";
import useResetInspectionOfItem from "hooks/receiving/useResetInspectionOfItem";
import {
  CounterDataForInspection,
  getCounterKeyForMultiLocationInspection,
  getCounterKeyForSingleLocationInspection,
  SKUCountingForInspection,
} from "hooks/receiving/useSKUCountingForInspection";
import useSKUImageInfoModal from "hooks/sku/useSKUImageInfoModal";

import CompleteByForce from "components/CompleteByForce";
import CountForScanning from "components/CountForScanning";
import PersonInCharge from "components/PersonInCharge";
import ResetCounting from "components/ResetCounting";
import SelectMultiLocation from "components/SelectMultiLocation";

export interface ReceivingInspectionDetailTableItem {
  SKUId: React.ReactNode;
  count: React.ReactNode;
  personInCharge?: React.ReactNode;
  status: "Y" | "N";
  reset: React.ReactNode;
  completeByForce: React.ReactNode;
  selectMultiLocation: React.ReactNode;
  // printBarcode: React.ReactNode;
}

function getCanReset({
  skuId,
  counterData,
  hasRightToReset,
  hasMultiLocation,
}: {
  skuId: number;
  counterData: CounterDataForInspection;
  hasRightToReset: boolean;
  hasMultiLocation: boolean;
}) {
  if (hasMultiLocation) {
    return hasRightToReset;
  }

  const counterKeyAsSingleLocation =
    getCounterKeyForSingleLocationInspection(skuId);
  const itemCounter = counterData[counterKeyAsSingleLocation];

  return (
    hasRightToReset &&
    (!!itemCounter?.current || !!itemCounter?.isCompleteInspecting)
  );
}

function getHasRightToReset({
  hasMultiLocation,
  currentManagerId,
  inspectItems,
}: {
  hasMultiLocation: boolean;
  currentManagerId: number | undefined;
  inspectItems: ReceivingInspectItem[];
}) {
  // 분할검수일때는 검수자에 해당하면 권한을 부여
  if (hasMultiLocation) {
    return inspectItems?.some((ii) => ii.inspectorId === currentManagerId);
  }

  return currentManagerId === inspectItems[0].inspectorId;
}

function getCurrentSKUCount({
  skuId,
  counterData,
  inspectItems,
  hasMultiLocation,
}: {
  skuId: number;
  counterData: CounterDataForInspection;
  inspectItems: ReceivingInspectItem[];
  hasMultiLocation: boolean;
}) {
  if (!counterData) return 0;

  return inspectItems.reduce((a, c) => {
    const counterKey = hasMultiLocation
      ? getCounterKeyForMultiLocationInspection(skuId, c.inspectingId)
      : getCounterKeyForSingleLocationInspection(skuId);

    const currentTempCount = counterData[counterKey]?.current;

    return a + (currentTempCount || c.actualQty || 0);
  }, 0);
}

function getPersonInCharge({
  inspecItems,
  getWorkerById,
}: {
  inspecItems: ReceivingInspectItem[];
  getWorkerById: (workerId?: number | undefined) => BofulWorker | undefined;
}) {
  if (!inspecItems.length) return "";

  const workerIds = inspecItems.reduce((a: number[], c) => {
    if (c.inspectorId) {
      a.push(c.inspectorId);
    }
    return a;
  }, []);

  const setOfWorkerIds = new Set(workerIds);

  const isMultiInspection = workerIds.length > 1;

  const firstWorkerName = getWorkerById(workerIds[0])?.name ?? "";

  const isMultiWorker = setOfWorkerIds.size > 1;

  return isMultiInspection
    ? `[분할]${firstWorkerName} ${
        isMultiWorker ? ` 외 ${setOfWorkerIds.size - 1}` : ""
      }`
    : firstWorkerName;
}

function SKUList({
  receivingId,
  receivingItems,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  skuCounting,
  counterData,
  addCountByInput,
  resetAfterRemoveInProgressPersonInCharge,
  resetCountByCounterKey,
  setConfirmModal,
}: {
  receivingId: number;
  receivingItems: ReceivingItem[] | undefined;
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight | undefined) => void;
  skuCounting: SKUCountingForInspection;
  counterData: CounterDataForInspection;
  addCountByInput: ({ id, count }: { id: string; count: number }) => void;
  resetAfterRemoveInProgressPersonInCharge: (counterKey: string) => () => void;
  resetCountByCounterKey: (counterKey: string) => void;
  setConfirmModal: React.Dispatch<
    React.SetStateAction<ConfirmModal | undefined>
  >;
}) {
  const history = useHistory();

  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const isCurrentManagerReceivingManager = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_RECEIVING_MANAGER
  );

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  } = useSKUImageInfoModal();

  const getWorkerById = useGetWorkerByIdFactory();

  const { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge } =
    useRemoveInspectionPersonInCharge();

  const {
    resetInspectionOfItem,
    ResponseHandlerOfResetInspectionOfItemExceptInspector,
  } = useResetInspectionOfItem();

  const {
    completeInspectionByForce,
    ResponseHandlerOfCompletingInspectionByForce,
  } = useCompleteInspectionByForce();

  const handleCountAddByDirectInput = useCallback(
    (key: string) => (count: number | undefined) => {
      if (!count) return;

      const target = skuCounting.counter.counterInfo[key];

      addCountByInput({ id: key, count });

      setRowInfoToHighlight({
        rowKey: target.itemId,
      });
    },
    [addCountByInput, setRowInfoToHighlight, skuCounting]
  );

  const resetLocalCount = useCallback(
    ({
        hasMultiLocation,
        inspectItems,
        skuId,
      }: {
        hasMultiLocation: boolean;
        inspectItems: ReceivingInspectItem[];
        skuId: number;
      }) =>
      () => {
        const counterKeyListToReset = inspectItems.map((inspectItem) =>
          getCounterKeyForMultiLocationInspection(
            skuId,
            inspectItem.inspectingId
          )
        );

        if (hasMultiLocation) {
          counterKeyListToReset.forEach((counterKey) =>
            resetCountByCounterKey(counterKey)
          );
        } else {
          resetCountByCounterKey(
            getCounterKeyForSingleLocationInspection(skuId)
          );
        }
      },
    [resetCountByCounterKey]
  );

  const tableDataList = useMemo(() => {
    const list = receivingItems;

    if (!list) return [];

    const currentManagerId = currentManager?.id;

    return list.map((v) => {
      const hasMultiLocation = getHasMultiLocationInspection(
        v.quantity,
        v.inspectItems
      );

      const counterKey = getCounterKeyForSingleLocationInspection(v.sku.id);

      const skuId = getFormattedSingleSkuId(v.sku.id);

      const currentCount = getCurrentSKUCount({
        skuId: v.sku.id,
        counterData,
        inspectItems: v.inspectItems,
        hasMultiLocation,
      });

      const currentUserIsSingleLocationInspectionAssignee =
        !hasMultiLocation &&
        v.inspectItems[0]?.inspectorId === currentManagerId;
      const completedSingleLocationInspection =
        !hasMultiLocation && v.inspectItems[0]?.isCompleteInspecting;
      const skuInProgressInSingleLocationInspection =
        !hasMultiLocation &&
        v.inspectItems[0]?.inspectingId ===
          skuCounting.skuInProgress?.inspectingId;
      const canOpenDirectInputModal =
        currentUserIsSingleLocationInspectionAssignee &&
        !completedSingleLocationInspection &&
        skuInProgressInSingleLocationInspection;

      const completedInspection = v.inspectItems.every(
        (ii) => ii.isCompleteInspecting
      );

      const hasRightToReset = getHasRightToReset({
        hasMultiLocation,
        currentManagerId,
        inspectItems: v.inspectItems,
      });
      const canReset = getCanReset({
        skuId: v.sku.id,
        counterData,
        hasRightToReset,
        hasMultiLocation,
      });

      const canCompleteInspectionByForce =
        !completedInspection &&
        isCurrentManagerReceivingManager &&
        !currentCount;

      const confirmedAsSingleLocationInspection =
        checkConfirmedAsSingleLocationInspection(v);
      const canSelectMultiLocation = !confirmedAsSingleLocationInspection;

      const mappedReceiving: TableDataListItem<ReceivingInspectionDetailTableItem> =
        {
          rowKey: v.id,

          SKUId: (
            <div
              onClick={() => {
                fetchImageInfoBySKUId(v.sku.id);
              }}
            >
              {skuId}{" "}
              {v.sku.barCode ? (
                <>
                  /
                  <br />
                  {v.sku.barCode}
                </>
              ) : (
                ""
              )}
            </div>
          ),

          count: (
            <CountForScanning
              // type="inspection"
              canOpenDirectInputModal={canOpenDirectInputModal}
              // skuId={v.sku.id}
              currentQty={currentCount}
              quantity={v.quantity}
              // onCountAdd={handleCountAddByDirectInput(counterKey)}
              openDirectInputModal={() => {
                handleDirectInputModalOpen();
                setCountForScanning({
                  type: "receivingInspection",
                  skuId: v.sku.id,
                  currentQty: currentCount,
                  quantity: v.quantity,
                  onCountAdd: handleCountAddByDirectInput(counterKey),
                });
              }}
            />
          ),

          personInCharge: (
            <PersonInCharge
              worker={getPersonInCharge({
                inspecItems: v.inspectItems,
                getWorkerById,
              })}
              isRemovable={isCurrentManagerReceivingManager}
              openConfirmModal={() => {
                setConfirmModal({
                  uiType: "titleOnly",
                  title: "SKU 담당자를 삭제하시겠습니까?",
                  actions: {
                    actionPositive: {
                      label: "네",
                      handleClick: removePersonInCharge({
                        receivingId,
                        itemId: v.id,
                        resetAfterRemoveInProgressPersonInCharge:
                          resetAfterRemoveInProgressPersonInCharge(counterKey),
                      }),
                    },
                    actionNegative: {
                      label: "아니오",
                      handleClick: () => setConfirmModal(undefined),
                    },
                  },
                });
              }}
              // removePersonInCharge={removePersonInCharge({
              //   receivingId,
              //   itemId: v.id,
              //   resetAfterRemoveInProgressPersonInCharge:
              //     resetAfterRemoveInProgressPersonInCharge(counterKey),
              // })}
            />
          ),

          status: completedInspection ? "Y" : "N",

          reset: (
            <ResetCounting
              skuId={v.sku.id}
              canReset={canReset}
              openConfirmModal={() => {
                setConfirmModal({
                  uiType: "content",
                  title: `${skuId}(SKU ID)`,
                  body: (
                    <>
                      초기화를 진행하겠습니까? <br />
                      (분할내역도 초기화 됩니다.)
                    </>
                  ),
                  actions: {
                    actionPositive: {
                      label: "네",
                      handleClick: resetInspectionOfItem({
                        receivingId,
                        itemId: v.id,
                        resetLocalCount: resetLocalCount({
                          hasMultiLocation,
                          inspectItems: v.inspectItems,
                          skuId: v.sku.id,
                        }),
                      }),
                    },
                    actionNegative: {
                      label: "아니오",
                      handleClick: () => setConfirmModal(undefined),
                    },
                  },
                });
              }}
              // reset={resetInspectionOfItem({
              //   receivingId,
              //   itemId: v.id,
              //   resetLocalCount: resetLocalCount({
              //     hasMultiLocation,
              //     inspectItems: v.inspectItems,
              //     skuId: v.sku.id,
              //   }),
              // })}
            />
          ),

          completeByForce: (
            <CompleteByForce
              skuId={v.sku.id}
              canCompleteByForce={canCompleteInspectionByForce}
              openConfirmModal={() => {
                setConfirmModal({
                  uiType: "titleOnly",
                  title: `강제로 완료처리하시겠습니까? (SKU ID: ${skuId})`,
                  actions: {
                    actionPositive: {
                      label: "예",
                      handleClick: completeInspectionByForce({
                        receivingId,
                        skuId: v.sku.id,
                        setConfirmModal,
                      }),
                    },
                    actionNegative: {
                      label: "아니오",
                      handleClick: () => setConfirmModal(undefined),
                    },
                  },
                });
              }}
              // completeByForce={completeInspectionByForce({
              //   receivingId: receivingId,
              //   skuId: v.sku.id,
              // })}
            />
          ),

          selectMultiLocation: (
            <SelectMultiLocation
              type="inspection"
              canSelectMultiLocation={canSelectMultiLocation}
              skuId={v.sku.id}
              openConfirmModal={() => {
                setConfirmModal({
                  uiType: "content",
                  title: `${skuId}(SKU ID)`,
                  body: `분할검수를 진행하겠습니까?`,
                  actions: {
                    actionPositive: {
                      label: "네",
                      handleClick: () => {
                        setConfirmModal(undefined);
                        history.push(
                          `${history.location?.pathname}/multi-location/${v.sku.id}`
                        );
                      },
                    },
                    actionNegative: {
                      label: "아니오",
                      handleClick: () => setConfirmModal(undefined),
                    },
                  },
                });
              }}
            />
          ),
          // printBarcode: <PrintBarcode />,
        };

      return mappedReceiving;
    });
  }, [
    receivingItems,
    currentManager?.id,
    counterData,
    skuCounting.skuInProgress?.inspectingId,
    isCurrentManagerReceivingManager,
    getWorkerById,
    fetchImageInfoBySKUId,
    handleDirectInputModalOpen,
    setCountForScanning,
    handleCountAddByDirectInput,
    setConfirmModal,
    removePersonInCharge,
    receivingId,
    resetAfterRemoveInProgressPersonInCharge,
    resetInspectionOfItem,
    resetLocalCount,
    completeInspectionByForce,
    history,
  ]);

  // function print() {
  //   WebToApp.print();
  // }

  return (
    <>
      <TableForCounting<ReceivingInspectionDetailTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          SKUId: {
            label: "SKU ID / 상품바코드",
            fixedWidth: 130,
          },
          count: {
            label: "카운트",
            fixedWidth: 110,
          },
          personInCharge: {
            label: "담당자",
            fixedWidth: 150,
          },
          status: {
            label: "검품완료여부",
            fixedWidth: 100,
          },
          reset: {
            label: "초기화",
            fixedWidth: 110,
          },
          completeByForce: {
            label: "강제처리(카운트: 0)",
            fixedWidth: 150,
          },
          selectMultiLocation: {
            label: "분할검수",
            fixedWidth: 110,
          },
        }}
        dataList={tableDataList}
      />

      {CountForScanningModal}

      {SKUImageInfoModal}
      {ResponseHandlerOfGettingFileURLList}
      {ResponseHandlerOfGettingSKUInfo}

      {ResponseHandlerOfRemovingPersonInCharge}
      {ResponseHandlerOfResetInspectionOfItemExceptInspector}
      {ResponseHandlerOfCompletingInspectionByForce}
    </>
  );
}

export default memo(SKUList);
