import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

import {
  InputSearchLabelInfo,
  InputSearchSize,
  LabelInfoPosition,
  SearchIconPosition,
} from ".";

const container = styled.div<{
  disabled?: boolean;
  labelInfoPosition?: LabelInfoPosition;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ labelInfoPosition }) => {
    if (labelInfoPosition === "top") {
      return css`
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      `;
    }
  }}

  > .error-message {
    margin-top: 2px;
    color: ${COLOR.error_400};
    ${setFontStyle("Body5")};
  }
`;

const inputContainer = styled.div<{
  labelInfo?: InputSearchLabelInfo;
  size: InputSearchSize;
}>`
  display: flex;
  width: 100%;

  ${({ labelInfo }) => {
    if (labelInfo?.position === "left") {
      const { minWidth = "0px" } = labelInfo;

      return css`
        // 라벨 영역에 min-width가 있는 경우 inputContainer 이후 모든 형제 요소에게 레이아웃을 맞추는 스타일
        & ~ * {
          margin-left: ${minWidth};
        }
      `;
    }

    return css`
      flex-direction: column;
    `;
  }}

  .input-section {
    display: flex;
    align-items: center;
    width: 100%;

    > .button {
      margin-left: 8px;

      button {
        // 디자이너의 요청으로 커스터마이징
        height: ${({ size }) => (size === "small" ? "40px" : "48px")};
      }
    }
  }
`;

const inputWrapper = styled.div<{
  disabled?: boolean;
  isFocused?: boolean;
  inputWidth?: number;
  searchIconPosition: SearchIconPosition;
  size: InputSearchSize;
  hasErrorMessage: boolean;
}>`
  position: relative;
  width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}px` : "100%")};
  min-width: 160px;
  height: ${({ size }) => (size === "small" ? "40px" : "48px")};
  padding: ${({ size }) => (size === "small" ? "11px 12px" : "12px")};
  border: 1px solid
    ${({ isFocused, hasErrorMessage }) =>
      isFocused
        ? COLOR.primary_800
        : hasErrorMessage
        ? COLOR.error_400
        : COLOR.grayScale_400};
  border-radius: 2px;
  background-color: ${COLOR.wh};

  ${({ disabled, size, isFocused, hasErrorMessage }) => {
    if (disabled) {
      return css`
        background-color: ${COLOR.grayScale_100};
        border-color: ${COLOR.grayScale_300};
        cursor: not-allowed;
      `;
    }

    if (size === "small") {
      return css`
        border-color: ${isFocused
          ? COLOR.primary_800
          : hasErrorMessage
          ? COLOR.error_400
          : COLOR.grayScale_300};
      `;
    }
  }}

  input {
    position: absolute;
    width: calc(100% - 65px);
    top: calc(50% - 12px);
    ${({ size }) => setFontStyle(size === "small" ? "Body4" : "Body2")};
    line-height: 24px;
    padding: 0;
    margin: 0;
    color: ${TEXT_COLOR.black_2};

    ${({ searchIconPosition }) => {
      if (searchIconPosition === "left") {
        return css`
          left: 36px;
        `;
      }

      if (searchIconPosition === "right") {
        return css`
          left: 12px;
        `;
      }
    }}

    ${({ disabled }) => {
      if (disabled) {
        return css`
          cursor: not-allowed;
          color: ${TEXT_COLOR.black_disabled};
        `;
      }
    }}

    &::placeholder {
      color: ${TEXT_COLOR.black_5};
    }
  }

  .magnifying-glass-icon {
    position: absolute;

    ${({ searchIconPosition, size }) => {
      if (searchIconPosition === "left") {
        return css`
          top: ${size === "small" ? "12px" : "16px"};
          left: 12px;
        `;
      }
      if (searchIconPosition === "right") {
        return css`
          top: ${size === "small" ? "12px" : "14px"};
          right: ${size === "small" ? "8px" : "14px"};
        `;
      }
    }}
  }

  .exclamation-triangle-icon {
    position: absolute;

    ${({ searchIconPosition, size }) => {
      if (searchIconPosition === "left") {
        return css`
          top: 16px;
          right: 8px;
        `;
      }
      if (searchIconPosition === "right") {
        return css`
          top: ${size === "small" ? "12px" : "16px"};
          right: ${size === "small" ? "32px" : "40px"};
        `;
      }
    }}
  }

  .x-mark-circle-icon {
    position: absolute;
    cursor: pointer;

    ${({ searchIconPosition, size, hasErrorMessage, isFocused }) => {
      if (hasErrorMessage && !isFocused) {
        if (searchIconPosition === "left") {
          return css`
            top: 16px;
            right: 28px;
          `;
        }
        if (searchIconPosition === "right") {
          return css`
            top: ${size === "small" ? "12px" : "16px"};
            right: ${size === "small" ? "52px" : "60px"};
          `;
        }
      }

      if (searchIconPosition === "left") {
        return css`
          top: 16px;
          right: 8px;
        `;
      }
      if (searchIconPosition === "right") {
        return css`
          top: ${size === "small" ? "12px" : "16px"};
          right: ${size === "small" ? "32px" : "40px"};
        `;
      }
    }}

    ${({ disabled }) => {
      if (disabled) {
        return css`
          cursor: not-allowed;
        `;
      }
    }}
  }
`;

const labelWrapper = styled.div<{
  disabled?: boolean;
  labelInfoPosition?: LabelInfoPosition;
  hasToolTip: boolean;
  minWidth?: string;
}>`
  ${setFontStyle("Body3")};
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${({ labelInfoPosition, disabled, hasToolTip, minWidth }) => {
    if (labelInfoPosition === "left") {
      return css`
        min-width: ${minWidth ? minWidth : "50px"};
        margin-right: ${minWidth ? "0" : "8px"};

        > span {
          /* 왼쪽 레이블일 경우는 disabled 컬러가 따로 없음(디자이너의 요청) */
          color: ${TEXT_COLOR.black_3};
        }
      `;
    }

    if (labelInfoPosition === "top") {
      return css`
        margin-bottom: 4px;

        > span {
          color: ${disabled && !hasToolTip
            ? TEXT_COLOR.black_disabled
            : COLOR.grayScale_800};
        }
      `;
    }
  }}

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
      `;
    }
  }}

    > .tooltip {
    margin-left: 2px;
  }
`;

const label = styled.label<{ minWidth?: string }>`
  ${setFontStyle("Body3")};
  min-width: ${({ minWidth = "auto" }) => minWidth};
`;

export default {
  container,
  inputContainer,
  inputWrapper,
  labelWrapper,
  label,
};
