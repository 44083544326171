import { ReactNode } from "react";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import {
  Driver,
  FileDomainType,
  FulfillmentAttachment,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import {
  BidItem,
  BLTypeInfo,
  InspectionStatus,
  ReceivingDelivery,
  ReceivingInspectItem,
  ReceivingInvoiceNo,
  ReceivingKind,
  ReceivingMemo,
  ReceivingStatus,
} from "@sellernote/_shared/src/types/fulfillment/receiving";

import { BidTransportType } from "../../types/forwarding/bid";
import {
  ReceivingItem,
  ReceivingPlaceItem,
} from "../../types/fulfillment/receiving";

import { UNIX_TIME_YEAR } from "../../constants/common/common";
import { getRemainedDays, toFormattedDate } from "../common/date";
import { getTitleAmongItemListName } from "../forwarding/bid";
import { toParcelCompanyLabel } from "./fulfillment";

const getFormattedReceivingId = (
  receiving:
    | {
        bidId?: number;
        id?: number;
      }
    | undefined
) => {
  if (!receiving) {
    return "";
  }

  return receiving.bidId || `A${receiving.id}`;
};

/**
 * 초기값으로 만들어진 분할입고만 가지고 있는지 여부를 체크
 */
function checkHasOnlyInitialWarehousing(receivingItem?: ReceivingItem) {
  return (
    receivingItem?.placeItems?.length === 1 &&
    receivingItem.placeItems[0].quantity === receivingItem.actualQty &&
    !receivingItem.placeItems[0].placeQty
  );
}

/**
 * 초기값으로 만들어진 분할검수만 가지고 있는지 여부를 체크
 */
function checkHasOnlyInitialInspection(receivingItem?: ReceivingItem) {
  return (
    receivingItem?.inspectItems?.length === 1 &&
    receivingItem.inspectItems[0].quantity === receivingItem.quantity &&
    !receivingItem.inspectItems[0].actualQty
  );
}

/**
 * 분할입고인지 체크
 */
function getHasMultiLocationWarehousing(
  actualQty?: number,
  placeItems?: ReceivingPlaceItem[]
) {
  if (!placeItems) return false;

  // 1개이더라도 모든 입고양이 할당되지 않았다면 분할입고로 간주
  if (placeItems.length === 1 && placeItems[0].quantity < (actualQty ?? 0)) {
    return true;
  }

  return placeItems.length > 1;
}

/**
 * 분할검수인지 체크
 */
function getHasMultiLocationInspection(
  quantity?: number,
  inspectItems?: ReceivingInspectItem[]
) {
  if (!inspectItems) return false;

  // 1개이더라도 모든 수량이 할당되지 않았다면 분할검수로 간주
  if (inspectItems.length === 1 && inspectItems[0].quantity < (quantity ?? 0)) {
    return true;
  }

  return inspectItems.length > 1;
}

function getRemainedQtyForWarehousing(receivingItem: ReceivingItem) {
  if (!receivingItem.placeItems) return 0;

  const hasOnlyInitialWarehousing =
    checkHasOnlyInitialWarehousing(receivingItem);

  const max = receivingItem.actualQty ?? 0;

  if (hasOnlyInitialWarehousing) {
    // 초기값으로 만들어진 분할입고에서는 잔여 입고량과 상관없이 다시 분할 가능함
    return max;
  }

  const partedQuantityTotal = receivingItem.placeItems.reduce((a, c) => {
    return a + (c.quantity ?? 0);
  }, 0);

  return max - partedQuantityTotal;
}

function getRemainedQtyForInspection(receivingItem: ReceivingItem) {
  if (!receivingItem.inspectItems) return 0;

  const hasOnlyInitialInspection = checkHasOnlyInitialInspection(receivingItem);

  const max = receivingItem.quantity ?? 0;

  if (hasOnlyInitialInspection) {
    // 초기값으로 만들어진 분할검수에서는 잔여 검수량과 상관없이 다시 분할 가능함
    return max;
  }

  const partedQuantityTotal = receivingItem.inspectItems.reduce((a, c) => {
    return a + (c.quantity ?? 0);
  }, 0);

  return max - partedQuantityTotal;
}

/**
 * 각 아이템 총 합을 반환
 */
function getTotalPCS(items?: { quantity: number }[]) {
  if (!items) return 0;

  return items.reduce((p, c) => {
    return p + c.quantity;
  }, 0);
}

function getInspectionStatusString(status: InspectionStatus) {
  switch (status) {
    case "normal": {
      return "입고(정상)";
    }
    case "consent": {
      return "입고(동의)";
    }
    case "hold": {
      return "보류";
    }
    case "returned": {
      return "회송";
    }
    default: {
      return status;
    }
  }
}

function getPictureTypeLabelByDomainType(domainType: FileDomainType) {
  switch (domainType) {
    case "cargo": {
      return "컨테이너 및 씰넘버 (화물차량 및 번호)";
    }
    case "invoice": {
      return "송장스캔";
    }
    case "packing": {
      return "패킹사진";
    }
    case "damages": {
      return "데미지";
    }
    case "shippingReceipt": {
      return "출고증";
    }
    default: {
      return "";
    }
  }
}

function getPictureListByDomainType(
  attachmentList: FulfillmentAttachment[],
  domainType: FileDomainType
) {
  if (!attachmentList.length) {
    return [];
  }

  return attachmentList.filter((v) => v.domain === domainType);
}

function getStatusTitle(status: ReceivingStatus) {
  switch (status) {
    // TODO: 일단 2개밖에 안 써서 이것만 추가함.
    case "hold": {
      return "보류";
    }
    case "done": {
      return "입고완료";
    }
    default: {
      return "";
    }
  }
}

/**
 * 운송방식 label을 반환
 */
function getTransportMethodTitle({
  freightType,
  delivery,
  invoiceNo,
  noNeedTransportNumber,
}: {
  freightType?: FreightType;
  delivery?: ReceivingDelivery;
  invoiceNo?: string;
  /**
   * 택배 번호등의 정보가 결합되지 않고 없이 운송방식 타입만 필요한 경우에 사용
   */
  noNeedTransportNumber?: boolean;
}) {
  if (freightType) {
    switch (freightType) {
      case "FCL": {
        return "컨테이너";
      }
      case "AIR":
      case "LCL": {
        return "화물차량";
      }
    }
  }

  if (delivery) {
    switch (delivery) {
      case "parcel": {
        return noNeedTransportNumber ? "택배" : `택배(${invoiceNo || "-"})`;
      }
      case "truck":
      case "truckRequest": {
        return "화물차량";
      }
    }
  }

  return "";
}

/**
 * 입고방식 label을 반환
 */
function getReceivingDeliveryTitle(delivery?: ReceivingDelivery) {
  switch (delivery) {
    case "parcel": {
      return "직접입고(택배)";
    }
    case "truck": {
      return "직접입고(화물차량)";
    }
    case "truckRequest": {
      return "배차요청(화물차량)";
    }
    default: {
      return "";
    }
  }
}

/**
 *
 * 해당 입고 item이 일반입고(cf. 분할입고)로 확정되었는지 체크
 */
function checkConfirmedAsSingleLocationWarehousing(
  receivingItem?: ReceivingItem
): boolean {
  if (!receivingItem) {
    return false;
  }

  const result =
    receivingItem.placeItems?.length === 1 &&
    receivingItem.placeItems[0].quantity === receivingItem.actualQty &&
    !!receivingItem.placeItems[0].placerId;

  return result;
}

/**
 *
 * 해당 입고 item이 일반검수(cf. 분할검수)로 확정되었는지 체크
 */
function checkConfirmedAsSingleLocationInspection(
  receivingItem?: ReceivingItem
): boolean {
  if (!receivingItem) {
    return false;
  }

  const result =
    receivingItem.inspectItems?.length === 1 &&
    receivingItem.inspectItems[0].quantity === receivingItem.quantity &&
    !!receivingItem.inspectItems[0].inspectorId;

  return result;
}

function getReceivingItemTitle({
  items,
}: {
  items: { sku?: { itemName?: string } }[] | undefined;
}) {
  if (!items || !items.length) {
    return "";
  }

  const itemNameList = items.map((v) => {
    return { name: v.sku?.itemName ?? "" };
  });

  return getTitleAmongItemListName(itemNameList, 10);
}

function getReceivingStartPointName({
  isOverseas,
  startType,
  startCountry,
  getCountryNameFn,
}: {
  isOverseas: boolean;
  startType: BidTransportType;
  startCountry: string;
  getCountryNameFn: (countryCode?: string | undefined) => string;
}) {
  if (!isOverseas) return "국내 내륙";

  if (isOverseas) {
    switch (startType) {
      case "air":
        return `${getCountryNameFn(startCountry)} 공항`;
      case "sea":
        return `${getCountryNameFn(startCountry)} 항구`;
      case "inland":
        return `${getCountryNameFn(startCountry)} 내륙`;
    }
  }
}

/**
 * 운송타입에 따른 운송관련 번호를 반환
 * - 차량 : 차량번호
 * - 택배 : 택배 송장번호
 * - 수입의 경우 B/L 번호
 */
function getTransportNumber({
  receivingKind,
  delivery,
  invoiceNo,
  BL,
  driver,
}: {
  receivingKind: ReceivingKind;
  delivery?: ReceivingDelivery;
  invoiceNo?: string;
  BL?: string;
  driver?: Driver;
}) {
  if (receivingKind === "import") return BL || "-";

  if (delivery === "parcel") {
    return invoiceNo || "-";
  }

  if (delivery === "truck") {
    return driver?.truckNo || "-";
  }

  return "";
}

/**
 * 택배/운송차량에 관한 정보를 반환
 */
function getTransportDeliveryNumber({
  delivery,
  invoiceNo,
  driver,
}: {
  delivery?: ReceivingDelivery;
  invoiceNo?: string;
  driver?: Driver;
}) {
  if (delivery === "parcel") {
    return invoiceNo || "-";
  }

  if (delivery === "truck") {
    return driver?.truckNo || "-";
  }

  return "-";
}

function makeInvoiceNoLabels(invoiceNo: ReceivingInvoiceNo[] | undefined) {
  if (!invoiceNo || !invoiceNo.length) {
    return [];
  }

  return invoiceNo.map(
    (v) => `${v.invoiceNo || "-"}(${toParcelCompanyLabel(v.parcelCompany)})`
  );
}

function makeInvoiceNoToDisplay(
  invoiceNo: ReceivingInvoiceNo[] | undefined,
  isForCSV?: boolean
) {
  if (!invoiceNo || !invoiceNo.length) return "";

  const invoiceNoLabels = makeInvoiceNoLabels(invoiceNo);

  if (isForCSV) {
    return invoiceNoLabels.join(", ");
  }

  return invoiceNoLabels.length === 1
    ? invoiceNoLabels[0]
    : `${invoiceNoLabels[0]} 외 ${invoiceNoLabels.length - 1}건`;
}

function getTotalPackingUnit({
  packingUnitPallet,
  packingUnitBox,
  packingUnitOther,
}: {
  packingUnitPallet?: number;
  packingUnitBox?: number;
  packingUnitOther?: number;
}) {
  const pallet = !!packingUnitPallet && `${packingUnitPallet} 팔레트`;
  const box = !!packingUnitBox && `${packingUnitBox} 박스`;
  const other = !!packingUnitOther && `${packingUnitOther} 기타`;

  return [pallet, box, other].filter((v) => !!v).join(", ");
}

/**
 * '3.33 CBM / 8,0000 kgs' 형태의 데이터를 분리해서 표시
 * 둘 다 존재하거나 kgs만 존재하는 경우 2가지로 나뉨
 */
const makeCbmAndWeight = (
  invoiceCbm: string | undefined
): { cbm: string; weight: string } => {
  if (!invoiceCbm) {
    return { cbm: "", weight: "" };
  }

  const splitCbm = invoiceCbm.split(" / ");

  const hasCbm = splitCbm.length === 2;

  const cbm = hasCbm ? splitCbm[0] : "";
  const weight = hasCbm ? splitCbm[1] : splitCbm[0];

  return { cbm, weight };
};

/**
 * 어드민 > 입고관리 > 확정수량
 */
const getAdminPlaceQty = ({
  placeQty,
  status,
}: {
  placeQty: number | null | undefined;
  status: ReceivingStatus;
}) => {
  const isInspectionStep = !placeQty;
  const isWarehousingProblemReportStep = status === "hold";
  const isWarehousingDoneStep = status === "done";

  if (isInspectionStep || isWarehousingProblemReportStep) {
    return "-";
  }

  if (isWarehousingDoneStep) {
    return placeQty;
  }

  return "-";
};

const getReceivingKindLabel = (receivingKind: ReceivingKind) =>
  receivingKind === "import" ? "수입" : "국내";

const getAdminRemainedDays = (dueDate: string | undefined) => {
  if (!dueDate) {
    return "-";
  }

  const remainedDays = getRemainedDays(new Date(), dueDate);
  const hasRemainedDays = remainedDays >= 0;
  if (hasRemainedDays) {
    return `${remainedDays}일`;
  }

  return "기한초과";
};

const getTotalMixQty = (items: ReceivingItem[] | undefined) => {
  if (!items) {
    return 0;
  }

  return items.reduce((totalMixQty, item) => totalMixQty + item.mixQty ?? 0, 0);
};

const getAdminTransportNumber = ({
  isForCSV,
  invoiceNo,
  transportNumber,
  TransportNumberWithTooltip,
}: {
  isForCSV: boolean | undefined;
  invoiceNo: ReceivingInvoiceNo[] | undefined;
  transportNumber: string;
  TransportNumberWithTooltip: ReactNode;
}) => {
  const needsToolTip = (invoiceNo?.length ?? 0) > 1;

  if (isForCSV || !needsToolTip) {
    return transportNumber;
  }

  return TransportNumberWithTooltip;
};

const getAdminProblems = ({
  items,
  ProblemsButton,
}: {
  items: ReceivingItem[] | undefined;
  ProblemsButton: ReactNode;
}) => {
  if (!items || !items.length) {
    return "";
  }

  const hasProblem = items.some((item) => {
    const hasInspectionProblem = item.inspectionProblem !== "none";
    const hasReceivingProblem = item.receivingProblem !== "none";

    return hasInspectionProblem || hasReceivingProblem;
  });
  if (!hasProblem) {
    return "";
  }

  return ProblemsButton;
};

const getAdminMemo = ({
  memo,
  MemoButton,
}: {
  memo: ReceivingMemo[] | undefined;
  MemoButton: ReactNode;
}) => {
  if (!memo || !memo.length) {
    return "";
  }

  return MemoButton;
};

function getBLNumber<T extends boolean>(
  bid: { BLType: BLTypeInfo; hBL?: string; mBL?: string } | undefined,
  needsAsString?: T
): T extends true ? string : ReactNode {
  if (!bid) {
    return "-";
  }

  const { BLType, hBL, mBL } = bid;

  if (!BLType) {
    return "-";
  }

  if (BLType === "HBL") {
    return hBL ?? "-";
  }

  if (BLType === "MBL" || BLType === "DirectMBL") {
    return mBL ?? "-";
  }

  if (BLType === "ALL") {
    if (!needsAsString) {
      <>
        {hBL}
        <br /> {mBL}
      </>;
    }

    return `${hBL} / ${mBL}`;
  }

  return "-";
}

function getBidTitle({
  items,
  maxLength,
}: {
  items: BidItem[] | undefined;
  maxLength?: number;
}) {
  if (!items || !items.length) {
    return "";
  }

  return getTitleAmongItemListName(items, maxLength);
}

const getReceivingExpectedDate = (
  receiving:
    | {
        receivingKind: ReceivingKind;
        expectedDate: string;
      }
    | undefined,
  /** 화주웹 포맷팅을 별도로 지정해주기 위함 */
  formatTypeForShipda?: "date" | "time"
) => {
  if (!receiving) {
    return "";
  }

  const { receivingKind, expectedDate } = receiving;

  if (
    receivingKind === "import" &&
    // TODO: 백엔드 변경 이후에는 1970년도가 아닌 null이 기준이 되도록 수정
    new Date(expectedDate).getFullYear() === UNIX_TIME_YEAR
  ) {
    return "수입신고 수리 전";
  }

  if (formatTypeForShipda === "time") {
    return toFormattedDate(expectedDate, `YYYY-MM-DD HH:mm`);
  }

  if (formatTypeForShipda === "date") {
    toFormattedDate(expectedDate, `YYYY-MM-DD`);
  }

  // ADMIN, PDA
  return toFormattedDate(expectedDate, "YYYY-MM-DD");
};

export {
  getFormattedReceivingId,
  getTotalPCS,
  getInspectionStatusString,
  getPictureTypeLabelByDomainType,
  getPictureListByDomainType,
  getStatusTitle,
  getTransportMethodTitle,
  getReceivingDeliveryTitle,
  checkConfirmedAsSingleLocationWarehousing,
  checkConfirmedAsSingleLocationInspection,
  checkHasOnlyInitialWarehousing,
  checkHasOnlyInitialInspection,
  getHasMultiLocationWarehousing,
  getHasMultiLocationInspection,
  getRemainedQtyForWarehousing,
  getRemainedQtyForInspection,
  getReceivingItemTitle,
  getReceivingStartPointName,
  getTransportNumber,
  getTransportDeliveryNumber,
  getTotalPackingUnit,
  makeInvoiceNoLabels,
  makeInvoiceNoToDisplay,
  makeCbmAndWeight,
  getAdminPlaceQty,
  getReceivingKindLabel,
  getAdminRemainedDays,
  getTotalMixQty,
  getAdminTransportNumber,
  getAdminProblems,
  getAdminMemo,
  getBLNumber,
  getBidTitle,
  getReceivingExpectedDate,
};
