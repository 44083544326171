import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function CompleteByForce({
  skuId,
  canCompleteByForce,
  // completeByForce,
  openConfirmModal,
}: {
  skuId: number;
  canCompleteByForce: boolean;
  // completeByForce: () => void;
  openConfirmModal: () => void;
}) {
  return (
    // <ConfirmWrapper
    //   confirmMessageTitle={`강제로 완료처리하시겠습니까? (SKU ID: S${skuId})`}
    //   confirmModalUiType="titleOnly"
    //   customButtonLabel={{ positive: "예", negative: "아니오" }}
    //   disabled={!canCompleteByForce}
    // >
    <Button
      label="강제처리"
      size="small"
      theme="tertiary"
      handleClick={openConfirmModal}
      // handleClick={completeByForce}
      disabled={!canCompleteByForce}
    />
    // </ConfirmWrapper>
  );
}

export default memo(CompleteByForce);
