import { useCallback, useMemo } from "react";

import {
  QUERY_STALE_TIME,
  useAppQuery,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import { BofulWorker } from "../../types/fulfillment/auth";
import { BofulLocation } from "../../types/fulfillment/common";
import { FulfillmentChanel } from "../../types/fulfillment/fulfillment";

import {
  GET_FILE_URL_LIST_REQ,
  GET_FILE_URL_LIST_RES,
} from "../../api-interfaces/boful-api/file";
import { GET_LOCATION_LIST_OF_WAREHOUSE_REQ } from "../../api-interfaces/boful-api/location";

export const COMMON_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/COMMON_QUERY" }] as const,
  getFileUrlList: (params: GET_FILE_URL_LIST_REQ) =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "fileUrlList",
      },
    ] as const,
  getWorkerList: () =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        entity: "workerList",
      },
    ] as const,
  getLocationListOfWarehouse: (params: GET_LOCATION_LIST_OF_WAREHOUSE_REQ) =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "locationListOfWarehouse",
      },
    ] as const,
};

function useGetWorkerList(params?: {
  enabled?: boolean;
  onSuccess?: ((data: BofulWorker[]) => void) | undefined;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getWorkerList>,
    BofulWorker[]
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getWorkerList(),
    requestOptions: {
      method: "get",
      path: "/warehouseManager/list",
    },

    enabled: params?.enabled,

    onSuccess: params?.onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "작업자 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetLocationListOfWarehouse({
  enabled,
  onSuccess,
  refetchOnMount,
  ...params
}: GET_LOCATION_LIST_OF_WAREHOUSE_REQ & {
  enabled?: boolean;
  onSuccess?: ((data: BofulLocation[]) => void) | undefined;
  refetchOnMount?: boolean | "always";
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getLocationListOfWarehouse>,
    BofulLocation[]
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getLocationListOfWarehouse(params),
    requestOptions: {
      method: "get",
      path: `/location/list`,
      params,
    },

    enabled,

    onSuccess,

    refetchOnMount,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "위치 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetFileUrlList({
  key,
  enabled,
}: GET_FILE_URL_LIST_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getFileUrlList>,
    GET_FILE_URL_LIST_RES
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getFileUrlList({ key }),
    enabled,
    requestOptions: {
      method: "patch",
      path: "/file/download",
      apiType: "BofulDefault",
      data: { key },
    },
  });

  return { ...queryResult };
}

function useGetChannelList() {
  const queryResult = useAppQuery<FulfillmentChanel[]>({
    queryKey: ["fulfillment", "shipping", "user", "channel"],
    requestOptions: {
      method: "get",
      path: `/shipper/channel`,
      apiType: "BofulDefault",
    },
    staleTime: QUERY_STALE_TIME.oneHour,
  });

  const channelOptionList = useMemo(() => {
    const data = queryResult.data;

    if (!data) return [{ label: "직접입력", value: 0 }];

    const channelOptionList = [
      { label: "직접입력", value: 0 },
      ...data.map(({ korName, id }: { korName: string; id: number }) => {
        return { label: korName, value: id };
      }),
    ];

    return channelOptionList;
  }, [queryResult.data]);

  /**
   * channel id로 접근할 수 있는 dictionary
   */
  const channelDict = useMemo(() => {
    const dict: { [K in string]: FulfillmentChanel } = {};

    queryResult.data?.forEach((v) => {
      dict[v.id] = v;
    });

    return dict;
  }, [queryResult.data]);

  return { ...queryResult, channelOptionList, channelDict };
}

/**
 * Select 사용 예시코드 (이 경우는 select보다 useMemo가 더 나아서 실제 사용하지는 않고 있음)
 */
function useGetChannelOptionList() {
  const queryResult = useAppQuery<
    FulfillmentChanel[],
    { label: string; value: number }[]
  >({
    queryKey: ["fulfillment", "shipping", "user", "channel"],
    requestOptions: {
      method: "get",
      path: `/shipper/channel`,
      apiType: "BofulDefault",
    },
    staleTime: QUERY_STALE_TIME.oneHour,
    select: useCallback((data) => {
      if (!data) return [{ label: "직접입력", value: 0 }];

      const channelOptionList = [
        { label: "직접입력", value: 0 },
        ...data.map(({ korName, id }: { korName: string; id: number }) => {
          return { label: korName, value: id };
        }),
      ];

      return channelOptionList;
    }, []),
  });

  return { ...queryResult };
}

const COMMON_QUERY = {
  useGetWorkerList,
  useGetFileUrlList,
  useGetLocationListOfWarehouse,
  useGetChannelList,
  useGetChannelOptionList,
};
export default COMMON_QUERY;
