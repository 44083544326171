import * as EmailValidator from "email-validator";

import { TransType } from "@sellernote/_shared/src/types/common/i18n";
import regEx from "@sellernote/_shared/src/utils/common/regEx";

import { APP_LOCALE } from "../../constants";
import { UPS_AND_FEDEX_ALLOW_CHARACTER_LIST } from "../../constants/fulfillment/shipping";
export interface ValidationResult {
  result: boolean;
  message?: React.ReactNode;
}

export function isValidHsCode(val?: string) {
  if (!(val && val.length === 12)) {
    return false;
  }

  const arr = val.split("");

  const isValidated = arr.every((v, i) => {
    if (i === 4 || i === 7) {
      return v === "-";
    }

    return Number.isInteger(Number(v));
  });

  return isValidated;
}

/**
 * 문자열에 중국어가 포함되어 있는지 확인
 * @param val
 */
export function hasChinese(val: string) {
  const result = /[\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/.test(
    val
  );
  return result;
}

/**
 * 문자열에 영어가 포함되어 있는지 확인
 * @param val
 */
export function hasEnglish(val: string) {
  const result = /[a-zA-Z]/i.test(val);
  return result;
}

/**
 * xxxx-xx-xxxx(x는 정수)의 형식으로 포맷팅
 * @param val
 */
export function filterForHsCode(val: string) {
  let arr = val.split("");

  if (arr[4] && arr[4] !== "-") {
    arr = insertDivider(arr, 4);
  }

  if (arr[7] && arr[7] !== "-") {
    arr = insertDivider(arr, 7);
  }

  if (arr.length > 12) {
    arr = arr.slice(0, 12);
  }

  const isValidated = arr.every((v, i) => {
    if (i === 4 || i === 7) {
      return v === "-";
    }

    return Number.isInteger(Number(v));
  });
  if (!isValidated) {
    arr = [];
  }

  const result = arr.join("");

  return result;

  function insertDivider(originArr: string[], position: number) {
    const beforeArr = originArr.slice(0, position);
    const afterArr = originArr.slice(position);
    const result = [...beforeArr, "-", ...afterArr];

    return result;
  }
}

export function isWhiteSpace(str?: any) {
  if (!str || typeof str !== "string") {
    return false;
  }

  return str.trim() ? false : true;
}

export function getValueAsNumberOnly(v?: string) {
  if (!v) return "";

  const regExrNumber = /[^0-9]/gi;

  return v.replace(regExrNumber, "");
}

export function getValueAsNumberOrLetter(v?: string) {
  if (!v) return "";

  const regExr = /[^A-Za-z0-9]/gi;

  return v.replace(regExr, "");
}

interface ValidateLengthFunc {
  ({
    value,
    minLength,
    maxLength,
  }: {
    value: string;
    minLength?: number;
    maxLength: number;
  }): boolean;
}

export const validateLength: ValidateLengthFunc = ({
  value,
  minLength = 0,
  maxLength,
}) => {
  if (minLength < 0 || maxLength < 0) return false;
  if (minLength === maxLength) return false;
  if (minLength > maxLength) return false;

  return minLength <= value.length && value.length <= maxLength;
};

export function getStringLengthValidation({
  value,
  maxLength,
}: {
  value?: string;
  maxLength: number;
}): ValidationResult {
  if (value && !validateLength({ value, maxLength })) {
    return {
      result: false,
      message: `${maxLength}자를 넘었습니다.`,
    };
  }

  return {
    result: true,
  };
}

export function getApplicantNameValidation(value?: string): ValidationResult {
  if (value && !validateLength({ value, maxLength: 36 })) {
    return {
      result: false,
      message: "36자 이하로 입력해주세요.",
    };
  }

  return {
    result: true,
  };
}

export function getReturningRequestsValidation(
  value?: string
): ValidationResult {
  if (value && !validateLength({ value, maxLength: 255 })) {
    return {
      result: false,
      message: "255자 이하로 입력해주세요.",
    };
  }

  return {
    result: true,
  };
}

export function getDetailAddressValidation(value?: string): ValidationResult {
  if (value && !validateLength({ value, maxLength: 255 })) {
    return {
      result: false,
      message: "255자 이하로 입력해주세요.",
    };
  }

  return {
    result: true,
  };
}

export function getPhoneNumberValidation(value?: string): ValidationResult {
  if (value && !validateLength({ value, minLength: 9, maxLength: 12 })) {
    return {
      result: false,
      message: "연락처 형식이 올바르지 않습니다.",
    };
  }

  return {
    result: true,
  };
}

export function getShippingDomesticPhoneNumberValidation(
  value?: string
): ValidationResult {
  if (value && !/^[\d]{9,12}$/gi.test(value)) {
    return {
      result: false,
      message: "9자리 이상 12자리 이하 숫자만 입력 가능합니다.",
    };
  }

  return {
    result: true,
  };
}

export function getEnglishNameValidation(value?: string): ValidationResult {
  if (value && !/^[a-zA-Z0-9\s]+$/.test(value)) {
    return {
      result: false,
      message: "이름 형식이 올바르지 않습니다.",
    };
  }

  return {
    result: true,
  };
}

export function getOverseasOrderNumberValidation(
  value?: string
): ValidationResult {
  const allowedList = UPS_AND_FEDEX_ALLOW_CHARACTER_LIST.map(
    (c) => `\\${c}`
  ).join("");
  if (
    value &&
    !new RegExp(`^[a-zA-Z0-9\\s${allowedList}]+$`, "g").test(value)
  ) {
    return {
      result: false,
      message: "주문 번호 형식이 올바르지 않습니다.",
    };
  }

  return {
    result: true,
  };
}

export function getInvoiceNoValidation({
  value,
  allowEmpty,
}: {
  value?: string;
  allowEmpty?: boolean;
}): ValidationResult {
  if (!allowEmpty && !value) {
    return {
      result: false,
      message: "운송장 번호를 입력해주세요.",
    };
  }

  if (value && !validateLength({ value, minLength: 8, maxLength: 24 })) {
    return {
      result: false,
      message: "운송장 형식에 맞게 입력해주세요. (예: 123456780001)",
    };
  }

  return {
    result: true,
  };
}

export function getEmailValidation(value?: string): ValidationResult {
  const regExrEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (value && !regExrEmail.test(value)) {
    return {
      result: false,
      message: "이메일 형식이 올바르지 않습니다.",
    };
  }

  return {
    result: true,
  };
}

export function validateEmail({
  val,
  allowEmpty,
  Trans,
}: {
  val?: string;
  allowEmpty?: boolean;
  Trans: TransType;
}): ValidationResult {
  if (!allowEmpty && !val) {
    return {
      result: false,
      message: <Trans i18nKey="utils:validation.emailGuide" />,
    };
  }

  if (val && !EmailValidator.validate(val)) {
    return {
      result: false,
      message: <Trans i18nKey="utils:validation.invalideEmail" />,
    };
  }

  return {
    result: true,
  };
}

// 한국 or 싱가폴 핸드폰번호 validation
export function validateRegExPhone(phoneNumber: string) {
  return APP_LOCALE === "KR"
    ? regEx.phone.test(phoneNumber)
    : regEx.phoneSG.test(phoneNumber);
}

// 한국 or 싱가폴 핸드폰 번호 글자 수 체크
export function validatePhoneLength(phoneNumber: string) {
  return APP_LOCALE === "KR"
    ? 8 < phoneNumber.length && phoneNumber.length < 12
    : 7 < phoneNumber.length && phoneNumber.length < 11;
}

export function validatePhoneNumberWithHyphen(phoneNumber: string) {
  const numberAndHyphenRegex = /^[0-9-]*$/;

  return numberAndHyphenRegex.test(phoneNumber);
}

export function validatePhone({
  val,
  allowEmpty,
  allowHyphen = false,
  Trans,
}: {
  val?: string;
  allowEmpty?: boolean;
  allowHyphen?: boolean;
  Trans: TransType;
}): ValidationResult {
  if (!allowEmpty && !val && !allowHyphen) {
    return {
      result: false,
      message: <Trans i18nKey="utils:validation.phonenumberGuide" />,
    };
  }

  if (
    val &&
    !validateRegExPhone(val) &&
    !validatePhoneLength(val) &&
    !allowHyphen
  ) {
    return {
      result: false,
      message: <Trans i18nKey="utils:validation.invalidePhonenumber" />,
    };
  }

  if (val && allowHyphen && !validatePhoneNumberWithHyphen(val)) {
    return {
      result: false,
      message: "숫자와 하이픈만 입력 가능합니다.",
    };
  }

  return {
    result: true,
  };
}

export function validateEnglishCompanyName(val: string) {
  if (val && !regEx.engOrNumberOrBlankOrSpecial.test(val)) {
    return {
      isValid: false,
      errorMessage: "영문과 특수문자만 입력 가능합니다.",
    };
  }

  return {
    isValid: true,
  };
}

export const validateCommonPassword = ({
  password,
  Trans,
}: {
  password: string;
  Trans: TransType;
}) => {
  if (password && password.length < 6)
    return {
      isValid: false,
      errorMessage: <Trans i18nKey="mypage:profile.passwordTooShort" />,
    };

  return {
    isValid: true,
  };
};

export const validateNewPassword = ({
  currentPassword,
  newPassword,
  Trans,
}: {
  currentPassword: string;
  newPassword: string;
  Trans: TransType;
}) => {
  if (newPassword && newPassword === currentPassword) {
    return {
      isValid: false,
      errorMessage: <Trans i18nKey="mypage:profile.sameCurrentPassword" />,
    };
  }

  if (newPassword && !regEx.password.test(newPassword)) {
    return {
      isValid: false,
      errorMessage: <Trans i18nKey="mypage:profile.passwordSpecialCharacter" />,
    };
  }

  return validateCommonPassword({ password: newPassword, Trans });
};

export const validateNewPasswordConfirm = ({
  currentPassword,
  newPassword,
  newPasswordConfirm,
  Trans,
}: {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  Trans: TransType;
}) => {
  if (currentPassword && !newPasswordConfirm) {
    return { isValid: false };
  }

  if (newPassword !== newPasswordConfirm) {
    return {
      isValid: false,
      errorMessage: (
        <Trans i18nKey="mypage:profile.newPasswordMustBeDifferent" />
      ),
    };
  }

  return { isValid: true };
};

/**
 * 최대 값 이하인지 확인
 * @param value
 */
export function validateMaxValue({
  value,
  maxValue,
}: {
  value: string | number;
  maxValue: number;
}) {
  if (Number(parseFloat(value.toString())) > maxValue) return false;
  else return true;
}

/**
 * 소수점 두 자리 이상인지 확인
 * @param value
 */
export function checkHasTwoDecimalPlaces(value: string | number) {
  const twoDecimalPlaces = /^\d*[.]\d{3}$/;

  return twoDecimalPlaces.test(value.toString());
}

/**
 * 소수점 두 개 이상인지 확인
 * @param value
 */
export function checkHasTwoDots(value: string | number) {
  const dots = /^\d*[.]\d*[.]\d*$/;

  return dots.test(value.toString());
}

export const validateDecimalPrice = (value: string | number) => {
  // 소수점 두 개 이상 입력 방지
  if (checkHasTwoDots(value)) return false;

  // 소수점 두 자리 이상 입력 방지
  if (checkHasTwoDecimalPlaces(value)) return false;

  return true;
};

export const validateFobPrice = (value: string | number) => {
  if (!validateDecimalPrice(value)) return false;

  // 최대값 999999999.99 이상 입력 방지
  if (
    !validateMaxValue({
      value: value,
      maxValue: 999999999.99,
    })
  )
    return false;

  return true;
};

export function validateCount({
  count,
  maxCount,
  message,
}: {
  count?: number;
  maxCount: number;
  message?: string;
}): ValidationResult {
  if (!count) {
    return {
      result: false,
    };
  }

  if (count > maxCount) {
    return {
      result: false,
      message: message ?? "잔여 수량 이하로 입력해주세요.",
    };
  }

  return {
    result: true,
  };
}

export const validateValueLength = ({
  value,
  max,
}: {
  value: string | null | undefined;
  max: number;
}): ValidationResult => {
  if (value && value.length > max) {
    return {
      result: false,
      message: `${max}자 이하로 입력해주세요.`,
    };
  }

  return {
    result: true,
  };
};

export const hasLeadingOrTrailingWhitespace = (value: string | undefined) => {
  if (!value) {
    return false;
  }

  return /^\s|\s$/.test(value);
};
