import React, { useMemo } from "react";
import produce from "immer";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { BofulProblem } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { BOFUL_PROBLEM_OPTION_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";
import InputTextArea from "@sellernote/_sds-v1/src/components/input/InputTextArea";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import { ReportingListItem } from "hooks/shipping/useReportPickingProblem";

import SkuIdForReportingProblem from "components/SkuIdForReportingProblem";

import { ProblemList } from ".";

type ReportProblemTableItem =
  | {
      locationBarcode: string;
      SKUId: React.ReactNode;
      count: React.ReactNode;
      workerName: string;
      problem: React.ReactNode;
    }
  | Record<string, unknown>;

export default function ProblemListTable({
  reportingList,
  problemList,
  setProblemList,
}: {
  reportingList: ReportingListItem[];
  problemList: ProblemList;
  setProblemList: React.Dispatch<React.SetStateAction<ProblemList>>;
}) {
  const problemTableList: TableDataListItem<ReportProblemTableItem>[] = useMemo(
    () =>
      reportingList.flatMap((v) => {
        const hasDirectInput = problemList[v.id].kind?.value === "directInput";

        const selectProblemRow = {
          rowKey: v.id,

          rowClassName:
            problemList[v.id]?.kind?.value === "directInput"
              ? "textarea-upper-row"
              : "",

          locationBarcode: v.locationBarcode,

          SKUId: (
            <SkuIdForReportingProblem
              skuId={v.skuId}
              skuBarcode={v.skuBarcode}
            />
          ),

          count: getStyledSKUCount({
            currentCount: v.currentQty,
            goalCount: v.quantity,
          }),

          workerName: v.workerName,

          problem: (
            <InputSelect<BofulProblem>
              placeholder="선택"
              uiType="outline"
              optionList={BOFUL_PROBLEM_OPTION_LIST}
              selectedOption={problemList[v.id].kind}
              handleSelect={(problem) =>
                setProblemList(
                  produce((draft) => {
                    draft[v.id].kind = problem;
                    draft[v.id].directInput = undefined;
                  })
                )
              }
              width="100%"
            />
          ),
        };

        const textAreaRow = {
          rowKey: `${v.id}-direct-input`,

          colSpan: {
            value: 5,
            content: (
              <InputTextArea
                value={problemList[v.id].directInput}
                setValue={(value) =>
                  setProblemList(
                    produce((draft) => {
                      draft[v.id].directInput = value;
                    })
                  )
                }
                placeholder="직접입력"
                isAutoResize
                isValidated={(problemList[v.id].directInput?.length ?? 0) > 0}
              />
            ),
            hasFullWidth: true,
          },
        };

        return hasDirectInput
          ? [selectProblemRow, textAreaRow]
          : selectProblemRow;
      }),
    [problemList, reportingList, setProblemList]
  );

  return (
    <Table<ReportProblemTableItem>
      className="report-problem-table"
      columnInfo={{
        locationBarcode: {
          label: "위치",
          fixedWidth: 90,
        },
        SKUId: {
          label: (
            <>
              SKU ID
              <br />
              (상품 바코드)
            </>
          ),
          fixedWidth: 120,
        },
        count: {
          label: "카운트",
        },
        workerName: {
          label: "담당자",
        },
        problem: {
          label: "발생문제 선택",
          fixedWidth: 150,
        },
      }}
      dataList={problemTableList}
    />
  );
}
