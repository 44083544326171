import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { noop } from "@sellernote/_shared/src/utils/common/etc";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import useScan from "hooks/common/useScan";
import {
  checkHasNotSkuBarcode,
  checkHasSkuBarcodeInMultiLocation,
  getCounterKeyFromScanResultByInspectingIdInProgress,
  getIncompleteSingleLocationCounterKeyFromScanResult,
  getIncompleteSingleLocationSKUListBySkuBarcode,
  getSingleLocationCounterKeyFromScanResult,
  InspectionCounterSKU,
  SKUCountingForInspection,
} from "hooks/receiving/useSKUCountingForInspection";

import useSelectDuplicateBarcode from "../common/useSelectDuplicateBarcode";
import useBindBarcodeToSKUId from "./useBindBarcodeToSKUId";

/**
 * 일반검수/분할검수를 구분
 */
type ScanTypeInfo =
  | {
      scanType: "single";
      setSkuInProgress: (val: InspectionCounterSKU | undefined) => void;
    }
  | { scanType: "multi"; skuId: number; inspectingId?: string };

export default function useScanInspectionSKU({
  skuCounting,
  receivingId,
  setRowInfoToHighlight,
  starInspectionAt,
  ...scanTypeInfo
}: ScanTypeInfo & {
  skuCounting: SKUCountingForInspection;
  receivingId: number;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
  starInspectionAt: string | undefined;
}) {
  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const queryClient = useQueryClient();

  const {
    mutate: assignInspectorToItem,
    ResponseHandler: ResponseHandlerOfAssigningInspectorToItem,
  } = RECEIVING_QUERY.useAssignInspectorToItem();

  const {
    mutate: setInspectionStarted,
    ResponseHandler: ResponseHandlerOfSettingInspectionStarted,
  } = RECEIVING_QUERY.useSetInspectionStarted();

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const {
    handleConfirmBindingModalOpen,

    ResultHandlerOfBindingBarcodeToSKUId,
  } = useBindBarcodeToSKUId(
    scanTypeInfo.scanType === "single"
      ? {
          receivingId,
          scanType: "single",
        }
      : {
          receivingId,
          scanType: "multi",
          skuId: scanTypeInfo.skuId,
        }
  );

  const {
    handleConfirmSelectionModalOpen,
    ResultHandlerOfSelectDuplicateBarcode,
  } = useSelectDuplicateBarcode({
    type: "receivingInspection",
    setSkuInProgress:
      scanTypeInfo.scanType === "single" ? scanTypeInfo.setSkuInProgress : noop,
  });

  const handleScanResult = useCallback(
    (scanResult: string) => {
      if (!receivingId || !currentManager) return;

      let scannedCounterKey: string | undefined;

      if (scanTypeInfo.scanType === "multi") {
        if (!scanTypeInfo.inspectingId) {
          setValidationError({
            title: `분할검수할 항목을 '선택'한 후 '상품스캔'을 진행해주세요.`,
          });
          return;
        }

        const hasSkuBarcodeInMultiLocation = checkHasSkuBarcodeInMultiLocation({
          counterData: skuCounting,
          skuId: scanTypeInfo.skuId,
        });
        // 분할검수의 경우 선택된 inspectingId를 기준으로 바코드를 찾는다.
        scannedCounterKey = getCounterKeyFromScanResultByInspectingIdInProgress(
          {
            counterData: skuCounting,
            scanResult,
            inspectingIdInProgress: scanTypeInfo.inspectingId,
          }
        );
        // 이미 등록된 상품 바코드가 있는 경우 선택 모달을 띄우지 않는다.
        if (hasSkuBarcodeInMultiLocation && !scannedCounterKey) {
          setValidationError({
            title: "상품 바코드가 일치하는 상품이 없습니다.",
          });
          return;
        }

        if (!scannedCounterKey) {
          handleConfirmBindingModalOpen(scanResult);
          return;
        }
      }

      if (scanTypeInfo.scanType === "single") {
        const incompleteSKUIdListBySkuBarcode =
          getIncompleteSingleLocationSKUListBySkuBarcode({
            counterData: skuCounting,
            scanResult,
          });

        /**
         * 새로운 검수 작업을 진행하는데, 검수완료되지 않은 중복된 바코드가 있는 경우
         * 작업자가 작업할 SKU ID를 선택하도록 한다.
         */
        if (
          !skuCounting.skuInProgress &&
          incompleteSKUIdListBySkuBarcode.length > 1
        ) {
          // 선택되는 경우 skuInProgress에 등록해서 이후 스캔 부터는 해당 상품이 스캔되도록 한다.
          handleConfirmSelectionModalOpen(incompleteSKUIdListBySkuBarcode);
          return;
        }

        if (incompleteSKUIdListBySkuBarcode.length > 1) {
          // 검수완료되지 않은 중복된 바코드가 2개 이상 있는 경우 위에서 등록된 skuInProgress를 기준으로 바코드를 찾는다.
          scannedCounterKey =
            getCounterKeyFromScanResultByInspectingIdInProgress({
              counterData: skuCounting,
              scanResult,
              inspectingIdInProgress: skuCounting.skuInProgress?.inspectingId,
            });

          /**
           * 현재 작업 중이진 않지만, 리스트에는 존재할 수 있다.
           * (더 적절한 오류 메시지를 띄우기 위해서 다시 찾는다.)
           */
          if (!scannedCounterKey) {
            scannedCounterKey = getSingleLocationCounterKeyFromScanResult({
              counterData: skuCounting,
              scanResult,
            });
          }
        } else if (incompleteSKUIdListBySkuBarcode.length === 1) {
          /**
           * 일반적인 미완료된 상품바코드 스캔(검수완료되지 않은 중복된 상품 바코드가 1개 남은 경우)
           * (이미 완료된 중복된 바코드와 구분을 위해서 별도로 바코드를 찾는다.)
           */
          scannedCounterKey =
            getIncompleteSingleLocationCounterKeyFromScanResult({
              counterData: skuCounting,
              scanResult,
            });
        } else {
          // SKU ID 또는 이미 검수완료된 상품바코드
          scannedCounterKey = getSingleLocationCounterKeyFromScanResult({
            counterData: skuCounting,
            scanResult,
          });
        }

        const hasNotSkuBarcode = checkHasNotSkuBarcode(skuCounting);
        // 상품 바코드가 모두 등록되어 있는 경우에는 선택 모달을 띄우지 않는다.
        if (!hasNotSkuBarcode && !scannedCounterKey) {
          setValidationError({
            title: "상품 바코드가 일치하는 상품이 없습니다.",
          });
          return;
        }

        if (!scannedCounterKey) {
          handleConfirmBindingModalOpen(scanResult);
          return;
        }
      }

      if (!scannedCounterKey) return; // 일반검수, 분할검수 각각 마지막에 확인하기 때문에 항상 존재한다.

      const target = skuCounting.counter.counterInfo[scannedCounterKey];

      if (target.isCompleteInspecting) {
        setValidationError({
          title: `이미 검수완료한 SKU ID 입니다.`,
        });
        return;
      }

      const otherSkuIsInProgress =
        !!skuCounting.skuInProgress &&
        target.counterKey !== skuCounting.skuInProgress.counterKey;
      if (otherSkuIsInProgress) {
        const title =
          scanTypeInfo.scanType === "single"
            ? `현재 작업중인 검수(SKU ID: ${getFormattedSingleSkuId(
                skuCounting.skuInProgress?.skuId
              )})를 완료한 후에 다른 검수를 진행할 수 있습니다`
            : `현재 작업중인 분할 검수를 완료한 후에 다른 분할 검수를 진행할 수 있습니다.`;

        setValidationError({
          title,
        });
        return;
      }

      const isInitialScan = !target.inspectorId && !target.current;
      // TODO: 스캔시 담당자 권한 확인 해야함 - 창고의 네트워크 상황때문에 해당 단계 뺐음
      if (isInitialScan) {
        // 1) 작업자 지정 api 호출
        assignInspectorToItem(
          {
            pathParams: {
              receivingId,
              itemId: target.itemId,
            },
            inspectingId: target.inspectingId,
          },
          {
            onSuccess: () => {
              // 2) local count ++
              // 위에서 존재여부 이미 검사함
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              skuCounting.counter.addCountById(scannedCounterKey!);
              skuCounting.setSkuInProgress(target);

              setRowInfoToHighlight({
                rowKey:
                  scanTypeInfo.scanType === "single"
                    ? target.itemId
                    : target.inspectingId,
              });

              // 3) 변경된 상세 데이터 불러오기
              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      } else {
        const isAssignedWorker = target.inspectorId === currentManager.id;
        if (!isAssignedWorker) {
          setValidationError({
            title: "이미 타 담당자가 해당 상품을 검수하고 있습니다.",
          });
          return;
        }

        skuCounting.counter.addCountById(scannedCounterKey);
        if (target.counterKey !== skuCounting.skuInProgress?.counterKey) {
          skuCounting.setSkuInProgress(target);
        }
        setRowInfoToHighlight({
          rowKey:
            scanTypeInfo.scanType === "single"
              ? target.itemId
              : target.inspectingId,
        });
      }
    },
    [
      receivingId,
      currentManager,
      scanTypeInfo,
      skuCounting,
      setValidationError,
      handleConfirmBindingModalOpen,
      handleConfirmSelectionModalOpen,
      assignInspectorToItem,
      setRowInfoToHighlight,
      queryClient,
    ]
  );

  const checkIsInspectionStarted = useCallback(
    (scanResult: string) => {
      if (!starInspectionAt) {
        setInspectionStarted(
          {
            pathParams: { receivingId },
          },
          {
            onSuccess: () => {
              handleScanResult(scanResult);
            },
          }
        );
        return;
      }

      handleScanResult(scanResult);
    },
    [starInspectionAt, handleScanResult, setInspectionStarted, receivingId]
  );

  useScan(checkIsInspectionStarted);

  const ResultHandlerOfScanSKU = useMemo(
    () => (
      <>
        {ValidationErrorModal}

        {ResultHandlerOfBindingBarcodeToSKUId}
        {ResultHandlerOfSelectDuplicateBarcode}

        {ResponseHandlerOfAssigningInspectorToItem}
        {ResponseHandlerOfSettingInspectionStarted}
      </>
    ),
    [
      ValidationErrorModal,
      ResultHandlerOfBindingBarcodeToSKUId,
      ResultHandlerOfSelectDuplicateBarcode,
      ResponseHandlerOfAssigningInspectorToItem,
      ResponseHandlerOfSettingInspectionStarted,
    ]
  );

  return {
    ResultHandlerOfScanSKU,
  };
}
