import React from "react";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import useMyPickingTable, {
  MyPickingTableItem,
} from "hooks/shipping/useMyPickingTable";
import {
  CompletedList,
  WorkingLocation,
} from "hooks/shipping/usePickingInvoice";

export default function MyPickingTable({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  SKUInfoForScanning,
  setSKUInfoForScanning,
  completedList,
  isFirstSKUScan,
  resetPickingState,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  rowInfoToHighlight?: TableRowInfoToHighlight;
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  setSKUInfoForScanning: React.Dispatch<
    React.SetStateAction<PickingSKUInfoForScanning[]>
  >;
  completedList: React.MutableRefObject<CompletedList>;
  isFirstSKUScan: React.MutableRefObject<boolean>;
  resetPickingState: () => void;
}) {
  const {
    ConfirmModal,
    CountForScanningModal,

    myPickingTableList,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
    MessageModalOfDirectInput,

    ChangePickingLocationModal,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfStartingPickingScanning,
    ResponseHandlerOfSettingRestockByPickingQuantity,
    ResponseHandlerOfGettingInventoryListBySkuId,
    ResponseHandlerOfChangingPickingLocation,
  } = useMyPickingTable({
    selectedScanButtonType,
    handleScanButtonClick,
    workingLocation,
    setWorkingLocation,
    SKUInfoForScanning,
    setSKUInfoForScanning,
    setRowInfoToHighlight,
    completedList,
    isFirstSKUScan,
    resetPickingState,
  });

  return (
    <>
      <TableForCounting<MyPickingTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        className="picking-list-table"
        columnInfo={{
          locationToPicking: {
            label: "위치",
            fixedWidth: 90,
          },
          skuId: {
            label: "SKU ID / 상품바코드",
            fixedWidth: 130,
          },
          count: {
            label: "카운트",
            fixedWidth: 150,
          },
          invoiceNo: {
            label: "송장번호 (QR 넘버)",
            fixedWidth: 130,
          },
          deliveryType: {
            label: "운송수단",
          },
          resetCount: {
            label: "초기화",
          },
        }}
        dataList={myPickingTableList}
      />

      {ConfirmModal}
      {CountForScanningModal}

      {SKUImageInfoModal}

      {ResponseHandlerOfGettingSKUInfo}
      {MessageModalOfDirectInput}
      {ResponseHandlerOfGettingFileURLList}

      {ChangePickingLocationModal}

      {ResponseHandlerOfCheckingCancelingInvoice}
      {ResponseHandlerOfStartingPickingScanning}
      {ResponseHandlerOfSettingRestockByPickingQuantity}
      {ResponseHandlerOfGettingInventoryListBySkuId}
      {ResponseHandlerOfChangingPickingLocation}
    </>
  );
}
