import { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_INVENTORY_ATOMS } from "@sellernote/_shared/src/states/fulfillment/inventory";

export default function useCompleteAdjustment({
  isEmptyLocation,
  locationId,
  skuId,
  quantity,
}: {
  isEmptyLocation: boolean;
  locationId: number;
  skuId: number | undefined;
  quantity: number | undefined;
}) {
  const setTempKey = useSetRecoilState(FULFILLMENT_INVENTORY_ATOMS.TEMP_KEY);

  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const {
    mutate: completeInventoryAdjustment,
    ResponseHandler: ResponseHandlerOfCompletingInventoryAdjustment,
  } = INVENTORY_QUERY.useCompleteInventoryAdjustment();

  const isValidToAdjust = useMemo(
    () => !!(locationId && quantity && skuId),
    [locationId, quantity, skuId]
  );

  const handleAdjustmentComplete = useCallback(() => {
    if (!skuId || !quantity) {
      return;
    }

    completeInventoryAdjustment(
      {
        locationId,
        skuId,
        quantity,
        ...(isEmptyLocation
          ? {
              warehouseId: currentUser?.warehouseId,
            }
          : undefined),
      },

      {
        onSuccess: () => {
          setTempKey((prev) => prev + 1);
        },
      }
    );
  }, [
    completeInventoryAdjustment,
    currentUser?.warehouseId,
    isEmptyLocation,
    locationId,
    quantity,
    setTempKey,
    skuId,
  ]);

  return {
    isValidToAdjust,
    handleAdjustmentComplete,

    ResponseHandlerOfCompletingInventoryAdjustment,
  };
}
