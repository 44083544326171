import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import { ScannedLocation } from "@sellernote/_shared/src/types/fulfillment/scan";
import { UseCounterData } from "@sellernote/_shared/src/utils/common/hook";

import useScan from "hooks/common/useScan";

import { WarehousingCounterSKU } from "./useSKUCountingForWarehousing";

export default function useScanLocation({
  skuIdInprogress,
  counterData,
}: {
  skuIdInprogress: number | undefined;
  counterData: UseCounterData<WarehousingCounterSKU>;
}) {
  const [active, setActive] = useState(false);
  const [scannedLocation, setScannedLocation] = useState<ScannedLocation>();

  const tempLocation = useRef("");

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const {
    mutate: interpretWarehousingLocation,
    ResponseHandler: ResponseHandlerOfScanningLocation,
  } = SCAN_QUERY.useInterpretWarehousingLocation({
    tempLocation: tempLocation.current,
  });

  useScan((scanResult) => {
    if (!active) return;

    if (!skuIdInprogress) return;

    const isCompletedLocation = Object.values(counterData).some(
      (counter) => counter.locationBarcode === scanResult
    );
    if (isCompletedLocation) {
      setValidationError({
        title: `해당 위치(${scanResult})에는 이미 입고된 상품이 있습니다.`,
      });
      return;
    }

    tempLocation.current = scanResult;

    interpretWarehousingLocation(
      { barCode: scanResult, skuId: skuIdInprogress },
      {
        onSuccess: ({ data }) => {
          setScannedLocation(data);
          tempLocation.current = "";
        },
      }
    );
  });

  useEffect(() => {
    return () => {
      setActive(false);
      setScannedLocation(undefined);
    };
  }, []);

  const setScanLocationActive = useCallback((val: boolean) => {
    setActive(val);
  }, []);

  const showSelectionErrorForScanLocation = useCallback(() => {
    setValidationError({
      title: `상품을 먼저 스캔해주세요.`,
    });
  }, [setValidationError]);

  const ResultHandlerOfScanLocation = useMemo(() => {
    return (
      <>
        {ValidationErrorModal}
        {ResponseHandlerOfScanningLocation}
      </>
    );
  }, [ResponseHandlerOfScanningLocation, ValidationErrorModal]);

  return {
    scannedLocation,
    setScannedLocation,
    setScanLocationActive,
    showSelectionErrorForScanLocation,
    ResultHandlerOfScanLocation,
  };
}
