import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { RECEIVING_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import SKU_QUERY from "@sellernote/_shared/src/queries/fulfillment/SKU_QUERY";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";

import Styled from "./index.styles";

type ScanTypeInfo =
  | {
      scanType: "single";
    }
  | { scanType: "multi"; skuId: number };

export default function useBindBarcodeToSKUId({
  receivingId,
  ...scanTypeInfo
}: {
  receivingId: number;
} & ScanTypeInfo) {
  const [isVisibleConfirmBindingModal, setIsVisibleConfirmBindingModal] =
    useState(false);
  const [
    isVisibleBindBarcodeToSKUIdModal,
    setIsVisibleBindBarcodeToSKUIdModal,
  ] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState<string>();
  const [selectedSKUId, setSelectedSKUId] = useState<number | undefined>(
    scanTypeInfo.scanType === "multi" ? scanTypeInfo.skuId : undefined
  );

  const skuItems = useRecoilValue(FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS);

  const queryClient = useQueryClient();

  const {
    mutate: bindBarcodeToSKUId,
    ResponseHandler: ResponseHandlerOfBindingBarcodeToSKUId,
  } = SKU_QUERY.useBindBarcodeToSKUId({
    skuId: selectedSKUId,
  });

  const handleConfirmBindingModalOpen = useCallback((barcode: string) => {
    setScannedBarcode(barcode);
    setIsVisibleConfirmBindingModal(true);
  }, []);

  const handleConfirmBindingModalClose = useCallback(() => {
    setIsVisibleConfirmBindingModal(false);
  }, []);

  const handleBindBarcodeToSKUIdModalOpen = useCallback(() => {
    handleConfirmBindingModalClose();
    setIsVisibleBindBarcodeToSKUIdModal(true);
  }, [handleConfirmBindingModalClose]);

  const handleBindBarcodeToSKUIdModalClose = useCallback(() => {
    setIsVisibleBindBarcodeToSKUIdModal(false);
    setScannedBarcode(undefined);
    setSelectedSKUId(undefined);
  }, []);

  const handleCompleteBindingClick = useCallback(() => {
    if (!scannedBarcode) return;

    bindBarcodeToSKUId(
      {
        barCode: scannedBarcode,
      },
      {
        onSuccess: () => {
          handleBindBarcodeToSKUIdModalClose();

          queryClient.invalidateQueries(
            RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
              receivingId,
            })
          );
        },
      }
    );
  }, [
    bindBarcodeToSKUId,
    handleBindBarcodeToSKUIdModalClose,
    queryClient,
    receivingId,
    scannedBarcode,
  ]);

  const handleOptionClick = useCallback(
    (selectedOption: InputSelectOption<number>) => {
      setSelectedSKUId(selectedOption.value);
    },
    []
  );

  const SKUIdOptionListToBind = useMemo(
    () =>
      skuItems
        .filter((item) => {
          if (scanTypeInfo.scanType === "single") {
            const isIncompleteInspectingItemInSingleLocation =
              item.inspectItems.some(
                (inspecItem) => !inspecItem.isCompleteInspecting
              );
            const hasNotBarcodeInSingleLocation = !item.sku.barCode;
            const conditionForSingleLocation =
              isIncompleteInspectingItemInSingleLocation &&
              hasNotBarcodeInSingleLocation;

            return conditionForSingleLocation;
          }

          if (scanTypeInfo.scanType === "multi") {
            const conditionForMultiLocation =
              item.sku.id === scanTypeInfo.skuId;

            return conditionForMultiLocation;
          }

          return false;
        })
        .map((item) => ({
          label: `${getFormattedSingleSkuId(item.sku.id)} - ${
            item.sku.itemName
          }`,
          value: item.sku.id,
        })),
    [skuItems, scanTypeInfo]
  );

  const selectedSKUIdOption = useMemo(
    () => SKUIdOptionListToBind.find((item) => item.value === selectedSKUId),
    [SKUIdOptionListToBind, selectedSKUId]
  );

  const ConfirmBindingModal = useMemo(
    () => (
      <Modal
        active={isVisibleConfirmBindingModal}
        title="상품 바코드가 일치하는 상품이 없습니다. 등록하시겠습니까?"
        uiType="titleOnly"
        actionPositive={{
          label: "네",
          handleClick: handleBindBarcodeToSKUIdModalOpen,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: handleConfirmBindingModalClose,
        }}
      />
    ),
    [
      handleBindBarcodeToSKUIdModalOpen,
      handleConfirmBindingModalClose,
      isVisibleConfirmBindingModal,
    ]
  );

  const BindBarcodeToSKUIdModal = useMemo(
    () => (
      <Modal
        active={isVisibleBindBarcodeToSKUIdModal}
        title="상품 바코드를 등록할 SKU ID 선택"
        uiType="contentWithCustomBody"
        body={
          <Styled.bindBarcodeToSKUIdModalBody>
            <InputSelect
              uiType="outline"
              optionList={SKUIdOptionListToBind}
              label="SKU ID 선택"
              selectedOption={selectedSKUIdOption}
              handleSelect={handleOptionClick}
            />
            <Button
              theme="primary"
              size="normal"
              label="선택 완료"
              disabled={!selectedSKUId}
              handleClick={handleCompleteBindingClick}
            />
          </Styled.bindBarcodeToSKUIdModalBody>
        }
        onClose={handleBindBarcodeToSKUIdModalClose}
      />
    ),
    [
      SKUIdOptionListToBind,
      handleBindBarcodeToSKUIdModalClose,
      handleCompleteBindingClick,
      handleOptionClick,
      isVisibleBindBarcodeToSKUIdModal,
      selectedSKUId,
      selectedSKUIdOption,
    ]
  );

  return {
    handleConfirmBindingModalOpen,

    ResultHandlerOfBindingBarcodeToSKUId: (
      <>
        {ConfirmBindingModal}
        {BindBarcodeToSKUIdModal}
        {ResponseHandlerOfBindingBarcodeToSKUId}
      </>
    ),
  };
}
