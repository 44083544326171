import { useEffect, useState } from "react";

import { InventoryDetailBySKUId } from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";

export interface InventoryToConsolidate {
  locationId: number | undefined;
  availableQty: number;
  consolidationQty: number;
}

export default function useInventoryConsolidation() {
  const [inventoryDetailOfSelectedSku, setInventoryDetailOfSelectedSku] =
    useState<InventoryDetailBySKUId[]>();
  const [listOfInventoryToConsolidate, setListOfInventoryToConsolidate] =
    useState<InventoryToConsolidate[]>([
      { locationId: undefined, availableQty: 0, consolidationQty: 0 },
    ]);
  const [locationToConsolidate, setLocationToConsolidate] = useState<
    BofulLocation | undefined
  >();

  useEffect(() => {
    if (inventoryDetailOfSelectedSku) {
      setListOfInventoryToConsolidate(
        inventoryDetailOfSelectedSku.map(({ locationId, availableQty }) => ({
          locationId,
          availableQty,
          consolidationQty: 0,
        }))
      );

      setLocationToConsolidate(undefined);
    }
  }, [inventoryDetailOfSelectedSku]);

  return {
    inventoryDetailOfSelectedSku,
    setInventoryDetailOfSelectedSku,

    listOfInventoryToConsolidate,
    setListOfInventoryToConsolidate,

    locationToConsolidate,
    setLocationToConsolidate,
  };
}
