import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

interface ContainerProps {}
const container = styled.div``;

const withContentAlignRightItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.625rem 0.25rem;
  flex-wrap: wrap;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  > .label {
    flex-shrink: 0;
    color: ${COLOR.grayScale_500};
    ${setFontStyle("SubHead3")};
  }

  > .value {
    flex: 1;
    text-align: right;

    color: ${COLOR.grayScale_800};
    ${setFontStyle("Body7")};
    overflow: scroll;
    margin-left: 1rem;
  }
`;

const withImage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 4px;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  > .label {
    color: ${COLOR.grayScale_500};
    ${setFontStyle("Body7")};
  }

  > .image-container {
    display: flex;
    padding: 8px 4px 0 4px;

    li {
      flex: 0 0 calc(33% - 4px);
      width: 64px;
      height: 64px;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-y: hidden;
    }

    li:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
    }

    li.no-image {
      background-color: #c4c4c4;
      ${setFontStyle("Caption")};
      color: ${COLOR.grayScale_700};

      span.no-image-desc {
        display: block;
      }
    }
  }
`;

const withButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 4px;

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  > .label {
    flex-shrink: 0;
    color: ${COLOR.grayScale_500};
    ${setFontStyle("Body7")};
  }

  > .value {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    color: ${COLOR.grayScale_800};
    ${setFontStyle("Body7")};
    margin-left: 1rem;

    > .button {
      min-width: 0;
      width: auto;
      height: auto;
      margin-left: 8px;
      padding: 4px 8px;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.6px;
    }
  }
`;

export default {
  container,
  withContentAlignRightItem,
  withImage,
  withButton,
};
