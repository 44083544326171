import ReportReturningProblem from "containers/ReportReturningProblem";
import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import CloseAsNormal from "./CloseAsNormal";

function CloseWarehousing({ returningId }: { returningId: number }) {
  const { isCompletedWarehousingWithMatchedCount } = useAppSelector((state) => {
    return {
      isCompletedWarehousingWithMatchedCount:
        returningSelectors.checkIsCompletedWarehousingWithMatchedCount(state),
    };
  });

  return (
    <>
      {isCompletedWarehousingWithMatchedCount ? (
        <CloseAsNormal returningId={returningId} />
      ) : (
        <ReportReturningProblem
          returningId={returningId}
          reportedStep="warehousing"
        />
      )}
    </>
  );
}

export default CloseWarehousing;
