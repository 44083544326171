import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import useCompleteAdjustment from "./useCompleteAdjustment";
import Styled from "./index.styles";

function CompleteAdjustment({
  isEmptyLocation,
  locationId,
  skuId,
  quantity,
}: {
  isEmptyLocation: boolean;
  locationId: number;
  skuId: number | undefined;
  quantity: number | undefined;
}) {
  const {
    isValidToAdjust,
    handleAdjustmentComplete,

    ResponseHandlerOfCompletingInventoryAdjustment,
  } = useCompleteAdjustment({
    isEmptyLocation,
    locationId,
    skuId,
    quantity,
  });

  return (
    <>
      <Styled.completeAdjustment>
        <ConfirmWrapper
          confirmModalUiType="titleOnly"
          confirmMessageTitle="재고 조정을 진행 하시겠습니까?"
          customButtonLabel={{ positive: "진행", negative: "취소" }}
          disabled={!isValidToAdjust}
        >
          <Button
            className="complete-button"
            theme="primary"
            size="block"
            label="조정완료"
            handleClick={handleAdjustmentComplete}
            disabled={!isValidToAdjust}
          />
        </ConfirmWrapper>
      </Styled.completeAdjustment>

      {ResponseHandlerOfCompletingInventoryAdjustment}
    </>
  );
}

export default memo(CompleteAdjustment);
