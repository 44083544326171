import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";

import Layout from "containers/Layout";
import { LayoutRefreshInfo } from "containers/Layout/Refresh";
import withRequireAuth from "hocs/withRequireAuth";
import useConfirmModal from "hooks/common/useConfirmModal";
import useGetReceivingDetail from "hooks/receiving/useGetReceivingDetail";
import useScanLocation from "hooks/receiving/useScanLocation";
import useScanWarehousingSKU from "hooks/receiving/useScanWarehousingSKU";
import useWarehousingCounting from "hooks/receiving/useSKUCountingForWarehousing";

import CloseWarehousing from "./CloseWarehousing";
import CompleteWarehousing from "./CompleteWarehousing";
import SKUList from "./SKUList";
import Styled from "./index.styles";

export type ScanModeForReceivingWarehousing = "skuId" | "location";

function ReceivingWarehousingDetail() {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const [scanMode, setScanMode] =
    useState<ScanModeForReceivingWarehousing>("skuId");
  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);

  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const canCloseWarehousing = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.CAN_CLOSE_WAREHOUSING
  );
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );
  const skuItemsFilteredByActualQty = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS_FILTERED_BY_ACTUAL_QTY
  );

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const {
    data: receivingDetail,
    refetch: refetchReceivingDetail,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail,
  } = useGetReceivingDetail({ receivingId: Number(id) });

  const skuCounting = useWarehousingCounting(skuItemsFilteredByActualQty);

  const { setScanSKUActive, ResultHandlerOfScanSKU } = useScanWarehousingSKU({
    scanType: "single",
    skuCounting,
    receivingId: Number(id),
    setRowInfoToHighlight,
    setSkuInProgress: skuCounting.setSkuInProgress,
  });

  const {
    scannedLocation,
    setScannedLocation,
    setScanLocationActive,
    showSelectionErrorForScanLocation,
    ResultHandlerOfScanLocation,
  } = useScanLocation({
    skuIdInprogress: skuCounting.skuInProgress?.skuId,
    counterData: skuCounting.counter.counterInfo,
  });

  useEffect(() => {
    setScanSKUActive(scanMode === "skuId");
    setScanLocationActive(scanMode === "location");
  }, [scanMode, setScanLocationActive, setScanSKUActive]);

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  const checkHasUnfinishedWarehousingOfMine = useCallback(
    (items?: ReceivingItem[]) => {
      if (!items) return;

      return items.some((item) => {
        if (!item.placeItems) return false;

        return item.placeItems.some(
          (pi) => pi.placerId === currentManager?.id && !pi.isCompletePlacing
        );
      });
    },
    [currentManager?.id]
  );

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  useEffect(() => {
    const hasUnfinishedWarehousingOfMine = checkHasUnfinishedWarehousingOfMine(
      skuItemsFilteredByActualQty
    );

    if (hasUnfinishedWarehousingOfMine) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [
    checkHasUnfinishedWarehousingOfMine,
    setCanNotLeavePage,
    skuItemsFilteredByActualQty,
  ]);

  const refreshInfo: LayoutRefreshInfo = useMemo(() => {
    let confirmMessage = "";

    if (skuCounting.skuInProgress) {
      confirmMessage = `현재 카운트 중인 SKU ID(${getFormattedSingleSkuId(
        skuCounting.skuInProgress.skuId
      )})가 초기화 됩니다`;
    }

    return {
      ...(confirmMessage ? { confirm: { message: confirmMessage } } : {}),
      handleRefresh: async (showSuccessMessage) => {
        const { data } = await refetchReceivingDetail({ throwOnError: true });

        if (!data) {
          return;
        }

        setRowInfoToHighlight(undefined);
        showSuccessMessage();
        skuCounting.reset(data.data.receiving.items);
      },
    };
  }, [skuCounting, refetchReceivingDetail]);

  const handleScanSKUClick = useCallback(() => {
    setScanMode("skuId");
  }, []);

  const handleScanLocationClick = useCallback(() => {
    if (!skuCounting.skuInProgress) {
      showSelectionErrorForScanLocation();
    }

    setScanMode("location");
  }, [showSelectionErrorForScanLocation, skuCounting.skuInProgress]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      skuCounting.setSkuInProgress(undefined);
      setRowInfoToHighlight(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterComplete = useCallback(() => {
    setScanMode("skuId");
    setScannedLocation(undefined);
    setRowInfoToHighlight(undefined);
    skuCounting.setSkuInProgress(undefined);
  }, [setScannedLocation, skuCounting]);

  return (
    <Layout
      navigator={{
        title: `${getFormattedReceivingId(
          receivingDetail?.receiving
        )} / ${getTeamLabelForBofulWorker({
          id: receivingDetail?.receiving.team?.id,
          name: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.name,
            maxLength: 10,
            ellipsis: "...",
          }),
          company: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.company,
            maxLength: 10,
            ellipsis: "...",
          }),
        })}`,
      }}
      refreshInfo={refreshInfo}
    >
      <Styled.container>
        {ConfirmModal}

        <div className="header">
          <div className="left">{scannedLocation?.barCode}</div>

          <div className="right">
            <Button
              label="상품스캔"
              size="small"
              theme={scanMode === "skuId" ? "tertiary" : "secondary"}
              handleClick={handleScanSKUClick}
            />

            <Button
              label="위치스캔"
              size="small"
              theme={scanMode === "location" ? "tertiary" : "secondary"}
              handleClick={handleScanLocationClick}
            />
          </div>
        </div>

        <SKUList
          receivingId={Number(id)}
          detailItems={skuItemsFilteredByActualQty}
          selectedLocation={scannedLocation}
          rowInfoToHighlight={rowInfoToHighlight}
          setRowInfoToHighlight={setRowInfoToHighlight}
          skuCounting={skuCounting}
          counterData={skuCounting.counter.counterInfo}
          placingIdInProgress={skuCounting.skuInProgress?.placingId}
          addCountByInput={skuCounting.counter.addCountByInput}
          resetCountByCounterKey={resetCountByCounterKey}
          setConfirmModal={setConfirmModal}
        />

        <div className="total-count">
          <span className="label">총 카운트:</span>
          <span className="value">{skuCounting.totalCount}</span>
        </div>

        <div className="footer">
          {canCloseWarehousing ? (
            <CloseWarehousing receivingId={Number(id)} />
          ) : (
            <CompleteWarehousing
              locationType="single"
              receivingId={Number(id)}
              selectedLocation={scannedLocation}
              counterInProgress={
                skuCounting.skuInProgress
                  ? skuCounting.counter.counterInfo[
                      skuCounting.skuInProgress.counterKey
                    ]
                  : undefined
              }
              resetAfterComplete={resetAfterComplete}
            />
          )}
        </div>
      </Styled.container>

      {ResponseHandlerOfGettingManagerReceivingDetail}
      {ResultHandlerOfScanLocation}
      {ResultHandlerOfScanSKU}
    </Layout>
  );
}

export default withRequireAuth(ReceivingWarehousingDetail);
