import ReportReturningProblem from "containers/ReportReturningProblem";
import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import CloseAsNormal from "./CloseAsNormal";

function CloseInspection({ returningId }: { returningId: number }) {
  const { isCompletedInspectionWithMatchedCount } = useAppSelector((state) => {
    return {
      isCompletedInspectionWithMatchedCount:
        returningSelectors.checkIsCompletedInspectionWithMatchedCount(state),
    };
  });

  return (
    <>
      {isCompletedInspectionWithMatchedCount ? (
        <CloseAsNormal returningId={returningId} />
      ) : (
        <ReportReturningProblem
          returningId={returningId}
          reportedStep="inspection"
        />
      )}
    </>
  );
}

export default CloseInspection;
