import { useRecoilValue } from "recoil";

import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";

import ReportReceivingProblem from "containers/ReportReceivingProblem";

import CloseAsNormal from "./CloseAsNormal";

function CloseWarehousing({ receivingId }: { receivingId: number }) {
  const isCompletedWarehousingWithMatchedCount = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_COMPLETED_WAREHOUSING_WITH_MATCHED_COUNT
  );

  return (
    <>
      {isCompletedWarehousingWithMatchedCount ? (
        <CloseAsNormal receivingId={receivingId} />
      ) : (
        <ReportReceivingProblem
          receivingId={receivingId}
          reportedStep="warehousing"
        />
      )}
    </>
  );
}

export default CloseWarehousing;
