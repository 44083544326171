import styled from "styled-components";

const bindBarcodeToSKUIdModalBody = styled.div`
  > .input-select {
    .label {
      text-align: left;
    }

    margin-bottom: 40px;
  }
`;

export default {
  bindBarcodeToSKUIdModalBody,
};
