// 어플리케이션의 Redux Store와 연결된 hook을 정의 (그 외의 공용 hook은 _shared에 정의)

import React from "react";
import { useRef } from "react";

import { FileMimeType } from "@sellernote/_shared/src/types/common/common";

/**
 * 앱의 '사진 촬영 or 사진 선택' 기능을 호출할 트리거가 될 React Element를 전달해주면,
 * 해당 Element를 Trigger로 만든 결과와 촬영/선택된 이미지 결과 데이터(base64), 이밎 결과 데이터 reset함수를 반환한다.
 */
export default function useCameraOrGallery({
  displayStyle = "inline",
  triggerComponent,
  onFileLoaded,
}: {
  displayStyle?: "inline" | "block";
  triggerComponent: React.ReactElement;
  onFileLoaded: ({
    fileName,
    fileMimeType,
    base64,
  }: {
    fileName: string;
    fileMimeType: FileMimeType;
    base64: string;
  }) => void;
}) {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const TriggerForCameraOrGallery = (
    <div
      className="trigger-wrapper"
      style={{
        display: displayStyle === "block" ? "flex" : "inline-flex",
      }}
      onClick={() => {
        inputFileRef.current?.click();
      }}
    >
      {triggerComponent}

      <input
        style={{ display: "none" }}
        ref={inputFileRef}
        type="file"
        name="file-upload"
        id="fileUpload"
        accept="image/*"
        capture="camera"
        onChange={(e) => {
          e.preventDefault();
          const reader = new FileReader();

          const file = e?.target?.files && e.target.files[0];

          let fileName = "";
          let fileMimeType = "" as FileMimeType;

          if (file) {
            fileName = file.name;
            fileMimeType = file.type as FileMimeType;
            reader.readAsDataURL(file);
          }

          reader.onloadend = () => {
            onFileLoaded({
              fileName,
              fileMimeType,
              base64: reader.result as string,
            });
          };
        }}
      />
    </div>
  );

  return [TriggerForCameraOrGallery] as const;
}
