import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";

import useCompletePicking from "hooks/shipping/useCompletePicking";

export default function CompletePickingButton({
  SKUInfoForScanning,
  resetPickingState,
}: {
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  resetPickingState: () => void;
}) {
  const {
    isActiveCompleteButton,
    checkIsCanceledInvoice,
    CanceledInvoiceModal,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfCompletingPicking,
    ResponseHandlerOfStartingPacking,
    ResponseHandlerOfCompletingPacking,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  } = useCompletePicking({
    SKUInfoForScanning,
    resetPickingState,
  });

  return (
    <>
      <Button
        theme="primary"
        size="block"
        label="피킹완료"
        handleClick={checkIsCanceledInvoice}
        className="complete-picking-button"
        disabled={!isActiveCompleteButton}
      />

      {CanceledInvoiceModal}

      {ResponseHandlerOfCheckingCancelingInvoice}
      {ResponseHandlerOfCompletingPicking}
      {ResponseHandlerOfStartingPacking}
      {ResponseHandlerOfCompletingPacking}
      {ResponseHandlerOfSettingRestockByPickingQuantity}
    </>
  );
}
