import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { BofulMenu } from "@sellernote/_shared/src/types/fulfillment/common";
import { BofulProblem } from "@sellernote/_shared/src/types/fulfillment/fulfillment";

export const BOFUL_FAQ: { question: string; answer: React.ReactNode }[] = [
  {
    question: "파렛트로 입고되는 경우 어떻게 보관되나요?",
    answer: (
      <>
        별도 요청사항이 없을 경우 파렛트 혹은 파렛트+ 셀로 입고됩니다.
        파렛트셀은 110×110×150(CM), 파렛트+셀은 110×110×180(CM) 기준이며 각각
        보관료가 상이합니다. 가로/세로/높이가 해당 규격을 초과하는 경우,
        초과분은 타 사이즈 셀로 보관됩니다. 파렛트 1개에 적치된 모든 물량 소진
        되어야 보관비가 발생하지 않습니다. 파렛트에 소량의 화물만 남아 보관료를
        절감하고 싶은 경우, 별도 담당자에게 재고이동을 신청하십시오. 단,
        재고이동 비용이 발생합니다.
      </>
    ),
  },
  {
    question: "셀의 크기와 비용이 궁금합니다.",
    answer: (
      <>
        미니 셀 - 30×50×20(CM) - 80원
        <br />
        소형 셀 - 30×50×40(CM) - 140원
        <br />
        중형 셀 - 60×50×40(CM) - 200원
        <br />
        대형 셀 - 60×110×50(CM) - 400원
        <br />
        파렛트 셀 - 110×110×150(CM) - 820원
        <br />
        파렛트+ 셀 - 110X110X180(CM) - 900원
        <br />
        <br />총 6개의 셀로 이루어져 있습니다. 1개의 셀에는 1개의 상품종류(SKU)
        입고가 원칙입니다.
      </>
    ),
  },
  {
    question: "출고 시 택배 사이즈 및 화물차량 종류는 어떻게 되나요?",
    answer: (
      <>
        <span className="category">[택배 사이즈]</span>
        <br />

        <div className="faq-table-container">
          <div className="faq-table-wrapper">
            <img
              src="/assets/images/boful/intro/faq_table.png"
              alt=""
              className="faq-table"
            />
          </div>
        </div>

        <br />
        <br />
        <span className="category">[화물차량 종류]</span>
        <br />
        <span className="category-desc">
          화물의 부피/중량에 따라 다마스/라보/1톤~20톤차량으로 진행 가능합니다.
        </span>
      </>
    ),
  },
  {
    question: "합포장 시 추가비용이 발생하나요?",
    answer: (
      <>
        상품 또는 상품 이외 출력물 등을 동봉하는 경우 합포장 수에 따라 50원씩
        추가비용이 발생합니다.
      </>
    ),
  },
  {
    question: "국내에서 풀필먼트 센터로 입고는 불가한가요?",
    answer: (
      <>
        가능합니다. [입고관리] - [입고요청등록/관리]에서 '국내 → 창고'로
        신청하시면 국내에서 풀필먼트 센터로 입고가 가능합니다.
      </>
    ),
  },
];

const MENU_LIST: BofulMenu[] = [
  {
    title: "입고관리",
    path: "/receiving",
    subMenuList: [
      {
        title: "의뢰확인 및 입하",
        path: "/receiving/confirm",
      },
      {
        title: "검수",
        path: "/receiving/inspection",
      },
      {
        title: "입고",
        path: "/receiving/warehousing",
      },
    ],
  },
  {
    title: "출고관리",
    path: "/shipping",
    subMenuList: [
      {
        title: "의뢰확인",
        path: "/shipping/confirm",
      },
      {
        title: "피킹",
        path: "/shipping/picking",
      },
      {
        title: "패킹",
        path: "/shipping/packing",
      },
      {
        title: "출하",
        path: "/shipping/shipment",
        subMenuList: [
          {
            title: "출하준비",
            path: "/shipping/shipment/preparation",
          },
          {
            title: "출하마감",
            path: "/shipping/shipment/closing",
            subMenuList: [
              {
                title: "출하(택배)",
                path: "/shipping/shipment/closing/parcel/all",
              },
              {
                title: "출하(화물차량)",
                path: "/shipping/shipment/closing/truck/all",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "재고관리",
    path: "/inventory",
    subMenuList: [
      {
        title: "위치 / 상품 정보",
        path: "/inventory/information",
      },
      {
        title: "재고 이동",
        path: "/inventory/movement",
        subMenuList: [
          {
            title: "재고 이동 / 분할",
            path: "/inventory/movement/split",
          },
          {
            title: "재고 병합",
            path: "/inventory/movement/consolidation",
          },
          {
            title: "재고 조정",
            path: "/inventory/movement/adjustment",
          },
        ],
      },
      {
        title: "재고 조사",
        path: "/inventory/inspection",
      },
    ],
  },
  {
    title: "반품관리",
    path: "/returning",
    subMenuList: [
      {
        title: "반품의뢰확인 및 입하",
        path: "/returning/confirm",
      },
      {
        title: "반품검수",
        path: "/returning/inspection",
      },
      {
        title: "반품입고",
        path: "/returning/warehousing",
      },
    ],
  },
  {
    title: "취소관리",
    path: "/canceling",
  },
];

const BOFUL_PROBLEM_OPTION_LIST: InputSelectOption<BofulProblem>[] = [
  { label: "1. 수량불일치", value: "quantityMismatch" },
  { label: "2. 상품불일치", value: "productMismatch" },
  { label: "3. 상품데미지", value: "productDamage" },
  { label: "4. 임가공문제", value: "tollProcessing" },
  { label: "5. 직접입력", value: "directInput" },
];

export { MENU_LIST, BOFUL_PROBLEM_OPTION_LIST };
