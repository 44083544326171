import React, { memo, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { BofulWorker } from "@sellernote/_shared/src/types/fulfillment/auth";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import {
  ReceivingItem,
  ReceivingPlaceItem,
} from "@sellernote/_shared/src/types/fulfillment/receiving";
import { ScannedLocation } from "@sellernote/_shared/src/types/fulfillment/scan";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkConfirmedAsSingleLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getHasMultiLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useCompleteWarehousingByForce from "hooks/returning/useCompleteWarehousingByForce";
import useRemoveWarehousingPersonInCharge from "hooks/returning/useRemoveWarehousingPersonInCharge";
import useResetWarehousingOfItem from "hooks/returning/useResetWarehousingOfItem";
import {
  CounterDataForWarehousing,
  getCounterKeyForMultiLocationWarehousing,
  getCounterKeyForSingleLocationWarehousing,
  SKUCountingForWarehousing,
} from "hooks/returning/useSKUCountingForWarehousing";
import useSKUImageInfoModal from "hooks/sku/useSKUImageInfoModal";
import { authSelectors } from "modules/auth";
import { useAppSelector } from "store";

import CompleteByForce from "components/CompleteByForce";
import CountForScanning from "components/CountForScanning";
import PersonInCharge from "components/PersonInCharge";
import ResetCounting from "components/ResetCounting";
import SelectMultiLocation from "components/SelectMultiLocation";

interface ReturningWarehousingDetailTableItem {
  location: string;
  SKUId: React.ReactNode;
  count: React.ReactNode;
  personInCharge?: React.ReactNode;
  status: string;
  reset: React.ReactNode;
  completeByForce: React.ReactNode;
  selectMultiLocation: React.ReactNode;
}

function getCanReset({
  skuId,
  counterData,
  hasRightToReset,
  hasMultiLocation,
}: {
  skuId: number;
  counterData: CounterDataForWarehousing;
  hasRightToReset: boolean;
  hasMultiLocation: boolean;
}): boolean {
  if (hasMultiLocation) {
    return hasRightToReset;
  }

  const counterKeyAsSingleLocation =
    getCounterKeyForSingleLocationWarehousing(skuId);
  const itemCounter = counterData[counterKeyAsSingleLocation];
  return (
    hasRightToReset &&
    (!!itemCounter?.current || !!itemCounter?.isCompletePlacing)
  );
}

function getHasRightToReset({
  hasMultiLocation,
  currentUserIsMainManager,
  currentUserId,
  placeItems,
}: {
  hasMultiLocation: boolean;
  currentUserIsMainManager: boolean;
  currentUserId: number | undefined;
  placeItems: ReceivingPlaceItem[];
}) {
  // 분할입고일때는 메인매니저만 초기화 권한을 주면 됨.
  if (hasMultiLocation) {
    return currentUserIsMainManager;
  }

  return currentUserIsMainManager || currentUserId === placeItems[0].placerId;
}

function getCurrentSKUCount({
  skuId,
  counterData,
  placeItems,
  hasMultiLocation,
}: {
  skuId: number;
  counterData: CounterDataForWarehousing;
  placeItems: ReceivingPlaceItem[];
  hasMultiLocation: boolean;
}) {
  if (!counterData) return 0;

  return placeItems.reduce((a, c) => {
    const counterKey = hasMultiLocation
      ? getCounterKeyForMultiLocationWarehousing(skuId, c.placingId)
      : getCounterKeyForSingleLocationWarehousing(skuId);

    const currentTempCount = counterData[counterKey]?.current;

    return a + (currentTempCount || c.placeQty || 0);
  }, 0);
}

function getPersonInCharge({
  placeItems,
  getWorkerById,
}: {
  placeItems: ReceivingPlaceItem[];
  getWorkerById: (workerId?: number | undefined) => BofulWorker | undefined;
}) {
  if (!placeItems.length) return "";

  const workerIds = placeItems.reduce((a: number[], c) => {
    if (c.placerId) {
      a.push(c.placerId);
    }
    return a;
  }, []);

  const setOfWorkerIds = new Set(workerIds);

  const firstWorkerName = getWorkerById(workerIds[0])?.name ?? "";

  const isMultiWorker = setOfWorkerIds.size > 1;

  return isMultiWorker
    ? `${firstWorkerName} 외 ${workerIds.length - 1}`
    : firstWorkerName;
}

function getLocationName({
  skuId,
  counterData,
  selectedLocation,
  hasMultiLocation,
  placeItems,
  locationListOfWarehouse,
  placingIdInProgress,
}: {
  skuId: number;
  counterData: CounterDataForWarehousing;
  hasMultiLocation: boolean;
  placeItems: ReceivingPlaceItem[];
  selectedLocation: ScannedLocation | undefined;
  locationListOfWarehouse: BofulLocation[];
  placingIdInProgress: string | undefined;
}) {
  if (hasMultiLocation) {
    return `분할입고 - ${placeItems.length}건`;
  }

  const itemAsSingleLocation = placeItems[0];

  const counterKey = getCounterKeyForSingleLocationWarehousing(skuId);

  const counter = counterData[counterKey];

  // 현재 작업 중
  if (
    placingIdInProgress &&
    placingIdInProgress === itemAsSingleLocation.placingId &&
    counter?.current
  ) {
    return selectedLocation?.barCode ?? "";
  }

  // 완료된 위치
  if (itemAsSingleLocation.locationId) {
    return (
      locationListOfWarehouse.find(
        (v) => v.id === itemAsSingleLocation.locationId
      )?.barCode ?? ""
    );
  }

  return "";
}

function SKUList({
  returningId,
  mainManagerId,
  detailItems,
  selectedLocation,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  skuCounting,
  counterData,
  placingIdInProgress,
  addCountByInput,
  resetCountByCounterKey,
  setConfirmModal,
}: {
  returningId: number;
  mainManagerId: number | undefined;
  detailItems: ReceivingItem[] | undefined;
  selectedLocation: ScannedLocation | undefined;
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
  skuCounting: SKUCountingForWarehousing;
  counterData: CounterDataForWarehousing;
  placingIdInProgress: string | undefined;
  addCountByInput: ({ id, count }: { id: string; count: number }) => void;
  resetCountByCounterKey: (counterKey: string) => () => void;
  setConfirmModal: React.Dispatch<
    React.SetStateAction<ConfirmModal | undefined>
  >;
}) {
  const history = useHistory();

  const { currentUser } = useAppSelector((state) => {
    return {
      currentUser: authSelectors.getCurrentUser(),
    };
  });

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  } = useSKUImageInfoModal();

  const getWorkerById = useGetWorkerByIdFactory();

  const { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge } =
    useRemoveWarehousingPersonInCharge();

  const {
    resetWarehousingOfItem,
    ResponseHandlerOfResetWarehousingOfItemExceptPlacer,
  } = useResetWarehousingOfItem();

  const {
    completeWarehousingByForce,
    ResponseHandlerOfCompletingWarehousingByForce,
  } = useCompleteWarehousingByForce();

  const handleCountAddByDirectInput = useCallback(
    (counterKey: string) => (count: number | undefined) => {
      if (!count) return;

      const target = skuCounting.counter.counterInfo[counterKey];

      addCountByInput({ id: counterKey, count });

      setRowInfoToHighlight({ rowKey: target.itemId });
    },
    [addCountByInput, setRowInfoToHighlight, skuCounting]
  );

  const resetLocalCount = useCallback(
    ({
        hasMultiLocation,
        placeItems,
        skuId,
      }: {
        hasMultiLocation: boolean;
        placeItems: ReceivingPlaceItem[];
        skuId: number;
      }) =>
      () => {
        const counterKeyListToReset = placeItems.map((placeItem) =>
          getCounterKeyForMultiLocationWarehousing(skuId, placeItem.placingId)
        );

        if (hasMultiLocation) {
          counterKeyListToReset.forEach((counterKey) =>
            resetCountByCounterKey(counterKey)()
          );
        } else {
          resetCountByCounterKey(
            getCounterKeyForSingleLocationWarehousing(skuId)
          )();
        }
      },
    [resetCountByCounterKey]
  );

  const tableDataList = useMemo(() => {
    const list = detailItems;

    if (!list) return [];

    const currentUserId = currentUser?.id;
    const currentUserIsMainManager = mainManagerId === currentUserId;

    return list.map((v) => {
      const hasMultiLocation = getHasMultiLocationWarehousing(
        v.actualQty,
        v.placeItems
      );

      const counterKey = getCounterKeyForSingleLocationWarehousing(v.sku.id);

      const skuId = getFormattedSingleSkuId(v.sku.id);

      const tempLocationName = getLocationName({
        skuId: v.sku.id,
        counterData,
        placeItems: v.placeItems,
        selectedLocation,
        hasMultiLocation,
        locationListOfWarehouse,
        placingIdInProgress,
      });

      const currentCount = getCurrentSKUCount({
        skuId: v.sku.id,
        counterData,
        placeItems: v.placeItems,
        hasMultiLocation,
      });

      const currentUserIsSingleLocationWarehousingAssignee =
        !hasMultiLocation && v.placeItems?.[0].placerId === currentUserId;
      const completedSingleLocationWarehousing =
        !hasMultiLocation && v.placeItems?.[0].isCompletePlacing;
      const skuInProgressInSingleLocationInspection =
        !hasMultiLocation && v.placeItems[0]?.placingId === placingIdInProgress;
      const canOpenDirectInputModal =
        currentUserIsSingleLocationWarehousingAssignee &&
        !completedSingleLocationWarehousing &&
        skuInProgressInSingleLocationInspection;

      const hasRightToReset = getHasRightToReset({
        hasMultiLocation,
        currentUserId,
        currentUserIsMainManager,
        placeItems: v.placeItems,
      });
      const canReset = getCanReset({
        skuId: v.sku.id,
        counterData,
        hasRightToReset,
        hasMultiLocation,
      });

      const completedWarehousing = v.placeItems.every(
        (pi) => pi.isCompletePlacing
      );
      const canCompleteWarehousingByForce =
        !completedWarehousing && currentUserIsMainManager && !currentCount;

      const confirmedAsSingleLocationWarehousing =
        checkConfirmedAsSingleLocationWarehousing(v);
      const canSelectMultiLocation = !confirmedAsSingleLocationWarehousing;

      const mapped: TableDataListItem<ReturningWarehousingDetailTableItem> = {
        rowKey: v.id,

        location: tempLocationName,

        SKUId: (
          <div
            onClick={() => {
              fetchImageInfoBySKUId(v.sku.id);
            }}
          >
            {skuId}{" "}
            {v.sku.barCode ? (
              <>
                /
                <br />
                {v.sku.barCode}
              </>
            ) : (
              ""
            )}
          </div>
        ),

        count: (
          <CountForScanning
            // type="warehousing"
            canOpenDirectInputModal={canOpenDirectInputModal}
            // skuId={v.sku.id}
            currentQty={currentCount}
            quantity={v.actualQty ?? 0}
            // onCountAdd={handleCountAddByDirectInput(counterKey)}
            openDirectInputModal={() => {
              handleDirectInputModalOpen();
              setCountForScanning({
                type: "returningWarehousing",
                skuId: v.sku.id,
                currentQty: currentCount,
                quantity: v.actualQty ?? 0,
                onCountAdd: handleCountAddByDirectInput(counterKey),
              });
            }}
          />
        ),

        personInCharge: (
          <PersonInCharge
            worker={getPersonInCharge({
              placeItems: v.placeItems,
              getWorkerById,
            })}
            isRemovable={currentUserIsMainManager}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: "SKU 담당자를 삭제하시겠습니까?",
                actions: {
                  actionPositive: {
                    label: "네",
                    handleClick: removePersonInCharge({
                      returningId,
                      itemId: v.id,
                      resetAfterRemoveInProgressPersonInCharge:
                        resetCountByCounterKey(counterKey),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // removePersonInCharge={removePersonInCharge({
            //   returningId,
            //   itemId: v.id,
            //   resetAfterRemoveInProgressPersonInCharge:
            //     resetCountByCounterKey(counterKey),
            // })}
          />
        ),

        status: v.placeItems.some((p) => p.isCompletePlacing) ? "Y" : "N",

        reset: (
          <ResetCounting
            skuId={v.sku.id}
            canReset={canReset}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "content",
                title: `${skuId}(SKU ID)`,
                body: (
                  <>
                    초기화를 진행하겠습니까? <br />
                    (분할내역도 초기화 됩니다.)
                  </>
                ),
                actions: {
                  actionPositive: {
                    label: "네",
                    handleClick: resetWarehousingOfItem({
                      returningId,
                      itemId: v.id,
                      resetLocalCount: resetLocalCount({
                        hasMultiLocation,
                        placeItems: v.placeItems,
                        skuId: v.sku.id,
                      }),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // reset={resetWarehousingOfItem({
            //   returningId,
            //   itemId: v.id,
            //   resetLocalCount: resetLocalCount({
            //     hasMultiLocation,
            //     placeItems: v.placeItems,
            //     skuId: v.sku.id,
            //   }),
            // })}
          />
        ),

        completeByForce: (
          <CompleteByForce
            skuId={v.sku.id}
            canCompleteByForce={canCompleteWarehousingByForce}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: `강제로 완료처리하시겠습니까? (SKU ID: ${skuId})`,
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: completeWarehousingByForce({
                      returningId,
                      skuId: v.sku.id,
                      setConfirmModal,
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // completeByForce={completeWarehousingByForce({
            //   returningId,
            //   skuId: v.sku.id,
            // })}
          />
        ),

        selectMultiLocation: (
          <SelectMultiLocation
            type="warehousing"
            canSelectMultiLocation={canSelectMultiLocation}
            skuId={v.sku.id}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "content",
                title: `${skuId}(SKU ID)`,
                body: `분할입고를 진행하겠습니까?`,
                actions: {
                  actionPositive: {
                    label: "네",
                    handleClick: () => {
                      setConfirmModal(undefined);
                      history.push(
                        `${history.location?.pathname}/multi-location/${v.sku.id}`
                      );
                    },
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
          />
        ),
      };

      return mapped;
    });
  }, [
    detailItems,
    currentUser?.id,
    mainManagerId,
    counterData,
    selectedLocation,
    locationListOfWarehouse,
    placingIdInProgress,
    getWorkerById,
    fetchImageInfoBySKUId,
    handleDirectInputModalOpen,
    setCountForScanning,
    handleCountAddByDirectInput,
    setConfirmModal,
    removePersonInCharge,
    returningId,
    resetCountByCounterKey,
    resetWarehousingOfItem,
    resetLocalCount,
    completeWarehousingByForce,
    history,
  ]);

  return (
    <>
      <TableForCounting<ReturningWarehousingDetailTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          location: {
            label: "위치",
            fixedWidth: 100,
          },
          SKUId: {
            label: "SKU ID / 상품바코드",
            fixedWidth: 130,
          },
          count: {
            label: "카운트",
            fixedWidth: 110,
          },
          personInCharge: {
            label: "담당자",
            fixedWidth: 150,
          },
          status: {
            label: "입고완료여부",
            fixedWidth: 100,
          },
          reset: {
            label: "초기화",
            fixedWidth: 110,
          },
          completeByForce: {
            label: "강제처리(카운트: 0)",
            fixedWidth: 150,
          },
          selectMultiLocation: {
            label: "분할입고",
            fixedWidth: 110,
          },
        }}
        dataList={tableDataList}
      />

      {CountForScanningModal}

      {SKUImageInfoModal}
      {ResponseHandlerOfGettingSKUInfo}
      {ResponseHandlerOfGettingFileURLList}

      {ResponseHandlerOfRemovingPersonInCharge}
      {ResponseHandlerOfResetWarehousingOfItemExceptPlacer}
      {ResponseHandlerOfCompletingWarehousingByForce}
    </>
  );
}

export default memo(SKUList);
