import { Dispatch, SetStateAction, useCallback, useState } from "react";

import useValidationErrorToast from "@sellernote/_shared/src/hooks/common/useValidationErrorToast";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import Toast from "@sellernote/_sds-v1/src/components/Toast";

import useScan from "hooks/common/useScan";

import { SkuToSelect } from "../useSelectSkuModal";

export default function useScanSku({
  tempSku,
  setTempSku,
  setSelectedSku,
  onSelectSkuModalClose,
}: {
  tempSku: SkuToSelect | undefined;
  setTempSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
  setSelectedSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
  onSelectSkuModalClose: () => void;
}) {
  const [scanResult, setScanResult] = useState<string>();
  const [duplicateSkuList, setDuplicateSkuList] = useState<SkuToSelect[]>();

  const [setValidationError, ValidationErrorToast] =
    useValidationErrorToast(Toast);

  const { mutate: interpretBarcode } = SCAN_QUERY.useInterpretBarcode<"sku">();

  useScan((scanResult) => {
    if (tempSku) {
      return;
    }

    interpretBarcode(
      { type: "sku", barCode: scanResult },
      {
        onSuccess: ({ data }) => {
          data.length === 1
            ? setTempSku(
                data.map<SkuToSelect>(({ id, barCode }) => ({ id, barCode }))[0]
              )
            : setDuplicateSkuList(
                data.reduce<SkuToSelect[]>((list, { id, barCode }) => {
                  if (list.some((item) => item.id === id)) {
                    return list;
                  }

                  return [...list, { id, barCode }];
                }, [])
              );

          setScanResult(scanResult);
        },

        onError: ({ response }) => {
          if (response?.data?.code === 404) {
            setValidationError({ message: "올바른 바코드를 스캔해주세요." });
            return;
          }

          setValidationError({
            message: "바코드 스캔 중에 오류가 발생했습니다.",
          });
        },
      }
    );
  });

  const handleSkuSelect = useCallback(
    (v: SkuToSelect) => () => {
      setTempSku(v);
    },
    [setTempSku]
  );

  const handleTempSkuReset = useCallback(() => {
    setTempSku(undefined);
    setDuplicateSkuList(undefined);
    setScanResult(undefined);
  }, [setTempSku]);

  const handleSelectSkuModalConfirm = useCallback(() => {
    setSelectedSku(tempSku);
    onSelectSkuModalClose();
  }, [onSelectSkuModalClose, setSelectedSku, tempSku]);

  return {
    scanResult,

    duplicateSkuList,
    handleSkuSelect,
    handleTempSkuReset,

    handleSelectSkuModalConfirm,

    ValidationErrorToast,
  };
}
