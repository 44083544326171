import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section`
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .left {
      ${setFontStyle("SubHead2", "Bold")};
      color: ${COLOR.grayScale_800};
    }

    > .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > .button {
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  > .table-for-counting {
    margin-top: 24px;
  }

  > .total-count {
    margin-top: 40px;
    padding: 8px;
    border-radius: 4px;
    background-color: ${COLOR.bgColor_3};
    text-align: center;

    > .label {
      ${setFontStyle("SubHead2")};
      margin-right: 8px;
    }

    > .value {
      ${setFontStyle("Body6", "Bold")};
    }
  }

  > .footer {
    margin-top: 40px;

    > .button {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
`;

export default {
  container,
};
