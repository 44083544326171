import { FC, ReactNode, useState } from "react";

import { useBodyScrollLock } from "../hooks/common/useBodyScrollLock";

import { ButtonPropsV2 } from "./button/useButton";
import { SvgIconComponent } from "./useSvgIcon";

type ModalPropsV1 = ModalCommonPropsV1 &
  (
    | ModalTitleOnlyProps
    | ModalWebNegativeProps
    | ModalContentProps
    | ModalFormInputProps
  );

interface ModalCommonPropsV1 extends ModalCommonProps {
  title: React.ReactNode;
}

interface ModalCommonProps {
  /**
   * 하위 element가 modal범위를 넘어가서 표시되어야 할 때 사용
   */
  allowOverflow?: boolean;
  barcodeValues?: {
    actionNegative?: string;
    actionPositive: string;
  };
  active: boolean;
  onClose?: () => void;
  needConfirmBeforeCloseModal?: boolean;
  needConfirmBeforeCloseModalInfo?: {
    title?: ReactNode;
    actionPositiveLabel?: ReactNode;
    actionNegativeLabel?: ReactNode;
  };
  className?: string;
  usePortal?: boolean;
  children?: ReactNode;
}

type ModalComponent = FC<ModalPropsV1>;

type ButtonPartialProps = Partial<Pick<ButtonPropsV2, "theme" | "borderType">>;

type ButtonInModalCommonProps = Omit<
  ButtonPropsV2,
  "size" | "theme" | "borderType"
> &
  ButtonPartialProps;

interface ModalFooterActions {
  actionPositive?: {
    label: React.ReactNode;
    handleClick: () => void;
    isCritical?: boolean;
    disabled?: boolean;
    borderType?: "filled" | "outlined";
  };

  actionNegative?: {
    label: React.ReactNode;
    handleClick: () => void;
    isCritical?: boolean;
    isSecondary?: boolean;
    borderType?: "filled" | "outlined";
  };

  actionOption?: ButtonInModalCommonProps;
}

type ModalContentProps = {
  uiType: "content" | "contentWithCustomBody";
  body: React.ReactNode;
  submitButton?: React.ReactNode;
  className?: string;
} & ModalFooterActions;

type ModalTitleOnlyProps = {
  uiType: "titleOnly" | "webNegativeTitleOnly";
  isWebNegativeTitle?: boolean;
  isWebNegativeActionPositive?: boolean;
} & ModalFooterActions;

type ModalWebNegativeProps = {
  uiType: "webNegative";
  body?: React.ReactNode;
} & ModalFooterActions;

interface ModalFormInputProps {
  uiType: "formInput";
  desc?: string | React.ReactNode;
  body: React.ReactNode;
  hasBorder: boolean;
  submitButton?: React.ReactNode;
  disableMaxHeight?: boolean;
}

// V2 Modal types
type ModalPropsV2 = ModalCommonProps &
  (
    | ModalTitleAndBodyProps
    | ModalTitleAndDescAndBodyProps
    | ModalOnlyDescriptionProps
    | ModalIconAndBodyProps
    | ModalInputProps
  );

type ModalComponentV2 = FC<ModalPropsV2>;

type ModalUiTypeV2 =
  | "titleAndBody"
  | "titleAndDescAndBody"
  | "onlyDescription"
  | "iconAndBody"
  | "formInput";

type ModalTitleAndBodyProps = {
  uiType: "titleAndBody";
  title: React.ReactNode;
  body: React.ReactNode;
} & ModalFooterActions;

type ModalTitleAndDescAndBodyProps = {
  uiType: "titleAndDescAndBody";
  title: React.ReactNode;
  body: React.ReactNode;
  desc: React.ReactNode;
} & ModalFooterActions;

type ModalOnlyDescriptionProps = {
  uiType: "onlyDescription";
  desc: React.ReactNode;
} & ModalFooterActions;

type ModalIconAndBodyProps = {
  uiType: "iconAndBody";
  Icon: SvgIconComponent;
  body: React.ReactNode;
} & ModalFooterActions;

type ModalInputProps = {
  uiType: "formInput";
  title?: React.ReactNode;
  desc?: React.ReactNode;
  body: React.ReactNode;
  width?: string;
  height?: string;
} & ModalFooterActions;

function useModal({
  active,
  onClose,
  needConfirmBeforeCloseModal,
}: Pick<
  ModalCommonProps,
  "active" | "onClose" | "needConfirmBeforeCloseModal"
>) {
  const [
    isVisibleConfirmBeforeCloseModal,
    setIsVisibleConfirmBeforeCloseModal,
  ] = useState(false);

  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock({
    enablesLocking: active,
  });

  const handleClickCloseModal = (e: React.MouseEvent) => {
    if (!onClose) return;

    if (needConfirmBeforeCloseModal)
      return setIsVisibleConfirmBeforeCloseModal(true);

    onClose();
    e.stopPropagation();
  };

  return {
    handleClickCloseModal,
    isVisibleConfirmBeforeCloseModal,
    setIsVisibleConfirmBeforeCloseModal,
  };
}

export default useModal;

export type {
  ModalPropsV1,
  ModalFooterActions,
  ModalTitleOnlyProps,
  ModalContentProps,
  ModalWebNegativeProps,
  ModalFormInputProps,
  ModalCommonProps,
  ModalCommonPropsV1,
  ButtonInModalCommonProps,
  ModalComponent,
  ModalUiTypeV2,
  ModalPropsV2,
  ModalComponentV2,
};
