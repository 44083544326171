import React, { useCallback, useMemo } from "react";
import produce from "immer";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import {
  getItemIdListByWorkingLocation,
  getPickingItemId,
} from "@sellernote/_shared/src/utils/fulfillment/shipping";

import useScan from "hooks/common/useScan";

import { CompletedList, WorkingLocation } from "./usePickingInvoice";
import usePickingMessageModal from "./usePickingMessageModal";

export default function useScanPickingSKU({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  setRowInfoToHighlight,
  SKUInfoForScanning,
  setSKUInfoForScanning,
  completedList,
  isFirstSKUScan,
  resetPickingState,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation?: WorkingLocation;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  setSKUInfoForScanning: React.Dispatch<
    React.SetStateAction<PickingSKUInfoForScanning[]>
  >;
  completedList: React.MutableRefObject<CompletedList>;
  isFirstSKUScan: React.MutableRefObject<boolean>;
  resetPickingState: () => void;
}) {
  const { shippingId, invoiceNo } = useRecoilValue(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );
  const isActiveScanSKUButton = shippingId !== 0;

  const {
    MessageModal,
    modalOpenHandler,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  } = usePickingMessageModal();

  const {
    mutate: interpretBarcode,
    ResponseHandler: ResponseHandlerOfCheckingCancelingInvoice,
  } = SCAN_QUERY.useInterpretBarcode<"shipping">({
    isToCheckForCanceling: true,
  });

  const {
    mutate: startPickingScanning,
    ResponseHandler: ResponseHandlerOfStartingPickingScanning,
  } = SHIPPING_QUERY.useStartPickingScanning();

  const setSKUScanningResult = useCallback(
    ({
      scannedSKUId,
      scannedItemId,
      itemIdListByWorkingLocation,
    }: {
      scannedSKUId: string;
      scannedItemId: number;
      itemIdListByWorkingLocation: number[];
    }) => {
      setRowInfoToHighlight({ rowKey: scannedItemId });
      restoreCompletedItemIdListByWorkingLocation();
      countSKU();
      checkIsCompletedScanByScannedItemId();

      function restoreCompletedItemIdListByWorkingLocation() {
        // 다른 위치 작업을 하다가 기존에 완료된 위치의 상품을 초기화하해서 다시 돌아온 경우 작업 위치에서 완료된 상품 목록을 복원해준다.
        if (
          !isFirstSKUScan.current &&
          completedList.current.itemIdListByWorkingLocation.size === 0
        ) {
          itemIdListByWorkingLocation.forEach((itemId) => {
            if (completedList.current.itemIdList.has(itemId)) {
              completedList.current.itemIdListByWorkingLocation.add(itemId);
            }
          });
        }
      }

      function countSKU() {
        setSKUInfoForScanning(
          produce((draft) => {
            const SKUInfoByScannedItemId = draft.find(
              (v) => v.id === scannedItemId
            );

            if (SKUInfoByScannedItemId) {
              SKUInfoByScannedItemId.currentQty += 1;
            }
          })
        );
      }

      // SKU 완료 확인 -> 취소 확인 -> 현재 작업 위치 완료 확인
      function checkIsCompletedScanByScannedItemId() {
        const scannedSKUInfo = SKUInfoForScanning.find(
          (v) => v.id === scannedItemId
        );
        const isCompletedScanByScannedItemId =
          (scannedSKUInfo?.currentQty ?? 0) + 1 === scannedSKUInfo?.quantity;

        if (isCompletedScanByScannedItemId) {
          completedList.current.itemIdListByWorkingLocation.add(scannedItemId);
          completedList.current.itemIdList.add(scannedItemId);

          checkIsCanceledInvoice();
        }

        function checkIsCanceledInvoice() {
          interpretBarcode(
            { type: "shipping", barCode: invoiceNo },
            {
              onSuccess: ({ data: invoiceData }) => {
                if (invoiceData.shippingStatus === "cancel") {
                  prepareToMoveToCanceling();
                  return;
                }

                modalOpenHandler.openCompletedPickingBySKUIdMessage(
                  scannedSKUId
                );

                checkIsCompletedScanByWorkingLocation();

                function prepareToMoveToCanceling() {
                  const restockItems = SKUInfoForScanning.map((v) => {
                    if (v.id === scannedItemId) {
                      return {
                        itemId: v.id,
                        restockQty: v.currentQty + 1,
                      };
                    }

                    return {
                      itemId: v.id,
                      restockQty: v.currentQty,
                    };
                  });

                  modalOpenHandler.openCanceledInvoiceInPickingMessage({
                    invoiceNo: invoiceData.invoiceNo,
                    shippingId,
                    restockItems,
                  });
                }

                function checkIsCompletedScanByWorkingLocation() {
                  const isCompletedScanByWorkingLocation =
                    JSON.stringify([...itemIdListByWorkingLocation].sort()) ===
                    JSON.stringify(
                      [
                        ...completedList.current.itemIdListByWorkingLocation,
                      ].sort()
                    );

                  if (isCompletedScanByWorkingLocation) {
                    completedList.current.locationIdList.add(
                      workingLocation?.id ?? 0
                    );

                    completedList.current.itemIdListByWorkingLocation.clear();
                    setWorkingLocation(undefined);
                    handleScanButtonClick(undefined);
                  }
                }
              },
            }
          );
        }
      }
    },
    [
      SKUInfoForScanning,
      completedList,
      handleScanButtonClick,
      interpretBarcode,
      isFirstSKUScan,
      modalOpenHandler,
      invoiceNo,
      shippingId,
      setRowInfoToHighlight,
      setSKUInfoForScanning,
      setWorkingLocation,
      workingLocation,
    ]
  );

  const itemIdListByWorkingLocation = useMemo(
    () =>
      getItemIdListByWorkingLocation({
        pickingList: SKUInfoForScanning,
        workingLocationId: workingLocation?.id ?? 0,
      }),
    [SKUInfoForScanning, workingLocation?.id]
  );
  const checkIsCorrectSKUBarcode = useCallback(
    ({ scannedBarcode }: { scannedBarcode: string }) => {
      if (!workingLocation) {
        return;
      }

      // 위치는 다르지만, 같은 상품이 존재할 수 있기 때문에 작업 중인 위치까지 비교해서 itemId를 기준으로 작업
      const scannedItemId = getPickingItemId({
        scannedBarcode,
        locationId: workingLocation.id,
        pickingList: SKUInfoForScanning,
      });

      const scannedSKUId = getFormattedSingleSkuId(
        SKUInfoForScanning.find((sku) => sku.id === scannedItemId)?.skuId
      );

      /**
       * 피킹 대상이 아닌 상품 스캔 - 송장에 아예 없는 상품
       * - itemId는 현재 작업 위치를 기준으로 구하기 때문에 skuId와 skuBarcode로 비교하는게 맞다.
       */
      if (
        !SKUInfoForScanning.some(
          (v) =>
            getFormattedSingleSkuId(v.skuId) === scannedBarcode ||
            v.skuBarcode === scannedBarcode
        )
      ) {
        modalOpenHandler.openNoSKUInPickingListMessage(scannedBarcode);
        return;
      }

      // 현재 스캔 중인 위치의 상품이 아닌 상품을 스캔한 경우
      if (!itemIdListByWorkingLocation.some((v) => v === scannedItemId)) {
        modalOpenHandler.openNoSKUInCurrentLocationMessage(
          workingLocation.barcode
        );
        return;
      }

      // 이미 풀카운트인 상품을 또 스캔한 경우
      if (completedList.current.itemIdList.has(scannedItemId)) {
        modalOpenHandler.openOverQuantityScanMessage(scannedSKUId);
        return;
      }

      // 상품 최초 스캔 카운트 1되기 직전에는 송장을 재확인해서 취소여부를 확인함
      isFirstSKUScan.current
        ? checkIsCanceledInvoice()
        : setSKUScanningResult({
            scannedSKUId,
            scannedItemId,
            itemIdListByWorkingLocation,
          });

      function checkIsCanceledInvoice() {
        interpretBarcode(
          { type: "shipping", barCode: invoiceNo },
          {
            onSuccess: ({ data: invoiceData }) => {
              if (invoiceData.shippingStatus === "cancel") {
                modalOpenHandler.openCanceledPickingInvoice(resetPickingState);
                return;
              }

              // 취소되지 않은 경우 피킹 스캔 시작을 알림
              startPickingScanning(
                {},
                {
                  onSuccess: () => {
                    isFirstSKUScan.current = false;

                    setSKUScanningResult({
                      scannedSKUId,
                      scannedItemId,
                      itemIdListByWorkingLocation,
                    });
                  },
                }
              );
            },
          }
        );
      }
    },
    [
      SKUInfoForScanning,
      completedList,
      resetPickingState,
      interpretBarcode,
      isFirstSKUScan,
      itemIdListByWorkingLocation,
      modalOpenHandler,
      invoiceNo,
      setSKUScanningResult,
      startPickingScanning,
      workingLocation,
    ]
  );

  const scanSKU = useCallback(
    (scannedBarcode: string) => {
      if (selectedScanButtonType !== "sku") {
        return;
      }

      checkIsCorrectSKUBarcode({ scannedBarcode });
    },
    [checkIsCorrectSKUBarcode, selectedScanButtonType]
  );

  useScan(scanSKU);

  return {
    isActiveScanSKUButton,
    modalOpenHandler,
    ScanSKUMessageModal: MessageModal,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfStartingPickingScanning,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  };
}
