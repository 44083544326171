import styled, { css } from "styled-components";

import { ToastStatus } from "@sellernote/_shared/src/headlessComponents/useToast";
import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  padding: 0 16px;
  z-index: 99999;

  .toast-block {
    position: absolute;
    display: flex;
    align-items: center;
    left: 40px;
    bottom: 24px;
    width: 480px;
    padding: 9px 17px;
    border-radius: 5px;
    background-color: #333;

    ${mobile(css`
      left: 16px;
      bottom: 72px;
      width: calc(100% - 32px);
    `)}

    ${({ status }: { status: ToastStatus }) => {
      if (status === "success")
        return css`
          background-color: ${COLOR.pointSuccess};
        `;

      if (status === "failure")
        return css`
          background-color: ${COLOR.pointWarning};
        `;
    }}

    .icon {
      margin-right: 8px;
    }

    .message {
      color: #fff;
      ${setFontStyle("SubHead3", "Bold")};
    }
  }
`;

export default {
  container,
};
