import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";

import { InspectionCounterSKU } from "hooks/returning/useSKUCountingForInspection";

import useCompleteInspection from "./useCompleteInspection";

function CompleteInspection({
  returningId,
  counterInProgress,
  resetAfterComplete,
}: {
  returningId: number;
  counterInProgress?: UseCounterDataValue<InspectionCounterSKU>;
  resetAfterComplete: () => void;
}) {
  const {
    canComplete,
    handleCompleteInspectionClick,

    ConfirmModal,

    ResponseHandlerOfSetItemInspectionDone,
  } = useCompleteInspection({
    returningId,
    counterInProgress,
    resetAfterComplete,
  });

  return (
    <>
      <Button
        className="btn-complete"
        label="검수완료"
        size="block"
        theme="primary"
        handleClick={handleCompleteInspectionClick}
        disabled={!canComplete}
      />

      {ConfirmModal}

      {ResponseHandlerOfSetItemInspectionDone}
    </>
  );
}

export default CompleteInspection;
