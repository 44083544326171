import { useMemo, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

import SKUListModal from "./SKUListModal";

export type ReceivingReportedStep = "inspection" | "warehousing";

/**
 * 입고 문제보고
 * reportedStep에 따라 다르게 작동함
 */
function ReportProblem({
  receivingId,
  reportedStep,
}: {
  receivingId: number;
  reportedStep: ReceivingReportedStep;
}) {
  const [isVisibleProblemList, setIsVisibleProblemList] = useState(false);

  const buttonLabel = useMemo(() => {
    if (reportedStep === "inspection") return "검수 마감";
    if (reportedStep === "warehousing") return "입고 마감";
  }, [reportedStep]);

  return (
    <>
      <Button
        label={buttonLabel}
        size="block"
        theme="primary"
        handleClick={() => setIsVisibleProblemList(true)}
      />

      {isVisibleProblemList && (
        <SKUListModal
          closeModal={() => setIsVisibleProblemList(false)}
          receivingId={receivingId}
          reportedStep={reportedStep}
        />
      )}
    </>
  );
}

export default ReportProblem;
