import { Dispatch, memo, SetStateAction } from "react";

import { getCombinedSkuValueForPDAInventory } from "@sellernote/_shared/src/utils/fulfillment/inventory";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import SelectSkuModal from "../../SelectSkuModal";
import { SkuToSelect } from "../../SelectSkuModal/useSelectSkuModal";
import SkuDetailModal from "../../SkuDetailModal";
import useSkuDetail from "./useSkuDetail";
import Styled from "./index.styles";

function SkuDetail({
  skuId,
  skuBarcode,
  availableQty,
  isEmptyLocation,
  selectedSku,
  setSelectedSku,
}: {
  skuId: number | undefined;
  skuBarcode: string | undefined;
  availableQty: number | undefined;
  isEmptyLocation: boolean;
  selectedSku: SkuToSelect | undefined;
  setSelectedSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
}) {
  const {
    skuIdToAdjust,
    skuBarcodeToAdjust,
    ButtonForSku,

    getAdjustableQty,

    isVisibleReSelectButton,

    isVisibleSkuDetailModal,
    handleSkuDetailModalClose,

    isVisibleSelectSkuModal,
    handleSelectSkuModalOpen,
    handleSelectSkuModalClose,

    skuDetail,

    ResponseHandlerOfGettingSkuDetail,
  } = useSkuDetail({
    skuId,
    skuBarcode,
    availableQty,
    isEmptyLocation,
    selectedSku,
  });

  return (
    <>
      <Styled.skuDetail>
        <ListItem
          label="SKU ID / 상품바코드"
          type="withButton"
          value={getCombinedSkuValueForPDAInventory({
            skuId: skuIdToAdjust,
            skuBarcode: skuBarcodeToAdjust,
          })}
          button={ButtonForSku}
        />

        {isVisibleReSelectButton && (
          <TextButton
            theme="gray"
            icon="arrowRight"
            label="SKU ID 재선택"
            size="14px"
            handleClick={handleSelectSkuModalOpen}
          />
        )}

        <ListItem
          label="가용수량"
          type="withContentAlignRight"
          value={getAdjustableQty()}
        />
      </Styled.skuDetail>

      <SkuDetailModal
        active={isVisibleSkuDetailModal}
        skuDetail={skuDetail?.sku}
        onClose={handleSkuDetailModalClose}
      />

      <SelectSkuModal
        active={isVisibleSelectSkuModal}
        setSelectedSku={setSelectedSku}
        onClose={handleSelectSkuModalClose}
      />

      {ResponseHandlerOfGettingSkuDetail}
    </>
  );
}

export default memo(SkuDetail);
