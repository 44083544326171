import React, { useCallback } from "react";
import { useMemo } from "react";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import { ResponseFailureInfo } from "../../types/common/common";
import { TransType } from "../../types/common/i18n";

import Loading from "../../componentsToMoveToV1/Loading";
import Modal from "../../componentsToMoveToV1/Modal";
import { APP_NAME } from "../../constants";
import Portal from "../Portal";
import Styled from "./index.styles";

export interface QueryResponseHandlerCustomMessage {
  messageType?: "titleOnly" | "bodyOnly" | "titleAndBody";
  title?: React.ReactNode;
  body?: React.ReactNode;
  className?: string;
  actionPositiveLabel?: string;
  /**
   * 성공 응답 내용에 따라 특정 경우에만 성공모달을 표시해야하는 경우에 사용
   */
  disabledSuccessModal?: boolean;

  /**
   * titleOnly일 때 제목 또는 확인 버튼만 pointWarning으로 표시해야하는 경우에 사용
   */
  isWebNegativeTitle?: boolean;
  isWebNegativeActionPositive?: boolean;
}

export interface QueryResponseHandlerSuccessModalInfo<
  SuccessResData = unknown
> {
  handleConfirmSuccess: (initQuery: () => void) => void;
  customizeMessage?: (
    res?: SuccessResData
  ) => QueryResponseHandlerCustomMessage;
  barcodeValue?: string;
}

export interface QueryResponseHandlerFailureModalInfo {
  handleConfirmFailure?: (failureInfo?: ResponseFailureInfo) => void;
  customizeMessage?: (
    failureInfo?: ResponseFailureInfo
  ) => QueryResponseHandlerCustomMessage;
  barcodeValue?: string;
}

export type QueryResponseHandlerConfirmModalStatus =
  | "error"
  | "success"
  | undefined;

/**
 * TODO: 당장 필요한 것만 구현해 놓음. 필요한것은 QueryResponseHandler 참고하여 그때 그때 구현할 예정
 */
export default function QueryResponseHandler<SuccessResData>({
  isLoading,
  loadingLabel,
  hidesLoading,
  hidesFailureModal,
  successResData,
  successModalInfo,
  failureModalInfo,
  failureInfo,
  Trans,
  confirmModalStatus,
  initConfirmModalStatus,
  initQuery,
  className,
}: {
  isLoading: boolean;
  loadingLabel?: React.ReactNode;
  hidesLoading?: boolean;
  /**
   * 오류/실패 모달을 숨기고 싶을때 사용
   *
   * (ex. 견적조회의 경우 404가 에러가 아니라 오류 모달을 띄워선 안 된다)
   */
  hidesFailureModal?: boolean;
  successResData?: SuccessResData;
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<SuccessResData>;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  failureInfo?: ResponseFailureInfo;
  Trans?: TransType;
  confirmModalStatus: QueryResponseHandlerConfirmModalStatus;
  initConfirmModalStatus: () => void;
  initQuery?: () => void;
  className?: string;
}) {
  const customFailureMessage = failureModalInfo?.customizeMessage
    ? failureModalInfo.customizeMessage(failureInfo)
    : undefined;

  function getBarcodeValues(
    failureModalInfo?: QueryResponseHandlerFailureModalInfo
  ) {
    return failureModalInfo?.barcodeValue
      ? { actionPositive: failureModalInfo?.barcodeValue }
      : undefined;
  }

  const getTitle = useCallback(
    (customFailureMessage?: QueryResponseHandlerCustomMessage) => {
      if (customFailureMessage?.messageType === "bodyOnly") {
        return "";
      }

      return (
        customFailureMessage?.title ||
        (Trans ? (
          <Trans i18nKey="components:requestResponseHandler.failureMessageTitle" />
        ) : (
          "요청을 처리하는 도중 오류가 발생했습니다."
        ))
      );
    },
    [Trans]
  );

  const getBody = useCallback(
    (customFailureMessage?: QueryResponseHandlerCustomMessage) => {
      if (customFailureMessage?.messageType === "titleOnly") {
        return "";
      }

      return (
        customFailureMessage?.body ||
        (Trans ? (
          <Trans i18nKey="components:requestResponseHandler.failureMessageBody" />
        ) : (
          "고객센터에 문의해주세요."
        ))
      );
    },
    [Trans]
  );

  const SuccessModal = useMemo(() => {
    if (confirmModalStatus !== "success" || !successModalInfo) return null;

    const customSuccessMessage = successModalInfo?.customizeMessage
      ? successModalInfo.customizeMessage(successResData)
      : undefined;

    if (customSuccessMessage?.disabledSuccessModal) return null;

    const title =
      customSuccessMessage?.messageType !== "bodyOnly"
        ? customSuccessMessage?.title ||
          (Trans ? (
            <Trans i18nKey="components:requestResponseHandler.successMessageTitle" />
          ) : (
            "처리되었습니다."
          ))
        : "";
    const body =
      customSuccessMessage?.messageType !== "titleOnly"
        ? customSuccessMessage?.body || ""
        : "";
    const barcodeValues = successModalInfo.barcodeValue
      ? { actionPositive: successModalInfo.barcodeValue }
      : undefined;

    const handleConfirm = () => {
      successModalInfo.handleConfirmSuccess(() => {
        initConfirmModalStatus();
        if (initQuery) initQuery();
      });
    };

    if (customSuccessMessage?.messageType === "titleOnly") {
      return (
        <Modal
          barcodeValues={barcodeValues}
          className={`${customSuccessMessage.className || ""} success-modal`}
          active={true}
          uiType="titleOnly"
          title={title}
          actionPositive={{
            label:
              customSuccessMessage?.actionPositiveLabel ||
              (Trans ? <Trans i18nKey="common:confirm" /> : "확인"),
            handleClick: handleConfirm,
          }}
          isWebNegativeTitle={customSuccessMessage?.isWebNegativeTitle}
          isWebNegativeActionPositive={
            customSuccessMessage?.isWebNegativeActionPositive
          }
        />
      );
    }

    return (
      <Modal
        barcodeValues={barcodeValues}
        className={`success-modal`}
        active={true}
        uiType="content"
        title={title}
        body={body}
        actionPositive={{
          label: Trans ? <Trans i18nKey="common:confirm" /> : "확인",
          handleClick: handleConfirm,
        }}
      />
    );
  }, [
    confirmModalStatus,
    successModalInfo,
    successResData,
    Trans,
    initConfirmModalStatus,
    initQuery,
  ]);

  const FailureModal = useMemo(() => {
    if (confirmModalStatus !== "error") return null;

    if (hidesFailureModal) return null;

    const isBofulAppForWorker =
      APP_NAME === "boful-worker-pda" || APP_NAME === "boful-worker-web";

    const title = isBofulAppForWorker ? (
      <>
        {getTitle(customFailureMessage)}
        <br />
        <div style={{ color: COLOR.grayScale_600 }}>{failureInfo?.error}</div>
      </>
    ) : (
      getTitle(customFailureMessage)
    );

    const handleConfirm = () => {
      initConfirmModalStatus();

      if (initQuery) initQuery();

      if (failureModalInfo?.handleConfirmFailure)
        failureModalInfo.handleConfirmFailure(failureInfo);
    };

    if (customFailureMessage?.messageType === "titleOnly") {
      return (
        <Modal
          barcodeValues={getBarcodeValues(failureModalInfo)}
          className={`${customFailureMessage.className || ""} failure-modal`}
          active={true}
          uiType="titleOnly"
          title={title}
          actionPositive={{
            label:
              customFailureMessage?.actionPositiveLabel ||
              (Trans ? <Trans i18nKey="common:confirm" /> : "확인"),
            handleClick: handleConfirm,
          }}
          isWebNegativeTitle={customFailureMessage?.isWebNegativeTitle}
          isWebNegativeActionPositive={
            customFailureMessage?.isWebNegativeActionPositive
          }
        />
      );
    }

    return (
      <Modal
        barcodeValues={getBarcodeValues(failureModalInfo)}
        className={`failure-modal`}
        active={true}
        uiType="content"
        title={title}
        body={getBody(customFailureMessage)}
        actionPositive={{
          label: Trans ? <Trans i18nKey="common:confirm" /> : "확인",
          handleClick: handleConfirm,
        }}
      />
    );
  }, [
    confirmModalStatus,
    hidesFailureModal,
    getTitle,
    customFailureMessage,
    failureInfo,
    failureModalInfo,
    getBody,
    Trans,
    initConfirmModalStatus,
    initQuery,
  ]);

  return (
    <Portal selector="#app-portal">
      <Styled.container
        className={`${className ? className : ""} query-response-handler`}
      >
        {isLoading && !hidesLoading && (
          <Loading label={loadingLabel} active={isLoading} />
        )}

        {FailureModal}

        {SuccessModal}
      </Styled.container>
    </Portal>
  );
}
