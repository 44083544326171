import React, { useMemo, useState } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { BofulWorker } from "@sellernote/_shared/src/types/fulfillment/auth";
import { BofulProblem } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { BOFUL_PROBLEM_OPTION_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";
import InputTextArea from "@sellernote/_sds-v1/src/components/input/InputTextArea";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import SkuIdForReportingProblem from "components/SkuIdForReportingProblem";

import { ReturningReportedStep } from "..";
import Styled from "../index.styles";
import ButtonForReporting from "./ButtonForReporting";

type ItemListToReportTableItem =
  | {
      skuId: React.ReactNode;
      counter: React.ReactNode;
      manager: string;
      problem: React.ReactNode;
    }
  | Record<string, unknown>;

export type BofulProblemDict = {
  [itemId in string]: BofulProblemDictValue;
};

export type BofulProblemDictValue = {
  type: InputSelectOption<BofulProblem> | undefined;
  directInput?: string;
};

function SKUProblem({
  selectedProblem,
  setProblem,
}: {
  selectedProblem?: InputSelectOption<BofulProblem>;
  setProblem: (problem: InputSelectOption<BofulProblem>) => void;
}) {
  return (
    <InputSelect<BofulProblem>
      placeholder="선택"
      uiType="outline"
      optionList={BOFUL_PROBLEM_OPTION_LIST}
      selectedOption={selectedProblem}
      handleSelect={(problem) => setProblem(problem)}
      width="100%"
    />
  );
}

function getCounterByReportedStep({
  reportedStep,
  quantity,
  actualQty,
  placeQty,
}: {
  reportedStep: ReturningReportedStep;
  quantity?: number;
  actualQty?: number;
  placeQty?: number;
}) {
  if (reportedStep === "inspection") {
    return getStyledSKUCount({
      currentCount: actualQty,
      goalCount: quantity,
    });
  }

  if (reportedStep === "warehousing") {
    return getStyledSKUCount({
      currentCount: placeQty,
      goalCount: actualQty,
    });
  }
}

function getManagerNameByReportedStep({
  reportedStep,
  getWorkerById,
  inspectorId,
  placerIds,
}: {
  reportedStep: ReturningReportedStep;
  getWorkerById: (workerId?: number | undefined) => BofulWorker | undefined;
  inspectorId: number | undefined;
  placerIds: number[];
}) {
  if (reportedStep === "inspection") {
    return getWorkerById(inspectorId)?.name || "";
  }

  if (reportedStep === "warehousing") {
    if (!placerIds.length) return "";

    const isMultiWarehousing = placerIds.length > 1;

    const workerIds = [...new Set(placerIds)];

    const isMultiWorker = workerIds.length > 1;

    const titleWorkerName = workerIds ? getWorkerById(workerIds[0])?.name : "";

    return isMultiWarehousing
      ? `[분할] ${titleWorkerName}${
          isMultiWorker ? ` 외 ${placerIds.length - 1}` : ""
        }`
      : titleWorkerName;
  }
}

function SKUList({
  closeModal,
  returningId,
  reportedStep,
}: {
  closeModal: () => void;
  returningId: number;
  reportedStep: ReturningReportedStep;
}) {
  const {
    SKUItemsFilteredByQuantityReturning,
    SKUItemsFilteredByActualQtyReturning,
  } = useAppSelector((state) => {
    return {
      SKUItemsFilteredByQuantityReturning:
        returningSelectors.getSKUItemsFilteredByQuantity(state),
      SKUItemsFilteredByActualQtyReturning:
        returningSelectors.getSKUItemsFilteredByActualQty(state),
    };
  });

  const getWorkerById = useGetWorkerByIdFactory();

  const itemsToReport = useMemo(() => {
    if (reportedStep === "inspection") {
      return SKUItemsFilteredByQuantityReturning.filter((v) => {
        return !v.actualQty || v.quantity !== v.actualQty;
      });
    }

    if (reportedStep === "warehousing") {
      return SKUItemsFilteredByActualQtyReturning.filter((v) => {
        if (!v.placeItems) return true;

        const sumOfPlaceQty = v.placeItems.reduce(
          (totalOfPlaceItems, placeItem) =>
            totalOfPlaceItems + (placeItem.placeQty ?? 0),
          0
        );

        return v.actualQty !== sumOfPlaceQty;
      });
    }

    return [];
  }, [
    reportedStep,
    SKUItemsFilteredByQuantityReturning,
    SKUItemsFilteredByActualQtyReturning,
  ]);

  const [problemDict, setProblemDict] = useState<BofulProblemDict>(
    itemsToReport.reduce((a, c) => {
      a[c.id] = { type: undefined };
      return a;
    }, {} as BofulProblemDict)
  );

  const TableToReport = useMemo(() => {
    const dataList: TableDataListItem<ItemListToReportTableItem>[] = [];

    itemsToReport.forEach((v) => {
      const hasTextArea = problemDict[v.id].type?.value === "directInput";

      const mapped: TableDataListItem<ItemListToReportTableItem> = {
        rowKey: v.id,
        noBorderBottom: hasTextArea,

        skuId: (
          <SkuIdForReportingProblem
            skuId={v.sku.id}
            skuBarcode={v.sku.barCode}
          />
        ),
        counter: getCounterByReportedStep({
          reportedStep,
          quantity: v.quantity,
          actualQty: v.actualQty,
          placeQty: v.placeItems
            ? v.placeItems.reduce((a, c) => {
                return a + (c.placeQty || 0);
              }, 0)
            : 0,
        }),
        manager: getManagerNameByReportedStep({
          reportedStep,
          getWorkerById,
          inspectorId: v.inspectorId,
          placerIds: v.placeItems
            ? v.placeItems.reduce((a: number[], c) => {
                if (c.placerId) {
                  a.push(c.placerId);
                }
                return a;
              }, [])
            : [],
        }),
        problem: (
          <SKUProblem
            selectedProblem={problemDict[v.id]?.type}
            setProblem={(problem) => {
              const newDict = { ...problemDict };
              newDict[v.id] = {
                ...newDict[v.id],
                type: problem,
                directInput: undefined,
              };

              setProblemDict(newDict);
            }}
          />
        ),
      };

      dataList.push(mapped);

      if (hasTextArea) {
        const additional: TableDataListItem<ItemListToReportTableItem> = {
          rowKey: `${v.skuId}-additional`,
          colSpan: {
            value: 4,
            content: (
              <InputTextArea
                value={problemDict[v.id].directInput}
                setValue={(value) => {
                  const newDict = { ...problemDict };
                  newDict[v.id] = {
                    ...newDict[v.id],
                    directInput: value,
                  };

                  setProblemDict(newDict);
                }}
                placeholder="직접입력"
                isAutoResize
                isValidated={!!problemDict[v.id].directInput}
              />
            ),
            hasFullWidth: true,
          },
        };

        dataList.push(additional);
      }
    });

    return (
      <Styled.modalBodyContainer>
        <Table<ItemListToReportTableItem>
          columnInfo={{
            skuId: {
              label: (
                <>
                  SKU ID
                  <br />
                  (상품 바코드)
                </>
              ),
              fixedWidth: 120,
            },
            counter: {
              label: "카운트",
              fixedWidth: 100,
            },
            manager: {
              label: "담당자",
              fixedWidth: 150,
            },
            problem: {
              label: "발생문제 선택",
              fixedWidth: 200,
            },
          }}
          dataList={dataList}
        />

        <ButtonForReporting
          returningId={returningId}
          problemDict={problemDict}
          reportedStep={reportedStep}
        />
      </Styled.modalBodyContainer>
    );
  }, [itemsToReport, returningId, problemDict, reportedStep, getWorkerById]);

  if (!itemsToReport.length) return null;

  return (
    <Modal
      active
      uiType="contentWithCustomBody"
      title={"문제가 발생한 상품(들)을 선택해주세요."}
      body={<>{TableToReport}</>}
      onClose={closeModal}
    />
  );
}

export default SKUList;
