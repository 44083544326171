import { useCallback } from "react";
import { useQueryClient } from "react-query";

import {
  RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ,
  RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ_PATH_PARAMS,
} from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

export default function useResetInspectionOfItem() {
  const queryClient = useQueryClient();

  const {
    mutate: resetInspectionOfItemExceptInspector,
    ResponseHandler: ResponseHandlerOfResetInspectionOfItemExceptInspector,
  } = RECEIVING_QUERY.useResetInspectionOfItemExceptInspector();

  const resetInspectionOfItem = useCallback(
    ({
        receivingId,
        itemId,
        inspectingId,
        resetLocalCount,
      }: RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ &
        RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ_PATH_PARAMS & {
          resetLocalCount: () => void;
        }) =>
      () => {
        if (!receivingId) return;

        resetInspectionOfItemExceptInspector(
          {
            pathParams: {
              receivingId,
              itemId,
            },
            inspectingId,
          },
          {
            onSuccess: () => {
              resetLocalCount();

              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      },
    [queryClient, resetInspectionOfItemExceptInspector]
  );

  return {
    resetInspectionOfItem,
    ResponseHandlerOfResetInspectionOfItemExceptInspector,
  };
}
