import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import Styled from "./index.styles";

// useSKUImageInfoModal와 함께 사용
export default function SKUInfo({
  skuId,
  skuBarcode,
  handleSKUIdClick,
}: {
  skuId: number;
  skuBarcode: string | undefined;
  handleSKUIdClick: (skuId: number) => void;
}) {
  return (
    <Styled.container onClick={() => handleSKUIdClick(skuId)}>
      {getFormattedSingleSkuId(skuId)}{" "}
      {!!skuBarcode && (
        <>
          /
          <br />
          {skuBarcode}
        </>
      )}
    </Styled.container>
  );
}
