import React from "react";
import { useMemo } from "react";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import {
  ApiResponseState,
  ResponseFailureInfo,
  ResponseStatus,
} from "../../types/common/common";
import { TransType } from "../../types/common/i18n";
import { LoadingState, LoadingWithKey } from "../../types/common/loading";

import Loading from "../../componentsToMoveToV1/Loading";
import Modal from "../../componentsToMoveToV1/Modal";
import { APP_NAME } from "../../constants";
import Styled from "./index.styles";

export interface RequestResponseHandlerLoading {
  combinedLoadingState: LoadingState;
  actionType: string;
  actionTypeKey?: number | string;
  label?: React.ReactNode;
  /**
   * loading의 active상태를 수동으로 바꾸고 싶을때 사용
   * (ex. 견적조회할때 네트워크 요청시간보다 로딩시간을 더 주고 싶을때 사용)ㅉㅉ
   */
  activeByForce?: boolean;
}

export interface RequestResponseHandlerCustomMessage {
  messageType?: "titleOnly" | "bodyOnly" | "titleAndBody";
  title?: React.ReactNode;
  body?: React.ReactNode;
  className?: string;
  actionPositiveLabel?: string;
}

export interface RequestResponseHandlerSuccessModalInfo {
  handleConfirmSuccess: () => void;
  customizeMessage?: () => RequestResponseHandlerCustomMessage;
  barcodeValue?: string;
}

export interface RequestResponseHandlerFailureModalInfo {
  handleConfirmFailure?: (failureInfo?: ResponseFailureInfo) => void;
  customizeMessage?: (
    failureInfo?: ResponseFailureInfo
  ) => RequestResponseHandlerCustomMessage;
  barcodeValue?: string;
}

export interface RequestResponseHandlerProps {
  response?: Partial<ApiResponseState<any>>;
  initRequest: () => void;
  loading?: RequestResponseHandlerLoading;
  successModalInfo?: RequestResponseHandlerSuccessModalInfo;
  failureModalInfo?: RequestResponseHandlerFailureModalInfo;
  Trans?: TransType;
  className?: string;
}

export type RequestResponseHandlerType = (
  props: RequestResponseHandlerProps
) => ReturnType<typeof RequestResponseHandler>;

/**
 * HTTP 요청응답에 대한 공통적인 처리(성공확인 모달, 싪패 모달, 로딩 표시)들을 담당하는 컴포넌트
 * - '실패 모달'은 항상 표시되지만, '성공 모달', '로딩'은  각각 success와 loading에 정보를 넘길때만 표시된다.
 */
export default function RequestResponseHandler({
  response,
  initRequest,
  loading,
  successModalInfo,
  failureModalInfo,
  Trans,
  className,
}: RequestResponseHandlerProps) {
  const isLoading = useMemo((): boolean => {
    if (!loading) return false;

    if (loading.activeByForce) return true;

    if (!(loading.combinedLoadingState && loading.actionType)) return false;

    let loadingState = false;

    if (loading.actionTypeKey || typeof loading.actionTypeKey === "number") {
      loadingState = loading.combinedLoadingState[loading.actionType]
        ? (loading.combinedLoadingState[loading.actionType] as LoadingWithKey)[
            loading.actionTypeKey
          ]
        : false;
    } else {
      loadingState = loading.combinedLoadingState[
        loading.actionType
      ] as boolean;
    }

    return loadingState;
  }, [loading]);

  function getTitle(
    customFailureMessage?: RequestResponseHandlerCustomMessage
  ) {
    if (customFailureMessage?.messageType === "bodyOnly") {
      return "";
    }

    return (
      customFailureMessage?.title ||
      (Trans ? (
        <Trans i18nKey="components:requestResponseHandler.failureMessageTitle" />
      ) : (
        "요청을 처리하는 도중 오류가 발생했습니다."
      ))
    );
  }

  function getBody(customFailureMessage?: RequestResponseHandlerCustomMessage) {
    if (customFailureMessage?.messageType === "titleOnly") {
      return "";
    }

    return (
      customFailureMessage?.body ||
      (Trans ? (
        <Trans i18nKey="components:requestResponseHandler.failureMessageBody" />
      ) : (
        "고객센터에 문의해주세요."
      ))
    );
  }

  function getBarcodeValues(
    failureModalInfo?: RequestResponseHandlerFailureModalInfo
  ) {
    return failureModalInfo?.barcodeValue
      ? { actionPositive: failureModalInfo?.barcodeValue }
      : undefined;
  }

  function getSuccessModal({
    responseStatus,
    customSuccessMessage,
    successModalInfo,
  }: {
    responseStatus?: ResponseStatus;
    customSuccessMessage?: RequestResponseHandlerCustomMessage;
    successModalInfo?: RequestResponseHandlerSuccessModalInfo;
  }) {
    if (responseStatus !== "SUCCESS") return null;
    if (!successModalInfo) return null;

    const title =
      customSuccessMessage?.messageType !== "bodyOnly"
        ? customSuccessMessage?.title ||
          (Trans ? (
            <Trans i18nKey="components:requestResponseHandler.successMessageTitle" />
          ) : (
            "처리되었습니다."
          ))
        : "";

    const body =
      customSuccessMessage?.messageType !== "titleOnly"
        ? customSuccessMessage?.body || ""
        : "";

    const barcodeValues = successModalInfo.barcodeValue
      ? { actionPositive: successModalInfo.barcodeValue }
      : undefined;

    if (customSuccessMessage?.messageType === "titleOnly") {
      return (
        <Modal
          barcodeValues={barcodeValues}
          className={`${customSuccessMessage.className || ""} success-modal`}
          active={true}
          uiType="titleOnly"
          title={title}
          actionPositive={{
            label:
              customSuccessMessage?.actionPositiveLabel ||
              (Trans ? <Trans i18nKey="common:confirm" /> : "확인"),
            handleClick: successModalInfo.handleConfirmSuccess,
          }}
        />
      );
    }

    return (
      <Modal
        barcodeValues={barcodeValues}
        className={`success-modal`}
        active={true}
        uiType="content"
        title={title}
        body={body}
        actionPositive={{
          label: Trans ? <Trans i18nKey="common:confirm" /> : "확인",
          handleClick: successModalInfo.handleConfirmSuccess,
        }}
      />
    );
  }

  function getFailureModal({
    responseStatus,
    customFailureMessage,
    initRequest,
    failureModalInfo,
    failureInfo,
  }: {
    responseStatus?: ResponseStatus;
    customFailureMessage?: RequestResponseHandlerCustomMessage;
    initRequest: () => void;
    failureModalInfo?: RequestResponseHandlerFailureModalInfo;
    failureInfo?: ResponseFailureInfo;
  }) {
    if (responseStatus !== "FAILURE") return null;

    const isBofulAppForWorker = APP_NAME === "boful-worker-pda";

    const title = isBofulAppForWorker ? (
      <>
        {getTitle(customFailureMessage)}
        <br />
        <div style={{ color: COLOR.grayScale_600 }}>{failureInfo?.error}</div>
      </>
    ) : (
      getTitle(customFailureMessage)
    );

    if (customFailureMessage?.messageType === "titleOnly") {
      return (
        <Modal
          barcodeValues={getBarcodeValues(failureModalInfo)}
          className={`${customFailureMessage.className || ""} failure-modal`}
          active={true}
          uiType="titleOnly"
          title={title}
          actionPositive={{
            label:
              customFailureMessage?.actionPositiveLabel ||
              (Trans ? <Trans i18nKey="common:confirm" /> : "확인"),
            handleClick: () => {
              initRequest();

              if (failureModalInfo?.handleConfirmFailure)
                failureModalInfo.handleConfirmFailure(failureInfo);
            },
          }}
        />
      );
    }

    return (
      <Modal
        barcodeValues={getBarcodeValues(failureModalInfo)}
        className={`failure-modal`}
        active={true}
        uiType="content"
        title={title}
        body={getBody(customFailureMessage)}
        actionPositive={{
          label: Trans ? <Trans i18nKey="common:confirm" /> : "확인",
          handleClick: () => {
            initRequest();

            if (failureModalInfo?.handleConfirmFailure)
              failureModalInfo.handleConfirmFailure(failureInfo);
          },
        }}
      />
    );
  }

  if (!response && !loading?.combinedLoadingState[loading.actionType])
    return null;

  const customFailureMessage = failureModalInfo?.customizeMessage
    ? failureModalInfo.customizeMessage(response?.failureInfo)
    : undefined;

  const customSuccessMessage = successModalInfo?.customizeMessage
    ? successModalInfo.customizeMessage()
    : undefined;

  if (!isLoading && !response?.status) return null;

  // 요청에 성공했으나 성공시 모달을 지정하지 않은 경우에는 아무것도 표시하지 않는다
  if (!isLoading && response?.status === "SUCCESS" && !successModalInfo)
    return null;

  return (
    <Styled.container
      className={`${className ? className : ""} request-response-handler`}
    >
      {loading && <Loading label={loading?.label} active={isLoading} />}

      {getSuccessModal({
        responseStatus: response?.status,
        customSuccessMessage,
        successModalInfo,
      })}

      {getFailureModal({
        responseStatus: response?.status,
        customFailureMessage,
        initRequest,
        failureModalInfo,
        failureInfo: response?.failureInfo,
      })}
    </Styled.container>
  );
}
