import { Dispatch, SetStateAction, useCallback, useState } from "react";

import useValidationErrorToast from "@sellernote/_shared/src/hooks/common/useValidationErrorToast";
import Toast from "@sellernote/_sds-v1/src/components/Toast";

import useScan from "hooks/common/useScan";

import { ModalType } from "../useCountQuantityModal";

export default function useScanSku({
  type,
  skuId,
  skuBarcode,
  availableQty,
  tempQuantity,
  setTempQuantity,
}: {
  type: ModalType;
  skuId: string;
  skuBarcode: string | undefined;
  availableQty: number;
  tempQuantity: number | undefined;
  setTempQuantity: Dispatch<SetStateAction<number | undefined>>;
}) {
  const [scanResult, setScanResult] = useState<string>();

  const [setValidationError, ValidationErrorToast] =
    useValidationErrorToast(Toast);

  useScan((scanResult) => {
    if (!(scanResult === skuId || scanResult === skuBarcode)) {
      setValidationError({ message: "바코드가 일치하지 않습니다." });
      return;
    }

    // 재고 조정 시에는 수량 입력에 제한이 없음
    const isCompleted =
      type === "adjustment"
        ? (tempQuantity ?? 0) === Number.MAX_SAFE_INTEGER
        : (tempQuantity ?? 0) === availableQty;
    if (isCompleted) {
      setValidationError({ message: "최대 수량을 초과하였습니다." });
      return;
    }

    setTempQuantity((prev) => (prev ?? 0) + 1);

    setScanResult(scanResult);
  });

  const handleTempQuantityReset = useCallback(() => {
    setTempQuantity(undefined);
  }, [setTempQuantity]);

  return {
    scanResult,

    handleTempQuantityReset,

    ValidationErrorToast,
  };
}
