import { useCallback, useEffect, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";

import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  useCounter,
  UseCounterData,
  UseCounterReturn,
} from "@sellernote/_shared/src/utils/common/hook";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { getHasMultiLocationInspection } from "@sellernote/_shared/src/utils/fulfillment/receiving";

type CounterType = "single" | "multi";

export interface InspectionCounterSKU {
  counterKey: string;
  itemId: number;
  skuId: number;
  skuBarcode: string | undefined; // 상품 바코드
  inspectorId?: number;
  inspectingId: string;
  isCompleteInspecting: boolean;
  counterType: CounterType;
}

type CounterForInspection = UseCounterReturn<InspectionCounterSKU>;
export type CounterDataForInspection = UseCounterData<InspectionCounterSKU>;

export interface SKUCountingForInspection {
  skuInProgress: InspectionCounterSKU | undefined;
  setSkuInProgress: (val?: InspectionCounterSKU) => void;
  totalCount: string;
  counter: CounterForInspection;
  reset: (items: ReceivingItem[]) => void;
}

/**
 * 검수 카운팅용(분할검수용) 키를 생성.
 * (분할검수를 가능하기 위해 skuId와 inspectingId를 조합)
 */
export function getCounterKeyForMultiLocationInspection(
  skuId: number,
  inspectingId: string
) {
  return `S${skuId}-${inspectingId}`;
}

/**
 * 입고 카운팅용(일반검수용) 키를 생성(skuId).
 */
export function getCounterKeyForSingleLocationInspection(skuId: number) {
  return `S${skuId}`;
}

export function getCounterKeyFromScanResultByInspectingIdInProgress({
  counterData,
  scanResult,
  inspectingIdInProgress,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
  inspectingIdInProgress: string | undefined;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      (getFormattedSingleSkuId(counter.skuId) === scanResult ||
        counter.skuBarcode === scanResult) &&
      counter.inspectingId === inspectingIdInProgress
  )?.counterKey;
}

export function getIncompleteSingleLocationSKUListBySkuBarcode({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).filter(
    (counter) =>
      counter.skuBarcode === scanResult &&
      !counter.isCompleteInspecting &&
      counter.counterType === "single"
  );
}

export function getIncompleteSingleLocationCounterKeyFromScanResult({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      counter.skuBarcode === scanResult &&
      !counter.isCompleteInspecting &&
      counter.counterType === "single"
  )?.counterKey;
}

export function getSingleLocationCounterKeyFromScanResult({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      (getFormattedSingleSkuId(counter.skuId) === scanResult ||
        counter.skuBarcode === scanResult) &&
      counter.counterType === "single"
  )?.counterKey;
}

export function checkHasSkuBarcodeInMultiLocation({
  counterData,
  skuId,
}: {
  counterData: SKUCountingForInspection;
  skuId: number;
}) {
  return !!Object.values(counterData.counter.counterInfo).find(
    (counter) => counter.skuId === skuId
  )?.skuBarcode;
}

export function checkHasNotSkuBarcode(counterData: SKUCountingForInspection) {
  return Object.values(counterData.counter.counterInfo).some(
    (counter) => !counter.skuBarcode
  );
}

export default function useSKUCountingForInspection(
  items: ReceivingItem[] | undefined
): SKUCountingForInspection {
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const counter = useCounter<InspectionCounterSKU>();

  const [skuInProgress, setSkuInProgress] = useState<InspectionCounterSKU>();

  const initCounter = useCallback(
    ({
      counter,
      receivingItemList,
      needReset,
    }: {
      counter: CounterForInspection;
      receivingItemList: ReceivingItem[] | undefined;
      needReset?: boolean; // 이전 local에서 카운팅했던 값을 무시할때 사용
    }) => {
      if (!receivingItemList) return;
      const newInfo: CounterDataForInspection = {};

      receivingItemList.forEach((v) => {
        const hasMultiLocationInspection = getHasMultiLocationInspection(
          v.quantity,
          v.inspectItems
        );

        v.inspectItems.forEach((ii) => {
          if (!v.skuId || !ii.inspectingId) return;

          // 일반검수와 분할검수의 바코드 형식을 다르게 함
          const counterKey = hasMultiLocationInspection
            ? getCounterKeyForMultiLocationInspection(v.sku.id, ii.inspectingId)
            : getCounterKeyForSingleLocationInspection(v.sku.id);

          const previousCountInfo =
            !needReset && counter.counterInfo
              ? counter.counterInfo[counterKey]
              : null;

          newInfo[counterKey] = {
            counterKey,
            itemId: v.id,
            skuId: v.skuId,
            skuBarcode: v.sku.barCode,
            inspectorId: ii.inspectorId,
            inspectingId: ii.inspectingId,
            isCompleteInspecting: ii.isCompleteInspecting,
            counterType: hasMultiLocationInspection ? "multi" : "single",
            max: ii.quantity,
            current: previousCountInfo?.current || ii.actualQty,
          };
        });
      });

      counter.initCounterInfo(newInfo);
    },
    []
  );

  useEffect(() => {
    initCounter({ counter, receivingItemList: items });
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, initCounter]);

  useEffect(() => {
    if (skuInProgress) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [skuInProgress, setCanNotLeavePage]);

  useEffect(() => {
    if (skuInProgress) {
      const target = counter.counterInfo[skuInProgress.counterKey];
      setSkuInProgress(target);
    } else {
      setSkuInProgress(undefined);
    }
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuInProgress]);

  const getTotalCount = useCallback(
    (counter: CounterDataForInspection, list: ReceivingItem[] | undefined) => {
      if (!list) return "";

      let sum = 0;
      let total = 0;

      Object.values(counter).forEach((v) => {
        sum += v.current || 0;
      });

      list.forEach((v) => {
        total += v.quantity || 0;
      });

      return `${sum} / ${total}`;
    },
    []
  );

  const totalCount = useMemo(
    () => getTotalCount(counter.counterInfo, items),
    [items, counter.counterInfo, getTotalCount]
  );

  const reset = useCallback(
    (items: ReceivingItem[]) => {
      setSkuInProgress(undefined);

      initCounter({
        counter,
        receivingItemList: items,
        needReset: true,
      });
    },
    [counter, initCounter]
  );

  return {
    skuInProgress,
    setSkuInProgress,
    counter,
    totalCount,
    reset,
  };
}
