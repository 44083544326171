import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  useCounter,
  UseCounterData,
  UseCounterReturn,
} from "@sellernote/_shared/src/utils/common/hook";
import { getLocationBarcodeById } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { getHasMultiLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";

type CounterType = "single" | "multi";

export interface WarehousingCounterSKU {
  counterKey: string;
  itemId: number;
  skuId: number;
  skuBarcode: string | undefined; // 상품 바코드
  locationBarcode: string;
  placerId?: number;
  placingId: string;
  isCompletePlacing: boolean;
  counterType: CounterType;
}

type CounterForWarehousing = UseCounterReturn<WarehousingCounterSKU>;
export type CounterDataForWarehousing = UseCounterData<WarehousingCounterSKU>;

export interface SKUCountingForWarehousing {
  skuInProgress: WarehousingCounterSKU | undefined;
  setSkuInProgress: (val?: WarehousingCounterSKU) => void;
  totalCount: string;
  counter: CounterForWarehousing;
  reset: (items: ReceivingItem[]) => void;
}

/**
 * 입고 카운팅용(분할입고용) 키를 생성.
 * (분할입고를 가능하기 위해 skuId와 placingId를 조합)
 */
export function getCounterKeyForMultiLocationWarehousing(
  skuId: number,
  placingId: string
) {
  return `S${skuId}-${placingId}`;
}

/**
 * 입고 카운팅용(일반입고용) 키를 생성(skuId).
 */
export function getCounterKeyForSingleLocationWarehousing(skuId: number) {
  return `S${skuId}`;
}

export function getCounterKeyFromScanResultByPlacingIdInProgress({
  counterData,
  scanResult,
  placingIdInProgress,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
  placingIdInProgress: string | undefined;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      (getFormattedSingleSkuId(counter.skuId) === scanResult ||
        counter.skuBarcode === scanResult) &&
      counter.placingId === placingIdInProgress
  )?.counterKey;
}

export function getIncompleteSingleLocationSKUListBySkuBarcode({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).filter(
    (counter) =>
      counter.skuBarcode === scanResult &&
      !counter.isCompletePlacing &&
      counter.counterType === "single"
  );
}

export function getIncompleteSingleLocationCounterKeyFromScanResult({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      counter.skuBarcode === scanResult &&
      !counter.isCompletePlacing &&
      counter.counterType === "single"
  )?.counterKey;
}

export function getSingleLocationCounterKeyFromScanResult({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      (getFormattedSingleSkuId(counter.skuId) === scanResult ||
        counter.skuBarcode === scanResult) &&
      counter.counterType === "single"
  )?.counterKey;
}

export default function useSKUCountingForWarehousing(
  items: ReceivingItem[] | undefined
): SKUCountingForWarehousing {
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const counter = useCounter<WarehousingCounterSKU>();

  const [skuInProgress, setSkuInProgress] = useState<WarehousingCounterSKU>();

  const initCounter = useCallback(
    ({
      counter,
      receivingItemList,
      needReset,
    }: {
      counter: CounterForWarehousing;
      receivingItemList: ReceivingItem[] | undefined;
      needReset?: boolean; // 이전 local에서 카운팅했던 값을 무시할때 사용
    }) => {
      if (!receivingItemList) return;

      const newInfo: CounterDataForWarehousing = {};

      receivingItemList.forEach((v) => {
        const hasMultiLocationWarehousing = getHasMultiLocationWarehousing(
          v.actualQty,
          v.placeItems
        );

        v.placeItems.forEach((pi) => {
          if (!v.skuId || !pi.placingId) return;

          // 일반입고와 분할입고의 바코드 형식을 다르게 함
          const counterKey = hasMultiLocationWarehousing
            ? getCounterKeyForMultiLocationWarehousing(v.sku.id, pi.placingId)
            : getCounterKeyForSingleLocationWarehousing(v.sku.id);

          const previousCountInfo =
            !needReset && counter.counterInfo
              ? counter.counterInfo[counterKey]
              : null;

          newInfo[counterKey] = {
            counterKey,
            itemId: v.id,
            skuId: v.skuId,
            skuBarcode: v.sku.barCode,
            locationBarcode: getLocationBarcodeById({
              locationList: locationListOfWarehouse,
              locationId: pi.locationId,
            }),
            placerId: pi.placerId,
            placingId: pi.placingId,
            isCompletePlacing: pi.isCompletePlacing,
            counterType: hasMultiLocationWarehousing ? "multi" : "single",
            max: pi.quantity,
            current: previousCountInfo?.current || pi.placeQty,
          };
        });
      });

      counter.initCounterInfo(newInfo);
    },
    [locationListOfWarehouse]
  );

  useEffect(() => {
    initCounter({ counter, receivingItemList: items });
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, initCounter]);

  useEffect(() => {
    if (skuInProgress) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [skuInProgress, setCanNotLeavePage]);

  useEffect(() => {
    if (skuInProgress) {
      const target = counter.counterInfo[skuInProgress.counterKey];
      setSkuInProgress(target);
    } else {
      setSkuInProgress(undefined);
    }
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuInProgress]);

  const getTotalCount = useCallback(
    (counter: CounterDataForWarehousing, list: ReceivingItem[] | undefined) => {
      if (!list) return "";

      let sum = 0;
      let total = 0;

      Object.values(counter).forEach((v) => {
        sum += v.current || 0;
      });

      list.forEach((v) => {
        total += v.actualQty || 0;
      });

      return `${sum} / ${total}`;
    },
    []
  );

  const totalCount = useMemo(
    () => getTotalCount(counter.counterInfo, items),
    [getTotalCount, counter.counterInfo, items]
  );

  const reset = useCallback(
    (items: ReceivingItem[]) => {
      setSkuInProgress(undefined);

      initCounter({
        counter,
        receivingItemList: items,
        needReset: true,
      });
    },
    [counter, initCounter]
  );

  return {
    skuInProgress,
    setSkuInProgress,
    counter,
    totalCount,
    reset,
  };
}
