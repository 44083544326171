import { FC, useCallback, useEffect, useState } from "react";

type ToastStatus = "success" | "failure";
// SDS V2에서는 Toast -> Snackbar
type SnackbarStatus = "neutral" | "positive" | "negative" | "informative";

type SnackbarResetProps = () => void;

type SnackbarMessageProps = string;

type SnackbarButtonProps = {
  onClick?: () => void;
  label?: string;
};

type SnackbarCommonProps = {
  message: SnackbarMessageProps;
  reset: SnackbarResetProps;
  className?: string;
};

type SnackbarWithOnlyMessage = {
  uiType: "onlyMessage";
  status: "neutral";
};

type SnackbarWithMessageWithIcon = {
  uiType: "messageWithIcon";
  status: "positive" | "negative" | "informative";
};

type SnackbarWithClearIcon = {
  uiType: "clearIcon";
  status: SnackbarStatus;
};

type SnackbarWithButton = {
  uiType: "button";
  status: SnackbarStatus;
  buttonInfo?: SnackbarButtonProps;
};

type SnackbarWithClearIconAndButton = {
  uiType: "clearIconWithButton";
  status: "neutral";
  buttonInfo?: SnackbarButtonProps;
};

type SnackbarProps = SnackbarCommonProps &
  (
    | SnackbarWithOnlyMessage
    | SnackbarWithMessageWithIcon
    | SnackbarWithClearIcon
    | SnackbarWithButton
    | SnackbarWithClearIconAndButton
  );

interface ToastProps {
  status: ToastStatus;
  message: React.ReactNode;
  reset: SnackbarResetProps;
  needIcon?: boolean;
  className?: string;
}

type ToastComponent = FC<ToastProps>;

function useToast({ reset }: { reset: () => void }) {
  const [isVisibleToast, setIsVisibleToast] = useState(true);

  const disappearToast = useCallback(() => {
    setTimeout(() => {
      setIsVisibleToast(false);

      reset();
    }, 2000);
  }, [reset]);

  useEffect(() => {
    disappearToast();
  }, [disappearToast]);

  return { isVisibleToast, setIsVisibleToast };
}

export default useToast;

export type {
  ToastProps,
  SnackbarProps,
  ToastStatus,
  SnackbarStatus,
  ToastComponent,
};
