import { useEffect, useMemo } from "react";
import { useCallback, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { noop } from "@sellernote/_shared/src/utils/common/etc";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";

import Layout from "containers/Layout";
import { LayoutRefreshInfo } from "containers/Layout/Refresh";
import withRequireAuth from "hocs/withRequireAuth";
import useConfirmModal from "hooks/common/useConfirmModal";
import useGetReceivingDetail from "hooks/receiving/useGetReceivingDetail";
import useScanInspectionSKU from "hooks/receiving/useScanInspectionSKU";
import useSKUCountingForInspection from "hooks/receiving/useSKUCountingForInspection";

import AddPersonInCharge from "./AddPersonInCharge";
import CloseInspection from "./CloseInspection";
import CompleteInspection from "./CompleteInspection";
import PersonInChargeList from "./PersonInChargeList";
import SKUList from "./SKUList";
import Styled from "./index.styles";

function ReceivingInspectionDetail() {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);

  const [canNotLeavePage, setCanNotLeavePage] = useRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );
  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const isCurrentManagerPersonInCharge = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_PERSON_IN_CHARGE
  );
  const isCurrentManagerReceivingManager = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_RECEIVING_MANAGER
  );
  const canCloseInspection = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.CAN_CLOSE_INSPECTION
  );
  const skuItems = useRecoilValue(FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS);

  const {
    data: receivingDetail,
    refetch: refetchReceivingDetail,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail,
  } = useGetReceivingDetail({ receivingId: Number(id) });

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const skuCounting = useSKUCountingForInspection(skuItems);

  const { ResultHandlerOfScanSKU } = useScanInspectionSKU({
    scanType: "single",
    skuCounting,
    receivingId: Number(id),
    setRowInfoToHighlight,
    setSkuInProgress: skuCounting.setSkuInProgress,
    starInspectionAt: receivingDetail?.receiving.startInspectionAt,
  });

  // 검수가 시작된 후에는 파트타이머가 빠져나갈 수 없도록(뒤로가기 할 수 없도록) 함
  useEffect(() => {
    const currentUserIsPartTimer = currentManager?.authority === "partTimer";

    const canNotGoBackPosition =
      currentUserIsPartTimer && isCurrentManagerPersonInCharge;

    if (!canNotLeavePage && canNotGoBackPosition) {
      setCanNotLeavePage(true);
    }
  }, [
    canNotLeavePage,
    setCanNotLeavePage,
    currentManager?.authority,
    isCurrentManagerPersonInCharge,
  ]);

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  const checkUnfinishedInspectionOfMine = useCallback(
    (items: ReceivingItem[] | undefined) => {
      if (!items) return;

      return items.some((item) => {
        if (!item.inspectItems) return false;

        return item.inspectItems.some(
          (ii) =>
            ii.inspectorId === currentManager?.id && !ii.isCompleteInspecting
        );
      });
    },
    [currentManager?.id]
  );

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  useEffect(() => {
    const hasUnfinishedInspectionOfMine = checkUnfinishedInspectionOfMine(
      receivingDetail?.receiving?.items
    );

    if (hasUnfinishedInspectionOfMine) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [
    checkUnfinishedInspectionOfMine,
    receivingDetail?.receiving?.items,
    setCanNotLeavePage,
  ]);

  const refreshInfo: LayoutRefreshInfo = useMemo(() => {
    let confirmMessage = "";

    if (skuCounting.skuInProgress) {
      confirmMessage = `현재 카운트 중인 SKU ID(${getFormattedSingleSkuId(
        skuCounting.skuInProgress.skuId
      )})가 초기화 됩니다`;
    }

    return {
      ...(confirmMessage ? { confirm: { message: confirmMessage } } : {}),
      handleRefresh: async (showSuccessMessage) => {
        const { data } = await refetchReceivingDetail({ throwOnError: true });

        if (!data) {
          return;
        }

        setRowInfoToHighlight(undefined);
        showSuccessMessage();
        skuCounting.reset(data.data.receiving.items);
      },
    };
  }, [skuCounting, refetchReceivingDetail]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);

      // 초기화하면 초기화한 SKU작업이 진행중인 작업이 된다.
      const target = skuCounting.counter.counterInfo[counterKey];
      skuCounting.setSkuInProgress(target);
      setRowInfoToHighlight({ rowKey: target.itemId });
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterRemoveInProgressPersonInCharge = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      skuCounting.setSkuInProgress(undefined);
      setRowInfoToHighlight(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterComplete = useCallback(() => {
    setRowInfoToHighlight(undefined);
    skuCounting.setSkuInProgress(undefined);
  }, [skuCounting, setRowInfoToHighlight]);

  return (
    <Layout
      navigator={{
        title: `${getFormattedReceivingId(
          receivingDetail?.receiving
        )} / ${getTeamLabelForBofulWorker({
          id: receivingDetail?.receiving.team?.id,
          name: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.name,
            maxLength: 10,
            ellipsis: "...",
          }),
          company: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.company,
            maxLength: 10,
            ellipsis: "...",
          }),
        })}`,
      }}
      refreshInfo={refreshInfo}
    >
      <Styled.container>
        {ConfirmModal}

        <div className="header">
          <AddPersonInCharge
            isManager={isCurrentManagerReceivingManager}
            workerList={receivingDetail?.receiving.workerList}
          />

          <Button
            label="상품스캔"
            size="small"
            theme="tertiary"
            handleClick={noop}
          />
        </div>

        <PersonInChargeList
          receivingManagerId={receivingDetail?.receiving.managerId}
          workerList={receivingDetail?.receiving.workerList}
        />

        <SKUList
          receivingId={Number(id)}
          receivingItems={receivingDetail?.receiving.items}
          rowInfoToHighlight={rowInfoToHighlight}
          setRowInfoToHighlight={setRowInfoToHighlight}
          skuCounting={skuCounting}
          counterData={skuCounting.counter.counterInfo}
          addCountByInput={skuCounting.counter.addCountByInput}
          resetAfterRemoveInProgressPersonInCharge={
            resetAfterRemoveInProgressPersonInCharge
          }
          resetCountByCounterKey={resetCountByCounterKey}
          setConfirmModal={setConfirmModal}
        />

        <div className="total-count">
          <span className="label">총 카운트:</span>
          <span className="value">{skuCounting.totalCount}</span>
        </div>

        <div className="footer">
          {canCloseInspection ? (
            <CloseInspection receivingId={Number(id)} />
          ) : (
            <CompleteInspection
              locationType="single"
              receivingId={Number(id)}
              counterInProgress={
                skuCounting.skuInProgress
                  ? skuCounting.counter.counterInfo[
                      skuCounting.skuInProgress.counterKey
                    ]
                  : undefined
              }
              resetAfterComplete={resetAfterComplete}
            />
          )}
        </div>
      </Styled.container>

      {ResponseHandlerOfGettingManagerReceivingDetail}
      {ResultHandlerOfScanSKU}
    </Layout>
  );
}

export default withRequireAuth(ReceivingInspectionDetail);
