import { memo, useEffect, useMemo, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import Styled from "../index.styles";
import useAddPartialInspection from "./useAddPartialInspection";

function AddPartialInspection({
  receivingId,
  receivingItem,
}: {
  receivingId: number;
  receivingItem: ReceivingItem;
}) {
  const {
    addPartialInspectionToItem,
    canAddPartialInspection,
    remainedQtyForInspection,
    ResponseHandlerOfAddingPartialInspectionToItem,
  } = useAddPartialInspection({ receivingId, receivingItem });

  const [visibleInputModal, setVisibleInputModal] = useState(false);

  const [qtyInput, setQtyInput] = useState<number>();

  useEffect(() => {
    if (!visibleInputModal) {
      setQtyInput(undefined);
    }
  }, [visibleInputModal]);

  const qtyInputValidation = useMemo(():
    | { valid: true }
    | { valid: false; errorMsg?: string } => {
    if (!qtyInput) {
      return {
        valid: false,
      };
    }

    if (qtyInput > remainedQtyForInspection) {
      return {
        valid: false,
        errorMsg: "잔여 수량 이하로 입력해주세요.",
      };
    }

    return {
      valid: true,
    };
  }, [qtyInput, remainedQtyForInspection]);

  return (
    <>
      <Button
        className="btn-add"
        theme="secondary"
        size="block"
        label={"분할검수 추가"}
        handleClick={() => setVisibleInputModal(true)}
        disabled={!canAddPartialInspection}
      />

      <Modal
        active={visibleInputModal}
        uiType="contentWithCustomBody"
        title={`${getFormattedSingleSkuId(receivingItem.sku?.id)}(SKU ID)`}
        body={
          <Styled.addPartialModalBody>
            <div className="remained">잔여수량: {remainedQtyForInspection}</div>

            <InputText
              borderType="outline"
              valueType="int"
              label="검수 수량"
              placeholder="입력해주세요."
              value={qtyInput}
              setValue={setQtyInput}
              errorMessage={
                !qtyInputValidation.valid &&
                (
                  qtyInputValidation as {
                    valid: false;
                    errorMsg?: string;
                  }
                ).errorMsg
              }
            />

            <Button
              theme="primary"
              size="normal"
              label="확인"
              disabled={!qtyInputValidation.valid}
              handleClick={addPartialInspectionToItem({
                itemId: receivingItem.id,
                remainedQty: remainedQtyForInspection,
                skuId: receivingItem.sku?.id,
                // validation에서 확인
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                quantity: qtyInput!,
                isInit: false,
                callbackAfterAdd: () => setVisibleInputModal(false),
              })}
            />
          </Styled.addPartialModalBody>
        }
        onClose={() => setVisibleInputModal(false)}
      />

      {ResponseHandlerOfAddingPartialInspectionToItem}
    </>
  );
}

export default memo(AddPartialInspection);
