import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  RECEIVING_PROBLEM_ITEM,
  SET_WAREHOUSING_DONE_REQ_ITEM,
} from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";

import useInputMixQty from "hooks/receiving/useInputMixQty";

import { ReceivingReportedStep } from "..";
import Styled from "../index.styles";
import { BofulProblemDict, BofulProblemDictValue } from ".";

function ButtonForReporting({
  receivingId,
  problemDict,
  reportedStep,
}: {
  receivingId: number;
  problemDict: BofulProblemDict;
  reportedStep: ReceivingReportedStep;
}) {
  const history = useHistory();

  const skuItemsFilteredByActualQty = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS_FILTERED_BY_ACTUAL_QTY
  );

  const {
    mutate: reportInspectionProblem,
    ResponseHandler: ResponseHandlerOfReportingInspectionProblem,
  } = RECEIVING_QUERY.useReportInspectionProblem({ receivingId });

  const {
    mutate: reportWarehousingProblem,
    ResponseHandler: ResponseHandlerOfReportingWarehousingProblem,
  } = RECEIVING_QUERY.useReportWarehousingProblem({ receivingId });

  const {
    mutate: setWarehousingDone,
    ResponseHandler: ResponseHandlerOfSetWarehousingDone,
  } = RECEIVING_QUERY.useSetWarehousingDone({
    receivingId,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.replace("/receiving/warehousing");
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "입고 마감 처리되었습니다.",
      }),
    },
  });

  const {
    handleCheckHasMixQtyModalOpen,

    ResultHandlerOfInputMixQty,
  } = useInputMixQty({ receivingId });

  const [allProblemAreSelected, problemList] = useMemo(() => {
    let allProblemAreSelected = true;
    const problemList: (RECEIVING_PROBLEM_ITEM | undefined)[] = [];

    Object.entries(problemDict).forEach(([key, val]) => {
      if (!hasSelectedProblem(val)) {
        allProblemAreSelected = false;
      }

      const problem: RECEIVING_PROBLEM_ITEM | undefined = val.type
        ? {
            problem: val.type.value,
            itemId: +key,
            ...(val.directInput ? { problemDirectInput: val.directInput } : {}),
          }
        : undefined;

      problemList.push(problem);
    });

    return [allProblemAreSelected, problemList];

    function hasSelectedProblem(val: BofulProblemDictValue) {
      if (!val.type?.value) return false;

      if (val.type.value === "directInput") {
        return !!val.directInput;
      }

      return true;
    }
  }, [problemDict]);

  const handleSubmit = useCallback(() => {
    if (!allProblemAreSelected) return;

    if (reportedStep === "inspection") {
      reportInspectionProblem(
        { problems: problemList as RECEIVING_PROBLEM_ITEM[] },
        { onSuccess: handleCheckHasMixQtyModalOpen }
      );
    }

    if (reportedStep === "warehousing") {
      reportWarehousingProblem(
        { problems: problemList as RECEIVING_PROBLEM_ITEM[] },
        {
          onSuccess: () => {
            const putAwayItems = skuItemsFilteredByActualQty.reduce(
              (a: SET_WAREHOUSING_DONE_REQ_ITEM[], c) => {
                if (c.placeItems) {
                  c.placeItems.forEach((pi) => {
                    a.push({
                      skuId: c.sku?.id,
                      // 입고마감은 모든 입고가 완료된 상태이기 때문에 placeQty가 항상 존재
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      placeQty: pi.placeQty!,
                      placingId: pi.placingId,
                      // 입고마감은 모든 입고가 완료된 상태이기 때문에 locationId가 항상 존재
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      locationId: pi.locationId!,
                    });
                  });
                }

                return a;
              },
              []
            );

            setWarehousingDone({ putAwayItems });
          },
        }
      );
    }
  }, [
    skuItemsFilteredByActualQty,
    allProblemAreSelected,
    handleCheckHasMixQtyModalOpen,
    problemList,
    reportInspectionProblem,
    reportWarehousingProblem,
    reportedStep,
    setWarehousingDone,
  ]);

  return (
    <>
      <Styled.modalButtonContainer>
        <Button
          theme="primary"
          size="normal"
          label="확인"
          handleClick={handleSubmit}
          disabled={!allProblemAreSelected}
        />
      </Styled.modalButtonContainer>

      {reportedStep === "inspection" && (
        <>
          {ResponseHandlerOfReportingInspectionProblem}

          {ResultHandlerOfInputMixQty}
        </>
      )}

      {reportedStep === "warehousing" && (
        <>
          {ResponseHandlerOfReportingWarehousingProblem}

          {ResponseHandlerOfSetWarehousingDone}
        </>
      )}
    </>
  );
}

export default ButtonForReporting;
