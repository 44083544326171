import { useCallback } from "react";

import RadioActiveCheckedIcon from "@sellernote/_sds-v2/src/components/svgIcons/RadioActiveCheckedIcon";
import RadioActiveIcon from "@sellernote/_sds-v2/src/components/svgIcons/RadioActiveIcon";
import RadioDisabledIcon from "@sellernote/_sds-v2/src/components/svgIcons/RadioDisabledIcon";

import Styled from "./index.styles";

export type TabDirection = "horizontal" | "vertical";

export type TabUiType = "box" | "lined" | "rounded" | "text" | "radioCheck";

type TabUiTypeBox = {
  uiType: "box";
  direction: "horizontal" | "vertical";
};

type TabUiTypeLined<T> = {
  uiType: "lined";
  direction: "horizontal";
  /**
   * Figma상에 붉은 색 점으로 표시됨 (탭 내부에 새소식/이벤트가 있을때 등에 사용)
   */
  emphasizedTabs?: T[];
};

type TabUiTypeRounded = {
  uiType: "rounded";
  direction: "horizontal";
};

type TabUiTypeText = {
  uiType: "text";
  direction: "horizontal";
};

type TabUiTypeRadioCheck = {
  uiType: "radioCheck";
  direction: "horizontal";
};

export type TabUiTypeCase<T> =
  | TabUiTypeBox
  | TabUiTypeLined<T>
  | TabUiTypeRounded
  | TabUiTypeText
  | TabUiTypeRadioCheck;

export type TabProps<T> = {
  selectedTab: T;
  onSelect: (value: T) => void;
  tabDataList: TabDataItem<T>[];
  className?: string;
} & TabUiTypeCase<T>;

export interface TabDataItem<T> {
  tabValue: T;
  title: React.ReactNode;
  disabled?: boolean;
}

export default function Tab<T>({
  direction,
  selectedTab,
  onSelect,
  tabDataList,
  className,
  ...propsByUiType
}: TabProps<T>) {
  const getRadioIcon = useCallback(
    ({
      showsRadioIcon,
      disabled,
      selected,
    }: {
      showsRadioIcon: boolean;
      disabled?: boolean;
      selected?: boolean;
    }) => {
      if (!showsRadioIcon) return null;

      const iconWidth = 16;
      const iconHeight = 16;

      if (disabled)
        return <RadioDisabledIcon width={iconWidth} height={iconHeight} />;

      if (selected)
        return <RadioActiveCheckedIcon width={iconWidth} height={iconHeight} />;

      return <RadioActiveIcon width={iconWidth} height={iconHeight} />;
    },
    []
  );

  return (
    <Styled.container className={`${className ? className : ""} tab`}>
      <Styled.tabList uiType={propsByUiType.uiType} direction={direction}>
        {tabDataList.map(({ tabValue, title, disabled }, i) => {
          const RadioIcon = getRadioIcon({
            showsRadioIcon: propsByUiType.uiType === "radioCheck",
            disabled,
            selected: tabValue === selectedTab,
          });

          const isEmphasized = !!(
            propsByUiType.uiType === "lined" &&
            propsByUiType.emphasizedTabs?.includes(tabValue)
          );

          return (
            <Styled.tabItem
              key={`tab-${tabValue}`}
              id={`tab-${tabValue}`}
              onClick={() => onSelect(tabValue)}
              className={tabValue === selectedTab ? "selected" : "enable"}
              uiType={propsByUiType.uiType}
              type="button"
              disabled={disabled}
            >
              {RadioIcon && <div className="radio-icon">{RadioIcon}</div>}

              {title}

              {propsByUiType.uiType === "text" && <div className="divider" />}

              {isEmphasized && <div className="upper-dot" />}

              {propsByUiType.uiType === "lined" && (
                <div className="underline" />
              )}
            </Styled.tabItem>
          );
        })}
      </Styled.tabList>
    </Styled.container>
  );
}
