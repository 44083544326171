import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ReceivingListItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  isTodayOrBeforeToday,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import { getPageSize } from "@sellernote/_shared/src/utils/common/etc";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import {
  getFormattedReceivingId,
  getReceivingExpectedDate,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";
import Paging from "@sellernote/_sds-v1/src/components/Paging";
import TabFilter from "@sellernote/_sds-v1/src/components/TabFilter";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import AssignToMe, { ItemToAssign } from "./AssignToMe";
import Styled from "./index.styles";

type ReceivingTableItem = {
  combinedTitle: React.ReactNode;
  SKU: number;
  PCS: number;
  bidId: number | string;
} & Pick<ReceivingListItem, "dueDate" | "expectedDate">;

type FilterForIsMine = "mine" | "unSet";

const PAGE_UNIT = 10;

function ReceivingConfirm() {
  const [filterForIsMine, setFilterForIsMine] =
    useState<FilterForIsMine>("mine");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemToAssign, setItemToAssign] = useState<ItemToAssign | undefined>();

  const history = useHistory();

  const {
    data: receivingList,
    refetch: refetchReceivingList,
    ResponseHandler: ResponseHandlerOfGettingReceivingList,
  } = RECEIVING_QUERY.useGetManagerReceivingList({
    page: currentPage,
    perPage: PAGE_UNIT,
    mode: "beforeReceiving",
    isMine: filterForIsMine === "mine",
  });

  const tableDataList = useMemo(() => {
    const list = receivingList?.list;

    if (!list) {
      return [];
    }

    return list.map((v, i) => {
      const mapped: TableDataListItem<ReceivingTableItem> = {
        rowKey: v.id,
        handleRowClick: () =>
          filterForIsMine === "mine"
            ? handleClickMyItem(v.id)
            : handleClickUnSetItem({
                id: v.id,
                bidId: v.bidId,
                companyName: v.team?.company,
                pcs: v.totalItem.quantity,
              }),
        rowFontColor: isTodayOrBeforeToday(v.dueDate)
          ? COLOR.pointWarning
          : undefined,

        combinedTitle: getTeamLabelForBofulWorker(v.team),
        bidId: getFormattedReceivingId(v),
        SKU: v.totalItem.sku,
        PCS: v.totalItem.quantity,
        expectedDate: getReceivingExpectedDate(v),
        dueDate: toFormattedDate(v.dueDate, "YYYY-MM-DD"),
      };

      return mapped;
    });

    function handleClickMyItem(id: number) {
      history.push(`${history.location.pathname}/${id}`);
    }

    function handleClickUnSetItem({
      id,
      bidId,
      companyName,
      teamName,
      pcs,
    }: {
      id: number;
      bidId: number | undefined;
      companyName?: string;
      teamName?: string;
      pcs?: number;
    }) {
      setItemToAssign({
        id,
        summary: (
          <>
            {getFormattedReceivingId({ bidId, id })}
            <br />
            {companyName ?? "-"} ({teamName ?? "-"}) / {pcs} PCS
          </>
        ),
      });
    }
  }, [receivingList?.list, filterForIsMine, history]);

  return (
    <Layout
      navigator={{
        title: "의뢰확인 및 입하",
      }}
    >
      <Styled.container>
        <TabFilter<FilterForIsMine>
          filterList={[
            {
              label: `My 의뢰 (${
                filterForIsMine === "mine"
                  ? receivingList?.total
                  : receivingList?.anotherTotal
              })`,
              filter: "mine",
            },
            {
              label: `미배정의뢰 (${
                filterForIsMine === "mine"
                  ? receivingList?.anotherTotal
                  : receivingList?.total
              })`,
              filter: "unSet",
            },
          ]}
          selectedFilter={filterForIsMine}
          setFilter={(filter) => {
            setFilterForIsMine(filter);
            setCurrentPage(0);
          }}
        />

        <Paging
          pageSize={getPageSize(PAGE_UNIT, receivingList?.total)}
          currentPage={currentPage}
          currentPageData={
            <Table<ReceivingTableItem>
              columnInfo={{
                combinedTitle: {
                  label: "회사명 (팀명)",
                  fixedWidth: 120,
                },
                bidId: {
                  label: "의뢰번호",
                },
                SKU: {
                  label: (
                    <>
                      입고상품수
                      <br />
                      (SKU)
                    </>
                  ),
                },
                PCS: {
                  label: (
                    <>
                      입고요청수량
                      <br />
                      (PCS)
                    </>
                  ),
                  fixedWidth: 100,
                },
                expectedDate: {
                  label: "도착요청일",
                  fixedWidth: 120,
                },
                dueDate: {
                  label: "입고마감일",
                  fixedWidth: 100,
                },
              }}
              dataList={tableDataList}
            />
          }
          handleClickPage={setCurrentPage}
          isZeroBasedPage
        />
      </Styled.container>

      <AssignToMe
        item={itemToAssign}
        resetItem={() => setItemToAssign(undefined)}
        fetchList={refetchReceivingList}
      />

      {ResponseHandlerOfGettingReceivingList}
    </Layout>
  );
}

export default withRequireAuth(ReceivingConfirm);
