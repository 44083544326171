import React from "react";

import {
  MutationSideEffectType,
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import { ResponseFailureInfo } from "../../types/common/common";
import { ResponseFailureInfoOfDeleteItems } from "../../types/fulfillment/common";
import { ProductGroup } from "../../types/fulfillment/sku";
import { HOUR_AS_MILLISECONDS } from "../../utils/common/date";
import regEx from "../../utils/common/regEx";

import {
  DELETE_GROUP_SKU_ITEM_REQ,
  DELETE_GROUP_SKU_ITEM_RES,
  DELETE_SINGLE_SKU_ITEM_REQ,
  DELETE_SINGLE_SKU_ITEM_RES,
  POST_GROUP_SKU_ITEM_REQ,
  POST_GROUP_SKU_ITEM_RES,
  SKU_ITEM_INFO_REQ,
  SKU_ITEM_INFO_RES,
} from "../../api-interfaces/boful-api/fulfillment";
import {
  ADD_SKU_ITEM_INFO_RES,
  CHANGE_HETEROMORPHIC_REQ,
  CHANGE_HETEROMORPHIC_REQ_PATH_PARAMS,
  GET_GROUP_SKU_DETAIL_REQ,
  GET_GROUP_SKU_DETAIL_RES,
  GET_GROUP_SKU_LIST_REQ,
  GET_GROUP_SKU_LIST_RES,
  GET_GROUP_SKU_LIST_V2_REQ,
  GET_GROUP_SKU_LIST_V2_RES,
  GET_GROUP_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_GROUP_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_GROUP_SKU_SEARCH_LIST_REQ,
  GET_GROUP_SKU_SEARCH_LIST_RES,
  GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_REQ,
  GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_RES,
  GET_HS_CODE_ENG_NAME_LIST_REQ_PATH_PARAMS,
  GET_HS_CODE_ENG_NAME_LIST_RES,
  GET_PRODUCT_LABEL_PRINTING_DATA_REQ,
  GET_PRODUCT_LABEL_PRINTING_DATA_RES,
  GET_PRODUCT_SEARCH_SUGGESTION_LIST_REQ,
  GET_PRODUCT_SEARCH_SUGGESTION_LIST_RES,
  GET_SINGLE_SKU_DETAIL_REQ,
  GET_SINGLE_SKU_DETAIL_RES,
  GET_SINGLE_SKU_LIST_REQ,
  GET_SINGLE_SKU_LIST_RES,
  GET_SINGLE_SKU_LIST_V2_REQ,
  GET_SINGLE_SKU_LIST_V2_RES,
  GET_SINGLE_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_SINGLE_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_SINGLE_SKU_SEARCH_LIST_REQ,
  GET_SINGLE_SKU_SEARCH_LIST_RES,
  GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_REQ,
  GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_RES,
  UPDATE_SKU_ITEM_INFO_RES,
} from "../../api-interfaces/boful-api/sku";

export const SKU_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/SKU_QUERY" }] as const,

  singleSkuList: () =>
    [{ ...SKU_QUERY_KEY_GEN.all()[0], subScope: "singleSkuList" }] as const,
  getSingleSkuList: (params: GET_SINGLE_SKU_LIST_REQ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.singleSkuList()[0],
        ...params,
        entity: "singleSkuList",
      },
    ] as const,
  getSingleSkuListV2: (params: GET_SINGLE_SKU_LIST_V2_REQ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.singleSkuList()[0],
        ...params,
        entity: "singleSkuListV2",
      },
    ] as const,

  groupSkuList: () =>
    [{ ...SKU_QUERY_KEY_GEN.all()[0], subScope: "groupSkuList" }] as const,
  getGroupSkuList: (params: GET_GROUP_SKU_LIST_REQ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.groupSkuList()[0],
        ...params,
        entity: "groupSkuList",
      },
    ] as const,
  getGroupSkuListV2: (params: GET_GROUP_SKU_LIST_V2_REQ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.groupSkuList()[0],
        ...params,
        entity: "groupSkuListV2",
      },
    ] as const,

  skuSearchList: () =>
    [{ ...SKU_QUERY_KEY_GEN.all()[0], subScope: "skuSearchList" }] as const,
  getSingleSkuSearchList: (params: GET_SINGLE_SKU_SEARCH_LIST_REQ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.skuSearchList()[0],
        ...params,
        entity: "singleSkuSearchList",
      },
    ] as const,
  getGroupSkuSearchList: (params: GET_GROUP_SKU_SEARCH_LIST_REQ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.skuSearchList()[0],
        ...params,
        entity: "groupSkuSearchList",
      },
    ] as const,

  getSingleSkuSearchListForCSVDownload: (
    params: GET_SINGLE_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_REQ
  ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.skuSearchList()[0],
        ...params,
        entity: "singleSkuSearchListForCSVDownload",
      },
    ] as const,
  getGroupSkuSearchListForCSVDownload: (
    params: GET_GROUP_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_REQ
  ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.skuSearchList()[0],
        ...params,
        entity: "groupSkuSearchListForCSVDownload",
      },
    ] as const,

  skuDetail: () =>
    [{ ...SKU_QUERY_KEY_GEN.all()[0], subScope: "skuDetail" }] as const,
  getSingleSkuDetail: (params: GET_SINGLE_SKU_DETAIL_REQ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.skuDetail()[0],
        ...params,
        entity: "singleSkuDetail",
      },
    ] as const,
  getGroupSkuDetail: (params: GET_GROUP_SKU_DETAIL_REQ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.skuDetail()[0],
        ...params,
        entity: "groupSkuDetail",
      },
    ] as const,

  getSingleSkuSearchSuggestionList: (
    params: GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_REQ
  ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "singleSkuSearchSuggestionList",
      },
    ] as const,
  getGroupSkuSearchSuggestionList: (
    params: GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_REQ
  ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "groupSkuSearchSuggestionList",
      },
    ] as const,

  getProductSearchSuggestionList: (
    params: GET_PRODUCT_SEARCH_SUGGESTION_LIST_REQ
  ) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "productSearchSuggestionList",
      },
    ] as const,

  getHsCodeEngNameList: (params: GET_HS_CODE_ENG_NAME_LIST_REQ_PATH_PARAMS) =>
    [
      {
        ...SKU_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "hsCodeEngNameList",
      },
    ] as const,
};

function useGetSingleSkuDetail({
  skuId,
  enabled = true,
  onSuccess,
  onError,
}: GET_SINGLE_SKU_DETAIL_REQ & {
  enabled?: boolean;
  onSuccess?: (data: GET_SINGLE_SKU_DETAIL_RES) => void;
  onError?: (err: ResponseFailureInfo | undefined) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getSingleSkuDetail>,
    GET_SINGLE_SKU_DETAIL_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getSingleSkuDetail({ skuId }),
    requestOptions: {
      method: "get",
      path: `/sku/${skuId}`,
      apiType: "BofulDefault",
    },

    enabled,

    onSuccess,
    onError,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `SKU 정보 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetGroupSkuDetail({
  groupSkuId,
  enabled = true,
  onSuccess,
  onError,
}: GET_GROUP_SKU_DETAIL_REQ & {
  enabled?: boolean;
  onSuccess?: ((data: ProductGroup) => void) | undefined;
  onError?: (err: ResponseFailureInfo | undefined) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getGroupSkuDetail>,
    GET_GROUP_SKU_DETAIL_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getGroupSkuDetail({ groupSkuId }),
    requestOptions: {
      method: "get",
      path: `/group/${groupSkuId}`,
      apiType: "BofulDefault",
    },

    enabled,

    onSuccess,

    onError,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `SKU 정보 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetSingleSkuList({
  onSuccess,
  ...params
}: GET_SINGLE_SKU_LIST_REQ & {
  onSuccess?: (data: GET_SINGLE_SKU_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getSingleSkuList>,
    GET_SINGLE_SKU_LIST_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getSingleSkuList(params),
    requestOptions: {
      method: "get",
      path: "/sku",
      apiType: "BofulDefault",
      params: params,
    },
    failureModalInfo: {
      customizeMessage: () => ({
        title: `단일상품 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },

    onSuccess,
  });

  return { ...queryResult };
}

function useGetSingleSkuListV2({
  onSuccess,
  ...params
}: GET_SINGLE_SKU_LIST_V2_REQ & {
  onSuccess?: (data: GET_SINGLE_SKU_LIST_V2_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getSingleSkuListV2>,
    GET_SINGLE_SKU_LIST_V2_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getSingleSkuListV2(params),
    requestOptions: {
      method: "get",
      path: "/sku/v2",
      apiType: "BofulDefault",
      params: params,
    },
    failureModalInfo: {
      customizeMessage: () => ({
        title: `단일상품 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },

    onSuccess,
  });

  return { ...queryResult };
}

function useGetGroupSkuList({
  onSuccess,
  ...params
}: GET_GROUP_SKU_LIST_REQ & {
  onSuccess: (data: GET_GROUP_SKU_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getGroupSkuList>,
    GET_GROUP_SKU_LIST_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getGroupSkuList(params),
    requestOptions: {
      method: "get",
      path: "/group",
      apiType: "BofulDefault",
      params: params,
    },
    failureModalInfo: {
      customizeMessage: () => ({
        title: `그룹상품 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },

    onSuccess,
  });

  return { ...queryResult };
}

function useGetGroupSkuListV2({
  onSuccess,
  ...params
}: GET_GROUP_SKU_LIST_V2_REQ & {
  onSuccess: (data: GET_GROUP_SKU_LIST_V2_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getGroupSkuListV2>,
    GET_GROUP_SKU_LIST_V2_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getGroupSkuListV2(params),
    requestOptions: {
      method: "get",
      path: "/group/v2",
      apiType: "BofulDefault",
      params: params,
    },
    failureModalInfo: {
      customizeMessage: () => ({
        title: `그룹상품 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },

    onSuccess,
  });

  return { ...queryResult };
}

function useGetSingleSkuSearchList({
  enabled,
  onSuccess,
  onError,
  ...params
}: GET_SINGLE_SKU_SEARCH_LIST_REQ & {
  enabled?: boolean;
  onSuccess?: (data: GET_SINGLE_SKU_SEARCH_LIST_RES) => void;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getSingleSkuSearchList>,
    GET_SINGLE_SKU_SEARCH_LIST_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getSingleSkuSearchList(params),

    enabled,

    requestOptions: {
      method: "get",
      path: "/sku/search",
      apiType: "BofulDefault",
      params,
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `단일 상품 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },

    onSuccess,
    onError,
  });

  return { ...queryResult };
}

function useGetGroupSkuSearchList({
  enabled,
  onSuccess,
  onError,
  ...params
}: GET_GROUP_SKU_SEARCH_LIST_REQ & {
  enabled?: boolean;
  onSuccess?: (data: GET_GROUP_SKU_SEARCH_LIST_RES) => void;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getGroupSkuSearchList>,
    GET_GROUP_SKU_SEARCH_LIST_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getGroupSkuSearchList(params),

    enabled,

    requestOptions: {
      method: "get",
      path: "/group/search",
      apiType: "BofulDefault",
      params,
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `그룹 상품 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },

    onSuccess,
    onError,
  });

  return { ...queryResult };
}

function useGetSingleSkuSearchListForCSVDownload({
  enabled,
  ...params
}: GET_SINGLE_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getSingleSkuSearchListForCSVDownload>,
    GET_SINGLE_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getSingleSkuSearchListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/sku/search",
      apiType: "BofulDefault",
      params: {
        ...params,
        // 고객에게 보여지는 id 에 S가 붙으므로 S를 제거하고 검색.
        id: params.id?.replace(regEx.number, ""),
      },
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetGroupSkuSearchListForCSVDownload({
  enabled,
  ...params
}: GET_GROUP_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getGroupSkuSearchListForCSVDownload>,
    GET_GROUP_SKU_SEARCH_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getGroupSkuSearchListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/group/search",
      apiType: "BofulDefault",
      params: {
        ...params,
        // 고객에게 보여지는 id 에 G가 붙으므로 G를 제거하고 검색.
        id: params.id?.replace(regEx.number, ""),
      },
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useAddSingleSkuItem() {
  const mutation = useAppMutation<SKU_ITEM_INFO_REQ, ADD_SKU_ITEM_INFO_RES>({
    requestOptions: {
      method: "post",
      path: `/sku`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "SKU 등록 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useUpdateSingleSkuItem({ skuId }: { skuId: number }) {
  const mutation = useAppMutation<SKU_ITEM_INFO_REQ, UPDATE_SKU_ITEM_INFO_RES>({
    requestOptions: {
      method: "patch",
      path: `/sku/${skuId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "SKU 수정 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useDeleteSingleSkuItem({
  failureInfoBody,
}: {
  failureInfoBody: (
    failureInfo: ResponseFailureInfoOfDeleteItems
  ) => React.ReactNode;
}) {
  const mutation = useAppMutation<
    DELETE_SINGLE_SKU_ITEM_REQ,
    DELETE_SINGLE_SKU_ITEM_RES
  >({
    requestOptions: {
      method: "delete",
      path: `/sku`,
      apiType: "BofulDefault",
    },

    successModalInfo: {
      customizeMessage: () => ({ title: "삭제완료되었습니다." }),
      handleConfirmSuccess: (initQuery) => initQuery(),
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 422)
          return {
            body: failureInfoBody(failureInfo),
            messageType: "bodyOnly",
          };

        return { title: "단일 상품 삭제 중 오류가 발생했습니다." };
      },
    },
  });

  return { ...mutation };
}

function useAddGroupSkuItem() {
  const mutation = useAppMutation<
    POST_GROUP_SKU_ITEM_REQ,
    POST_GROUP_SKU_ITEM_RES
  >({
    requestOptions: {
      method: "post",
      path: `/group`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "그룹 SKU 등록 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useUpdateGroupSkuItem({ skuId }: { skuId: number }) {
  const mutation = useAppMutation<
    POST_GROUP_SKU_ITEM_REQ,
    POST_GROUP_SKU_ITEM_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/group/${skuId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "SKU 수정 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useDeleteGroupSkuItem({
  failureInfoBody,
}: {
  failureInfoBody: (
    failureInfo: ResponseFailureInfoOfDeleteItems
  ) => React.ReactNode;
}) {
  const mutation = useAppMutation<
    DELETE_GROUP_SKU_ITEM_REQ,
    DELETE_GROUP_SKU_ITEM_RES
  >({
    requestOptions: {
      method: "delete",
      path: `/group`,
      apiType: "BofulDefault",
    },

    successModalInfo: {
      customizeMessage: () => ({ title: "삭제완료되었습니다." }),
      handleConfirmSuccess: (initQuery) => initQuery(),
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 422)
          return {
            body: failureInfoBody(failureInfo),
            messageType: "bodyOnly",
          };

        return { title: "그룹 상품 삭제 중 오류가 발생했습니다." };
      },
    },
  });

  return { ...mutation };
}

function useBindBarcodeToSKUId({ skuId }: { skuId: number | undefined }) {
  const mutation = useAppMutation<{ barCode: string }, SKU_ITEM_INFO_RES>({
    requestOptions: {
      method: "patch",
      path: `/sku/${skuId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "상품 바코드를 SKU ID에 등록하는 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useChangeHeteromorphic({
  type,
  onSuccess,
}: {
  type: "single" | "group";
  onSuccess?: () => void;
}) {
  const mutation = useAppMutation<
    CHANGE_HETEROMORPHIC_REQ,
    unknown,
    CHANGE_HETEROMORPHIC_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ skuId }) =>
        `/${type === "single" ? "sku" : "group"}/heteromorphic/${skuId}`,
      apiType: "BofulDefault",
    },

    onSuccess,
  });

  return { ...mutation };
}

function useGetProductLabelPrintingData(
  sideEffectOptions?: MutationSideEffectType<
    GET_PRODUCT_LABEL_PRINTING_DATA_REQ,
    GET_PRODUCT_LABEL_PRINTING_DATA_RES
  >
) {
  const mutation = useAppMutation<
    GET_PRODUCT_LABEL_PRINTING_DATA_REQ,
    GET_PRODUCT_LABEL_PRINTING_DATA_RES
  >({
    requestOptions: {
      method: "post",
      path: `/sku/print`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,
  });

  return { ...mutation };
}

function useGetSingleSkuSearchSuggestionList({
  enabled,
  onSuccess,
  ...params
}: {
  enabled?: boolean;
  onSuccess?: (data: GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_RES) => void;
} & GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getSingleSkuSearchSuggestionList>,
    GET_SINGLE_SKU_SEARCH_SUGGESTION_LIST_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getSingleSkuSearchSuggestionList(params),

    requestOptions: {
      method: "get",
      path: "/search/suggestion/sku",
      apiType: "BofulDefault",
      params,
    },

    enabled,

    onSuccess,
  });

  return { ...queryResult };
}

function useGetGroupSkuSearchSuggestionList({
  enabled,
  onSuccess,
  ...params
}: {
  enabled?: boolean;
  onSuccess?: (data: GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_RES) => void;
} & GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getGroupSkuSearchSuggestionList>,
    GET_GROUP_SKU_SEARCH_SUGGESTION_LIST_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getGroupSkuSearchSuggestionList(params),

    requestOptions: {
      method: "get",
      path: "/search/suggestion/group",
      apiType: "BofulDefault",
      params,
    },

    enabled,
    onSuccess,
  });

  return { ...queryResult };
}

function useGetProductSearchSuggestionList({
  enabled,
  ...params
}: {
  enabled?: boolean;
} & GET_PRODUCT_SEARCH_SUGGESTION_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getProductSearchSuggestionList>,
    GET_PRODUCT_SEARCH_SUGGESTION_LIST_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getProductSearchSuggestionList(params),
    requestOptions: {
      method: "get",
      path: "/search/suggestion/product",
      apiType: "BofulDefault",
      params,
    },

    enabled,
  });

  return { ...queryResult };
}

/**
 * HS 코드 6자리를 통해서 품목분류명(영문) 리스트를 조회
 */
function useGetHsCodeEngNameList({
  enabled,
  hsCode,
  onSuccess,
}: {
  enabled?: boolean;
  onSuccess?: (data: GET_HS_CODE_ENG_NAME_LIST_RES) => void;
} & GET_HS_CODE_ENG_NAME_LIST_REQ_PATH_PARAMS) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SKU_QUERY_KEY_GEN.getHsCodeEngNameList>,
    GET_HS_CODE_ENG_NAME_LIST_RES
  >({
    queryKey: SKU_QUERY_KEY_GEN.getHsCodeEngNameList({ hsCode }),
    requestOptions: {
      method: "get",
      path: `/sku/hsCode/${hsCode}`,
      apiType: "BofulDefault",
    },

    enabled,

    onSuccess,

    staleTime: HOUR_AS_MILLISECONDS,
    cacheTime: HOUR_AS_MILLISECONDS,
  });

  return { ...queryResult };
}

const SKU_QUERY = {
  useGetSingleSkuDetail,
  useGetGroupSkuDetail,

  useGetSingleSkuList,
  useGetSingleSkuListV2,
  useGetGroupSkuList,
  useGetGroupSkuListV2,

  useGetSingleSkuSearchList,
  useGetGroupSkuSearchList,
  useGetSingleSkuSearchListForCSVDownload,
  useGetGroupSkuSearchListForCSVDownload,

  useAddSingleSkuItem,
  useUpdateSingleSkuItem,
  useDeleteSingleSkuItem,

  useAddGroupSkuItem,
  useUpdateGroupSkuItem,
  useDeleteGroupSkuItem,

  useBindBarcodeToSKUId,

  useChangeHeteromorphic,

  useGetProductLabelPrintingData,

  useGetSingleSkuSearchSuggestionList,
  useGetGroupSkuSearchSuggestionList,

  useGetProductSearchSuggestionList,

  useGetHsCodeEngNameList,
};

export default SKU_QUERY;
