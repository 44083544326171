import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";

import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import { InspectionCounterSKU as ReceivingInspectionCounterSKU } from "../../receiving/useSKUCountingForInspection";
import { WarehousingCounterSKU as ReceivingWarehousingCounterSKU } from "../../receiving/useSKUCountingForWarehousing";
import { InspectionCounterSKU as ReturningInspectionCounterSKU } from "../../returning/useSKUCountingForInspection";
import { WarehousingCounterSKU as ReturningWarehousingCounterSKU } from "../../returning/useSKUCountingForWarehousing";
import Styled from "./index.styles";

type PageType =
  | "receivingInspection"
  | "receivingWarehousing"
  | "returningInspection"
  | "returningWarehousing";

type SkuInprogressValue<Type> = Type extends "receivingInspection"
  ? ReceivingInspectionCounterSKU
  : Type extends "receivingWarehousing"
  ? ReceivingWarehousingCounterSKU
  : Type extends "returningInspection"
  ? ReturningInspectionCounterSKU
  : Type extends "returningWarehousing"
  ? ReturningWarehousingCounterSKU
  : never;

export default function useSelectDuplicateBarcode<Type extends PageType>({
  type,
  setSkuInProgress,
}: {
  type: Type;
  setSkuInProgress: (val: SkuInprogressValue<Type> | undefined) => void;
}) {
  const { returningSKUItems } = useAppSelector((state) => {
    return {
      returningSKUItems: returningSelectors.getSKUItems(state),
    };
  });

  const [isVisibleConfirmSelectionModal, setIsVisibleConfirmSelectionModal] =
    useState(false);
  const [isVisibleSelectSKUIdModal, setIsVisibleSelectSKUIdModal] =
    useState(false);
  const [incompleteDuplicateSKUList, setIncompleteDuplicateSKUList] =
    useState<UseCounterDataValue<SkuInprogressValue<Type>>[]>();
  const [selectedSKUId, setSelectedSKUId] = useState<number>();

  const receivingItems = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS
  );

  const handleConfirmSelectionModalOpen = useCallback(
    (
      incompleteDuplicateSKUIdList: UseCounterDataValue<
        SkuInprogressValue<Type>
      >[]
    ) => {
      setIncompleteDuplicateSKUList(incompleteDuplicateSKUIdList);
      setIsVisibleConfirmSelectionModal(true);
    },
    []
  );

  const handleConfirmSelectionModalClose = useCallback(() => {
    setIsVisibleConfirmSelectionModal(false);
  }, []);

  const handleSelectSKUIdModalOpen = useCallback(() => {
    handleConfirmSelectionModalClose();
    setIsVisibleSelectSKUIdModal(true);
  }, [handleConfirmSelectionModalClose]);

  const handleSelectSKUIdModalClose = useCallback(() => {
    setIsVisibleSelectSKUIdModal(false);
    setIncompleteDuplicateSKUList(undefined);
    setSelectedSKUId(undefined);
  }, []);

  const handleSelectionCompleteClick = useCallback(() => {
    setSkuInProgress(
      incompleteDuplicateSKUList?.find((item) => item.skuId === selectedSKUId)
    );
    handleSelectSKUIdModalClose();
  }, [
    handleSelectSKUIdModalClose,
    incompleteDuplicateSKUList,
    selectedSKUId,
    setSkuInProgress,
  ]);

  const handleOptionClick = useCallback(
    (selectedOption: InputSelectOption<number>) => {
      setSelectedSKUId(selectedOption.value);
    },
    []
  );

  const SKUIdOptionListToSelect = useMemo(
    () =>
      (incompleteDuplicateSKUList ?? []).map((incompleteDuplicateSKU) => {
        const receivingType =
          type === "receivingInspection" || type === "receivingWarehousing";
        const returningType =
          type === "returningInspection" || type === "returningWarehousing";
        const SKUItemsByType = receivingType
          ? receivingItems
          : returningType
          ? returningSKUItems
          : [];

        const itemName = SKUItemsByType.find(
          (item) => item.skuId === incompleteDuplicateSKU.skuId
        )?.sku.itemName;

        return {
          label: `${getFormattedSingleSkuId(
            incompleteDuplicateSKU.skuId
          )} - ${itemName} ${
            incompleteDuplicateSKU.skuBarcode
              ? `(${incompleteDuplicateSKU.skuBarcode})`
              : ""
          }`,
          value: incompleteDuplicateSKU.skuId,
        };
      }),
    [incompleteDuplicateSKUList, receivingItems, returningSKUItems, type]
  );

  const selectedSKUIdOption = useMemo(
    () => SKUIdOptionListToSelect.find((item) => item.value === selectedSKUId),
    [SKUIdOptionListToSelect, selectedSKUId]
  );

  const ConfirmSelectionModal = useMemo(
    () => (
      <Modal
        active={isVisibleConfirmSelectionModal}
        title="상품 바코드가 동일한 상품이 있습니다. 선택하시겠습니까?"
        uiType="titleOnly"
        actionPositive={{
          label: "네",
          handleClick: handleSelectSKUIdModalOpen,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: handleConfirmSelectionModalClose,
        }}
      />
    ),
    [
      handleSelectSKUIdModalOpen,
      handleConfirmSelectionModalClose,
      isVisibleConfirmSelectionModal,
    ]
  );

  const SelectSKUIdModal = useMemo(
    () => (
      <Modal
        active={isVisibleSelectSKUIdModal}
        title="상품 바코드에 해당하는 SKU ID 선택"
        uiType="contentWithCustomBody"
        body={
          <Styled.selectDuplicateBarcodeModalBody>
            <InputSelect
              uiType="outline"
              optionList={SKUIdOptionListToSelect}
              label="SKU ID 선택"
              selectedOption={selectedSKUIdOption}
              handleSelect={handleOptionClick}
            />
            <Button
              theme="primary"
              size="normal"
              label="선택 완료"
              disabled={!selectedSKUId}
              handleClick={handleSelectionCompleteClick}
            />
          </Styled.selectDuplicateBarcodeModalBody>
        }
        onClose={handleSelectSKUIdModalClose}
      />
    ),
    [
      SKUIdOptionListToSelect,
      handleSelectSKUIdModalClose,
      handleSelectionCompleteClick,
      handleOptionClick,
      isVisibleSelectSKUIdModal,
      selectedSKUId,
      selectedSKUIdOption,
    ]
  );

  return {
    handleConfirmSelectionModalOpen,
    ResultHandlerOfSelectDuplicateBarcode: (
      <>
        {ConfirmSelectionModal}
        {SelectSKUIdModal}
      </>
    ),
  };
}
