import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import FILE_QUERY from "@sellernote/_shared/src/queries/fulfillment/FILE_QUERY";
import {
  FileDomainType,
  FulfillmentAttachment,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import {
  getPictureListByDomainType,
  getPictureTypeLabelByDomainType,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getFormattedReturningId } from "@sellernote/_shared/src/utils/fulfillment/returning";
import ImageSliderModal from "@sellernote/_sds-v1/src/components/ImageSliderModal";

import BofulRequestResponseHandler from "containers/BofulRequestResponseHandler";
import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import { returningActions } from "modules/returning";
import { useAppSelector } from "store";

import Styled from "./index.styles";

function PicturesByDomainType() {
  const match = useRouteMatch<{ id: string; domainType: FileDomainType }>();
  const {
    params: { id, domainType },
  } = match;
  const dispatch = useDispatch();

  const { getDetailReturningRes } = useAppSelector((state) => {
    return {
      getDetailReturningRes: state.returning.GET_DETAIL,
    };
  });

  const [imageSliderInfo, setImageSliderInfo] = useState<{
    visible: boolean;
    initialSlideIndex?: number;
  }>({
    visible: false,
  });

  const [imageURLList, setImageURLList] = useState<string[]>([]);

  const {
    mutate: getFileURLList,
    ResponseHandler: ResponseHandlerOfGettingFileURLList,
  } = FILE_QUERY.useGetFileURLList();

  const getImageURLList = useCallback(
    (res: { attachment: FulfillmentAttachment[] }) => {
      const pictureURLList = getPictureListByDomainType(
        res.attachment,
        domainType
      );

      if (pictureURLList.length) {
        getFileURLList(
          {
            key: pictureURLList.map((v) => v.key),
          },
          {
            onSuccess: (res) => {
              setImageURLList(res.data.map((v) => v.url));
            },
          }
        );
      }
    },
    [domainType, getFileURLList]
  );

  const clear = useCallback(() => {
    dispatch(returningActions.INIT_GET_DETAIL({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      returningActions.GET_DETAIL({
        id: +id,

        _postSuccessCallback: getImageURLList,
      })
    );

    return clear;
  }, [clear, dispatch, getImageURLList, id]);

  const imageListTitle = useMemo(() => {
    return getPictureTypeLabelByDomainType(domainType);
  }, [domainType]);

  if (!getDetailReturningRes?.data) return null;

  return (
    <>
      <Layout
        navigator={{
          title: `${getFormattedReturningId(
            getDetailReturningRes?.data?.returning
          )} / ${getTeamLabelForBofulWorker({
            id: getDetailReturningRes?.data?.returning.team?.id,
            name: omitWithEllipsis({
              src: getDetailReturningRes?.data?.returning.team?.name,
              maxLength: 10,
              ellipsis: "...",
            }),
            company: omitWithEllipsis({
              src: getDetailReturningRes?.data?.returning.team?.company,
              maxLength: 10,
              ellipsis: "...",
            }),
          })}`,
        }}
      >
        <Styled.container>
          <div className="title">{imageListTitle} 이미지</div>

          <Styled.thumbnailList>
            {imageURLList.map((v, i) => {
              return (
                <Styled.thumbnail
                  imageUrl={v}
                  key={i}
                  onClick={() =>
                    setImageSliderInfo({ visible: true, initialSlideIndex: i })
                  }
                />
              );
            })}
          </Styled.thumbnailList>
        </Styled.container>
      </Layout>

      <ImageSliderModal
        active={imageSliderInfo.visible}
        initialSlideIndex={imageSliderInfo.initialSlideIndex}
        title={"상세 이미지"}
        imageTitle={imageListTitle}
        imagePaths={imageURLList}
        onClose={() =>
          setImageSliderInfo({ visible: false, initialSlideIndex: 0 })
        }
      />

      <BofulRequestResponseHandler
        response={getDetailReturningRes}
        initRequest={() => {
          dispatch(returningActions.INIT_GET_DETAIL({}));
        }}
        loading={{
          actionType: returningActions.GET_DETAIL.type,
        }}
      />

      {ResponseHandlerOfGettingFileURLList}
    </>
  );
}

export default withRequireAuth(PicturesByDomainType);
