import { css, FlattenInterpolation } from "styled-components";

import {
  MOBILE_MAX_WIDTH,
  MY_PAGE_FOOTER_MAX_WIDTH,
  TABLET_MAX_WIDTH,
} from "../constants/common/common";
import { COLOR } from "../stylesToMoveToV1/constants";

/** 모바일 size용 스타일 */
export function mobile(styles: FlattenInterpolation<any>) {
  return css`
    @media (max-width: ${MOBILE_MAX_WIDTH}px) {
      ${styles}
    }
  `;
}

/**
 * @deprecated 태블릿 사이즈는 지원하지 않으므로 사용하지 말것
 * TODO: 사용코드가 없어지면 삭제
 * 태블릿 size용 스타일
 */
export function tablet(styles: FlattenInterpolation<any>) {
  return css`
    @media (max-width: ${TABLET_MAX_WIDTH}px) {
      ${styles}
    }
  `;
}

/**
 * 마이페이지 > 푸터 size용 스타일
 * TODO: 푸터 리뉴얼시 삭제 예정
 */
export function myPage(styles: FlattenInterpolation<any>) {
  return css`
    @media (max-width: ${MY_PAGE_FOOTER_MAX_WIDTH}px) {
      ${styles}
    }
  `;
}

/**
 * 스크롤 바를 표시하지 않는다
 */
export function hideScrollBar() {
  return css`
    &::-webkit-scrollbar {
      display: none;
    }
  `;
}

/**
 * 세로 스크롤바를 항상 표시한다
 */
export function showVerticalScrollBarAlways() {
  return css`
    &::-webkit-scrollbar:vertical {
      width: 8px;
      background-color: transparent;
      display: block;
    }
    &::-webkit-scrollbar-thumb:vertical {
      background-color: ${COLOR.grayScale_400};
      border: 3px solid transparent;
      border-radius: 9px;
    }
  `;
}

/**
 *
 * showVerticalScrollBarAlways의 효과를 무효화
 */
export function disableSettingOfShowVerticalScrollBarAlways() {
  return css`
    &::-webkit-scrollbar:vertical {
      display: none;
    }
  `;
}
