import useRestockCanceling from "hooks/canceling/useRestockCanceling";

import CompleteRestockingButton from "./CompleteRestockingButton";
import ReportProblemButton from "./ReportProblemButton";
import RestockingTable from "./RestockingTable";
import ScanLocationButton from "./ScanLocationButton";
import ScannedLocation from "./ScannedLocation";
import ScanSKUButton from "./ScanSKUButton";
import Styled from "./index.styles";

export default function RestockInvoice() {
  const {
    workingLocation,
    setWorkingLocation,

    rowInfoToHighlight,
    setRowInfoToHighlight,

    completedList,

    isFirstSKUScan,

    selectedScanButtonType,
    handleScanButtonClick,

    SKUInfoForScanning,
    setSKUInfoForScanning,

    allRestockingAreCompleted,

    moveToCancelingListPage,
  } = useRestockCanceling();

  return (
    <>
      <Styled.restockInvoiceContainer>
        <Styled.scanContainer>
          <ScannedLocation location={workingLocation?.barcode} />

          <Styled.scanButtonsContainer>
            <ScanLocationButton
              selectedScanButtonType={selectedScanButtonType}
              handleScanButtonClick={handleScanButtonClick}
              workingLocation={workingLocation}
              setWorkingLocation={setWorkingLocation}
              SKUInfoForScanning={SKUInfoForScanning}
              completedList={completedList}
            />
            <ScanSKUButton
              selectedScanButtonType={selectedScanButtonType}
              handleScanButtonClick={handleScanButtonClick}
              workingLocation={workingLocation}
              setWorkingLocation={setWorkingLocation}
              setRowInfoToHighlight={setRowInfoToHighlight}
              SKUInfoForScanning={SKUInfoForScanning}
              setSKUInfoForScanning={setSKUInfoForScanning}
              completedList={completedList}
              isFirstSKUScan={isFirstSKUScan}
            />
          </Styled.scanButtonsContainer>
        </Styled.scanContainer>

        <RestockingTable
          selectedScanButtonType={selectedScanButtonType}
          handleScanButtonClick={handleScanButtonClick}
          workingLocation={workingLocation}
          setWorkingLocation={setWorkingLocation}
          rowInfoToHighlight={rowInfoToHighlight}
          SKUInfoForScanning={SKUInfoForScanning}
          setSKUInfoForScanning={setSKUInfoForScanning}
          setRowInfoToHighlight={setRowInfoToHighlight}
          completedList={completedList}
          isFirstSKUScan={isFirstSKUScan}
        />

        {allRestockingAreCompleted ? (
          <CompleteRestockingButton
            SKUInfoForScanning={SKUInfoForScanning}
            moveToCancelingListPage={moveToCancelingListPage}
          />
        ) : (
          <ReportProblemButton
            SKUInfoForScanning={SKUInfoForScanning}
            moveToCancelingListPage={moveToCancelingListPage}
          />
        )}
      </Styled.restockInvoiceContainer>
    </>
  );
}
