import * as SentryReact from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import {
  APP_ENV,
  APP_NAME,
  APP_RESPONSIBILITY,
  IS_UNDER_LOCAL_DEVELOPMENT,
} from "../../constants";

/**
 * CRA용 sentry init 함수.
 * src/index.tsx 에서 호출한다.
 */
export const initSentryForCRA = () => {
  if (IS_UNDER_LOCAL_DEVELOPMENT || !process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  SentryReact.init({
    environment: APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    initialScope: {
      // custom tag
      tags: {
        errorType: "FE",
        appName: APP_NAME,
        responsibility: APP_RESPONSIBILITY.join(", "),
      },
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};
